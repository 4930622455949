import axios from "axios";
import statusCode from "./constants/statusCodes";
import helper from "./helper";

const HTTP = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  headers: {
    "Content-type": "application/json",
    // "Accept-Language": "en-US,en;"
  },
});

HTTP.interceptors.request.use(function (config) {
  const token = localStorage.getItem("access_token");
  if (token) {
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${token}`,
    };
  }
  return config;
});

HTTP.interceptors.response.use(
  (response) => {
    const data = response?.data;
    if (response?.status === statusCode.UN_AUTHORIZED) {
      handleUnauthorizedError();
    } else if (
      data ||
      [statusCode.OK, statusCode.CREATED].includes(data?.status)
    ) {
    } else if (
      data?.error ||
      [statusCode.BAD_REQUEST, statusCode.INTERNAL_SERVER_ERROR].includes(
        data?.status
      )
    ) {
      if (data?.status === statusCode.UN_AUTHORIZED) {
        handleUnauthorizedError();
      }
      console.log("SERVER ERROR");
    }
    return data;
  },
  (error) => {
    if (error.response) {
      if (error.response.status === statusCode.UN_AUTHORIZED) {
        handleUnauthorizedError();
      } else if (
        error.response?.data &&
        error.response.status < statusCode.INTERNAL_SERVER_ERROR
      ) {
        // TODO: Handle error
        return {
          error: error.message,
          statusCode: error.response.status,
          status: error.response.status,
          data: error.response.data,
        };
      }
    }
    throw error;
  }
);

// handle unauthorized requests
const handleUnauthorizedError = async () => {
  // const refresh_token = localStorage.getItem("refresh_token");

  const clearStoragesAndRedirect = () => {
    helper.clearStorages([
      "access_token",
      "role",
      "refresh_token",
      "loglevel",
      "is_superuser",
    ]);
    if (!window.location.pathname.includes("/login")) {
      window.location.href = "/login";
    }
  };

  // if (refresh_token) {
  //   try {
  //     const res = await axios.post('/api/token/refresh/', { refresh: refresh_token });
  //     if (res.status === statusCode.OK) {
  //       localStorage.setItem("access_token", res?.data?.access);
  //       return;
  //     }
  //   } catch (error) {
  //     clearStoragesAndRedirect();
  //   }
  // }
  clearStoragesAndRedirect();
};

export default HTTP;
