/* eslint-disable react-hooks/exhaustive-deps */
import Box from "@mui/material/Box";
import styles from "./styles.module.scss";
import React from "react";
import FormCreateorUpdateOta from "../../../../components/FormCreateorUpdateOta";

export default function ReceiveSuggestions() {

  return (
    <Box component="main" className={styles["box-main"]}>
      <FormCreateorUpdateOta id={null}/>
    </Box>
  );
}
