import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import HTTP from '../http_common';

export const getDetailUser = createAsyncThunk('getDetailUser/getDetailUser', async (id) => {
  const response = await HTTP.get("/api/user/" + id + "/");
  return response;
});
const apiSlice = createSlice({
  name: 'detail-user',
  initialState: {
    data: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDetailUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDetailUser.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getDetailUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;

      });
  },
});

export default apiSlice.reducer;
