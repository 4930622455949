/* eslint-disable jsx-a11y/anchor-is-valid */
import { Box, TextField, Typography } from "@mui/material";
import styles from "./styles.module.scss";
import { useDispatch } from "react-redux";
import { register } from "../../features/auth/authSlice";
import React, { useState } from "react";
import { Formik } from "formik";
import { Button } from "@mui/base";
import * as Yup from "yup";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

export default function RegisterEmail() {
  const dispatch = useDispatch();
  const [isRegisterForm, setIsRegisterForm] = useState(true);
  const [customError, setCustomError] = useState("");
  const formikConfig = {
    initialValues: {
      email: "",
    },
    onSubmit: async (values, { setSubmitting, setErrors }) => {
      dispatch(register(values)).then((res) => {
        setSubmitting(false);
        if (res.payload?.error) {
          if (res.payload?.data?.message) {
            setCustomError(res.payload?.data?.message);
            setErrors("");
          } else {
            setErrors(res.payload?.data);
            setCustomError("");
          }
        } else {
          setIsRegisterForm(false);
        }
      });
    },
    validationSchema: () => {
      return Yup.object({
        email: Yup.string()
          .email("メールアドレスの形式が正しくありません")
          .required("メールアドレスを入力してください。"),
      });
    },
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        pt: 10,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {isRegisterForm ? (
        <>
          <Box className={styles["box-content"]}>
            <Box sx={{ width: "100%" }}>
              <Typography className={styles["content-email"]}>
                新規アカウント作成
              </Typography>
              <Box
                sx={{ marginTop: "24px" }}
                className={styles["content-email"]}
              >
                <Typography className={styles["content-bottom-email"]}>
                  登録に進むとサービス利用規約、
                </Typography>
                <Typography className={styles["content-bottom-email"]}>
                  プライバシーポリシーに同意したことにします。
                </Typography>
                <Typography className={styles["content-bottom-email"]}>
                  有料版のご利用にはクレジットカードの登録が必要です。
                </Typography>
              </Box>
              <Formik
                validateOnChange={false}
                initialValues={formikConfig.initialValues}
                onSubmit={formikConfig.onSubmit}
                validationSchema={formikConfig.validationSchema}
              >
                {({
                  handleChange,
                  handleBlur,
                  values,
                  errors,
                  handleSubmit,
                }) => (
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <TextField
                        error={!!errors.email}
                        className={styles["custom-field"]}
                        id="outlined-basic"
                        name="email"
                        label="メールアドレスを入力"
                        variant="outlined"
                        onChange={handleChange("email")}
                        onBlur={handleBlur("email")}
                        value={values.email}
                        helperText={errors.email}
                      />
                      {customError && (
                        <Box className={styles["custom-error-message"]}>
                          {customError}
                        </Box>
                      )}
                    </Box>
                    <Box className={styles["box-continue"]}>
                      <Button
                        className={styles["text-button"]}
                        onClick={handleSubmit}
                      >
                        Continue
                      </Button>
                    </Box>
                  </>
                )}
              </Formik>
              <Box
                sx={{
                  textAlign: "center",
                  marginTop: "20px",
                  color: "#1A1B35;",
                  fontWeight: "500",
                }}
              >
                アカウントをお持ちですか？&nbsp;
                <Link
                  style={{ textDecoration: "none" }}
                  className={styles["link"]}
                  to="/login"
                >
                  ログイン
                </Link>
              </Box>
            </Box>
          </Box>
        </>
      ) : (
        <>
          <Box className={styles["box-content"]}>
            <Box sx={{ width: "100%" }}>
              <Typography className={styles["content-email"]}>
                メールアドレス認証
              </Typography>
              <Box className={styles["content-email"]}>
                <Typography
                  sx={{ width: "400px" }}
                  className={styles["content-bottom-email"]}
                >
                  入力いただいたメールアドレス宛に、認証メールをお送りしました。
                  <br />
                  メールに記載されているURLをクリックし、登録に進んでください。
                </Typography>
              </Box>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
}
