import { configureStore } from "@reduxjs/toolkit";
import usersReducer from "./features/users/usersSlice";
import noticeReduce from "./features/notifications/noticeSlice";
import authReduce from "./features/auth/authSlice";
import otaReduce from "./features/ota/otasSlice";
import plansSlice from "./features/plans/plansSlice";
import applicationReduce from "./features/application/applicationSlice";
import planGroupSlice from "./features/plan/planGroupSlice";
import recommendPlanSlice from "./features/recommendPlan/recommendPlanSlice";
import reportSlice from "./features/report/reportSlice";
import competitorSlice from "./features/competitor/competitorSlice";

export const store = configureStore({
  reducer: {
    auth: authReduce,
    plans: plansSlice,
    recommendPlan: recommendPlanSlice,
    planGroup: planGroupSlice,
    report: reportSlice,
    users: usersReducer,
    notifications: noticeReduce,
    ota: otaReduce,
    application: applicationReduce,
    competition: competitorSlice,
  },
});
