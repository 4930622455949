/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useDispatch } from "react-redux";
import { fetchLogout } from "../features/apiLogout";
import styles from "./styles.module.scss";
import Popover from "@mui/material/Popover";
import { Avatar, Stack, Box, Button } from "@mui/material";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Input from "@mui/material/Input";
import { useAuth } from "../ProvideAuth";

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export default function Header(props) {
  const { user: currentUser } = useAuth();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const name = !currentUser
    ? ""
    : String(currentUser.first_name) + String(currentUser.last_name);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const openPopover = Boolean(anchorEl);

  const handleLogout = async () => {
    const res = await dispatch(fetchLogout());
    if (res.payload) {
      window.location.href = "/login";
    }
  };

  return (
    <AppBar
      position="static"
      sx={{ background: "#2E4E76", padding: "20px 43px 14px 32px" }}
    >
      <Toolbar sx={{ padding: "0 !important", minHeight: "0 !important" }}>
        {currentUser?.is_superuser ? (
          <Typography component="h1" variant="h6" fontWeight={"bold"}>
            ADMIN
          </Typography>
        ) : (
          <>
            <Input
              id="standard-adornment-password"
              placeholder={currentUser?.hotel_name}
              sx={{
                background: "#F7FAFC",
                padding: "12px 28px",
                position: "relative",
                borderRadius: "25px",
                minWidth: "348px",
                color: "#1A1B35",
                fontWeight: 500,
                mr: "32px",

                "&::before, &::after": {
                  display: "none",
                },

                "& .MuiInputBase-input": {
                  padding: 0,
                  fontSize: "12px",

                  "&::placeholder": {
                    color: "#1A1B35",
                    opacity: 1,
                    fontWeight: 500,
                  },
                },

                "& .MuiInputAdornment-root": {
                  position: "absolute",
                  right: 0,
                },
              }}
            />
            <Box className={styles["box-rate"]}>
              <Typography className={styles["text-title"]}>
                今月のチケット
              </Typography>
              <Typography className={styles["text-number"]}>
                {parseInt(currentUser?.rate) -
                  parseInt(currentUser?.used_time) || 0}
              </Typography>
              /
              <Typography className={styles["text-number-right"]}>
                {currentUser?.rate || 0}
              </Typography>
              <Button
                component={Link}
                to={"/invite"}
                sx={{
                  p: "1px",
                  minWidth: "unset",
                  color: "#7764E4",
                  borderRadius: "50%",
                  background: "#fff",
                  border: "1px solid #7764E4",
                }}
              >
                <AddRoundedIcon />
              </Button>
            </Box>
          </>
        )}

        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
          sx={{ flexGrow: 1 }}
        ></Typography>
        {/* <IconButton
          className={styles["box-button"]}
          color="inherit"
          sx={{ p: 0, fontSize: "21px" }}
        >
          <i className="icon icon-bell"></i>
        </IconButton> */}
        {/* <IconButton
          className={styles["box-button"]}
          color="inherit"
          sx={{ p: 0, mx: "43px", fontSize: "21px" }}
        >
          <i className="icon icon-help-circle"></i>
        </IconButton> */}
        <Stack
          onClick={handleClick}
          sx={{ marginRight: "16px", cursor: "pointer" }}
          direction="row"
          spacing={2}
        >
          <Avatar
            alt="Remy Sharp"
            src={currentUser?.avatar}
            sx={{ width: "53px", height: "53px" }}
          />
        </Stack>
        {!currentUser?.is_superuser && (
          <Popover
            open={openPopover}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <Typography
              onClick={handleLogout}
              sx={{ px: 3, py: 1, cursor: "pointer" }}
            >
              Logout
            </Typography>
          </Popover>
        )}
        <Typography sx={{ fontSize: "12px", fontWeight: 500 }}>
          {name}
        </Typography>
      </Toolbar>
    </AppBar>
  );
}
