/* eslint-disable jsx-a11y/anchor-is-valid */
import { Box, Typography } from "@mui/material";
import styles from "./styles.module.scss";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RELEASE_TYPE, CHECK_STATUS, checkPagination } from "../../constants/index";
import { getListNotice } from "../../features/notifications/noticeSlice";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

export default function ReleaseNote() {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const notifications = useSelector((state) => state.notifications);

  useEffect(() => {
    const dataQuery = {
      category: RELEASE_TYPE,
      page: currentPage,
      status: CHECK_STATUS,
      is_admin_interface: true,
    };
    dispatch(getListNotice(dataQuery));
  }, [currentPage, dispatch]);

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const handlePreviousPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const convertDate = (isoDate) => {
    const date = new Date(isoDate);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}.${month}.${day}`;
  };

  return (
    <>
      <Box className={styles["box-main-content"]}>
        <Box className={styles["box-header"]}>
          <Typography className={styles["title-header"]}>
            ALL RELEASES
          </Typography>
        </Box>
        {notifications?.list?.data?.map((item, index) => {
          const isLastItem = index === notifications?.list?.length - 1;
          const boxContentSx = {
            borderBottom: isLastItem ? "1px solid #3F7EAE" : "0",
          };
          return (
            <>
              <Box
                className={styles["box-content"]}
                sx={boxContentSx}
                key={index}
              >
                <Box className={styles["box"]}>
                  <Typography className={styles["text"]}>
                    {convertDate(item.publication_date)}
                  </Typography>
                  <Typography className={styles["title"]}>
                    {item.title}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    component={Link}
                    to={"/release-note/" + item.id}
                    sx={{ display: "flex", alignItems: "center" }}
                    className={styles["text-link"]}
                  >
                    Read
                  </Typography>
                </Box>
              </Box>
            </>
          );
        })}
        {notifications?.list?.total_pages > checkPagination ? (
          <Box className={styles["pagination-footer"]}>
            {notifications?.previous ? (
              <Typography
                onClick={() => handlePreviousPage()}
                className={styles["pagination-title"]}
              >
                Previous
              </Typography>
            ) : (
              ""
            )}
            <Typography className={styles["pagination-title"]}>
              {currentPage}/{notifications?.list?.total_pages}
            </Typography>
            {notifications?.next ? (
              <Typography
                onClick={() => handleNextPage()}
                className={styles["pagination-title"]}
              >
                Next
              </Typography>
            ) : (
              ""
            )}
          </Box>
        ) : (
          <Box className={styles["pagination-footer"]}>
            <Typography className={styles["pagination-title"]}>
            {notifications?.list?.data?.length > 0
              ? `${currentPage}/${notifications?.list?.total_pages}`
              : ""}
            </Typography>
          </Box>
        )}
      </Box>
    </>
  );
}
