import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const { REACT_APP_API_ENDPOINT } = process.env;
export const forgetPassword = createAsyncThunk('forgetPassword/fetchForgetPassword', async (body) => {
  const formData = new FormData();
  formData.append('email', body.email);

  const response = await fetch(`${REACT_APP_API_ENDPOINT}/api/password-reset/`, {
    method: 'POST',
    body: formData,
  });
  const jsonData = await response.json();
  return jsonData;
});

const apiSlice = createSlice({
  name: 'register',
  initialState: {
    data: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(forgetPassword.pending, (state) => {
        state.loading = true;
      })
      .addCase(forgetPassword.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(forgetPassword.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default apiSlice.reducer;
