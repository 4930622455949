/* eslint-disable react-hooks/exhaustive-deps */
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import styles from './styles.module.scss';
import { Button, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect , useState} from "react";
import Pagination from "../../../components/pagination";
import { useHistory } from "react-router-dom";
import { getListNotice } from "../../../features/notifications/noticeSlice";
import{ NOTIFICATION_CATEGORY, NOTIFICATION_RECIPIENTS, NOTIFICATION_STATUS } from "../../../constants/index";
import ComponentModalDelete from "../../../components/modalDelete"
import { deleteNotiById } from "../../../features/notifications/noticeSlice"

export default function ReceiveSuggestions() {
  const dispatch = useDispatch();
  const notifications = useSelector((state) => state.notifications.list);
  const [currentPage, setCurrentPage] = useState(1);
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [id, setID] = useState();
  const handleClose = () => setOpen(false);

  const submitCreate = () => {
    return history.push(`/admin/notifications/create`);
  };

  useEffect(() => {
    const dataQuery = {
      page: currentPage,
    };
    dispatch(getListNotice(dataQuery));
  }, [open,currentPage]);

  const handleOpen = (id) => {
    setOpen(true);
    setID(id)
  };

  const submitEdit = (id) => {
    return history.push(`/admin/notifications/${id}`);
  };

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        overflow: "auto",
        backgroundColor: "#F1F3F9",
      }}
    >
      <Container className={styles["home-center"]} maxWidth="xl">
        <Box className={styles["box-header"]}>
          <Typography className={styles["text-header-home"]}>
            お知らせ一覧
          </Typography>
          <Box onClick={submitCreate} className={styles["box-create-noti"]}>
            新規作成
          </Box>
        </Box>
        <Table
          sx={{ minWidth: 650, backgroundColor: "#F1F3F9" }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell className={styles["text-head"]}>
                <Box className={styles["content-head"]}>タイトル </Box>
              </TableCell>
              <TableCell className={styles["text-head"]}>
                <Box className={styles["content-head"]}>カテゴリ </Box>
              </TableCell>
              <TableCell className={styles["text-head"]}>
                <Box className={styles["content-head"]}>公開先</Box>
              </TableCell>
              <TableCell className={styles["text-head"]}>
                <Box className={styles["content-head"]}>公開日</Box>
              </TableCell>
              <TableCell className={styles["text-head"]}>
                <Box className={styles["content-head"]}>公開範囲</Box>
              </TableCell>
              <TableCell className={styles["text-head"]}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{ backgroundColor: "#ffff" }} className="even-row">
            {notifications?.data?.map((item) => {
              return (
                <TableRow
                  key={item?.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    className={`${styles["text-table"]} ${styles["cut-text"]}`}
                  >
                    {item?.title}
                  </TableCell>
                  <TableCell className={styles["text-table"]}>
                    {NOTIFICATION_CATEGORY(item?.category)}
                  </TableCell>
                  <TableCell className={styles["text-table"]}>
                    {NOTIFICATION_RECIPIENTS(item?.recipients)}
                  </TableCell>
                  <TableCell className={styles["text-table"]}>
                    {item?.publication_date.slice(0, 10)}
                  </TableCell>
                  <TableCell className={styles["text-table"]}>
                    {NOTIFICATION_STATUS(item?.status)}
                  </TableCell>
                  <TableCell
                    sx={{ display: "flex"}}
                    className={styles["text-table"]}
                    align="center"
                  >
                    <Button onClick={() => submitEdit(item?.id)} className={styles["text-edit"]}>
                      編集
                    </Button>
                    <Button onClick={() => handleOpen(item?.id)} className={styles["text-delete"]}>
                      削除
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <ComponentModalDelete
        action={deleteNotiById(id)}
        handleClose={handleClose} setOpen={setOpen} open={open} title={"お知らせを削除しますか？"} />
        {
        notifications?.total_pages > 1 ?
          <Pagination onChange={(event, value) => setCurrentPage(value)} count={notifications?.total_pages} variant="outlined" shape="rounded" />
          :
          ""
        }
      </Container>
    </Box>
  );
}
