/* eslint-disable react-hooks/exhaustive-deps */
import Box from "@mui/material/Box";
import styles from "./styles.module.scss";
import React from "react";
import FormCreateOrUpdateNoti from "../../../../components/FormCreateOrUpdateNoti";

export default function ReceiveSuggestions() {
  return (
    <Box component="main" className={styles["box-main"]}>
      <FormCreateOrUpdateNoti id={null} />
    </Box>
  );
}
