/* eslint-disable react-hooks/exhaustive-deps */
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import styles from './styles.module.scss';
import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { getRecommendPlanListAll } from "../../../features/recommendPlan/recommendPlanSlice";

export default function ReceiveSuggestions() {
  const dispatch = useDispatch();
  const history = useHistory();
  const recommendPlan = useSelector(state => state?.recommendPlan?.listAll);

  const handleToEditApplication = (id) => {
    return history.push(`/admin/recommend-plans/${id}`);
  }

  useEffect(() => {
    dispatch(getRecommendPlanListAll());
  }, []);

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        overflow: "auto",
        backgroundColor: "#F1F3F9",
      }}
    >
      <Container
        className={styles["home-center"]}
        maxWidth="xl"
        sx={{ mb: 4, p: 5 }}
      >
        <Box className={styles["box-header"]}>
          <Typography className={styles["text-header-home"]}>
            作成プランの管理
          </Typography>
        </Box>
        <Table
          sx={{ minWidth: 650, backgroundColor: "#F1F3F9" }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell className={styles["text-head"]}>
                <Box className={styles["content-head"]}>作成日 </Box>
              </TableCell>
              <TableCell className={styles["text-head"]}>
                <Box className={styles["content-head"]}>プランタイトル </Box>
              </TableCell>

              <TableCell className={styles["text-head"]}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{ backgroundColor: "#ffff" }} className="even-row">
            {recommendPlan?.map((item) => {
              return (
                <TableRow
                  key={item?.id}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell className={styles["text-table"]}>
                    { (new Date(item?.created_at)).toISOString().split('T')[0].replace(/-/g, '.')}
                  </TableCell>
                  <TableCell className={styles["text-table"]}>
                    {item?.title}
                  </TableCell>

                  <TableCell
                    sx={{ display: "flex" }}
                    className={styles["text-table"]}
                    align="center"
                  >
                    <Box
                      className={styles["action-text"]}
                      onClick={() => handleToEditApplication(item?.id)}
                    >
                      アクション
                    </Box>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <Box>
          {/*{*/}
          {/*  recommendPlan?.total_pages > 1 ?*/}
          {/*    <Pagination onChange={(event, value) => setCurrentPage(value)} count={recommendPlan?.total_pages} variant="outlined" shape="rounded" />*/}
          {/*    :*/}
          {/*    ""*/}
          {/*}*/}
        </Box>
      </Container>
    </Box>
  );
}
