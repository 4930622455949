import { createTheme } from "@mui/material/styles";

export const dataRooms = ["シングルルーム", "ツイン", "ダブル"];

export const dataDays = ["全日", "平日", "休前日", "祝祭日"];

export const dataMeals = ["朝", "夕", "朝夕", "素泊まり", "昼", "朝昼夕"];
export const dataAccount = [
  "無料アカウント",
  "Basicプラン",
  "エンタープライズプラン",
];
export const dataSelect = [
  {
    title: "すべての機能",
    name: "all_is_enabled",
  },
  {
    title: "プラン提案",
    name: "plan_is_enabled",
  },
  {
    title: "広告提案",
    name: "ad_is_enabled",
  },
  {
    title: "セール提案",
    name: "sale_is_enabled",
  },
  {
    title: "クーポン提案",
    name: "coupon_is_enabled",
  },
  {
    title: "レポート機能",
    name: "report_is_enabled",
  },
];
export const dataRate = ["5", "10", "15", "20", "50", "100"];

export const dataPerks = [
  "アーリーチェックイン",
  "レイトチェックアウト",
  "ルームアップグレード",
  "ウェルカムドリンク",
  "ミニバーアイテム無料",
  "ホテルクレジット",
  "飲み放題",
  "お土産セット",
  "インルームダイニング",
  "ボトルシャンパン",
  "ボトルスパークリングワイン",
  "その他"
];

export const validateUserName = (checkRequired) => {
  return checkRequired === "" ? "このフィールドは必須です。" : "";
};
export const data = [
  {
    category: "セールプラン",
    name: "楽天トラベル",
    measure_name: "春夏キャンペーン",
    start_date: "2020/01/01",
    end_date: "2020/01/12",
    status: "販売完了",
  },
  {
    category: "通常プラン",
    name: "すべて",
    measure_name: "春夏キャンペーン",
    start_date: " ",
    end_date: " ",
    status: "結果入力",
  },
  {
    category: "クーポン",
    name: "Relux",
    measure_name: "春夏キャンペーン",
    start_date: "2020/01/01",
    end_date: "2020/01/12",
    status: "販売中",
  },
  {
    category: "広告",
    name: "エクスペディア",
    measure_name: "春夏キャンペーン",
    start_date: "2020/01/01",
    end_date: "2020/01/12",
    status: "販売中",
  },
];

export const checkPagination = 1;
export const NEW_TYPE = 1;
export const CHECK_STATUS = 1;
export const RELEASE_TYPE = 2;

export const OTA_OPTIONS = (type) => {
  switch (type) {
    case 1:
      return "楽天トラベル";
    case 2:
      return "じゃらん";
    case 3:
      return "一休";
    default:
      break;
  }
};

export const OTA_CATEGORY = (type) => {
  switch (type) {
    case 1:
      return "クーポン";
    case 2:
      return "セール";
    case 3:
      return "キャンペーン";
    default:
      break;
  }
};

export const NOTIFICATION_CATEGORY = (type) => {
  switch (type) {
    case 1:
      return "お知らせ";
    case 2:
      return "アップデート";
    default:
      break;
  }
};

export const NOTIFICATION_RECIPIENTS = (type) => {
  switch (type) {
    case 1:
      return "LP";
    case 2:
      return "管理画面";
    case 3:
      return "すべて";
    default:
      break;
  }
};

export const NOTIFICATION_STATUS = (type) => {
  switch (type) {
    case 1:
      return "公開";
    case 2:
      return "非公開";
    default:
      break;
  }
};

const fontList = [
  "SourceHanSansJP",
  "-apple-system",
  "BlinkMacSystemFont",
  "Segoe UI",
  "Roboto",
  "Oxygen",
  "Ubuntu",
  "Cantarell",
  "Fira Sans",
  "Droid Sans",
  "Helvetica Neue",
  "sans-serif",
].join(",");

export const customTheme = createTheme({
  typography: {
    fontFamily: fontList,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontFamily: fontList,
        },
        ":root": {
          "--font-family": fontList,
        },
      },
    },
  },
});

export const PLAN_DATE_FORMAT = "yyyy.MM.dd";

export const OTA_LIST = [
  {
    title: "楽天トラベル",
    value: "楽天トラベル",
  },
  {
    title: "じゃらんNet",
    value: "じゃらんNet",
  },
  {
    title: "一休.com",
    value: "一休.com",
  },
  {
    title: "Relux",
    value: "Relux",
  },
  {
    title: "るるぶトラベル",
    value: "るるぶトラベル",
  },
];


export const PDF_PLAN_HEADERS = [
  {
    title: "プラングループ名",
    id: "name",
  },
  {
    title: "予約件数",
    id: "number_of_reservations",
  },
  {
    title: "予約金額",
    id: "reservation_amount",
    prefix: "¥",
  },
  {
    title: "平均予約単価",
    id: "avarege_price",
    prefix: "¥",
  },
];

export const UNREGISTER = "未登録";
export const UNREGISTER_GROUP = "グループ登録なし";

export const PLAN_TIME_FORMAT = "YYYY-MM-DD";

export const ADMIN_ROLE = 1;
export const USER_ROLE = 2;
export const DEFAULT_PAGE_TITLE = "Levitt";

export const CSV_HEADER_CONFIG = [
  "プラン名",
  "販売客室タイプ名",
  "泊数",
  "販売先名",
  "合計宿泊料金",
  "チェックイン日",
  "チェックアウト日",
  "予約日"
]
