import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const { REACT_APP_API_ENDPOINT } = process.env;
export const fetchDeleteUser = createAsyncThunk('deleteUser/fetchDeleteUser', async (id) => {
  const access_token = localStorage.getItem('role')
  const response = await fetch(`${REACT_APP_API_ENDPOINT}/api/delete-user/${id}/`, {
    method: 'DELETE',
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${access_token}`
    },
  });
  const jsonData = await response.json();
  return jsonData;
});
const apiSlice = createSlice({
  name: 'delete-user',
  initialState: {
    data: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDeleteUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchDeleteUser.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchDeleteUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;

      });
  },
});

export default apiSlice.reducer;
