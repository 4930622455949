import React, { useCallback, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import styles from "../styles.module.scss";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  MenuItem,
  TextField,
} from "@mui/material";
import EditNoteIcon from "@mui/icons-material/EditNote";
import CachedIcon from "@mui/icons-material/Cached";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import BreadCrumb from "../../../../components/breadCrumb/index";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import {
  Link,
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  createRecommendPlan,
  deleteRecommendPlan,
  getRecommendPlan,
} from "../../../../features/recommendPlan/recommendPlanSlice";
import PostAddSharpIcon from "@mui/icons-material/PostAddSharp";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import DatepickerButton from "../../../../components/DatepickerButton";
import ja from "date-fns/locale/ja";
import moment from "moment";
import { dataMeals, OTA_LIST, PLAN_DATE_FORMAT } from "../../../../constants";
import * as Yup from "yup";
import {
  TEXT_REQUIRED,
  generateMaxTextValidation,
} from "../../../../constants/validation";
import { ErrorMessage, Field, Formik } from "formik";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import PlanDetailDocument from "../../../../components/pdf/PlanDetailDocument";
import { PDFDownloadLink } from "@react-pdf/renderer";
import Select from "@mui/material/Select";

registerLocale("ja", ja);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      sx={{
        width: "90%",
      }}
      className={styles["tab-panel"]}
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </Box>
  );
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const SubmittedReview = (props) => {
  const { children, value, isSubmitted, valueType } = props;

  function convertValueToString(value) {
    if (!value) {
      return "";
    }
    switch (valueType) {
      case "date":
        return moment(value).format("YYYY.MM.DD");
      case "array":
        return value.join(",");
      default:
        return value;
    }
  }

  return (
    <>
      {isSubmitted ? (
        <Typography className={styles["text-box"]}>
          {convertValueToString(value)}
        </Typography>
      ) : (
        <>{children}</>
      )}
    </>
  );
};

SubmittedReview.propTypes = {
  isSubmitted: PropTypes.bool.isRequired,
};

export default function PlanResultDetail(props) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const history = useHistory();
  // Get the 'planResult' from the 'plans' state
  const planResult = window.history?.state?.planResult;
  // Get the 'plan' from the 'recommendPlan' state
  const { plan } = useSelector((state) => state.recommendPlan);

  // If 'planResult' is null or undefined, use 'plan' instead
  const [createdPlan, setCreatedPlan] = useState(planResult || plan);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    if (createdPlan) {
      if (createdPlan?.sales_start_period) {
        setStartDate(moment(createdPlan?.sales_start_period).toDate());
      }

      if (createdPlan?.sales_end_period) {
        setEndDate(moment(createdPlan?.sales_end_period).toDate());
      }
    }
  }, [createdPlan]);

  const getSaleDate = (term) => {
    const dateRange = term?.match(/\d{4}年\d{2}月\d{2}日/g);
    const startDateString = dateRange ? dateRange[0] : "";
    const endDateString = dateRange ? dateRange[1] : "";
    const startDate = startDateString
      ? moment(startDateString, "YYYY年MM月DD日").toDate()
      : "";
    const endDate = endDateString
      ? moment(endDateString, "YYYY年MM月DD日").toDate()
      : "";
    setStartDate(startDate);
    setEndDate(endDate);
  };

  useEffect(() => {
    if (!id && !planResult) {
      history.push("/suggestions/plans/create");
    } else {
      // If 'planResult' is not null or undefined, use 'planResult'
      if (planResult) {
        setCreatedPlan(JSON.parse(planResult));
        getSaleDate(planResult?.term);
      } else {
        async function getPlanResult() {
          const res = await dispatch(getRecommendPlan(id));
          setCreatedPlan(res.payload[0]);
          // getSaleDate(res.payload?.term);
        }

        getPlanResult();
      }
    }
  }, [id, history, dispatch, planResult]);

  const [currentTab, setCurrentTab] = useState(0);
  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const formikConfig = {
    initialValues: {
      title: createdPlan?.title || "",
      description: createdPlan?.description || "",
      term: createdPlan?.term || "",
      room_type: createdPlan?.room_type || "",
      benefits: createdPlan?.benefits || "",
      meal_type: createdPlan?.meal_type || "",
      budget: createdPlan?.budget || "20000",
      ota_sell: createdPlan?.ota_sell || [],
    },
    onSubmit: async (values, { setSubmitting, setErrors }) => {
      setSubmitting(true);
      if (values.ota_sell.length === 0) {
        values.ota_sell = [];
      } else {
        values.ota_sell = values.ota_sell.join(",");
      }

      const payload = { ...createdPlan, ...values };
      let res = null;

      if (!id) {
        res = await dispatch(
          createRecommendPlan({
            body: payload,
          })
        );

        if (res.error) {
          setSubmitting(false);
          toast.error("システムエラー");
        } else {
          setSubmitting(false);
          setIsSubmitted(true);
        }
      } else {
        setSubmitting(false);
        setIsSubmitted(true);
      }
    },
    validationSchema: () => {
      return Yup.object({
        title: Yup.string()
          .max(50, generateMaxTextValidation(50))
          .required(TEXT_REQUIRED),
        description: Yup.string().required(TEXT_REQUIRED),
        // term: Yup.string().required(TEXT_REQUIRED),
        room_type: Yup.string().required(TEXT_REQUIRED),
        benefits: Yup.string().required(TEXT_REQUIRED),
        meal_type: Yup.string().required(TEXT_REQUIRED),
        budget: Yup.string().required(TEXT_REQUIRED),
      });
    },
  };

  const [triggerExit, setTriggerExit] = useState({ onOk: false, path: "" });

  useEffect(() => {
    if (triggerExit.onOk) {
      handleGoToIntendedPage(triggerExit.path);
    }
    const unblock = history.block((location) => {
      if (isSubmitted) {
        return true;
      }
      if (location.pathname !== "/results/plan/import/group") {
        setTriggerExit((obj) => ({
          ...obj,
          onOk: true,
        }));
      }
      setTriggerExit((obj) => ({ ...obj, path: location.pathname }));
      if (triggerExit.onOk) {
        return true;
      }
      return false;
    });
    return () => {
      unblock();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerExit, isSubmitted]);

  const handleGoToIntendedPage = useCallback(
    (location) => {
      history.push(location);
      window.history.replaceState(null, "", location);
    },
    [history]
  );

  const handleDeletePlan = async () => {
    const res = await dispatch(deleteRecommendPlan(id));
    if (res.error) {
      toast.error("システムエラー");
    } else {
      toast.success("提案を削除しました");
      history.push("/suggestions");
    }
  };

  const Placeholder = ({ children }) => {
    return <span className={styles["text-placeholder"]}>{children}</span>;
  };

  return (
    <Box
      component="main"
      sx={{
        backgroundColor: "#F1F3F9",
        p: 4,
      }}
    >
      <BreadCrumb
        content="プラン詳細"
        textRoutes={["ダッシュボード", "AIプラン作成", "プラン造成"]}
      />
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          borderRadius: "10px",
        }}
      >
        <Tabs
          orientation="vertical"
          value={currentTab}
          onChange={handleTabChange}
          indicatorColor="transparent"
          sx={{
            backgroundColor: "#F1F3F9",
          }}
        >
          <Tab
            className={styles["text-tab"]}
            icon={<PostAddSharpIcon size="24px" />}
            sx={{
              mb: "28px",
            }}
            {...a11yProps(1)}
          />
          <Tab
            className={styles["action-tab"]}
            label={<RefreshOutlinedIcon size="24px" />}
            {...a11yProps(2)}
            sx={{
              mb: "10px",
            }}
          />
          {id && (
            <Tab
              className={styles["action-tab"]}
              label={<DeleteForeverOutlinedIcon size="24px" />}
              {...a11yProps(3)}
            />
          )}
        </Tabs>
        <TabPanel value={currentTab} index={0}>
          <Formik
            enableReinitialize={true}
            validateOnChange={false}
            initialValues={formikConfig.initialValues}
            onSubmit={formikConfig.onSubmit}
            validationSchema={formikConfig.validationSchema}
          >
            {({
              handleChange,
              isSubmitting,
              values,
              errors,
              handleSubmit,
              setFieldValue,
            }) => (
              <>
                <Typography className={styles["text-title"]}>
                  プランタイトル
                </Typography>
                <SubmittedReview
                  value={values?.title}
                  isSubmitted={isSubmitted}
                >
                  <TextField
                    name="title"
                    value={values?.title}
                    onChange={handleChange}
                    className={styles["text-input"]}
                    helperText={errors.title}
                    error={!!errors.title}
                  />
                </SubmittedReview>
                <Typography
                  className={styles["text-number"]}
                  sx={{
                    mt: 2,
                    mb: 0,
                  }}
                >
                  文字数：{values?.title?.length ?? 0}/50
                </Typography>
                <Typography className={styles["text-title"]}>
                  プラン説明文
                </Typography>
                <SubmittedReview
                  value={values?.description}
                  isSubmitted={isSubmitted}
                >
                  <TextField
                    name="description"
                    multiline
                    rows={10}
                    value={values?.description}
                    onChange={handleChange}
                    className={styles["text-input"]}
                    helperText={errors.description}
                    error={!!errors.description}
                  />
                </SubmittedReview>

                <Typography className={styles["text-number"]}>
                  文字数：{values?.description?.length || 0}
                </Typography>
                <Typography className={styles["text-title"]}>特典</Typography>
                <SubmittedReview
                  value={values?.benefits}
                  isSubmitted={isSubmitted}
                >
                  <TextField
                    name="benefits"
                    multiline
                    rows={4}
                    value={values?.benefits}
                    onChange={handleChange}
                    className={styles["text-input"]}
                    helperText={errors.benefits}
                    error={!!errors.benefits}
                  />
                </SubmittedReview>

                <Grid sx={{ marginTop: "15px" }} container spacing={2}>
                  <Grid
                    item
                    xs={3}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Typography className={styles["text-box"]}>
                      参考販売価格
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sx={{ display: "flex", alignItems: "flex-end" }}
                  >
                    <SubmittedReview
                      value={values?.budget}
                      isSubmitted={isSubmitted}
                    >
                      <TextField
                        name="budget"
                        onChange={handleChange}
                        value={values?.budget}
                        className={styles["text-input"]}
                        helperText={errors.budget}
                        error={!!errors.budget}
                      />
                    </SubmittedReview>

                    <Typography
                      className={styles["text-box"]}
                      sx={{
                        marginLeft: "12px",
                        whiteSpace: "nowrap",
                        fontSize: "16px !important",
                      }}
                    >
                      円 /泊 1名
                    </Typography>
                  </Grid>
                </Grid>
                <Grid sx={{ mt: "15px", mb: 3 }} container spacing={2}>
                  <Grid
                    item
                    xs={3}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Typography className={styles["text-box"]}>
                      食事の有無
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <FormControl sx={{ width: "100%" }}>
                      <SubmittedReview
                        value={values?.meal_type}
                        isSubmitted={isSubmitted}
                      >
                        <Select
                          className={styles["text-select"]}
                          displayEmpty
                          name="meal_type"
                          value={values.meal_type}
                          onChange={handleChange}
                          error={!!errors.meal_type}
                          sx={{
                            borderRadius: "8px",
                            background: "#FFFF 0% 0% no-repeat padding-box",
                          }}
                          renderValue={
                            !!values.meal_type
                              ? undefined
                              : () => (
                                  <Placeholder>食事の提供も可能</Placeholder>
                                )
                          }
                        >
                          {dataMeals?.map(
                            (item, index) =>
                              item && (
                                <MenuItem
                                  key={index}
                                  className={styles["tag"]}
                                  value={item}
                                >
                                  {item}
                                </MenuItem>
                              )
                          )}
                        </Select>
                      </SubmittedReview>
                    </FormControl>
                    <ErrorMessage name="meal_type" />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={3}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Typography className={styles["text-box"]}>
                      販売期間
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Box
                      sx={{
                        display: "flex",
                        width: "100%",
                        gap: "16px",
                        alignItems: "center",
                      }}
                      className="custom-datepicker-container"
                    >
                      <SubmittedReview
                        valueType="date"
                        value={startDate}
                        isSubmitted={isSubmitted}
                      >
                        <Box sx={{ flexGrow: 1 }}>
                          <ReactDatePicker
                            locale="ja"
                            calendarClassName="custom-datepicker"
                            selected={startDate}
                            dateFormat={PLAN_DATE_FORMAT}
                            customInput={<DatepickerButton />}
                            disabled
                          />
                        </Box>
                      </SubmittedReview>

                      <Box>〜</Box>
                      <SubmittedReview
                        valueType="date"
                        value={endDate}
                        isSubmitted={isSubmitted}
                      >
                        <Box sx={{ flexGrow: 1 }}>
                          <ReactDatePicker
                            locale="ja"
                            selected={endDate}
                            calendarClassName="custom-datepicker"
                            dateFormat={PLAN_DATE_FORMAT}
                            customInput={<DatepickerButton />}
                            disabled
                          />
                        </Box>
                      </SubmittedReview>
                    </Box>
                  </Grid>
                </Grid>
                {isSubmitted && (
                  <Grid container spacing={2} sx={{ mt: "15px" }}>
                    <Grid
                      item
                      xs={3}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <Typography className={styles["text-box"]}>
                        販売するOTAを選ぶ
                      </Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <Typography className={styles["text-box"]}>
                        {typeof values.ota_sell === "string"
                          ? values.ota_sell
                          : values.ota_sell?.map(
                              (item, index) =>
                                item +
                                (index === values.ota_sell.length - 1
                                  ? ""
                                  : ",")
                            )}
                      </Typography>
                    </Grid>
                  </Grid>
                )}

                {!isSubmitted && (
                  <>
                    <Box
                      className={styles["text-title-box"]}
                      sx={{ marginTop: "40px" }}
                    >
                      {values?.room_type ?? (
                        <>なぜこのプランを導き出したのかの説明が入ります</>
                      )}
                    </Box>
                    <Typography
                      sx={{ marginTop: "40px" }}
                      className={styles["text-title"]}
                    >
                      販売するOTAを選ぶ
                    </Typography>
                    <FormGroup sx={{ flexDirection: "row" }}>
                      {OTA_LIST?.map((item, index) => (
                        <Field
                          type="checkbox"
                          name="ota_sell"
                          value={item.value}
                          key={item.value}
                          as={FormControlLabel}
                          control={<Checkbox />}
                          checked={values.ota_sell?.includes(item.value)}
                          label={item.title}
                        />
                      ))}
                    </FormGroup>
                  </>
                )}

                {!isSubmitted ? (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      mt: 5,
                      gap: "40px",
                    }}
                  >
                    <Box
                      className={styles["box-button-sale"]}
                      component={Button}
                      onClick={() => handleSubmit()}
                      disabled={isSubmitting}
                    >
                      <Typography className={styles["text-button-sale"]}>
                        販売を検討(保留)する
                      </Typography>
                    </Box>
                    <Box
                      className={styles["box-button"]}
                      component={Button}
                      onClick={() => handleSubmit()}
                      disabled={isSubmitting}
                    >
                      <Typography className={styles["text-button"]}>
                        上記の内容で確定する
                      </Typography>
                    </Box>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      mt: 5,
                      alignItems: "center",
                    }}
                  >
                    <PDFDownloadLink
                      document={
                        <PlanDetailDocument
                          plan={values}
                          startDate={startDate}
                          endDate={endDate}
                        />
                      }
                      fileName={values?.title}
                      className={styles["box-button"]}
                    >
                      <Typography
                        className={styles["text-button"]}
                        sx={{ textDecoration: "none" }}
                      >
                        プランをPDFで出力する
                      </Typography>
                    </PDFDownloadLink>
                    <Typography
                      color="#7764E4"
                      sx={{ mt: 3.75, textDecoration: "none" }}
                      component={Link}
                      to="/suggestions/plans/create"
                    >
                      戻る
                    </Typography>
                  </Box>
                )}
              </>
            )}
          </Formik>
        </TabPanel>
        <TabPanel value={currentTab} index={1}>
          <Typography className={styles["text-title"]}>
            プランを再作成する
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Box className={styles["box-button-edit"]}>
              <EditNoteIcon className={styles["icon-edit"]} />
              <Typography className={styles["text-button-edit"]}>
                条件を変更して再作成する
              </Typography>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Box
                  className={styles["button-edit"]}
                  component={Link}
                  to="/suggestions/plans/create"
                  sx={{
                    textDecoration: "none",
                    p: "8px 28px",
                    mt: "20px",
                    fontWeight: 700,
                  }}
                >
                  入力画面へ
                </Box>
              </Box>
            </Box>
            <Box className={styles["box-button-cache"]}>
              <CachedIcon className={styles["icon-cache"]} />
              <Typography className={styles["text-button-cache"]}>
                条件を変えずに再作成する
              </Typography>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Box
                  className={styles["button-cache"]}
                  component={Link}
                  to="/suggestions/plans/create?is_recreate=true"
                  sx={{
                    textDecoration: "none",
                    p: "8px 28px",
                    mt: "20px",
                    fontWeight: 700,
                  }}
                >
                  再作成を開始
                </Box>
              </Box>
            </Box>
          </Box>
        </TabPanel>
        <TabPanel value={currentTab} index={2}>
          <Typography className={styles["text-title"]}>
            作成したプランを削除する
          </Typography>
          <Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "25px",
                marginTop: "25px",
              }}
            >
              <DeleteOutlineIcon sx={{ fontSize: "100px", color: "#F53C56" }} />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "25px",
              }}
            >
              <Typography className={styles["text-delete"]}>
                一度削除したプランは再度作成することはできません。
                検討する場合は、作成されたそれぞれのプランより、
                「販売を検討(保留)する」をクリックしてください。
              </Typography>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Box
                className={styles["box-delete"]}
                component={Button}
                onClick={handleDeletePlan}
                sx={{
                  background: "#F53C56",
                  minWidth: "232px",
                  color: "#fff",
                  "&:hover": {
                    background: "#e80826",
                  },
                }}
              >
                完全に削除する
              </Box>
            </Box>
          </Box>
        </TabPanel>
      </Box>
    </Box>
  );
}
