import "./App.scss";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { ProvideAuth } from "./ProvideAuth";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { routers } from "./routes";
import "react-datepicker/dist/react-datepicker.css";
import { ThemeProvider } from "@mui/material/styles";
import { DEFAULT_PAGE_TITLE, customTheme } from "./constants";
import { Helmet, HelmetProvider } from "react-helmet-async";

function App() {
  return (
    <HelmetProvider>
      <Helmet>
        <title>{DEFAULT_PAGE_TITLE}</title>
      </Helmet>
      <ThemeProvider theme={customTheme}>
        <ToastContainer />
        <ProvideAuth>
          <Router>
            <Switch>
              {routers.map((route, i) =>
                route.isAuthentication ? (
                  <PrivateRoute key={i} {...route} exact />
                ) : (
                  <PublicRoute key={i} {...route} exact />
                )
              )}
            </Switch>
          </Router>
        </ProvideAuth>
      </ThemeProvider>
    </HelmetProvider>
  );
}

export default App;
