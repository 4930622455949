import React from "react";
import {
  Page,
  View,
  Document,
  StyleSheet,
  Font,
  Image,
} from "@react-pdf/renderer";
import SourceHanSansJP_Regular from "../../assets/fonts/SourceHanSansJP-Regular.otf";
import SourceHanSansJP_Bold from "../../assets/fonts/SourceHanSansJP-Bold.otf";
import SourceHanSansJP_Medium from "../../assets/fonts/SourceHanSansJP-Medium.otf";
import Logo from "../../assets/images/logo.png";

Font.register({
  family: "SourceHanSansJP",
  fonts: [
    {
      src: SourceHanSansJP_Regular,
    },
    { src: SourceHanSansJP_Medium, fontWeight: 500 },
    { src: SourceHanSansJP_Bold, fontWeight: 700 },
  ],
});

const styles = StyleSheet.create({
  page: {
    display: "flex",
    flexDirection: "column",
    fontFamily: "SourceHanSansJP",
    padding: "10pt 16pt",
  },
  image: {
    flexGrow: 1,
  },
  createdAt: {
    color: "#3F7EAE",
    fontSize: 12,
    fontWeight: 500,
    marginBottom: 12,
  },
});

const ReportChartsDocument = (props) => {
  const { images } = props;

  return (
    <Document>
      {images.map((image, index) => (
        <Page key={index} orientation="landscape" style={styles.page}>
          <View style={styles.image}>
            <Image src={image} />
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "row-reverse",
              marginTop: "20px",
              width: "100%",
              marginRight: "20px",
            }}
            fixed
          >
            <Image src={Logo} style={{ width: "100px" }} />
          </View>
        </Page>
      ))}
    </Document>
  );
};

export default ReportChartsDocument;
