import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import HTTP from "../../http_common";
import { UNREGISTER } from "../../constants";

const PAGINATE_INITIAL_STATE = {
  count: 0,
  next: null,
  previous: null,
  total_pages: 0,
};
const initialState = {
  plans: [],
  groups: [],
  paginate: {
    ...PAGINATE_INITIAL_STATE,
  },
  groupPlansPaginate: {
    ...PAGINATE_INITIAL_STATE,
  },
  loading: false,
  error: null,
  totalAll: {
    number_reservations: 0,
    reservation_amount: 0,
    avarege_price: 0,
  }
};

export const deleteMultiGroupPlan = createAsyncThunk(
  "planGroup/deleteMultiGroupPlan",
  async (body) => {
    const response = await HTTP.post(`api/group_plans/delete-multiple/`, {
      ids: [...body],
    });
    return response;
  }
);

export const createGroupPlan = createAsyncThunk(
  "planGroup/createGroupPlan",
  async (data) => {
    const response = await HTTP.post(`api/group_plans/`, {
      name: data,
    });
    return response;
  }
);

export const getDetailGroupPlan = createAsyncThunk(
  "planGroup/detailGroupPlan",
  async ({ id, body = {} }) => {
    const response = await HTTP.get(`api/plans/?group_plan_id=${id}`, {
      params: {
        ...body,
      },
    });
    return response;
  }
);

export const editGroupPlan = createAsyncThunk(
  "planGroup/editGroupPlan",
  async (data) => {
    const response = await HTTP.put(`/api/group_plans/${data?.id}/`, data);
    return response;
  }
);

export const getPlanGroups = createAsyncThunk(
  "planGroup/getPlanGroups",
  async (data = {}) => {
    const response = await HTTP.get(`api/group_plans/`, {
      params: {
        ...data,
      },
    });
    return response;
  }
);

export const getDetailPlanGroups = createAsyncThunk(
  "planGroup/getDetailPlanGroups",
  async (id) => {
    const response = await HTTP.get(`api/group_plans/${id}/`);
    return response;
  }
);

export const planGroupSlice = createSlice({
  name: "planGroup",
  initialState,
  reducers: {},
  extraReducers: {
    [deleteMultiGroupPlan.rejected]: (state, { error }) => {
      state.error = error.message;
    },
    [getDetailGroupPlan.pending]: (state) => {
      state.loading = true;
    },
    [getDetailGroupPlan.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.groupPlansPaginate = {
        count: payload?.count,
        next: payload?.next,
        previous: payload?.previous,
        total_pages: payload?.total_pages,
      };
      state.totalAll = {
        number_reservations: payload?.results?.total_number_reservations,
        reservation_amount: payload?.results?.total_reservation_amount,
        avarege_price: payload?.results?.total_avarege_price,
      };
      state.plans = payload?.results?.data.map((plan) => {
        if (plan) {
          return {
            ...plan,
            sale_period:
              plan?.sale_period_start && plan?.sale_period_end
                ? `${plan?.sale_period_start}~${plan?.sale_period_end}`
                : UNREGISTER,
            accommodation_period:
              plan?.accommodation_period_start && plan?.accommodation_period_end
                ? `${plan?.accommodation_period_start}~${plan?.accommodation_period_end}`
                : UNREGISTER,
          };
        }
        return plan;
      });
    },
    [getDetailPlanGroups.fulfilled]: (state, { payload }) => {
      state.detailPlanGroups = payload?.group_plan_detail;
    },
    [getPlanGroups.pending]: (state) => {
      state.loading = true;
    },
    [getPlanGroups.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.groups = payload?.results?.data;
      state.paginate.count = payload?.count;
      state.paginate.next = payload?.next;
      state.paginate.previous = payload?.previous;
      state.paginate.total_pages = payload?.results?.total_pages;
    },
    [getPlanGroups.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },
  },
});

export default planGroupSlice.reducer;
