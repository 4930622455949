/* eslint-disable jsx-a11y/anchor-is-valid */
import { Box, Typography } from "@mui/material";
import styles from "./styles.module.scss";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { getDetailNotice } from "../../../features/notifications/noticeSlice";
import { useParams } from "react-router-dom";
import moment from 'moment';

export default function ReleaseNoteDetail() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [notification, setNotification] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const res = await dispatch(getDetailNotice(id));
      setNotification(res?.payload);
    };
    fetchData();
  }, [id, dispatch]);

  const convertDate = (isoDate) => {
    const date = moment(isoDate);
    return date.format('YYYY.MM.DD');
  };
  return (
    <>
      <Box className={styles["box-main-content"]}>
        <Box className={styles["box-header"]}>
          <Typography className={styles["title-header"]}>
            {notification?.title}
          </Typography>
        </Box>
        <>
          <Box className={styles["box-content"]}>
            <Box className={styles["box"]}>
              <Typography className={styles["text"]} sx={{ width: "23%" }}>
                {convertDate(notification?.publication_date)}
              </Typography>
              <div className={styles["text"]} sx={{ width: "40%" }}>
                {React.createElement("div", {
                  dangerouslySetInnerHTML: { __html: notification?.content },
                })}
              </div>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
              marginRight: "80px",
              marginBottom: "80px",
              marginTop: "30px",
            }}
          >
            <a className={styles["text-link"]} href={"/release-note"}>
              View All
            </a>
          </Box>
        </>
      </Box>
    </>
  );
}
