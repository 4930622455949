import {
  Box,
  Button,
  Card,
  InputAdornment,
  Popover,
  TextField,
  Typography,
} from "@mui/material";
import BreadCrumb from "../../components/breadCrumb";
import styles from "./styles.module.scss";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useState, useEffect } from "react";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import { FileUploader } from "react-drag-drop-files";
import { useSelector } from "react-redux";
import { updateProfile, deleteProfile } from "../../features/users/usersSlice";
import { useDispatch } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import { regex } from "../../helper";
import { getAuth } from "../../features/auth/authSlice";
import {
  generateMaxTextValidation,
  TEXT_REQUIRED,
} from "../../constants/validation";
import { toast } from "react-toastify";
import statusCode from "../../constants/statusCodes";

const fileTypes = ["JPEG", "PNG", "GIF", "JPG"];

const Setting = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(null);
  const authData = useSelector((state) => state.auth);
  const [selectedImage, setSelectedImage] = useState('');
  const [avatar, setAvatar] = useState(null);
  // const [isSuggestionFromMicado, setIsSuggestionFromMicado] = useState(authData.currentUser?.is_suggestion_from_micado || false);
  // const [isSaleNotification, setIsSaleNotification] = useState(authData.currentUser?.is_sale_notification || false);
  // const [isPlannedCouponNotification, setIsPlannedCouponNotification] = useState(authData.currentUser?.is_planned_coupon_notification || false);

  // useEffect(() => {
  //   setIsPlannedCouponNotification(authData.currentUser?.is_planned_coupon_notification || false)
  //   setIsSaleNotification(authData.currentUser?.is_sale_notification || false)
  //   setIsSuggestionFromMicado(authData.currentUser?.is_suggestion_from_micado || false)
  // }, [authData])


  const dispatch = useDispatch();

  const shouldShowIcon = showPassword;
  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    if (authData?.currentUser) {
      setSelectedImage(authData.currentUser?.avatar)
    }
  }, [authData]);

  const formikConfig = {
    initialValues: {
      id: authData.currentUser?.id || '',
      email: authData.currentUser?.email || '',
      first_name: authData.currentUser?.first_name || '',
      last_name: authData.currentUser?.last_name || '',
      password: "",
    },
    onSubmit: async (values, { setSubmitting }) => {
      if (avatar) {
        values = { ...values, avatar: avatar };
      }
      const res = await dispatch(updateProfile(values));
      setSubmitting(false);
      if (res?.payload?.status === 200) {
        toast.success(res?.payload?.message);
      } else {
        toast.error("error");
      }
      await dispatch(getAuth());
    },
    validationSchema: () => {
      return Yup.object({
        first_name: Yup.string()
          .max(255, generateMaxTextValidation())
          .required(TEXT_REQUIRED),
        last_name: Yup.string()
          .max(255, generateMaxTextValidation())
          .required(TEXT_REQUIRED),
        email: Yup.string()
          .email("メールアドレスの形式が正しくありません")
          .required("メールアドレスを入力してください。"),
        password: Yup.string()
          .min(8, "パスワードは８文字以上で入力してください。")
          .matches(
            regex.password,
            "パスワードは英大文字、英小文字、数字、特殊文字が必要です。"
          ),
      });
    },
  };

  // const formikPartnerConfig = {
  //   initialValues: {
  //     rakuten_id: authData.currentUser?.rakuten_id || '',
  //     jaran_id: authData.currentUser?.jaran_id || '',
  //     ikkyu_id: authData.currentUser?.ikkyu_id || '',
  //     hotel_name: authData.currentUser?.hotel_name || '',
  //   },
  //   onSubmit: async (values, { setSubmitting }) => {
  //     const res = await dispatch(updatePartnerId(values));
  //     setSubmitting(false);
  //     if (res?.payload?.status === 200) {
  //       toast.success(res?.payload?.message);
  //     } else {
  //       toast.error("error");
  //     }
  //     await dispatch(getAuth());
  //   },
  //   validationSchema: Yup.object({
  //     rakuten_id: Yup.string()
  //       .nullable()
  //       .test('is-3-6-digit', '楽天トラベル施設IDは3~6桁の数字を入力してください。', value => !value || (/^[0-9]{3,6}$/.test(value))),
  //     jaran_id: Yup.string()
  //       .nullable()
  //       .test('is-6-digit', 'じゃらん施設IDは6桁の数字を入力してください。', value => !value || (/^[0-9]{6}$/.test(value))),
  //     ikkyu_id: Yup.string()
  //       .nullable()
  //       .test('is-8-digit', '一休施設IDは8桁の数字を入力してください。', value => !value || (/^[0-9]{8}$/.test(value))),
  //   }),
  // };

  const handleOpenConfirmDelete = (e) => {
    setConfirmDelete(e.currentTarget);
  };
  const handleCloseConfirmDelete = () => {
    setConfirmDelete(null);
  };

  const confirmDeleteUser = async () => {
    const res = await dispatch(deleteProfile());
    if (res?.payload?.status === statusCode.OK) {
      window.location.href = "/login";
    } else {
      toast.error("error");
    }
  };

  // const handleChangeNotification = async (name, checked) => {
  //   switch (name) {
  //     case 'is_sale_notification':
  //       setIsSaleNotification(checked)
  //       break;
  //     case 'is_planned_coupon_notification':
  //       setIsPlannedCouponNotification(checked)
  //       break;
  //     case 'is_suggestion_from_micado':
  //       setIsSuggestionFromMicado(checked)
  //       break;
  //     default:
  //       break;
  //   }

  //   const res = await dispatch(updateNotification({ [name]: checked }));

  //   if (res?.payload?.status === 200) {
  //     toast.success(res?.payload?.message);
  //   } else {
  //     toast.error("error");
  //   }
  // }

  const handleChangeAvatar = async (file) => {
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const base64Data = e.target.result;
        setSelectedImage(base64Data);
      };
      reader.readAsDataURL(file);
      setAvatar(file);
    } else {
      setSelectedImage(null);
    }
  };

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        overflow: "auto",
        p: 4,
      }}
    >
      <BreadCrumb content="設定" textRoutes={["ダッシュボード", "設定"]} />
      <Box className={styles["setting"]}>
        <Typography variant="h6" className={styles["setting--title"]}>
          一般
        </Typography>
        <Card className={styles["setting-card"]} sx={{ mt: 3 }}>
          <Box className={styles["setting-card--header"]}>
            <PersonOutlineOutlinedIcon sx={{ fontSize: "30px", mr: 2 }} />
            <Typography className={styles["setting-card--title"]}>
              アカウント情報
            </Typography>
          </Box>
          <Box className={styles["setting-card--body"]}>
            <Box>
              <Typography className={styles["setting-card--text"]}>
                アカウントの情報を上書きし、
                <br />
                アップデートボタンをクリックして
                <br />
                保存してください。
              </Typography>
            </Box>
            <Formik
              enableReinitialize={true}
              validateOnChange={false}
              initialValues={formikConfig.initialValues}
              onSubmit={formikConfig.onSubmit}
              validationSchema={formikConfig.validationSchema}
            >
              {({ handleChange, handleBlur, values, errors, handleSubmit }) => (
                <Box>
                  <Typography className={styles["setting-card--title"]}>
                    アバター画像
                  </Typography>
                  <FileUploader
                    handleChange={handleChangeAvatar}
                    name="avatar"
                    types={fileTypes}
                  >
                    <Box
                      className={styles["setting-card--image"]}
                      sx={{ mt: "14px" }}
                    >
                      {selectedImage ? (
                        <img
                          src={selectedImage}
                          alt="User Avatar"
                          style={{
                            width: "100%",
                            height: "100%",
                            borderRadius: "50%",
                          }}
                        />
                      ) : (
                        <Box className={styles["setting-card--image--icon"]}>
                          <FileUploadOutlinedIcon sx={{ fontSize: "40px" }} />
                        </Box>
                      )}
                    </Box>
                  </FileUploader>
                  <Typography
                    className={styles["setting-card--title"]}
                    sx={{ mt: "28px" }}
                  >
                    姓
                  </Typography>
                  <TextField
                    variant="outlined"
                    fullWidth
                    sx={{ mt: "12px" }}
                    className={styles["setting-card--input"]}
                    value={values.first_name}
                    name="first_name"
                    onChange={handleChange}
                    onBlur={handleBlur("first_name")}
                    error={!!errors.first_name}
                    helperText={errors.first_name}
                  />
                  <Typography
                    className={styles["setting-card--title"]}
                    sx={{ mt: "28px" }}
                  >
                    名
                  </Typography>
                  <TextField
                    variant="outlined"
                    fullWidth
                    sx={{ mt: "12px" }}
                    className={styles["setting-card--input"]}
                    value={values.last_name}
                    name="last_name"
                    onChange={handleChange}
                    onBlur={handleBlur("last_name")}
                    error={!!errors.last_name}
                    helperText={errors.last_name}
                  />
                  <Typography
                    className={styles["setting-card--title"]}
                    sx={{ mt: "28px" }}
                  >
                    メールアドレス
                  </Typography>
                  <TextField
                    variant="outlined"
                    fullWidth
                    sx={{ mt: "12px" }}
                    className={styles["setting-card--input"]}
                    value={values.email}
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur("email")}
                    error={!!errors.email}
                    helperText={errors.email}
                  />
                  <Typography
                    className={styles["setting-card--title"]}
                    sx={{ mt: "28px" }}
                  >
                    パスワード
                  </Typography>
                  <TextField
                    fullWidth
                    sx={{ mt: "12px" }}
                    className={styles["setting-card--input"]}
                    id="outlined-adornment-password"
                    type={showPassword ? "text" : "password"}
                    placeholder="変更するパスワードを入力してください。"
                    value={values?.password}
                    name="password"
                    onChange={handleChange}
                    onBlur={handleBlur("password")}
                    error={!!errors.password}
                    helperText={errors.password}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {shouldShowIcon ? (
                            <VisibilityOff
                              onClick={handleTogglePasswordVisibility}
                              style={{ cursor: "pointer", color: "#3f7eae" }}
                            />
                          ) : (
                            <Visibility
                              onClick={handleTogglePasswordVisibility}
                              style={{ cursor: "pointer", color: "#3f7eae" }}
                            />
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Button
                    variant="text"
                    className={styles["setting-card--button"]}
                    sx={{ mt: 3 }}
                    onClick={handleSubmit}
                  >
                    アップデート
                  </Button>
                </Box>
              )}
            </Formik>
          </Box>
        </Card>
        {/* <Card className={styles["setting-card"]} sx={{ mt: "60px" }}>
          <Box className={styles["setting-card--header"]}>
            <ApartmentRoundedIcon sx={{ fontSize: "30px", mr: 2 }} />
            <Typography className={styles["setting-card--title"]}>
              施設情報
            </Typography>
          </Box>
          <Box className={styles["setting-card--body"]}>
            <Box>
              <Typography className={styles["setting-card--text"]}>
                施設の情報を上書きし、
                <br />
                アップデートボタンをクリックして
                <br />
                保存してください。
              </Typography>
            </Box>
            <Formik
              enableReinitialize={true}
              validateOnChange={false}
              initialValues={formikPartnerConfig.initialValues}
              onSubmit={formikPartnerConfig.onSubmit}
              validationSchema={formikPartnerConfig.validationSchema}
            >
              {({ handleChange, handleBlur, values, errors, handleSubmit }) => (
                <Box>
                <Typography className={styles["setting-card--title"]}>
                  施設名
                </Typography>
                <Typography
                  className={styles["setting-card--title"]}
                  sx={{
                    mt: "12px",
                    fontSize: "16px",
                    fontWeight: "500 !important",
                  }}
                >
                  {values.hotel_name}
                </Typography>
                <Typography
                  className={styles["setting-card--title"]}
                  sx={{ mt: "28px" }}
                >
                  楽天トラベル施設ID
                  <Typography
                    component={"span"}
                    className={styles["setting-card--subtitle"]}
                  >
                    (施設トップページのURLのHOTEL/の後に続く3~6桁の数字)
                  </Typography>
                </Typography>
                <TextField
                  variant="outlined"
                  fullWidth
                  sx={{ mt: "12px" }}
                  className={styles["setting-card--input"]}
                  value={values.rakuten_id}
                  name="rakuten_id"
                  onChange={handleChange}
                  onBlur={handleBlur("rakuten_id")}
                  error={!!errors.rakuten_id}
                  helperText={errors.rakuten_id}
                />
                <Typography
                  className={styles["setting-card--title"]}
                  sx={{ mt: "28px" }}
                >
                  じゃらん施設ID
                  <Typography
                    component={"span"}
                    className={styles["setting-card--subtitle"]}
                  >
                    (施設トップページのURLのyadの後に続く6桁の数字)
                  </Typography>
                </Typography>
                <TextField
                  variant="outlined"
                  fullWidth
                  sx={{ mt: "12px" }}
                  className={styles["setting-card--input"]}
                  value={values.jaran_id}
                  name="jaran_id"
                  onChange={handleChange}
                  onBlur={handleBlur("jaran_id")}
                  error={!!errors.jaran_id}
                  helperText={errors.jaran_id}
                />
                <Typography
                  className={styles["setting-card--title"]}
                  sx={{ mt: "28px" }}
                >
                  一休施設ID
                  <Typography
                    component={"span"}
                    className={styles["setting-card--subtitle"]}
                  >
                    (施設トップページのURLのikyu.com/の後に続く8桁の数字)
                  </Typography>
                </Typography>
                <TextField
                  variant="outlined"
                  fullWidth
                  sx={{ mt: "12px" }}
                  className={styles["setting-card--input"]}
                  value={values.ikkyu_id}
                  name="ikkyu_id"
                  onChange={handleChange}
                  onBlur={handleBlur("ikkyu_id")}
                  error={!!errors.ikkyu_id}
                  helperText={errors.ikkyu_id}
                />
                <Button
                  variant="text"
                  className={styles["setting-card--button"]}
                  sx={{ mt: 3 }}
                  onClick={handleSubmit}
                >
                  アップデート
                </Button>
              </Box>
              )}
            </Formik>
          </Box>
        </Card> */}
        {/* <Card className={styles["setting-card"]} sx={{ mt: "60px" }}>
          <Box className={styles["setting-card--header"]}>
            <NotificationsNoneRoundedIcon sx={{ fontSize: "30px", mr: 2 }} />
            <Typography className={styles["setting-card--title"]}>
              通知メール設定
            </Typography>
          </Box>
          <Box className={styles["setting-card--body"]}>
            <Box>
              <Typography className={styles["setting-card--text"]}>
                どのような通知を受け取るかお選びください
              </Typography>
            </Box>
            <Box>
              <FormGroup className={styles["setting-card--checkboxes"]}>
                <FormControlLabel
                  control={<Checkbox />}
                  checked={isSaleNotification}
                  onChange={(e)=> handleChangeNotification('is_sale_notification', e.target.checked)}
                  label="セール開催通知"
                />
                <FormControlLabel
                  control={<Checkbox />}
                  checked={isPlannedCouponNotification}
                  onChange={(e)=> handleChangeNotification('is_planned_coupon_notification', e.target.checked)}
                  label="企画型クーポン通知"
                />
                <FormControlLabel
                  control={<Checkbox />}
                  checked={isSuggestionFromMicado}
                  onChange={(e)=> handleChangeNotification('is_suggestion_from_micado', e.target.checked)}
                  label="micado++からの提案"
                />
                <Typography className={styles["setting-card--title"]}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      transform: "translateX(-12px)",
                    }}
                  >
                    <Box
                      sx={{
                        width: "42px",
                        height: "42px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <CheckRoundedIcon sx={{ fontSize: "16px" }} />
                    </Box>
                    お知らせ
                  </Box>
                </Typography>
              </FormGroup>
            </Box>
          </Box>
        </Card> */}
        <Card className={styles["setting-card"]} sx={{ mt: "60px" }}>
          <Box
            className={styles["setting-card--header"]}
            sx={{
              color: "#CE858F",
            }}
          >
            <DeleteForeverOutlinedIcon sx={{ fontSize: "30px", mr: 2 }} />
            <Typography
              className={styles["setting-card--title"]}
              sx={{
                color: "#CE858F !important",
              }}
            >
              アカウントを削除する
            </Typography>
          </Box>
          <Box
            sx={{
              p: "33px 30px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box>
              <Typography className={styles["setting-card--text"]}>
                右のボタンをクリックするとアカウントを削除できます。
              </Typography>
              <Typography sx={{ mt: 1, color: "#CE858F", fontWeight: 500 }}>
                ※この操作は取り消せません。
              </Typography>
            </Box>
            <Box>
              <Button
                variant="text"
                sx={{
                  border: "1px solid #CE858F",
                  color: "#CE858F",
                  p: "12px 28px",
                  borderRadius: "5px",
                  fontSize: "13px",
                  fontWeight: "bold",
                }}
                onClick={handleOpenConfirmDelete}
                aria-describedby="confirmDelete"
              >
                削除する
              </Button>
              <Popover
                id="confirmDelete"
                open={Boolean(confirmDelete)}
                anchorEl={confirmDelete}
                onClose={handleCloseConfirmDelete}
                classes={{ paper: styles["confirm-delete"] }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 500,
                    color: "#7764E4",
                    cursor: "pointer",
                  }}
                  onClick={handleCloseConfirmDelete}
                >
                  キャンセル
                </Typography>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 500,
                    ml: "20px",
                    color: "#CE858F",
                    cursor: "pointer",
                  }}
                  onClick={confirmDeleteUser}
                >
                  削除する
                </Typography>
              </Popover>
            </Box>
          </Box>
        </Card>
      </Box>
    </Box>
  );
};

export default Setting;
