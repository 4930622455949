import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import HTTP from "../../http_common";

const initialState = {
  list: [],
  count: 0,
  loading: false,
  error: null,
  previous: null,
  next: null,
};

export const getListApplication = createAsyncThunk(
  "application/getListApplication",
  async (body) => {
    const response = await HTTP.get(`/api/apply_restration_hotel/`, {
      params: { ...body },
    });
    return response;
  }
);

export const getDetailApplication = createAsyncThunk(
  "application/getDetailApplication",
  async (id) => {
    const response = await HTTP.get(`/api/apply_restration_hotel/${id}/`);
    return response;
  }
);

export const updateApplication = createAsyncThunk(
  "application/updateApplication",
  async (body) => {
      const response = await HTTP.put(`/api/apply_restration_hotel/${body.id}/`, body);
      return response;
  }
);

export const noticeSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {},
  extraReducers: {
    [getListApplication.pending]: (state) => {
      state.loading = true;
    },
    [getListApplication.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.list = payload.results;
      state.count = payload.count;
      state.previous = payload.previous;
      state.next = payload.next;
    },
    [getListApplication.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },
    [updateApplication.pending]: (state) => {
      state.loading = true;
    },
    [updateApplication.fulfilled]: (state) => {
      state.loading = false;
    },
    [updateApplication.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },
  },
});

// Action creators are generated for each case reducer function
// export const {} = noticeSlice.actions

export default noticeSlice.reducer;
