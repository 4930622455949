/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useMemo, useState} from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import DatePicker, {registerLocale} from "react-datepicker";
import CircularProgress from "@mui/material/CircularProgress";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  IconButton,
  MenuItem,
  Popover,
  TextField,
} from "@mui/material";
import Modal from "@mui/material/Modal";
import {dataDays, dataMeals, dataPerks, dataRooms, PLAN_DATE_FORMAT,} from "../../../constants/index";
import { createPlan, getGPTPlanAsync } from "../../../features/plans/plansSlice";
import styles from "./styles.module.scss";
import {useDispatch, useSelector} from "react-redux";
import moment from "moment";
import BreadCrumb from "../../../components/breadCrumb/index";
import * as Yup from "yup";
import {TEXT_REQUIRED} from "../../../constants/validation";
import {ErrorMessage, Formik} from "formik";
import ja from "date-fns/locale/ja";
import DatepickerButton from "../../../components/DatepickerButton";
import {Link, useLocation} from "react-router-dom/cjs/react-router-dom";
import {getRecommendPlanList} from "../../../features/recommendPlan/recommendPlanSlice";
import EnhancedTable from "../../../components/EnhancedTable";
import PaginationPage from "../../../components/pagination";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import {getListHotelCompetition} from "../../../features/competitor/competitorSlice";
import {getHotels} from "../../../features/apiGetHotel";
import Autocomplete, {createFilterOptions} from "@mui/material/Autocomplete";

registerLocale("ja", ja);
const filter = createFilterOptions();
export default function CreatePlan() {
  const [open, setOpen] = useState(false);
  const [checkLoading, setCheckLoading] = useState(true);
  const dispatch = useDispatch();
  const { planResult, error } = useSelector((state) => state.plans);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentCell, setCurrentCell] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [listRecommendPlan, setListRecommendPlan] = useState([]);
  const [hotels, setHotels] = useState([]);
  const [delaySearch, setDelaySearch] = useState();

  useEffect(() => {
    dispatch(getListHotelCompetition());
  }, [])

  const inputHotelOnChange = async (query) => {
    const dataQuery = { search_param: query };
    clearTimeout(delaySearch);
    const search = setTimeout(async () => {
      const data = await fetchDataFromAPI(dataQuery);
      setHotels(data);
    }, 500);
    setDelaySearch(search);
  };

  const fetchDataFromAPI = async (dataQuery) => {
    try {
      const res = await dispatch(getHotels(dataQuery));
      setHotels(res.payload?.results?.data);
      return res.payload?.results?.data;
    } catch (error) {
      return [];
    }
  };

  function useQuery() {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
  }

  const isRecreate = useQuery().get("is_recreate");
  let recentRequestedPlan = undefined;
  if (isRecreate) {
    recentRequestedPlan = localStorage.getItem("planRequested");
    if (recentRequestedPlan) {
      recentRequestedPlan = JSON.parse(recentRequestedPlan);

      if (recentRequestedPlan.start_tarm) {
        recentRequestedPlan.start_tarm = moment(
          recentRequestedPlan.start_tarm
        ).toDate();
      }
      if (recentRequestedPlan.end_tarm) {
        recentRequestedPlan.end_tarm = moment(
          recentRequestedPlan.end_tarm
        ).toDate();
      }
    }
  }

  const { list: recommendPlans, paginate } = useSelector(
    (state) => state.recommendPlan
  );

  const handleCloseCellAction = () => {
    setCurrentCell(null);
    setSelectedRow(null);
  };

  const handleOpenCellAction = (event, row) => {
    setCurrentCell(event.target);
    setSelectedRow(row);
  };

  useEffect(() => {
    const list = recommendPlans || [];
    const formatListDate = list.map((item) => {
      return {
        ...item,
        created_at: moment(item.created_at).format("YYYY.MM.DD"),
      };
    });
    setListRecommendPlan(formatListDate);
  }, [recommendPlans]);

  useEffect(() => {
    dispatch(
      getRecommendPlanList({
        page: currentPage,
      })
    );
  }, [currentPage, dispatch]);

  const formikConfig = {
    initialValues: {
      date_select_type:
        recentRequestedPlan?.date_select_type === false,
      room_type: recentRequestedPlan?.room_type || "",
      date_type: recentRequestedPlan?.date_type || "",
      meal: recentRequestedPlan?.meal || "",
      benefits: recentRequestedPlan?.benefits || "",
      hotel_id: recentRequestedPlan?.hotel_id || "",
      input_perks: recentRequestedPlan?.input_perks || "",
      start_tarm: recentRequestedPlan?.start_tarm || "",
      end_tarm: recentRequestedPlan?.end_tarm || "",
    },
    onSubmit: async (values, { setSubmitting, setErrors }) => {
      setSubmitting(true);
      const copyValues = { ...values };
      if (copyValues.date_select_type) {
        delete copyValues.start_tarm;
        delete copyValues.end_tarm;
      } else {
        copyValues.start_tarm = moment(copyValues.start_tarm).format(
          "YYYY-MM-DD"
        );
        copyValues.end_tarm = moment(copyValues.end_tarm).format(
          "YYYY-MM-DD"
        );
      }
      copyValues.hotel_id = values.hotel_id?.in_site_id
      localStorage.setItem("planRequested", JSON.stringify(copyValues));
      delete copyValues.date_select_type;
      await handleCreatePlan(copyValues);
      setSubmitting(false);
    },
    validationSchema: () => {
      return Yup.object({
        date_select_type: Yup.boolean(),
        room_type: Yup.string().required(TEXT_REQUIRED),
        date_type: Yup.string().required(TEXT_REQUIRED),
        meal: Yup.string().required(TEXT_REQUIRED),
        benefits: Yup.string().required(TEXT_REQUIRED),
        hotel_id: Yup.mixed().required(TEXT_REQUIRED),
        input_perks: Yup.string().when("benefits", {
          is: (benefits) => benefits === "その他",
          then: (schema) => schema.required(TEXT_REQUIRED),
          otherwise: (schema) => schema,
        }),
        start_tarm: Yup.mixed().when("date_select_type", {
          is: false,
          then: (schema) => schema.required(TEXT_REQUIRED),
          otherwise: (schema) => schema,
        }),
        end_tarm: Yup.mixed().when("date_select_type", {
          is: false,
          then: (schema) => schema.required(TEXT_REQUIRED),
          otherwise: (schema) => schema,
        }),
      });
    },
  };

  const handleCreatePlan = async (values) => {
    try {
      setOpen(true);
      setCheckLoading(true);
      const res = await dispatch(createPlan(values));

      const taskId = res.payload?.data?.task_id;
      const status = setInterval(() => checkTaskStatusCreatePlan(taskId, status), 5000);
    } catch (error) {
      console.error("error:", error);
    }
  };

  const checkTaskStatusCreatePlan = async (id, status) => {
    try {
      const response = await dispatch(getGPTPlanAsync(id));
      console.log("response:: ", response.payload?.status)

      if (response && response.payload?.status !== 'pending') {
        setCheckLoading(false);
        clearInterval(status);
      }
    } catch (error) {
      console.error("error:", error);
    }
  };

  const Placeholder = ({ children }) => {
    return <span className={styles["text-placeholder"]}>{children}</span>;
  };

  const redirectCreateRecommendPlan = () => {
    window.history.pushState({ planResult: planResult }, "", "/suggestions/plans/result");
    window.location.href = "/suggestions/plans/result";
  }

  const LoadingModal = useMemo(
    () => (
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={styles["box-modal"]}>
          {checkLoading ? (
            <>
              <Typography
                sx={{ textAlign: "center", marginTop: "50px" }}
                id="modal-modal-title"
                variant="h6"
                component="h2"
              >
                <CircularProgress />
              </Typography>
              <Typography
                className={styles["title-modal"]}
                id="modal-modal-description"
                sx={{ mt: 2 }}
              >
                作成中
              </Typography>
              <Typography
                className={styles["text-modal"]}
                id="modal-modal-description"
                sx={{ mt: 2 }}
              >
                作成中です。通常5秒から1分ほどかかります。
              </Typography>
            </>
          ) : (
            <>
              {error ? (
                <>
                  <Typography
                    sx={{ textAlign: "center" }}
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    <ErrorOutlineRoundedIcon className={styles["icon-modal"]} />
                  </Typography>
                  <Typography
                    className={styles["title-modal"]}
                    id="modal-modal-description"
                    sx={{ mt: 2 }}
                  >
                    ERROR!!
                  </Typography>
                  <Box
                    onClick={() => {
                      setOpen(false);
                    }}
                    sx={{ marginLeft: "25%" }}
                    className={styles["box-button"]}
                  >
                    <Typography className={styles["text-button"]}>
                      CLOSE
                    </Typography>
                  </Box>
                </>
              ) : (
                <>
                  <Typography
                    sx={{ textAlign: "center" }}
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    <CheckCircleIcon className={styles["icon-modal"]} />
                  </Typography>
                  <Typography
                    className={styles["title-modal"]}
                    id="modal-modal-description"
                    sx={{ mt: 2 }}
                  >
                    完了
                  </Typography>
                  <Box
                    sx={{ marginLeft: "25%" }}
                    className={styles["box-button"]}
                    onClick={() => redirectCreateRecommendPlan()}
                  >
                    <Typography
                      // component={Link}
                      className={styles["text-button"]}
                      sx={{ textDecoration: "none" }}
                    >
                      作成結果を見る
                    </Typography>
                  </Box>
                </>
              )}
            </>
          )}
        </Box>
      </Modal>
    ),
    [open, checkLoading, error, planResult]
  );

  const RecommendPlansTable = useMemo(() => {
    const headCells = [
      {
        id: "created_at",
        label: "作成日",
      },
      {
        id: "title",
        label: "プランタイトル",
      },
      {
        id: "id",
        label: "",
        options: {
          component: (row) => <CellAction row={row} />,
        },
      },
    ];

    const CellAction = ({ row }) => {
      return (
        <IconButton
          id={row.id}
          onClick={(event) => handleOpenCellAction(event, row)}
          sx={{
            boxShadow: "0px 3px 6px #2C28281C",
            color: "#172B4D",
          }}
        >
          <MoreVertRoundedIcon />
        </IconButton>
      );
    };

    return (
      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
          mt: "60px",
          width: "96%",
          marginLeft: "29px",
        }}
      >
        <Typography
          sx={{
            fontWeight: 700,
            p: "30px 42px",
            color: "#172B4D",
            fontSize: "18px",
          }}
        >
          過去に作成したプラン
        </Typography>
        <Box>
          <EnhancedTable
            selectAble={false}
            headCells={headCells}
            rows={listRecommendPlan}
          />
        </Box>
        {paginate?.total_pages > 1 && (
          <PaginationPage
            count={paginate.total_pages}
            onChange={(event, value) => setCurrentPage(value)}
            variant="outlined"
            shape="rounded"
          />
        )}
      </Paper>
    );
  }, [listRecommendPlan, paginate.total_pages]);

  return (
    <>
      <Paper
        sx={{
          backgroundColor: "#F1F3F9",
          flexGrow: 1,
          overflow: "auto",
          p: 4,
          boxShadow: "0px 0px 0px 0px rgba(0,0,0,0) !important",
        }}
      >
        <Box>
          <BreadCrumb
            content="プラン作成"
            textRoutes={["ダッシュボード", "提案を受ける", "プラン作成"]}
          />
          <Formik
            enableReinitialize={true}
            validateOnChange={false}
            initialValues={formikConfig.initialValues}
            onSubmit={formikConfig.onSubmit}
            validationSchema={formikConfig.validationSchema}
          >
            {({
              handleChange,
              values,
              errors,
              handleSubmit,
              setFieldValue,
              isSubmitting,
            }) => (
              <>
                <Paper sx={{ p: 5, display: "flex", flexDirection: "column" }}>
                  <Typography className={styles["text-title"]}>
                    強調したい要素を選ぶ
                  </Typography>
                  <Box sx={{ marginTop: "28px" }}>
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <Typography className={styles["text-box"]}>
                          強調したい要素を選んでください。
                          特にない場合は、「強調したい要素がない」
                          にチェックを入れてください。
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <FormControl sx={{ width: "100%" }}>
                          <Select
                            className={styles["text-select"]}
                            displayEmpty
                            name="room_type"
                            error={!!errors.room_type}
                            onChange={handleChange}
                            value={values.room_type}
                            sx={{
                              borderRadius: "8px",
                              height: "45px",
                              background: "#FFFF 0% 0% no-repeat padding-box",
                            }}
                            renderValue={
                              !!values.room_type
                                ? undefined
                                : () => (
                                    <Placeholder>
                                      すべての客室タイプ
                                    </Placeholder>
                                  )
                            }
                          >
                            {dataRooms?.map(
                              (item, index) =>
                                item && (
                                  <MenuItem
                                    key={index}
                                    className={styles["tag"]}
                                    value={item}
                                  >
                                    {item}
                                  </MenuItem>
                                )
                            )}
                          </Select>
                          {!!errors.room_type && (
                            <FormHelperText sx={{ color: "#d32f2f" }}>
                              客室タイプを選択してください。
                            </FormHelperText>
                          )}
                        </FormControl>
                        <FormControl sx={{ width: "100%", mt: 2.5 }}>
                          <Select
                            className={styles["text-select"]}
                            displayEmpty
                            value={values.date_type}
                            name="date_type"
                            onChange={handleChange}
                            sx={{
                              borderRadius: "8px",
                              height: "45px",
                              background: "#FFFF 0% 0% no-repeat padding-box",
                            }}
                            error={!!errors.date_type}
                            renderValue={
                              !!values.date_type
                                ? undefined
                                : () => <Placeholder>すべての曜日</Placeholder>
                            }
                          >
                            {dataDays?.map(
                              (item, index) =>
                                item && (
                                  <MenuItem
                                    key={index}
                                    className={styles["tag"]}
                                    value={item}
                                  >
                                    {item}
                                  </MenuItem>
                                )
                            )}
                          </Select>
                          {errors.date_type && (
                            <FormHelperText sx={{ color: "#d32f2f" }}>
                              曜日を選択してください。
                            </FormHelperText>
                          )}
                        </FormControl>
                        <FormControl sx={{ width: "100%", mt: 2.5 }}>
                          <Select
                            className={styles["text-select"]}
                            displayEmpty
                            name="meal"
                            value={values.meal}
                            onChange={handleChange}
                            error={!!errors.meal}
                            sx={{
                              borderRadius: "8px",
                              height: "45px",
                              background: "#FFFF 0% 0% no-repeat padding-box",
                            }}
                            renderValue={
                              !!values.meal
                                ? undefined
                                : () => (
                                    <Placeholder>食事の提供も可能</Placeholder>
                                  )
                            }
                          >
                            {dataMeals?.map(
                              (item, index) =>
                                item && (
                                  <MenuItem
                                    key={index}
                                    className={styles["tag"]}
                                    value={item}
                                  >
                                    {item}
                                  </MenuItem>
                                )
                            )}
                          </Select>
                          {errors.meal && (
                            <FormHelperText sx={{ color: "#d32f2f" }}>
                              食事の提供を選択してください。
                            </FormHelperText>
                          )}
                        </FormControl>
                        <FormControl sx={{ width: "100%", mt: 2.5 }}>
                          <Select
                            name="benefits"
                            className={styles["text-select"]}
                            displayEmpty
                            onChange={(e) => {
                              if (e.target.value !== "その他") {
                                setFieldValue("input_perks", "");
                              }
                              return handleChange(e);
                            }}
                            error={!!errors.benefits}
                            value={values.benefits}
                            sx={{
                              borderRadius: "8px",
                              height: "45px",
                              background: "#FFFF 0% 0% no-repeat padding-box",
                            }}
                            renderValue={
                              !!values.benefits
                                ? undefined
                                : () => <Placeholder>すべての特典</Placeholder>
                            }
                          >
                            {dataPerks?.map(
                              (item, index) =>
                                item && (
                                  <MenuItem
                                    key={index}
                                    className={styles["tag"]}
                                    value={item}
                                  >
                                    {item}
                                  </MenuItem>
                                )
                            )}
                          </Select>
                          {errors.benefits && (
                            <FormHelperText sx={{ color: "#d32f2f" }}>
                              特典を選択してください。
                            </FormHelperText>
                          )}
                        </FormControl>
                        <FormControl sx={{ width: "100%", mt: 2.5 }}>
                          <TextField
                            disabled={values.benefits !== "その他"}
                            variant="outlined"
                            placeholder="特典でその他を選択した場合にのみ入力"
                            className={`${styles["text-select"]} ${styles["text-input"]}`}
                            value={values.input_perks}
                            helperText={errors.input_perks}
                            error={!!errors.input_perks}
                            name="input_perks"
                            onChange={handleChange}
                          />
                        </FormControl>
                        <FormControl sx={{ width: "100%", mt: 2.5, mb: 7.5 }}>
                          <Autocomplete
                            freeSolo
                            sx={{ width: "100%", mt: 2.5 }}
                            helperText={errors.hotel_id}
                            disablePortal
                            disableClearable={true}
                            className={`${styles["input-autocomplete"]} ${styles["text-select"]} ${styles["text-input"]}`}
                            options={hotels}
                            name="hotel_id"
                            value={values.hotel_id}
                            onChange={(event, newValue) => {
                              setFieldValue("hotel_id", newValue)
                            }}
                            filterOptions={(options, params) => {
                              return filter(options, params);
                            }}
                            getOptionLabel={(option) => {
                              if (typeof option === "string") {
                                return option;
                              }
                              if (option?.inputValue) {
                                return option?.inputValue;
                              }
                              return option?.name;
                            }}
                            onInputChange={(event, newInputValue) => {
                              inputHotelOnChange(newInputValue);
                            }}
                            onFocus={() => {
                              inputHotelOnChange("");
                            }}
                            renderOption={(props, option) => (
                              <Box component="li" {...props} sx={{ p: "10px 24px" }}>
                                <Box
                                  sx={{
                                    width: "100%",
                                    color: "#2E4E76",
                                    fontWeight: "bold",
                                    display: "flex",
                                  }}
                                >
                                  <Typography
                                    component={"span"}
                                    sx={{
                                      textAlign: "left",
                                      minWidth: "70%",
                                      fontWeight: "bold",
                                      fontSize: "16px",
                                    }}
                                  >
                                    {option.name}
                                  </Typography>
                                  <Typography
                                    component={"span"}
                                    sx={{
                                      textAlign: "right",
                                      fontWeight: "bold",
                                      fontSize: "16px",
                                    }}
                                  >
                                    {option.address}
                                  </Typography>
                                </Box>
                              </Box>
                            )}

                            renderInput={(params) => (
                              <Box sx={{ width: "100%" }}>
                                <TextField
                                  sx={{
                                    width: "100%",
                                    "& .MuiOutlinedInput-root": {
                                      p: 0,
                                    },
                                    "& .MuiAutocomplete-endAdornment": {
                                      right: "3px",
                                    },
                                  }}
                                  label={values.name}
                                  value={values.in_site_id}
                                  {...params}
                                  className={styles["competitor--card--input"]}
                                  placeholder="宿泊施設名を入力してください"
                                />
                              </Box>
                            )}
                          />
                          {errors.hotel_id && (
                            <FormHelperText sx={{ color: "#d32f2f" }}>
                              ホテルを選択してください
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Box>
                  <Typography className={styles["text-title"]}>
                    期間を決める
                  </Typography>
                  <Box sx={{ marginTop: "28px" }}>
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <Typography className={styles["text-box"]}>
                          掲載期間、販売期間を指定してください。 特に
                          指定がない場合は、「期間を定めない」
                          にチェックを入れてください。
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="date_select_type"
                                onChange={handleChange}
                                checked={values.date_select_type}
                              />
                            }
                            label={
                              <Typography
                                variant="h7"
                                style={{ color: "#2E4E76", fontWeight: 500 }}
                              >
                                期間を定めない
                              </Typography>
                            }
                          />
                        </FormGroup>
                        <Typography
                          className={styles["text-date"]}
                          sx={{ mt: 3.5, fontWeight: 700 }}
                        >
                          販売期間
                        </Typography>
                        <Box
                          sx={{
                            display: "grid",
                            marginTop: "12px",
                            width: "100%",
                            gap: "16px",
                            alignItems: "center",
                            gridTemplateColumns: "1fr auto 1fr",
                          }}
                        >
                          <Box
                            sx={{ flexGrow: 1 }}
                            className="custom-datepicker-container"
                          >
                            <DatePicker
                              locale="ja"
                              selected={values.start_tarm}
                              disabled={values.date_select_type}
                              dateFormat={PLAN_DATE_FORMAT}
                              onChange={(date) => {
                                if (date) {
                                  const formattedDate = moment(date).toDate();
                                  setFieldValue(
                                    "start_tarm",
                                    formattedDate
                                  );
                                } else {
                                  setFieldValue("start_tarm", null);
                                }
                              }}
                              calendarClassName="custom-datepicker"
                              className="custom-datepicker-container"
                              customInput={<DatepickerButton />}
                            />
                            <ErrorMessage
                              className="error-text"
                              name="start_tarm"
                              component="div"
                            />
                          </Box>
                          <Box>〜</Box>
                          <Box
                            sx={{ flexGrow: 1 }}
                            className="custom-datepicker-container"
                          >
                            <DatePicker
                              locale="ja"
                              disabled={values.date_select_type}
                              selected={values.end_tarm}
                              dateFormat={PLAN_DATE_FORMAT}
                              onChange={(date) => {
                                if (date) {
                                  const formattedDate = moment(date).toDate();
                                  setFieldValue("end_tarm", formattedDate);
                                } else {
                                  setFieldValue("end_tarm", null);
                                }
                              }}
                              calendarClassName="custom-datepicker"
                              customInput={<DatepickerButton />}
                            />
                            <ErrorMessage
                              className="error-text"
                              name="end_tarm"
                              component="div"
                            />
                          </Box>
                        </Box>
                        <Box className={styles["box-button"]}>
                          <Typography
                            onClick={handleSubmit}
                            component={Button}
                            disabled={isSubmitting}
                            className={styles["text-button"]}
                          >
                            上記の内容でプランを造成する
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Paper>
              </>
            )}
          </Formik>
        </Box>

        {LoadingModal}
      </Paper>
      {RecommendPlansTable}
      <Popover
        open={!!currentCell}
        anchorEl={currentCell}
        onClose={handleCloseCellAction}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{
          "& .MuiPopover-paper": {
            borderRadius: 0,
            backgroundColor: "#2E4E76",
            minWidth: "130px",
            p: "10px 12px",
          },
        }}
      >
        <Typography
          sx={{
            color: "#FFFFFF",
            textDecoration: "none",
            fontSize: "14px",
            fontWeight: "bold",
          }}
          component={Link}
          to={`/suggestions/plans/result/${selectedRow?.id}`}
        >
          詳細を見る
        </Typography>
      </Popover>
    </>
  );
}
