import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import HTTP from "../../http_common";

const initialState = {
  list: [],
  loading: false,
  error: null,
  paginate: {
    count: 0,
    next: null,
    previous: null,
    total_pages: 0,
  },
};

export const getListOta = createAsyncThunk("otas/getListOta", async (body) => {
  const response = await HTTP.get(`/api/otas/`, {
    params: { ...body },
  });
  return response;
});

export const getOptionsViewOta = createAsyncThunk(
  "otas/getOtaSelectOptions",
  async () => {
    const response = await HTTP.get(`/api/ota/select-options/`);
    return response;
  }
);

export const createOrUpdateOta = createAsyncThunk(
  "otas/createOrUpdateOta",
  async (body) => {
    if (body.id) {
      const response = await HTTP.put(`/api/otas/${body.id}/`, body);
      return response;
    } else {
      const response = await HTTP.post(`/api/otas/`, body);
      return response;
    }
  }
);

export const getOtaById = createAsyncThunk("otas/getOtaById", async (id) => {
  const response = await HTTP.get(`/api/otas/${id}/`);
  return response;
});

export const deleteOtaById = createAsyncThunk("otas/deleteOtaById", async (id) => {
  const response = await HTTP.delete(`/api/otas/${id}/`);
  return response;
});

export const otasSlice = createSlice({
  name: "otas",
  initialState,
  reducers: {},
  extraReducers: {
    [getListOta.pending]: (state) => {
      state.loading = true;
    },
    [getListOta.fulfilled]: (state, { payload, meta }) => {
      state.loading = false;
      if (meta.arg.is_admin_interface) {
        const { data, total_pages } = payload.results;
        state.list = data;
        state.paginate = {
          total_pages,
        };
      } else {
        state.list = payload.data;
        state.paginate = {}
      }
    },
    [getListOta.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },
    [getOptionsViewOta.pending]: (state) => {
      state.loading = true;
    },
    [getOptionsViewOta.fulfilled]: (state, { payload }) => {
      state.loading = false;
    },
    [getOptionsViewOta.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },
    [createOrUpdateOta.pending]: (state) => {
      state.loading = true;
    },
    [createOrUpdateOta.fulfilled]: (state, { payload }) => {
      state.loading = false;
    },
    [createOrUpdateOta.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },
    [deleteOtaById.pending]: (state) => {
      state.loading = true;
    },
    [deleteOtaById.fulfilled]: (state, { payload }) => {
      state.loading = false;
    },
    [deleteOtaById.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },
  },
});

export default otasSlice.reducer;
