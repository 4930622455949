import { Box, Card, CardContent, Typography } from "@mui/material";
import BreadCrumb from "../../../../components/breadCrumb";
import styles from "../styles.module.scss"
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import React, { useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { customHeaderImportPlan } from "../../../../features/plans/plansSlice";
import { useDispatch } from "react-redux";
import CircularProgress from '@mui/material/CircularProgress';
import Modal from '@mui/material/Modal';
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";


const fileTypes = ["CSV"];
const textRoutes = ["ダッシュボード", "実績登録・確認", "プラン販売実績"];
export default function PlanGroupImport() {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleChange = async (file) => {
    setOpen(true);
    const res = await dispatch(customHeaderImportPlan(file));
    if (res?.payload?.status === 200) {
      const header_successes = res?.payload?.header_successes;
      const header_errors = res?.payload?.header_errors;
      const header_maps = res?.payload?.header_maps;
      history.push("/results/plan/import/items", {
        header_successes: header_successes,
        header_errors: header_errors,
        header_maps: header_maps,
        file: file
      });
      toast.success("CSVデータのインポートに成功しました。");
    } else {
      toast.error(res?.payload?.data?.error);
    }
    setOpen(false);
  };

  return (
    <Box
      component="main"
      sx={{ flexGrow: 1, height: "100%", overflow: "auto", p: 4, display: "flex", flexDirection: "column" }}>
      <BreadCrumb
        content="実績登録・確認"
        textRoutes={textRoutes} />
      <Box className={styles["plan-group-import-container"]} sx={{ flexGrow: 1 }}>
        <Typography variant="h6" className={styles["plan-group-import-container--title"]}>
          プランデータのインポート
          <HelpOutlineOutlinedIcon sx={{ ml: 2.5 }} />
        </Typography>
        <Card sx={{ flexGrow: 1, width: "100%", mt: 3, borderRadius: "10px", boxShadow: "0px 3px 6px #2C28281C", p: 5 }}>
          <FileUploader handleChange={handleChange} name="file" types={fileTypes} sx={{}}>
            <CardContent sx={{ p: 0 }} className={styles["plan-group-import--content"]}>
              <div className={styles["upload-description"]}>
                <Box className={styles["upload-description--img"]}>
                  <FileUploadOutlinedIcon sx={{ width: "50px", height: "50px" }} />
                </Box>
                <div className={styles["upload-description--text"]}>
                  クリックまたは、CSVファイルをドロップ
                </div>
                <div className={styles["upload-description--impotent"]}>
                  ※できるだけ長い期間のデータをアップロードしてください。
                  <br />
                  ※宿泊者の個人が特定できるようなデータは取り込まれません。
                </div>
              </div>
            </CardContent>
          </FileUploader>
        </Card>
      </Box>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <>
          <Box className={styles["box-modal"]}>
            <Typography
              sx={{ display: "flex", marginTop: "50px", justifyContent: "center" }}
              id="modal-modal-title"
              variant="h6"
              component="h2">
              <CircularProgress/>
            </Typography>
            <Typography
              className={styles["title-modal"]}
              id="modal-modal-description"
              sx={{ mt: 2 }}>
              Loading
            </Typography>
          </Box>
        </>
      </Modal>
    </Box>
  );
}
