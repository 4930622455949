import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const { REACT_APP_API_ENDPOINT } = process.env;
export const fetchSelectOptions = createAsyncThunk('getSelectOptions/fetchSelectOptions', async () => {
  const response = await fetch(`${REACT_APP_API_ENDPOINT}/api/notifications/select-options/`, {
    method: 'GET',
    headers: {
        'Content-Type': 'application/json',
    },
  });
  const jsonData = await response.json();
  return jsonData;
});
const apiSlice = createSlice({
  name: 'select-options',
  initialState: {
    data: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSelectOptions.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchSelectOptions.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchSelectOptions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;

      });
  },
});

export default apiSlice.reducer;
