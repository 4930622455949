import {
  Box,
  Card,
  Typography,
} from "@mui/material";
import BreadCrumb from "../../../components/breadCrumb";
import styles from "./styles.module.scss";
import MailOutlineIcon from "@mui/icons-material/MailOutline";

const ContactCompletePage = () => {

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        overflow: "auto",
        p: 4,
      }}
    >
      <BreadCrumb content="お問い合わせ" textRoutes={["ダッシュボード", "お問い合わせ", "お問い合わせ完了"]} />
      <Box className={styles["contactComplete"]}>
        <Card className={styles["contactComplete-card"]} sx={{ mt: 3 }}>
          <Box className={styles["contactComplete-card--header"]}>
            <MailOutlineIcon sx={{ fontSize: "30px", mr: 2 }} />
            <Typography className={styles["contactComplete-card--title"]}>
              お問い合わせ完了
            </Typography>
          </Box>
          <Box className={styles["contactComplete-card--body"]}>
            <Box>
              <Typography className={styles["contactComplete-card--text"]}>
                お問い合わせは正常に送信されました。
                <br />
                2~3営業日経ってもご返信が無い場合は
                <br />
                <a
                  href="mailto:info@levitt.ai"
                  className={styles["contactComplete-card--text"]}
                >
                  info@levitt.ai
                </a>
                にご連絡くださいませ。
              </Typography>
            </Box>
          </Box>
        </Card>
      </Box>
    </Box>
  );
};

export default ContactCompletePage;
