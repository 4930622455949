import { Box } from "@mui/material";
import styles from "./styles.module.scss";

const lineWidth = (value, maxValue, limitWidth = 1) => {
  const result = (value / maxValue) * 100 * limitWidth;
  if (!result) {
    return 0;
  }
  return result;
};
const ReportStackBarChart = (props) => {
  const { data, limitWidth = 0.55, maxValue = 1 } = props;
  return (
    <Box className={styles["chart-container"]}>
      <Box
        sx={{
          "--width": lineWidth(data.total.value, maxValue, limitWidth) + "%",
          display: "flex",
          borderColor: data.total.color || "#7764E4",
          backgroundColor: data.total.color || "#7764E4",
          animation: "width-increase 1s ease-in-out forwards",
        }}
        className={styles["chart-data"]}
      >
        <Box className={styles["grouped-detail"]}>
          <p className={styles["grouped-detail-title"]}>{data.total.title}</p>
          <p className={styles["grouped-detail-value"]}>{data.total.value}</p>
        </Box>
      </Box>
      {data?.groups?.length && (
        <>
          <Box className={styles["line"]}></Box>
          <Box className={styles["grouped-line"]}>
            {data?.groups?.map((item, index) => {
              const width = lineWidth(item.value, maxValue, limitWidth);
              return (
                <Box
                  key={index}
                  sx={{
                    "--width": width + "%",
                    display: width ? "block" : "none",
                    borderColor: item.color || "#7764E4",
                    backgroundColor: item.color || "#7764E4",
                    animation: "width-increase 1s ease-in-out forwards",
                  }}
                  className={styles["chart-data"]}
                >
                  <Box className={styles["grouped-detail"]}>
                    <p className={styles["grouped-detail-title"]}>
                      {item.title}
                    </p>
                    <p className={styles["grouped-detail-value"]}>
                      {item.value}
                    </p>
                  </Box>
                </Box>
              );
            })}
          </Box>
        </>
      )}
    </Box>
  );
};
export default ReportStackBarChart;
