import * as React from "react";
import Typography from "@mui/material/Typography";
import styles from "./styles.module.scss";
import { Box } from "@mui/system";
import Home from '../../assets/lucide_home.png';

export default function BreadCrumb(props) {
  const { content, textRoutes } = props;
  return (
    <Box
      sx={{
        display: "flex",
        marginBottom: "50px",
        alignItems: "center",
        color: "#1A1B35",
      }}
    >
      <Typography className={styles["content-left"]}>{content}</Typography>
      <img
        src={Home}
        alt="Home"
        style={{
          objectFit: "contain",
          marginRight: "20px",
        }}
      />
      {Array.isArray(textRoutes) &&
        textRoutes.map((text, index) => (
          <React.Fragment key={index}>
            <Typography className={styles["content-center"]}>
              {text}
              {index + 1 !== textRoutes.length && (
                <Typography className={styles["minus"]} component={"span"}>
                  -
                </Typography>
              )}
            </Typography>
          </React.Fragment>
        ))}
    </Box>
  );
}
