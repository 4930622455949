/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import styles from "./styles.module.scss";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getRecommendPlan } from "../../../../features/recommendPlan/recommendPlanSlice";
import { registerLocale } from "react-datepicker";
import ja from "date-fns/locale/ja";
import moment from "moment";
import PropTypes from "prop-types";
import Container from "@mui/material/Container";

registerLocale("ja", ja);

const SubmittedReview = (props) => {
  const { children, value, isSubmitted, valueType } = props;

  function convertValueToString(value) {
    if (!value) {
      return "";
    }
    switch (valueType) {
      case "date":
        return moment(value).format("YYYY.MM.DD");
      case "array":
        return value.join(",");
      default:
        return value;
    }
  }

  return (
    <>
      {isSubmitted ? (
        <Typography className={styles["text-box"]}>
          {convertValueToString(value)}
        </Typography>
      ) : (
        <>{children}</>
      )}
    </>
  );
};

SubmittedReview.propTypes = {
  isSubmitted: PropTypes.bool.isRequired,
};

export default function PlanResultDetail(props) {
  const dispatch = useDispatch();
  const { id } = useParams();
  // Get the 'planResult' from the 'plans' state
  const planResult = window.history?.state?.planResult;
  // Get the 'plan' from the 'recommendPlan' state
  const { plan } = useSelector((state) => state.recommendPlan);

  // If 'planResult' is null or undefined, use 'plan' instead
  const [createdPlan, setCreatedPlan] = useState(planResult || plan);


  useEffect(() => {
    async function getPlanResult() {
      const res = await dispatch(getRecommendPlan(id));
      setCreatedPlan(res.payload[0]);

    }

    getPlanResult();
  }, [id]);
  const history = useHistory();

  const sumbitCancel = () => {
    return history.push(`/admin/recommend-plans`);
  };

  return (
    <Box
      component="main"
      sx={{
        backgroundColor: "#F1F3F9",
      }}
    >
      <Container className={styles["home-center"]} maxWidth="xl">
        <Grid container spacing={2}>
          <Grid className={styles["text-left"]} item xs={2}>
            プランタイトル
          </Grid>
          <Grid item xs={10}>
            <Typography className={styles["text-header-home"]}>
              {createdPlan?.title}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid className={styles["text-left"]} item xs={2}>
            プラン説明文
          </Grid>
          <Grid item xs={10}>
            <Typography className={styles["text-header-home"]}>
              {createdPlan?.description}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid className={styles["text-left"]} item xs={2}>
            特典
          </Grid>
          <Grid item xs={10}>
            <Typography className={styles["text-header-home"]}>
              {createdPlan?.benefits}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid className={styles["text-left"]} item xs={2}>
            参考販売価格
          </Grid>
          <Grid item xs={10}>
            <Typography className={styles["text-header-home"]}>
              {createdPlan?.budget ?? 20000}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid className={styles["text-left"]} item xs={2}>
            売価1得格
          </Grid>
          <Grid item xs={10}>
            <Typography className={styles["text-header-home"]}>
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid className={styles["text-left"]} item xs={2}>
            食事の有無
          </Grid>
          <Grid item xs={10}>
            <Typography className={styles["text-header-home"]}>
              {createdPlan?.meal_type}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid className={styles["text-left"]} item xs={2}>
            販売期間
          </Grid>
          <Grid item xs={10}>
            <Typography className={styles["text-header-home"]}>
              {createdPlan?.sales_start_period} ~ {createdPlan?.sales_end_period}
            </Typography>
          </Grid>
        </Grid>
        <Box className={styles["box-button"]}>
          <Box
            onClick={sumbitCancel}
            variant="h7"
            className={styles["text-cancel"]}>
            戻る
          </Box>
        </Box>
      </Container>
    </Box>

  );
}
