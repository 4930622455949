import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
  Image,
} from "@react-pdf/renderer";
import SourceHanSansJP_Regular from "../../assets/fonts/SourceHanSansJP-Regular.otf";
import SourceHanSansJP_Bold from "../../assets/fonts/SourceHanSansJP-Bold.otf";
import SourceHanSansJP_Medium from "../../assets/fonts/SourceHanSansJP-Medium.otf";
import Logo from "../../assets/images/logo.png";
import moment from "moment";

Font.register({
  family: "SourceHanSansJP",
  fonts: [
    {
      src: SourceHanSansJP_Regular,
    },
    { src: SourceHanSansJP_Medium, fontWeight: 500 },
    { src: SourceHanSansJP_Bold, fontWeight: 700 },
  ],
});

// Create styles
const styles = StyleSheet.create({
  page: {
    display: "flex",
    flexDirection: "column",
    fontFamily: "SourceHanSansJP",
    padding: "48px 32px",
  },
  createdAt: {
    color: "#3F7EAE",
    fontSize: 14,
    fontWeight: 500,
    marginBottom: 12,
  },
  title: {
    color: "#172B4D",
    fontSize: 20,
    paddingBottom: 12,
    borderStyle: "solid",
    borderWidth: 4,
    borderColor: "#97CBE2",
    borderRightWidth: 0,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    fontWeight: 700,
  },
  subTitle: {
    fontSize: 16,
    color: "#3F7EAE",
    paddingBottom: 12,
    borderStyle: "solid",
    borderWidth: 2,
    borderColor: "#97CBE2",
    borderRightWidth: 0,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    fontWeight: 700,
    marginBottom: 12,
    marginTop: 32,
  },
  contentText: {
    color: "#172B4D",
    fontSize: 14,
    fontWeight: 500,
  },
  dFlex: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 12,
  },
  subContentTextTitle: {
    color: "#172B4D",
    fontSize: 14,
    fontWeight: 500,
    width: 90,
    marginRight: 32,
  },
});

const PlanDetailDocument = ({ plan, startDate, endDate }) => {
  return (
    <Document>
      <Page size="A4" wrap={false} style={styles.page}>
        <View>
          <Text style={styles.createdAt}>
            作成日: {moment().format("YYYY.MM.DD")}
          </Text>
        </View>
        <View>
          <Text style={styles.title}>{plan?.title}</Text>
        </View>
        <View>
          <Text style={styles.subTitle}>プラン説明文</Text>
        </View>
        <View>
          <Text style={styles.contentText}>{plan?.description}</Text>
        </View>
        <View>
          <Text style={styles.subTitle}>特典</Text>
        </View>
        <View>
          <Text style={styles.contentText}>{plan?.benefits}</Text>
        </View>
        <View>
          <Text style={styles.subTitle}>販売条件</Text>
        </View>
        <View style={styles.dFlex}>
          <Text style={styles.subContentTextTitle}>参考販売価格</Text>
          <Text style={styles.contentText}>{plan?.budget}円 /泊 1名</Text>
        </View>
        <View style={styles.dFlex}>
          <Text style={styles.subContentTextTitle}>食事の有無</Text>
          <Text style={styles.contentText}>{plan?.meal}</Text>
        </View>
        <View style={styles.dFlex}>
          <Text style={styles.subContentTextTitle}>販売期間</Text>
          <Text style={styles.contentText}>
            {moment(startDate).format("YYYY.MM.DD")}〜
            {moment(endDate).format("YYYY.MM.DD")}
          </Text>
        </View>
        <View style={styles.dFlex}>
          <Text style={styles.subContentTextTitle}>販売するOTA</Text>
          <Text style={styles.contentText}>
            {typeof plan.ota_sell === "string"
              ? plan.ota_sell
              : plan.ota_sell.map(
                  (item, index) =>
                    item + (index === plan.ota_sell.length - 1 ? "" : ",")
                )}
          </Text>
        </View>
        <View
          style={{
            display: "flex",
            flexDirection: "row-reverse",
            marginTop: "20px",
            width: "100%",
            marginRight: "0",
          }}
          fixed
        >
          <Image src={Logo} style={{ width: "150px" }} />
        </View>
      </Page>
    </Document>
  );
};

export default PlanDetailDocument;
