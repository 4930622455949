import moment from "moment";
import { UNREGISTER } from "./constants";
import React from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const clearStorages = (storageItems) => {
  storageItems.forEach((item) => {
    localStorage.removeItem(item);
  });
};

export const regex = {
  password: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-.=_]).{8,}$/,
  postal_code: /^〒?[0-9]{3}(-?[0-9]{4})$/,
};

export const ChartAreaBorder = {
  id: "chartAreaBorder",
  beforeDraw(chart, args, options) {
    const {
      ctx,
      chartArea: { left, top, width, height },
    } = chart;
    ctx.save();
    ctx.strokeStyle = options.borderColor;
    ctx.lineWidth = options.borderWidth;
    ctx.strokeRect(left, top, width, height);
    ctx.restore();
  },
};

export const ChartBackgroundColor = {
  id: "chartBg",
  beforeDraw: (chart, args, options) => {
    const { ctx } = chart;
    ctx.save();
    ctx.globalCompositeOperation = "destination-over";
    ctx.fillStyle = options.color || "#99ffff";
    ctx.fillRect(0, 0, chart.width, chart.height);
    ctx.restore();
  },
};

export const getCellValue = (row, keyString, emptyText) => {
  const keys = keyString.split(".");
  let currentObj = row;

  for (const key of keys) {
    currentObj = currentObj[key];
    if (currentObj === undefined || currentObj === null) {
      currentObj = emptyText || UNREGISTER;
      break;
    }
  }

  return currentObj;
};

export function descendingComparator(a, b, orderBy) {
  if (getCellValue(b, orderBy) < getCellValue(a, orderBy)) {
    return -1;
  }
  if (getCellValue(b, orderBy) > getCellValue(a, orderBy)) {
    return 1;
  }
  return 0;
}

export function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort(array, comparator) {
  if (Array.isArray(array)) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }
}

export const matchPath = (...patterns) => {
  const path = window.location.pathname;
  for (let index = 0; index < patterns.length; index++) {
    const element = patterns[index];
    const regexPattern = new RegExp(`^${element.replace(/\*/g, ".*")}$`);
    if (regexPattern.test(path)) return true;
  }
  return false;
};

export const CurrencyFormat = (value) => {
  if (!value || typeof value !== "number") {
    return value;
  }
  if (Number.isNaN(Number.parseFloat(value))) {
    return value;
  }
  return value.toLocaleString("en-US", {
    maximumFractionDigits: 0,
  });
};

const FORMAT_DATE_DEFAULT = "YYYY-MM-DD";

export const getDefaultDateRange = () => {
  const now = moment();
  const firstOfMonth = now.clone().startOf("month");
  const lastOfMonth = now.clone().endOf("month");
  return [
    firstOfMonth.format(FORMAT_DATE_DEFAULT),
    lastOfMonth.format(FORMAT_DATE_DEFAULT),
  ];
};

export const getStartEndDateString = (
  dateRange,
  formatDate = FORMAT_DATE_DEFAULT
) => {
  const [start, end] = dateRange;
  return {
    start: moment(start).format(formatDate),
    end: moment(end).format(formatDate),
  };
};

const helper = {
  clearStorages,
  regex,
};

export const isObjectEmpty = (objectName) => {
  return (
    objectName &&
    Object.keys(objectName).length === 0 &&
    objectName.constructor === Object
  );
};

export function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export function triggerDownload(url, title = "document") {
  const a = document.createElement("a");
  a.href = url;
  a.download = title + ".pdf";
  a.click();
  a.remove();
}

export default helper;
