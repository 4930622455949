import React, { useState, useEffect } from "react";
import { usePDF } from "@react-pdf/renderer";
import PlansDocument from "./PlansDocument";
import { Typography } from "@mui/material";

/**
 * Triggers a download for a given URL by creating a hidden anchor element.
 * @param url - The URL of the file to be downloaded.
 * @param title - The title to be used for the downloaded file (default is 'document').
 */
function triggerDownload(url, title = "document") {
  const a = document.createElement("a");
  a.href = url;
  a.download = title + ".pdf";
  a.click();
}

export default function DownloadPdfLink({
  rows,
  onClick,
  pdfHeaders,
  totalPdfDetail,
  title,
  groupName,
}) {
  const [pdfInstance, updatePdfInstance] = usePDF({
    document: (
      <PlansDocument
        rows={rows}
        headers={pdfHeaders}
        totalPdfDetail={totalPdfDetail}
        groupName={groupName}
      />
    ),
  }); // Instantiate an empty PDF instance using the usePDF hook
  const [isDownloading, setIsDownloading] = useState(false);

  function initiateDownload() {
    setIsDownloading(true);
    updatePdfInstance(
      <PlansDocument
        rows={rows}
        headers={pdfHeaders}
        totalPdfDetail={totalPdfDetail}
        groupName={groupName}
      />
    ); // Update the PDF instance with your document when the download button is clicked
    if (onClick) onClick();
  }

  // Trigger the PDF download when the button is clicked and the PDF instance URL is available
  useEffect(() => {
    if (pdfInstance.url && isDownloading) {
      triggerDownload(pdfInstance.url, title);
      setIsDownloading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDownloading, pdfInstance.url]);

  return (
    <Typography
      sx={{
        cursor: "pointer",
        width: "100%",
        fontSize: "inherit",
        fontWeight: "inherit",
      }}
      onClick={initiateDownload}
      disabled={isDownloading}
    >
      .pdf
    </Typography>
  );
}
