import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import styles from "./styles.module.scss";
import BreadCrumb from "../../components/breadCrumb/index";
import { Link } from "react-router-dom/cjs/react-router-dom";
import AddPlan from "../../assets/icons/plan_add.svg";
import Ticket from "../../assets/icons/ticket.svg";
import Task from "../../assets/icons/tasks.svg";
import Sale from "../../assets/icons/sales.svg";

export default function ReceiveSuggestions() {
  return (
    <Box
      component="main"
      sx={{
        backgroundColor: "#F1F3F9",
        flexGrow: 1,
        height: "100vh",
        overflow: "auto",
      }}
    >
      <Container
        maxWidth="lg"
        sx={{ mt: 4, mb: 4, maxWidth: "97% !important" }}
      >
        <Grid container>
          <Grid item xs={12}>
            <BreadCrumb
              content="AIプラン作成"
              textRoutes={["ダッシュボード", "AIプラン作成"]}
            />
            <Box>
              <Typography className={styles["text-title"]}>
                アクションを選ぶ
              </Typography>
              <Grid
                container
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <Box
                  component={Link}
                  to={"/suggestions/plans/create"}
                  className={styles["box-button-edit"]}
                  sx={{ textDecoration: "none" }}
                >
                  <img
                    src={AddPlan}
                    alt="add plan"
                    className={styles["icon-common"]}
                  />
                  <Typography className={styles["text-button-edit"]}>
                    プラン作成
                  </Typography>
                </Box>
                <Box
                  className={`${styles["box-button-edit"]} ${styles["coming-soon-feature"]}`}
                >
                  <img
                    src={Ticket}
                    alt="Ticket"
                    className={styles["icon-common"]}
                  />
                  <Typography className={styles["text-button-edit"]}>
                    COMING SOON
                  </Typography>
                </Box>
                <Box
                  className={`${styles["box-button-edit"]} ${styles["coming-soon-feature"]}`}
                >
                  <img
                    src={Task}
                    alt="Ticket"
                    className={styles["icon-common"]}
                  />
                  <Typography className={styles["text-button-edit"]}>
                    COMING SOON
                  </Typography>
                </Box>
                <Box
                  className={`${styles["box-button-edit"]} ${styles["coming-soon-feature"]}`}
                >
                  <img
                    src={Sale}
                    alt="Ticket"
                    className={styles["icon-common"]}
                  />
                  <Typography className={styles["text-button-edit"]}>
                    COMING SOON
                  </Typography>
                </Box>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
