import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import HTTP from "../../http_common";

const initialState = {
  currentUser: undefined,
  loading: false,
  error: null,
};

export const getAuth = createAsyncThunk("auth/getAuth", async () => {
  const response = await HTTP.get(`/api/me/`);
  return response;
});

export const register = createAsyncThunk("auth/register", async (body) => {
  const response = await HTTP.post(`/api/users/register`, body);
  return response;
});

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: {
    [getAuth.pending]: (state) => {
      state.loading = true;
    },
    [getAuth.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.currentUser = payload;
    },
    [getAuth.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },
  },
});

// Action creators are generated for each case reducer function
// export const { setUsers } = usersSlice.actions

export default authSlice.reducer;
