/* eslint-disable react-hooks/exhaustive-deps */
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import styles from './styles.module.scss';
import { Button, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState} from "react";
import Pagination from '../../../components/pagination';
import { getListOta } from "../../../features/ota/otasSlice";
import { useHistory } from "react-router-dom";
import { OTA_OPTIONS, OTA_CATEGORY } from "../../../constants/index";
import ComponentModalDelete from "../../../components/modalDelete"
import { deleteOtaById } from "../../../features/ota/otasSlice"

export default function ReceiveSuggestions() {
  const dispatch = useDispatch();
  const { list: otas, paginate } = useSelector((state) => state.ota);
  const [currentPage, setCurrentPage] = useState(1);
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [id, setID] = useState();
  const handleClose = () => setOpen(false);

  useEffect(() => {
    const dataQuery = {
      is_admin_interface: "true",
      page: currentPage,
    };
    dispatch(getListOta(dataQuery));
  }, [open, currentPage]);

  const submitCreate = () => {
    return history.push(`/admin/OTA/create`);
  };

  const handleOpen = (id) => {
    setOpen(true);
    setID(id);
  };

  const submitEdit = (id) => {
    return history.push(`/admin/OTA/${id}`);
  };

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        overflow: "auto",
        backgroundColor: "#F1F3F9",
      }}
    >
      <Container className={styles["home-center"]} maxWidth="xl">
        <Box className={styles["box-header"]}>
          <Typography className={styles["text-header-home"]}>
            OTA施策一覧
          </Typography>
          <Box onClick={submitCreate} className={styles["box-create-noti"]}>
            新規作成
          </Box>
        </Box>
        <Table
          sx={{ minWidth: 650, backgroundColor: "#F1F3F9" }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell className={styles["text-head"]}>
                <Box className={styles["content-head"]}>OTA </Box>
              </TableCell>
              <TableCell className={styles["text-head"]}>
                <Box className={styles["content-head"]}>タイトル </Box>
              </TableCell>
              <TableCell className={styles["text-head"]}>
                <Box className={styles["content-head"]}>カテゴリ</Box>
              </TableCell>
              <TableCell className={styles["text-head"]}>
                <Box className={styles["content-head"]}>参画期限</Box>
              </TableCell>
              <TableCell className={styles["text-head"]}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{ backgroundColor: "#ffff" }} className="even-row">
            {otas?.map((item) => {
              return (
                <TableRow
                  key={item?.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell className={styles["text-table"]}>
                    {OTA_OPTIONS(item?.ota)}
                  </TableCell>
                  <TableCell
                    className={`${styles["text-table"]} ${styles["cut-text"]}`}
                  >
                    {item?.title}
                  </TableCell>
                  <TableCell className={styles["text-table"]}>
                    {OTA_CATEGORY(item?.category)}
                  </TableCell>
                  <TableCell className={styles["text-table"]}>
                    {item?.participation_deadline}
                  </TableCell>
                  <TableCell
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                    className={styles["text-table"]}
                    align="center"
                  >
                    <Button
                      onClick={() => submitEdit(item?.id)}
                      className={styles["text-edit"]}
                    >
                      編集
                    </Button>
                    <Button
                      onClick={() => handleOpen(item?.id)}
                      className={styles["text-delete"]}
                    >
                      削除
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <ComponentModalDelete
          action={deleteOtaById(id)}
          handleClose={handleClose}
          setOpen={setOpen}
          open={open}
          title={"OTA施策を削除しますか?"}
        />
        {paginate?.total_pages > 1 ? (
          <Pagination
            onChange={(event, value) => setCurrentPage(value)}
            count={paginate.total_pages}
            variant="outlined"
            shape="rounded"
          />
        ) : (
          ""
        )}
      </Container>
    </Box>
  );
}
