/* eslint-disable react-hooks/exhaustive-deps */
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import styles from './styles.module.scss';
import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect,useState } from "react";
import { useHistory } from "react-router-dom";
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import Pagination from '../../../components/pagination';
import { getListUser } from "../../../features/users/usersSlice";

export default function ReceiveSuggestions() {
  const dispatch = useDispatch();
  const users = useSelector(state => state.users.list.results);
  const [currentPage, setCurrentPage] = useState(1);
  const history = useHistory();

  const handleToEditUser = (id) => {
    return history.push(`/admin/user/edit/${id}`);
  }

  useEffect(() => {
    const dataQuery = {
      page: currentPage,
    };
    dispatch(getListUser(dataQuery));
  }, [currentPage]);


  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        overflow: "auto",
        backgroundColor: "#F1F3F9",
      }}
    >
      <Container className={styles["home-center"]} maxWidth="xl" sx={{ mb: 4 }}>
        <Box className={styles["box-header"]}>
          <PersonOutlineIcon className={styles["text-icon"]} />
          <Typography className={styles["text-header-home"]}>
            アカウント一覧
          </Typography>
        </Box>
        <Table
          sx={{ minWidth: 650, backgroundColor: "#F1F3F9" }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell className={styles["text-head"]}>
                <Box className={styles["content-head"]}>施設名 </Box>
              </TableCell>
              <TableCell className={styles["text-head"]}>
                <Box className={styles["content-head"]}>認証状況 </Box>
              </TableCell>
              <TableCell className={styles["text-head"]}>
                <Box className={styles["content-head"]}>担当者名</Box>
              </TableCell>
              <TableCell className={styles["text-head"]}>
                <Box className={styles["content-head"]}>メールアドレス</Box>
              </TableCell>
              <TableCell className={styles["text-head"]}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{ backgroundColor: "#ffff" }} className="even-row">
            {users?.data?.map((item) => {
              return (
                <TableRow
                  key={item?.id}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell className={`${styles["text-table"]} ${styles["cut-text"]}`}>
                    {item?.hotel_name ? item?.hotel_name : '未設定'}
                  </TableCell>
                  <TableCell className={styles["text-table"]}>
                    {item?.is_active ? "検証済み" : "未確認"}
                  </TableCell>
                  <TableCell className={styles["text-table"]}>
                    {item?.first_name + item?.last_name}
                  </TableCell>
                  <TableCell className={styles["text-table"]}>
                    {item?.email}
                  </TableCell>
                  <TableCell className={styles["text-button"]} align="center">
                    <Box
                      className={styles["action-text"]}
                      onClick={() => handleToEditUser(item?.id)}
                    >
                      アクション
                    </Box>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <Box>
          {
          users?.total_pages > 1 ?
          <Pagination onChange={(event, value) => setCurrentPage(value)} count={users?.total_pages} variant="outlined" shape="rounded" />
          :
          ""
          }
        </Box>
      </Container>
    </Box>
  );
}
