import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import HTTP from "../../http_common";

const initialState = {
  list: [],
  loading: false,
  paginate: {
    count: 0,
    next: null,
    previous: null,
    total_pages: 0,
  },
  error: null,
  planResult: null,
  planRequested: null,
};

export const getListHotelCompetition = createAsyncThunk(
  "hotelCompetition/getList",
  async (body) => {
    const response = await HTTP.get(`/api/competition-registration/`, {
      params: { ...body },
    });
    return response;
  }
);

export const registerHotelCompetition = createAsyncThunk(
  "hotelCompetition/register",
  async (hotel) => {
    try {
      const response = await HTTP.post(`/api/competition-registration/`, {hotel});
      return response;
    } catch (error) {
      throw error;
    }
});

export const deleteHotelCompetition = createAsyncThunk(
  "hotelCompetition/delete",
  async (id) => {
    const response = await HTTP.delete(`api/competition-registration/${id}/`);
    return response;
  }
);

export const competitorSlice = createSlice({
  name: "hotelCompetition",
  initialState,
  reducers: {},
  extraReducers: {
    [getListHotelCompetition.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getListHotelCompetition.fulfilled]: (state, { payload }) => {
      state.loading = false;
      const { results, ...paginate } = payload;
      state.list = results?.data;
      state.paginate = paginate;
    },
    [getListHotelCompetition.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },
    [registerHotelCompetition.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [registerHotelCompetition.fulfilled]: (state, { payload }) => {
      state.loading = false;
      if (payload.error) {
        state.error = payload.error;
      } else {
        const { data } = payload;
        state.planResult = data;
      }
    },
    [registerHotelCompetition.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error;
    },
    [deleteHotelCompetition.pending]: (state) => {
      state.loading = true;
    },
    [deleteHotelCompetition.fulfilled]: (state) => {
      state.loading = false;
    },
    [deleteHotelCompetition.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },
  },
});
export default competitorSlice.reducer;
