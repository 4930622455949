/* eslint-disable react-hooks/exhaustive-deps */
import { Box, TableCell, TableRow } from "@mui/material";
import BreadCrumb from "../../../../../components/breadCrumb";
import PlanGroupContainer from "../../../../../components/PlanGroupContainer";
import styles from "../../styles.module.scss";
import { getDetailGroupPlan ,getDetailPlanGroups } from "../../../../../features/plan/planGroupSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { deleteMultiPlans } from "../../../../../features/plans/plansSlice";
import { toast } from "react-toastify";
import PaginationPage from "../../../../../components/pagination";
import { CurrencyFormat } from "../../../../../helper";
import moment from "moment";
import { PLAN_TIME_FORMAT, UNREGISTER_GROUP } from "../../../../../constants";

export default function PlanGroupDetail() {
  const dispatch = useDispatch();
  let { id } = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [planList, setPlanList] = useState([]);
  const [timeRange, setTimeRange] = useState({
    start_time: moment().startOf("month").format(PLAN_TIME_FORMAT),
    end_time: moment().endOf("month").format(PLAN_TIME_FORMAT),
  });

  const { plans, groupPlansPaginate: paginate, totalAll } = useSelector(
    (state) => state.planGroup
  );

  const groupDetail = useSelector(
    (state) => state.planGroup.detailPlanGroups
  );

  useEffect(() => {
    dispatch(getDetailPlanGroups(id));
  }, [id]);

  useEffect(() => {
    if (searchValue === undefined) return
    fetchPlans();
  }, [currentPage, searchValue, timeRange]);

  const fetchPlans = async () => {
    await dispatch(
      getDetailGroupPlan({
        body: {
          page: currentPage,
          search_param: searchValue,
          ...timeRange,
        },
        id,
      })
    );
    if (paginate?.total_pages && paginate?.total_pages < currentPage) {
      setCurrentPage(paginate?.total_pages);
    }
  };

  const [total, setTotal] = useState({
    number_reservations: 0,
    reservation_amount: 0,
    avarege_price: 0,
  });

  const textRoutes = [
    "ダッシュボード",
    "実績登録・確認",
    "プラン販売実績",
    "プラングループ",
    groupDetail?.name,
  ];

  const handleDeletePlans = async (rows) => {
    try {
      const res = await dispatch(deleteMultiPlans(rows));
      if (res.payload.error) {
        toast.error("システムエラー");
      } else {
        fetchPlans();
      }
    } catch (error) {
      toast.error("システムエラー");
    }
  };

  const handleSearchPlans = (dataSearch) => {
    setCurrentPage(1);
    setSearchValue(dataSearch.search_param || "");
    if (
      dataSearch.time_range?.start_time !== timeRange?.start_time ||
      dataSearch.time_range?.end_time !== timeRange?.end_time
    ) {
      setTimeRange(dataSearch.time_range);
    }
  };

  useEffect(() => {
    const total = {
      number_reservations: 0,
      reservation_amount: 0,
      avarege_price: 0,
    };
    if (plans) {
      const transformPlans = plans.map((plan) => {
        total.reservation_amount += plan.reservation_amount;
        total.avarege_price += plan.avarege_price;
        total.number_reservations += plan.number_reservations;

        return {
          id: plan.id,
          name: plan.name,
          number_reservations: CurrencyFormat(plan.number_reservations),
          reservation_amount: "¥" + CurrencyFormat(plan.reservation_amount),
          avarege_price: "¥" + CurrencyFormat(plan.avarege_price),
        };
      });
      setTotal(total);
      setPlanList(transformPlans);
    }
  }, [plans]);

  const triggerDeletePlan = async (showPreviousPage) => {
    if (currentPage > 1 && showPreviousPage === true) {
     setCurrentPage(currentPage - 1);
   } else {
     fetchPlans();
   }
  }

  const triggerChangePlansGroup = async (showPreviousPage) => {
    if (currentPage > 1 && showPreviousPage === true) {
      setCurrentPage(currentPage - 1);
    } else {
      fetchPlans();
    }
  }

  const headCells = [
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "プラン名",
    },
    {
      id: "group_plan.name",
      numeric: false,
      disablePadding: true,
      label: "プラングループ",
      color: "#7764E4",
      options: {
        emptyText: groupDetail?.name || UNREGISTER_GROUP,
        emptyTextColor: "#7764e4",
      },
    },
    {
      id: "number_reservations",
      numeric: false,
      disablePadding: false,
      label: "予約件数",
      options: {
        nowrap: true,
      },
    },
    {
      id: "reservation_amount",
      numeric: false,
      disablePadding: false,
      label: "予約金額",
      options: {
        nowrap: true,
      },
    },
    {
      id: "avarege_price",
      numeric: false,
      disablePadding: false,
      label: "平均予約単価",
      prefix: "¥ ",
      options: {
        nowrap: true,
      },
    },
  ];


  const pdfHeaders = [
    {
      title: "プラン名",
      id: "name",
      width: "40%",
    },
    {
      title: "プラングループ名",
      id: "group_plan.name",
      width: "20%",
      bodyColor: "#172B4D",
      styles: {
        textAlign: "left",
        color: "#3F7EAE",
      },
    },
    {
      title: "予約件数",
      id: "number_reservations",
      width: "10%",
    },
    {
      title: "予約金額",
      id: "reservation_amount",
      width: "10%",
    },
    {
      title: "平均予約単価",
      id: "avarege_price",
      width: "10%",
    },
  ];

  const totalHeaders = [
    {
      title: "合計",
      value: "合計",
      width: "40%",
      styles: {
        textAlign: "left",
        color: "#172B4D",
      },
    },
    {
      title: "プラングループ名",
      value: groupDetail?.name || UNREGISTER_GROUP,
      width: "20%",
    },
    {
      title: "予約件数",
      id: "number_reservations",
      value: total.number_reservations,
      width: "10%",
      styles: {
        color: "#2E4E76",
      },
    },
    {
      title: "予約金額",
      id: "reservation_amount",
      value: total.reservation_amount,
      width: "10%",
      styles: {
        color: "#2E4E76",
      },
    },
    {
      title: "平均予約単価",
      id: "avarege_price",
      value: total.avarege_price,
      width: "10%",
      styles: {
        color: "#2E4E76",
      },
    },
  ];

  const csvFileName = `${groupDetail?.name}_${moment().format(
    "YYYYMMDDHHmmss"
  )}`;


  function TableFooter() {
    return (
      <>
        <TableRow
          className={`${styles["table-footer-row"]} ${styles["small-row"]}`}
        >
          <TableCell sx={{ bottom: "40px !important" }}></TableCell>
          <TableCell
            sx={{
              borderRight: "2px solid #8F8F9F",
              bottom: "40px !important",
            }}
          >
            プラングループ名の合計
          </TableCell>
          <TableCell sx={{ bottom: "40px !important" }}></TableCell>
          <TableCell sx={{ bottom: "40px !important" }}>
            {CurrencyFormat(totalAll.number_reservations)}
          </TableCell>
          <TableCell sx={{ bottom: "40px !important" }}>
            ¥{CurrencyFormat(totalAll.reservation_amount)}
          </TableCell>
          <TableCell sx={{ bottom: "40px !important" }}>
            ¥{CurrencyFormat(totalAll.avarege_price)}
          </TableCell>
        </TableRow>
        <TableRow
          className={`${styles["table-footer-row"]} ${styles["small-row"]}`}
        >
          <TableCell></TableCell>
          <TableCell
            sx={{
              borderRight: "2px solid #8F8F9F",
            }}
          >
            合計
          </TableCell>
          <TableCell></TableCell>
          <TableCell>{CurrencyFormat(total.number_reservations)}</TableCell>
          <TableCell>¥{CurrencyFormat(total.reservation_amount)}</TableCell>
          <TableCell>¥{CurrencyFormat(total.avarege_price)}</TableCell>
        </TableRow>
      </>
    );
  }

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        p: 4,
        height: "100%",
        overflow: "hidden",
        maxHeight: "100%",
        display: "flex",
        flexDirection: "column",
      }}
      className={styles["small-padding-table"]}
    >
      <BreadCrumb content="実績登録・確認" textRoutes={textRoutes} />
      <PlanGroupContainer
        rows={planList}
        headCells={headCells}
        TableFooter={TableFooter}
        isDetailPlanGroup
        isPlans={groupDetail?.name}
        groupID={id}
        handleDeleteRows={handleDeletePlans}
        onEventTriggerDelete={triggerDeletePlan}
        onEventTriggerSearch={handleSearchPlans}
        onUpdatedPlans={fetchPlans}
        pdfHeaders={pdfHeaders}
        totalPdfDetail={totalHeaders}
        csvFileName={csvFileName}
        onEventTriggerChangePlansGroup={triggerChangePlansGroup}
      >
        {paginate?.total_pages > 1 && (
          <PaginationPage
            count={paginate.total_pages}
            onChange={(event, value) => setCurrentPage(value)}
            variant="outlined"
            shape="rounded"
            page={currentPage}
          />
        )}
      </PlanGroupContainer>
    </Box>
  );
}
