import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import HTTP from '../http_common';
import helper from '../helper';

const { REACT_APP_API_ENDPOINT } = process.env;
export const fetchLogout = createAsyncThunk('logout/fetchLogout', async () => {
  const refresh_token = localStorage.getItem('refresh_token')
  const formData = new FormData();
  formData.append('refresh_token', refresh_token);
  const response = await HTTP.post(`${REACT_APP_API_ENDPOINT}/api/logout/`, formData)
  helper.clearStorages(["access_token", "role", "refresh_token"])
  return response;
});
const apiSlice = createSlice({
  name: 'logout',
  initialState: {
    data: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchLogout.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchLogout.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchLogout.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;

      });
  },
});

export default apiSlice.reducer;
