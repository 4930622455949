import { Box } from "@mui/material";
import styles from "./styles.module.scss";
import React from "react";

const lineWidth = (value = 0, maxValue = 1, limitWidth = 1) => {
  const result = (value / maxValue) * 100 * limitWidth;
  if (!result || result < 0) {
    return 0;
  }
  return result;
};
const ReportBarChart = (props) => {
  const { data, maxData = {} } = props;

  return (
    <Box className={styles["chart-container"]}>
      {data.map((item, index) => {
        const width = lineWidth(item.value, maxData[item.id], 0.55);

        return (
          <React.Fragment key={index}>
            <Box
              className={styles["chart-data"]}
              sx={{
                "--width": width + "%",
                display: width ? "flex" : "none",
                backgroundColor: item.color || "#7764E4",
                borderColor: item.color || "#7764E4",
                animation: "width-increase 1s ease-in-out forwards",
              }}
            ></Box>
            {index + 1 !== data.length && (
              <Box className={styles["line"]}></Box>
            )}
          </React.Fragment>
        );
      })}
    </Box>
  );
};
export default ReportBarChart;
