import { forwardRef } from "react";
import styles from "./styles.module.scss";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import { Button } from "@mui/material";

const DatepickerButton = forwardRef(({ value, onClick, disabled }, ref) => (
  <Button
    disabled={disabled}
    className={styles["custom-datepicker-button"]}
    onClick={onClick}
    ref={ref}
  >
    {value}
    <span className={styles["icon-choose"]}>
      <CalendarTodayOutlinedIcon />
    </span>
  </Button>
));

export default DatepickerButton;
