/* eslint-disable jsx-a11y/img-redundant-alt */
import * as React from 'react';
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Pagination from "@mui/material/Pagination";

const CustomPagination = styled(Pagination)`
  .MuiPaginationItem-root {
    min-width: 40px;
    height: 40px;
    margin: 0px;
    color: #3f7eae !important;
    background-color: #ffffff !important;
    border-radius: 0px;
    font: 500 16px/13px var(--font-family);
  }

  .MuiPaginationItem-outlined {
    border: 1px solid #b7e1f1 !important;
  }

  .MuiPaginationItem-root.Mui-selected {
    min-width: 40px;
    height: 40px;
    margin: 0px;
    color: #ffffff !important;
    background-color: #3f7eae !important;
    border-radius: 0px;
    border-color: #3f7eae !important;
    font: 500 16px/13px var(--font-family);
  }

  .MuiPaginationItem-ellipsis {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export default function PaginationPage(props) {
  const { count, onChange, page } = props;
  return (
    <>
      <Stack sx={{ padding: "24px 20px", alignItems: "flex-end" }} spacing={2}>
        <CustomPagination
          onChange={onChange}
          count={count}
          variant="outlined"
          shape="rounded"
          page={page}
        />
      </Stack>
    </>
  );
}
