import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import HTTP from "../../http_common";

const initialState = {
  menuOpen: true,
  data: {},
  planOta: [],
  planByWeek: [],
  roomType: [],
  prices: [],
  planRoomType: [],
  dayOfWeek: [],
  myHotel: {},
  loading: {
    overview: false,
    planOta: false,
    planByWeek: false,
    roomType: false,
    prices: false,
    planRoomType: false,
    dayOfWeek: false,
  },
};
export const getOverviewReport = createAsyncThunk(
  "report/getOverviewReport",
  async (body = {}) => {
    const response = await HTTP.get(`api/report/overview`, {
      params: body,
    });
    return response;
  }
);

export const getPlanOtaRanking = createAsyncThunk(
  "report/getPlanOtaRanking",
  async (body = {}) => {
    const response = await HTTP.get(`api/report/plan-ota-ranking`, {
      params: body,
    });
    return response;
  }
);

export const getPlanByDayOfWeek = createAsyncThunk(
  "report/getPlanByDayOfWeek",
  async (body = {}) => {
    const response = await HTTP.get(`api/report/plan-by-days-of-week`, {
      params: {
        ...body,
      },
    });
    return response;
  }
);

export const getPlanTopRoomType = createAsyncThunk(
  "report/getPlanTopRoomType",
  async (body = {}) => {
    const response = await HTTP.get(`api/report/plan-top-room-type`, {
      params: {
        ...body,
      },
    });
    return response;
  }
);

export const getRankingByPrice = createAsyncThunk(
  "report/getRankingByPrice",
  async (body = {}) => {
    const response = await HTTP.get(`api/report/plan-lead-time-price`, {
      params: {
        ...body,
      },
    });
    return response;
  }
);

export const getPlanRoomTypeRanking = createAsyncThunk(
  "report/getPlanRoomTypeRanking",
  async (body = {}) => {
    const response = await HTTP.get(`api/report/group-plan-each-room-type`, {
      params: {
        ...body,
      },
    });
    return response;
  }
);

export const getByDayOfWeekOverview = createAsyncThunk(
  "report/getByDayOfWeekOverview",
  async (body = {}) => {
    const response = await HTTP.get(`api/report/days-of-week-overview`, {
      params: {
        ...body,
      },
    });
    return response;
  }
);

export const reportSlice = createSlice({
  name: "report",
  initialState,
  reducers: {
    toggleMenu: (state) => {
      state.menuOpen = !state.menuOpen;
    },
  },
  extraReducers: {

    // overview
    [getOverviewReport.pending]: (state) => {
      state.loading.overview = true;
    },
    [getOverviewReport.fulfilled]: (state, { payload }) => {
      state.loading.overview = false;
      state.data = payload.data;
    },
    [getOverviewReport.rejected]: (state) => {
      state.loading.overview = false;
    },
    // plan OTA ranking
    [getPlanOtaRanking.pending]: (state) => {
      state.loading.planOta = true;
    },
    [getPlanOtaRanking.fulfilled]: (state, { payload }) => {
      state.loading.planOta = false;
      state.planOta = payload.data;
    },
    [getPlanOtaRanking.rejected]: (state) => {
      state.loading.planOta = false;
    },

    // plan by day of week
    [getPlanByDayOfWeek.pending]: (state) => {
      state.loading.planByWeek = true;
      state.error = null;
    },
    [getPlanByDayOfWeek.fulfilled]: (state, { payload }) => {
      state.loading.planByWeek = false;
      state.planByWeek = payload.data;
    },
    [getPlanByDayOfWeek.rejected]: (state) => {
      state.loading.planByWeek = false;
    },

    // plan top room type
    [getPlanTopRoomType.pending]: (state) => {
      state.loading.roomType = true;
    },
    [getPlanTopRoomType.fulfilled]: (state, { payload }) => {
      state.loading.roomType = false;
      state.roomType = payload.data;
    },
    [getPlanTopRoomType.rejected]: (state) => {
      state.loading.roomType = false;
    },

    // ranking by price
    [getRankingByPrice.pending]: (state) => {
      state.loading.prices = true;
    },
    [getRankingByPrice.fulfilled]: (state, { payload }) => {
      state.loading.prices = false;
      state.prices = payload.data;
    },
    [getRankingByPrice.rejected]: (state) => {
      state.loading.prices = false;
    },

    // plan room type ranking
    [getPlanRoomTypeRanking.pending]: (state) => {
      state.loading.planRoomType = true;
    },
    [getPlanRoomTypeRanking.fulfilled]: (state, { payload }) => {
      state.loading.planRoomType = false;
      state.planRoomType = payload.data;
    },
    [getPlanRoomTypeRanking.rejected]: (state) => {
      state.loading.planRoomType = false;
    },

    // by day of week
    [getByDayOfWeekOverview.pending]: (state) => {
      state.loading.dayOfWeek = true;
    },
    [getByDayOfWeekOverview.fulfilled]: (state, { payload }) => {
      state.loading.dayOfWeek = false;
      state.dayOfWeek = payload.data;
    },
    [getByDayOfWeekOverview.rejected]: (state) => {
      state.loading.dayOfWeek = false;
    },
  },
});

export const { toggleMenu } = reportSlice.actions;
export default reportSlice.reducer;
