/* eslint-disable jsx-a11y/anchor-is-valid */
import { Box, TextField, Typography, InputAdornment } from "@mui/material";
import styles from "./styles.module.scss";
import { useDispatch } from "react-redux";
import React, { useState } from "react";
import { resetPassword } from "../../../../../features/apiResetPassword";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { useParams, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Formik } from "formik";
import { Button } from "@mui/base";
import * as Yup from "yup";
import statusCode from "../../../../../constants/statusCodes";

export default function ResetPassword() {
  const [showPassword, setShowPassword] = useState(false);
  const [customError, setCustomError] = useState("");
  const history = useHistory();
  const dispatch = useDispatch();
  const { id, token } = useParams();

  const shouldShowIcon = showPassword;
  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const formikConfig = {
    initialValues: {
      password: "",
      uid: id,
      token: token,
    },
    onSubmit: async (values, { setSubmitting, setErrors }) => {
      dispatch(resetPassword(values)).then((res) => {
        setSubmitting(false);
        if (res.payload?.status === statusCode.CREATED) {
          toast.success(res.payload.message);
          return history.push("/login");
        } else {
          if (res.payload?.message) {
            setCustomError(res.payload?.message);
            setErrors("");
          } else {
            setErrors(res.payload?.data);
            setCustomError("");
          }
        }
      });
    },
    validationSchema: () => {
      return Yup.object({
        password: Yup.string()
          .required("パスワードを入力してください。")
          .min(8, "パスワードは８文字以上で入力してください。")
          .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#=])[A-Za-z\d@$!%*?&#=]+$/,
            "パスワードは英大文字、英小文字、数字、特殊文字が必要です。"
          ),
      });
    },
  };

  return (
    <>
      <Box className={styles["box-content"]}>
        <Box sx={{ width: "100%" }}>
          <Typography className={styles["content-password"]}>
            パスワード再設定
          </Typography>
          <Box className={styles["content-password"]}>
            <Typography className={styles["content-under-title"]}>
              新しいパスワードを入力してください
            </Typography>
          </Box>
          <Formik
            validateOnChange={false}
            initialValues={formikConfig.initialValues}
            onSubmit={formikConfig.onSubmit}
            validationSchema={formikConfig.validationSchema}
          >
            {({ handleChange, handleBlur, values, errors, handleSubmit }) => (
              <>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <TextField
                    error={!!errors.password}
                    className={styles["custom-field"]}
                    name="password"
                    type={showPassword ? "text" : "password"}
                    id="outlined-basic"
                    label="新しいパスワードを入力"
                    variant="outlined"
                    onChange={handleChange("password")}
                    onBlur={handleBlur("password")}
                    value={values.password}
                    helperText={errors.password}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {shouldShowIcon ? (
                            <VisibilityOffOutlinedIcon
                              onClick={handleTogglePasswordVisibility}
                              style={{ cursor: "pointer", color: "#3f7eae" }}
                            />
                          ) : (
                            <RemoveRedEyeOutlinedIcon
                              onClick={handleTogglePasswordVisibility}
                              style={{ cursor: "pointer", color: "#3f7eae" }}
                            />
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                  {customError && (
                    <Box className={styles["custom-error-message"]}>
                      {customError}
                    </Box>
                  )}
                </Box>
                <Box className={styles["box-continue"]}>
                  <Button
                    className={styles["text-button"]}
                    onClick={handleSubmit}
                  >
                    パスワードを再設定する
                  </Button>
                </Box>
              </>
            )}
          </Formik>
        </Box>
      </Box>
    </>
  );
}
