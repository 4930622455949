/* eslint-disable jsx-a11y/anchor-is-valid */
import { Box, TextField, Typography } from "@mui/material";
import styles from "./styles.module.scss";
import { useDispatch } from "react-redux";
import React, { useState } from "react";
import { forgetPassword } from "../../../features/apiForgetPassword";
import { Formik } from "formik";
import { Button } from "@mui/base";
import * as Yup from "yup";
import statusCode from "../../../constants/statusCodes";

export default function ForgetPassword() {
  const dispatch = useDispatch();
  const [isSentMail, setIsSentMail] = useState(false);
  const [customError, setCustomError] = useState("");

  const formikConfig = {
    initialValues: {
      email: "",
    },
    onSubmit: async (values, { setSubmitting, setErrors }) => {
      dispatch(forgetPassword(values)).then((res) => {
        setSubmitting(false);
        if (res.payload?.status === statusCode.CREATED) {
          setIsSentMail(true);
        } else {
          if (res.payload?.message) {
            setCustomError(res.payload?.message);
            setErrors("");
          } else {
            setErrors(res.payload?.data);
            setCustomError("");
          }
        }
      });
    },
    validationSchema: () => {
      return Yup.object({
        email: Yup.string()
          .email("メールアドレスの形式が正しくありません")
          .required("メールアドレスを入力してください。"),
      });
    },
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        pt: 10,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {isSentMail ? (
        <>
          <Box className={styles["box-content"]}>
            <Box sx={{ width: "100%" }}>
              <Typography className={styles["content-email"]}>
                パスワードリセット
              </Typography>
              <Box className={styles["content-email"]}>
                <Typography className={styles["content-bottom-email"]}>
                  パスワード再設定用のメールが送信されました。
                  <br />
                  15分以内にメールに記載のURLよりパスワードを再設定してください。
                </Typography>
              </Box>
              <Box className={styles["box-continue"]}>
                <a href="/login/forget" className={styles["text-button"]}>
                  再設定メールを送信
                </a>
              </Box>
            </Box>
          </Box>
        </>
      ) : (
        <>
          <Box className={styles["box-content"]}>
            <Box sx={{ width: "100%" }}>
              <Typography className={styles["content-email"]}>
                パスワードリセット
              </Typography>
              <Box className={styles["content-email"]}>
                <Typography className={styles["content-under-title"]}>
                  メールアドレスを入力してください
                </Typography>
              </Box>
              <Formik
                validateOnChange={false}
                initialValues={formikConfig.initialValues}
                onSubmit={formikConfig.onSubmit}
                validationSchema={formikConfig.validationSchema}
              >
                {({
                  handleChange,
                  handleBlur,
                  values,
                  errors,
                  handleSubmit,
                }) => (
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <TextField
                        error={!!errors.email}
                        className={styles["custom-field"]}
                        id="outlined-basic"
                        name="email"
                        label="メールアドレスを入力"
                        variant="outlined"
                        onChange={handleChange("email")}
                        onBlur={handleBlur("email")}
                        value={values.email}
                        helperText={errors.email}
                      />
                      {customError && (
                        <Box className={styles["custom-error-message"]}>
                          {customError}
                        </Box>
                      )}
                    </Box>
                    <Box className={styles["box-continue"]}>
                      <Button
                        className={styles["text-button"]}
                        onClick={handleSubmit}
                      >
                        Continue
                      </Button>
                    </Box>
                  </>
                )}
              </Formik>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
}
