import { Box } from "@mui/material";
import styles from "../styles.module.scss";
import ReportLayout from "../../../components/ReportLayout";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LineController,
  PointElement,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import {
  ChartAreaBorder,
  ChartBackgroundColor,
  getDefaultDateRange,
  getStartEndDateString,
} from "../../../helper";
import { useDispatch, useSelector } from "react-redux";
import { getRankingByPrice } from "../../../features/report/reportSlice";
import { useEffect, useRef, useState } from "react";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartBackgroundColor,
  ChartAreaBorder,
  LineElement,
  LineController,
  PointElement
);

const options = {
  layout: {
    padding: 8,
  },
  plugins: {
    legend: {
      display: false,
    },
    chartAreaBorder: {
      borderColor: "#D8D8E3",
      borderWidth: 1,
    },
    chartBg: {
      color: "#F8F9FD",
    },
  },
  responsive: true,
  datasets: {
    bar: {
      barPercentage: 0.4,
    },
    line: {
      pointHitRadius: 0,
      pointBorderWidth: 0,
      pointBackgroundColor: "transparent",
      pointBorderColor: "transparent",
    },
  },
  scales: {
    x: {
      stacked: true,
      ticks: {
        color: "#2E4E76",
        font: {
          weight: "500",
        },
      },
      grid: {
        color: "transparent",
      },
    },
    y: {
      stacked: true,
      ticks: {
        color: "#2E4E76",
        font: {
          weight: "500",
        },
      },
      grid: {
        color: "transparent",
      },
    },
  },
};

const ReportByPrice = () => {
  const dispatch = useDispatch();
  const reportData = useSelector((state) => state.report.prices);
  const [chartData, setChartData] = useState(undefined);
  const [dateRange, setDateRange] = useState(getDefaultDateRange());
  const [priceRangeString, setPriceRangeString] = useState("");
  const reportContainerRef = useRef(null);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const priceRanges = reportContainerRef.current?.priceRanges || [];

  useEffect(() => {
    fetchReportData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchReportData = (prices, dates = dateRange) => {
    setPriceRangeString(prices);
    setDateRange(dates);
    dispatch(
      getRankingByPrice({
        price_ranges: prices,
        ...getStartEndDateString(dates),
      })
    );
  };

  useEffect(() => {
    const times = [];
    const datasets = [];
    if (reportData?.length) {
      const groupData = {};
      reportData.forEach((report) => {
        times.push(report.time_label);
        const dataPriceRanges = report.price_ranges;
        const priceRangeList = report.price_ranges_list;

        priceRanges.forEach((range, rangeIndex) => {
          const key = range.to ?? "other";
          if (!groupData[key]) {
            groupData[key] = {
              color: range.color,
              data: [dataPriceRanges[rangeIndex] || null],
              label: priceRangeList[rangeIndex],
            };
          } else {
            groupData[key].data.push(dataPriceRanges[rangeIndex] || null);
          }
        });
      });

      const arrayReverseGroupData = Object.keys(groupData)
        .sort()
        .reverse()
        .map((key) => groupData[key]);

      arrayReverseGroupData.forEach((group) => {
        datasets.push({
          label: group.label,
          data: group.data,
          backgroundColor: group.color,
        });
        datasets.push({
          label: group.label,
          data: group.data,
          borderColor: group.color,
          type: "line",
          fill: false,
        });
      });
      setChartData({
        labels: times,
        datasets,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportData, priceRanges]);

  return (
    <ReportLayout
      ref={reportContainerRef}
      reportTitle="価格別リードタイム"
      reportTooltip={'リードタイムごとに最も売上が向上する価格帯を見つけることができます。\nリードタイムごとの予約実績に対する価格帯を見ることで、早割や先の予約に対して余分な割引を行なっていないかどうかを検討してください。\nまた、最適な割引をすることで予約数を最大化に繋がる可能性についても同時に検討してください。'}
      pageNumber={5}
      showFooter
      onChangePriceRanges={fetchReportData}
      onChangeDateRange={(newDates) =>
        fetchReportData(priceRangeString, newDates)
      }
    >
      <Box className={styles["report-ranking"]}>
        {chartData && <Bar options={options} data={chartData} />}
      </Box>
    </ReportLayout>
  );
};
export default ReportByPrice;
