/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import styles from "./styles.module.scss";
import BreadCrumb from "../../components/breadCrumb/index";
import ImageRakuten from "../../../src/assets/image (3).png";
import ImageYalan from "../../../src/assets/image (4).png";

export default function Coupons() {
  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        overflow: "auto",
        backgroundColor: "#F1F3F9",
      }}
    >
      <Container maxWidth="xl">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <BreadCrumb
              content="提案を受ける"
              textRoutes={["ダッシュボード", "AIプラン作成"]}
            />
            <Box>
              <Typography className={styles["text-title"]}>
                クーポンを発行するOTAを選ぶ
              </Typography>
              <Grid container spacing={6}>
                <Grid item xs={3}>
                  <Box className={styles["box-button-edit"]}>
                    <Box sx={{ textAlign: "center" }}>
                      <img
                        src={ImageRakuten}
                        alt="School photo"
                        style={{
                          width: "50%",
                          height: "50%",
                          objectFit: "contain",
                        }}
                      />
                    </Box>
                    <Typography className={styles["text-button-edit"]}>
                      楽天トラベル
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <Box className={styles["box-button-edit"]}>
                    <Box sx={{ textAlign: "center" }}>
                      <img
                        src={ImageYalan}
                        alt="School photo"
                        style={{
                          width: "50%",
                          height: "50%",
                          objectFit: "contain",
                        }}
                      />
                    </Box>
                    <Typography className={styles["text-button-edit"]}>
                      クーポン発行
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              <Box></Box>
            </Box>
            <Box sx={{ width: "15%" }} className={styles["box-content-right"]}>
              <Typography className={styles["text-content-right"]}>
                アクション一覧に戻る
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
