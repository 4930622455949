import CircularProgress from "@mui/material/CircularProgress";
import { Box } from "@mui/material";
export const LoadingComponent = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.3)",
        zIndex: 9999,
      }}
    >
      <CircularProgress sx={{ color: "#3f7eae" }} />
    </Box>
  );
};
