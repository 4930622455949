import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Checkbox from "@mui/material/Checkbox";
import style from "./style.module.scss";
import { visuallyHidden } from "@mui/utils";
import { TableFooter } from "@mui/material";
import { getCellValue, getComparator, stableSort } from "../../helper";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { UNREGISTER, UNREGISTER_GROUP } from "../../constants";

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headCells,
    selectAble = true,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow className={style["table-head"]}>
        {selectAble && (
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                "aria-label": "select all desserts",
              }}
              sx={{
                p: 0,
              }}
            />
          </TableCell>
        )}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{
              whiteSpace: headCell.options?.nowrap ? "nowrap" : "normal",
            }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              <span className={style["header-cell"]}>{headCell.label}</span>
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  headCells: PropTypes.array.isRequired,
};

const EnhancedTable = React.forwardRef((props, ref) => {
  const {
    headCells,
    rows,
    stickyHeader,
    footerComponent,
    onSelectedRow,
    selectAble = true,
  } = props;
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  React.useImperativeHandle(ref, () => ({
    handleDeselectAllClick: () => {
      setSelected([]);
    },
  }));

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const visibleRows = React.useMemo(
    () => stableSort(rows, getComparator(order, orderBy)),
    [order, orderBy, rows]
  );

  React.useEffect(() => {
    if (onSelectedRow) {
      onSelectedRow(selected);
    }
  }, [selected, onSelectedRow]);

  const generateCell = (row, headCell) => {
    if (headCell.options?.component) {
      return headCell.options?.component(row);
    }
    let text = getCellValue(row, headCell.id, headCell.options?.emptyText);

    if ([UNREGISTER, UNREGISTER_GROUP].includes(text)) {
      return (
        <>
          <span style={{ color: headCell.options?.emptyTextColor || "red" }}>
            {headCell.options?.emptyText || UNREGISTER}
          </span>
        </>
      );
    }
    if (headCell.options?.link) {
      if (text !== UNREGISTER) {
        return (
          <Link
            style={{
              color: headCell.color || "currentColor",
              textDecoration: "none",
            }}
            to={headCell.options?.link(row)}
          >
            {text}
          </Link>
        );
      }
      return (
        <>
          <span style={{ color: headCell.color || "currentColor" }}>
            <div style={{ color: "red" }}> {text} </div>
          </span>
        </>
      );
    }
    return (
      <>
        <span style={{ color: headCell.color || "currentColor" }}>
          <div style={{ color: text === UNREGISTER ? "red" : "#2E4E76" }}>
            {text}
          </div>
        </span>
      </>
    );
  };

  return (
    <TableContainer className={style["custom-table-container"]}>
      <Table
        stickyHeader={stickyHeader}
        aria-labelledby="tableTitle"
        size={"medium"}
        className={style["custom-table"]}
      >
        <EnhancedTableHead
          numSelected={selected.length}
          order={order}
          orderBy={orderBy}
          onSelectAllClick={handleSelectAllClick}
          onRequestSort={handleRequestSort}
          rowCount={rows?.length}
          headCells={headCells}
          selectAble={selectAble}
        />
        <TableBody className={style["table-body"]}>
          { visibleRows.length > 0 && visibleRows?.map((row, index) => {
            const isItemSelected = isSelected(row.id);
            const labelId = `enhanced-table-checkbox-${index}`;

            return (
              <TableRow
                hover
                role="checkbox"
                aria-checked={isItemSelected}
                tabIndex={-1}
                key={row.id}
                selected={isItemSelected}
                className={style["table-body-row"]}
              >
                {selectAble && (
                  <TableCell
                    padding="checkbox"
                    onClick={(event) => handleClick(event, row.id)}
                  >
                    <Checkbox
                      color="primary"
                      checked={isItemSelected}
                      inputProps={{
                        "aria-labelledby": labelId,
                      }}
                    />
                  </TableCell>
                )}
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    sx={{
                      whiteSpace: headCell.options?.nowrap ? "nowrap" : "normal",
                      color: headCell.options?.color || "currentColor",
                    }}
                  >
                    {generateCell(row, headCell)}
                  </TableCell>
                ))}
              </TableRow>
            );
          })}
          {visibleRows.length === 0 && (
            <TableRow>
              <TableCell colSpan={headCells.length + (selectAble ? 1 : 0)}>
                <div className={style["no-data"]}>
                  <div className={style['no-data-text']}>指定された月のデータはありません。</div>
                  <div className={style['no-data-link']}>
                    <Link to="/results/plan/import">データをインポートする</Link>
                  </div>
                </div>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          {footerComponent}
        </TableFooter>
      </Table>
    </TableContainer>
  );
});

export default EnhancedTable;
