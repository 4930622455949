import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import HTTP from "../../http_common";

const initialState = {
  list: [],
  count: 0,
  loading: false,
  error: null,
  previous: null,
  next: null,
};

export const getListNotice = createAsyncThunk(
  "notifications/getListNotice",
  async (body) => {
    const response = await HTTP.get(`/api/notifications/`, {
      params: { ...body },
    });
    return response;
  }
);

export const getDetailNotice = createAsyncThunk(
  "notifications/getDetailNotice",
  async (id) => {
    const response = await HTTP.get(`/api/notifications/${id}/`);
    return response;
  }
);

export const createOrUpdateNoti = createAsyncThunk(
  "notifications/createOrUpdateNoti",
  async (body) => {
    if (body.id) {
      const response = await HTTP.put(`/api/notifications/${body.id}/`, body);
      return response;
    } else {
      const response = await HTTP.post(`/api/notifications/`, body);
      return response;
    }
  }
);

export const deleteNotiById = createAsyncThunk("otas/deleteNotiById", async (id) => {
  const response = await HTTP.delete(`/api/notifications/${id}/`);
  return response;
});

export const noticeSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {},
  extraReducers: {
    [getListNotice.pending]: (state) => {
      state.loading = true;
    },
    [getListNotice.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.list = payload.results;
      state.count = payload.count;
      state.previous = payload.previous;
      state.next = payload.next;
    },
    [getListNotice.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },
    [createOrUpdateNoti.pending]: (state) => {
      state.loading = true;
    },
    [createOrUpdateNoti.fulfilled]: (state) => {
      state.loading = false;
    },
    [createOrUpdateNoti.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },
    [deleteNotiById.pending]: (state) => {
      state.loading = true;
    },
    [deleteNotiById.fulfilled]: (state) => {
      state.loading = false;
    },
    [deleteNotiById.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },
  },
});

// Action creators are generated for each case reducer function
// export const {} = noticeSlice.actions

export default noticeSlice.reducer;
