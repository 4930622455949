import Home from "../pages/home";
import CreatePlan from "../pages/plan/create";
import ReceiveSuggestions from "../pages/suggestions";
import CreateUser from "../pages/admin/users/create";
import ListUser from "../pages/admin/users";
import Noti from "../pages/admin/notifications";
import NotiCreate from "../pages/admin/notifications/create";
import NotiDetail from "../pages/admin/notifications/[id]";
import OTA from "../pages/admin/OTA/index";
import OTADetail from "../pages/admin/OTA/[id]/index";
import OTACreate from "../pages/admin/OTA/create";
import EditUser from "../pages/admin/users/edit/[id]";
import Application from "../pages/admin/application/index";
import ApplicationDetail from "../pages/admin/application/[id]/index";
import RecommendPlans from "../pages/admin/recommend-plans/index";
import RecommendPlansDetail from "../pages/admin/recommend-plans/[id]/index";
import Coupons from "../pages/coupons";
import Plan from "../pages/plan/result";
import PlanResultGroup from "../pages/plan/result/group";
import PlanGroupDetail from "../pages/plan/result/group/[id]/index";
import LandingPage from "../pages/landingPage/index";
import ReleaseNote from "../pages/release-note/index";
import News from "../pages/news/index";
import NewDetail from "../pages/news/[id]/index";
import ReleaseDetail from "../pages/release-note/[id]/index";
import Terms from "../pages/terms/index";
import Privacy from "../pages/privacy/index";
import Contact from "../pages/contact/index";
import ContactComplete from "../pages/contact/complete/index";
import LoginPage from "../pages/login";
import RegisterEmail from "../pages/register/index";
import RegisterAccount from "../pages/register/[id]/[token]/index";
import RegisterNewHotel from "../pages/register/[id]/[token]/new-hotel/index";
import ForgetPassword from "../pages/login/forget/index";
import ResetPassword from "../pages/login/reset/[id]/[token]/index";
import PlanResultPlans from "../pages/plan/result/plans";
import PlanGroupImport from "../pages/plan/result/import";
import PlanGroupImportItems from "../pages/plan/result/import/items";
import Report from "../pages/report";
import ReportPlanRanking from "../pages/report/planRanking";
import ReportRoomTypeRanking from "../pages/report/roomTypeRanking";
import ReportPlanByDayOfWeek from "../pages/report/planByDayOfWeek";
import ReportPlanRoomTypeRanking from "../pages/report/planRoomTypeRanking";
import ReportByPrice from "../pages/report/byPrice";
import ReportByDayOfWeek from "../pages/report/byDayOfWeek";
import PlanGroupImportGroup from "../pages/plan/result/import/group";
import CompetitorPage from "../pages/competitor";
import Setting from "../pages/setting";
import InvitePage from "../pages/invite";
import NoticeDetail from "../pages/notice/[id]/index";
import PlanResultDetail from "../pages/plan/result/[id]";

export const routers = [
  {
    path: "/",
    component: LandingPage,
  },
  {
    path: "/login",
    component: LoginPage,
    hasBackground: true,
    headerBgColor: "#fff",
    authLayout: true,
    backgroundFooter: "#fff",
    title: "ログイン",
  },
  {
    path: "/login/forget",
    component: ForgetPassword,
    hasBackground: true,
    headerBgColor: "#fff",
    authLayout: true,
    backgroundFooter: "#fff",
    title: "パスワードリセット",
  },
  {
    path: "/login/reset/:id/:token",
    component: ResetPassword,
    authLayout: true,
    hasBackground: true,
    headerBgColor: "#fff",
    backgroundFooter: "#fff",
    title: "パスワードリセット",
  },
  {
    path: "/release-note",
    component: ReleaseNote,
    hasBackground: true,
    headerBgColor: "#F1F3F9",
    title: "ALL RELEASES",
  },
  {
    path: "/news",
    component: News,
    hasBackground: true,
    headerBgColor: "#F1F3F9",
    title: "NEWS",
  },
  {
    path: "/news/:id",
    component: NewDetail,
    hasBackground: true,
    headerBgColor: "#F1F3F9",
    title: "NEWS",
  },
  {
    path: "/release-note/:id",
    component: ReleaseDetail,
    hasBackground: true,
    headerBgColor: "#F1F3F9",
    title: "RELEASES NOTE",
  },
  {
    path: "/terms",
    component: Terms,
    hasBackground: true,
    title: "サービス利用規約",
  },
  {
    path: "/privacy",
    component: Privacy,
    hasBackground: true,
    title: "プライバシーポリシー",
  },
  {
    path: "/register",
    component: RegisterEmail,
    hasBackground: true,
    headerBgColor: "#fff",
    authLayout: true,
    backgroundFooter: "#fff",
    title: "新規アカウント作成",
  },
  {
    path: "/register/:id/:token/new-hotel",
    component: RegisterNewHotel,
    hasBackground: true,
    headerBgColor: "#fff",
    authLayout: true,
    backgroundFooter: "#fff",
    title: "新規アカウント作成",
  },
  {
    path: "/register/:id/:token",
    component: RegisterAccount,
    hasBackground: true,
    headerBgColor: "#fff",
    authLayout: true,
    backgroundFooter: "#fff",
    title: "新規アカウント作成",
  },
  {
    path: "/home",
    component: Home,
    layout: true,
    isAuthentication: true,
    role: ["user"],
  },
  {
    path: "/suggestions/plans/create",
    component: CreatePlan,
    isAuthentication: true,
    role: ["user"],
    title: "プラン作成",
  },
  {
    path: "/results/plan",
    component: Plan,
    isAuthentication: true,
    role: ["user"],
    title: "プラン詳細",
  },
  {
    path: "/results/plan/group",
    component: PlanResultGroup,
    layout: true,
    isAuthentication: true,
    role: ["user"],
    title: "プラングループ",
  },
  {
    path: "/results/plan/group/:id",
    component: PlanGroupDetail,
    layout: true,
    isAuthentication: true,
    role: ["user"],
    title: "プラングループ",
  },
  {
    path: "/results/plan/plans",
    component: PlanResultPlans,
    layout: true,
    isAuthentication: true,
    role: ["user"],
    title: "プラングループ名が入ります",
  },
  {
    path: "/results/plan/import",
    component: PlanGroupImport,
    layout: true,
    isAuthentication: true,
    role: ["user"],
    title: "プランデータのインポート",
  },
  {
    path: "/results/plan/import/items",
    component: PlanGroupImportItems,
    layout: true,
    isAuthentication: true,
    role: ["user"],
    title: "プラン販売実績",
  },
  {
    path: "/results/plan/import/group",
    component: PlanGroupImportGroup,
    layout: true,
    isAuthentication: true,
    role: ["user"],
    title: "プラン販売実績",
  },
  {
    path: "/suggestions/plans/result/:id",
    component: PlanResultDetail,
    isAuthentication: true,
    role: ["user"],
    title: "プラン造成",
  },
  {
    path: "/suggestions/plans/result",
    component: PlanResultDetail,
    isAuthentication: true,
    role: ["user"],
    title: "プラン造成",
  },
  {
    path: "/report",
    component: Report,
    layout: true,
    isAuthentication: true,
    role: ["user"],
    title: "課題を解決する",
  },
  {
    path: "/report/plan-ranking",
    component: ReportPlanRanking,
    layout: true,
    isAuthentication: true,
    role: ["user"],
    title: "課題を解決する",
  },
  {
    path: "/report/plan-ranking/room-type",
    component: ReportPlanRoomTypeRanking,
    layout: true,
    isAuthentication: true,
    role: ["user"],
    title: "課題を解決する",
  },
  {
    path: "/report/plan-ranking/day-of-week",
    component: ReportPlanByDayOfWeek,
    layout: true,
    isAuthentication: true,
    role: ["user"],
    title: "課題を解決する",
  },
  {
    path: "/report/room-type-ranking",
    component: ReportRoomTypeRanking,
    layout: true,
    isAuthentication: true,
    role: ["user"],
    title: "課題を解決する",
  },
  {
    path: "/report/price-ranking",
    component: ReportByPrice,
    layout: true,
    isAuthentication: true,
    role: ["user"],
    title: "課題を解決する",
  },
  {
    path: "/report/day-of-week",
    component: ReportByDayOfWeek,
    layout: true,
    isAuthentication: true,
    role: ["user"],
    title: "課題を解決する",
  },
  {
    path: "/competitor",
    component: CompetitorPage,
    layout: true,
    isAuthentication: true,
    role: ["user"],
    title: "競合施設登録",
  },
  {
    path: "/setting",
    component: Setting,
    layout: true,
    isAuthentication: true,
    role: ["user"],
    title: "設定",
  },
  {
    path: "/contact",
    component: Contact,
    layout: true,
    isAuthentication: true,
    role: ["user"],
    title: "お問い合わせ",
  },
  {
    path: "/contact/complete",
    component: ContactComplete,
    layout: true,
    isAuthentication: true,
    role: ["user"],
    title: "お問い合わせ完了",
  },
  {
    path: "/notice/:id",
    component: NoticeDetail,
    layout: true,
    isAuthentication: true,
    role: ["user"],
    title: "お知らせ",
  },
  {
    path: "/invite",
    component: InvitePage,
    layout: true,
    isAuthentication: true,
    role: ["user"],
  },
  {
    path: "/admin/user/edit/:id",
    component: EditUser,
    isAuthentication: true,
    role: ["admin"],
  },
  {
    path: "/suggestions",
    component: ReceiveSuggestions,
    layout: true,
    isAuthentication: true,
    role: ["user"],
    title: "アクションを選ぶ",
  },
  {
    path: "/coupons",
    component: Coupons,
    isAuthentication: false,
  },
  {
    path: "/admin/user/create",
    component: CreateUser,
    isAuthentication: true,
    role: ["admin"],
    title: "アカウント情報",
  },
  {
    path: "/admin/application",
    component: Application,
    isAuthentication: true,
    role: ["admin"],
    title: "申請情報一覧",
  },
  {
    path: "/admin/application/:id",
    component: ApplicationDetail,
    isAuthentication: true,
    role: ["admin"],
    title: "申請情報",
  },

  {
    path: "/admin/recommend-plans",
    component: RecommendPlans,
    isAuthentication: true,
    role: ["admin"],
    title: "作成プランの管理",
  },
  {
    path: "/admin/recommend-plans/:id",
    component: RecommendPlansDetail,
    isAuthentication: true,
    role: ["admin"],
    title: "作成プランの管理",
  },

  {
    path: "/admin/users",
    component: ListUser,
    isAuthentication: true,
    role: ["admin"],
    title: "アカウント一覧",
  },
  {
    path: "/admin/notifications",
    component: Noti,
    isAuthentication: true,
    role: ["admin"],
    title: "お知らせ一覧",
  },
  {
    path: "/admin/OTA",
    component: OTA,
    isAuthentication: true,
    role: ["admin"],
    title: "OTA施策一覧",
  },
  {
    path: "/admin/OTA/create",
    component: OTACreate,
    isAuthentication: true,
    role: ["admin"],
    title: "お知らせ編集",
  },
  {
    path: "/admin/OTA/:id",
    component: OTADetail,
    isAuthentication: true,
    role: ["admin"],
    title: "お知らせ編集",
  },
  {
    path: "/admin/notifications/create",
    component: NotiCreate,
    isAuthentication: true,
    role: ["admin"],
    title: "お知らせ編集",
  },
  {
    path: "/admin/notifications/:id",
    component: NotiDetail,
    isAuthentication: true,
    role: ["admin"],
    title: "お知らせ編集",
  },
];
