/* eslint-disable react-hooks/exhaustive-deps */
import Box from "@mui/material/Box";
import styles from "./styles.module.scss";
import React from "react";
import FormCreateorUpdateOta from "../../../../components/FormCreateorUpdateOta";
import { useParams } from "react-router-dom";

export default function EditOta() {
  let { id } = useParams();
  return (
    <Box component="main" className={styles["box-main"]}>
      <FormCreateorUpdateOta id={id}/>
    </Box>
  );
}
