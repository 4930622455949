/* eslint-disable jsx-a11y/anchor-is-valid */
import { Box, TextField, Typography } from "@mui/material";
import { Button } from "@mui/base";
import styles from "./styles.module.scss";
import { useDispatch } from 'react-redux';
import { fetchLogin } from '../../features/apiLogin';
import React, { useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { regex, useQuery } from "../../helper";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ADMIN_ROLE, USER_ROLE } from "../../constants";

export default function Login() {
  const history = useHistory();
  const query = useQuery();
  const nextRouter = query.get("continue") || "/home";

  const dispatch = useDispatch();
  const [customError, setCustomError] = useState("");
  const [loading, setLoading] = useState(false);

  const formikConfig = {
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: async (values, { setSubmitting, setErrors }) => {
      setLoading(true);
      dispatch(fetchLogin(values)).then((res) => {
        if (res?.payload) {
          localStorage.setItem("role", res.payload.access);
          localStorage.setItem(
            "is_superuser",
            res.payload.is_superuser ? ADMIN_ROLE : USER_ROLE
          );
          localStorage.setItem("access_token", res.payload.access);
          localStorage.setItem("refresh_token", res.payload.refresh);
          if (res.payload.is_superuser) {
            history.push("/admin/users");
          } else {
            history.push(nextRouter);
          }
        } else {
          setCustomError("メールアドレスまたはパスワードが間違っています。");
          setLoading(false);
        }
      });
    },
    validationSchema: () => {
      return Yup.object({
        email: Yup.string()
          .email("メールアドレスの形式が正しくありません")
          .required("メールアドレスを入力してください。"),
        password: Yup.string()
          .required("パスワードを入力してください。")
          .min(8, "パスワードは８文字以上で入力してください。")
          .matches(
            regex.password,
            "パスワードは英大文字、英小文字、数字、特殊文字が必要です。"
          ),
      });
    },
  };

  return (
    <Box className={styles["box-main-content"]}>
      <Box sx={{ width: "100%" }}>
        <Typography className={styles["content-login"]}>ログイン</Typography>
        <Typography className={styles["content-bottom-login"]}>
          おかえりなさいませ
        </Typography>
        <Formik
          validateOnChange={false}
          initialValues={formikConfig.initialValues}
          onSubmit={formikConfig.onSubmit}
          validationSchema={formikConfig.validationSchema}
        >
          {({
            handleChange,
            handleBlur,
            values,
            errors,
            handleSubmit,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <TextField
                  error={!!errors.email}
                  className={styles["custom-field"]}
                  name="username"
                  label="メールアドレスを入力"
                  variant="outlined"
                  onChange={handleChange("email")}
                  onBlur={handleBlur("email")}
                  value={values.email}
                  helperText={errors.email}
                />
                <TextField
                  error={!!errors.password}
                  type="password"
                  className={styles["custom-field"]}
                  name="password"
                  label="パスワードを入力"
                  variant="outlined"
                  onChange={handleChange("password")}
                  onBlur={handleBlur("password")}
                  value={values.password}
                  helperText={errors.password}
                />
              </Box>
              {customError && (
                <Box className={styles["custom-error-message"]}>
                  {customError}
                </Box>
              )}
              <Box className={styles["box-continue"]}>
                <Button
                  type="submit"
                  className={styles["text-button"]}
                  disabled={loading}
                >
                  Continue
                </Button>
              </Box>
            </form>
          )}
        </Formik>
        <Box className={styles["more-action-information"]}>
          初めてのご利用ですか？{" "}
          <Link
            style={{ textDecoration: "none" }}
            className={styles["link"]}
            to="/register"
          >
            アカウント作成
          </Link>
        </Box>
        <Box className={styles["more-action-information"]}>
          <Link
            style={{ textDecoration: "none" }}
            className={styles["link"]}
            to="/login/forget"
          >
            パスワードを忘れた方
          </Link>
        </Box>
      </Box>
    </Box>
  );
}
