import { Fragment, React, useEffect, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MuiTooltip from "@mui/material/Tooltip";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import styles from "./styles.module.scss";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import { getListOta } from "../../features/ota/otasSlice";
import { getListNotice } from "../../features/notifications/noticeSlice";
import { useDispatch, useSelector } from "react-redux";
import InputAdornment from "@mui/material/InputAdornment";
import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from "chart.js";
import { Scatter } from "react-chartjs-2";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { OTA_OPTIONS, OTA_CATEGORY } from "../../constants/index";
import Home from "../../../src/assets/home.svg";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import {
  ChartBackgroundColor,
  CurrencyFormat,
  getDefaultDateRange,
  getStartEndDateString,
} from "../../helper";
import moment from "moment";
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";
import { getOverviewReport } from "../../features/report/reportSlice";
import ReportBarChart from "../../components/ReportHorizontalChart";
import { getListHotelCompetition } from "../../features/competitor/competitorSlice";

ChartJS.register(
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  ChartBackgroundColor
);
export const options = {
  layout: {
    padding: 0,
  },
  plugins: {
    legend: {
      display: false,
    },
    chartBg: {
      color: "transparent",
    },
  },
  legend: {
    labels: {
      fontSize: 0,
    },
  },
  gridLines: {
    zeroLineColor: "red",
  },
  scales: {
    y: {
      max: 50,
      min: 1,
      beginAtZero: true,
      position: "right",
      reverse: true,
      ticks: {
        stepSize: 10,
        color: "#3f7eae",
        font: {
          size: 10,
        },
        padding: 0,
      },
      border: {
        color: "#efeff6",
        lineWidth: 2,
      },
      grid: {
        color: "#efeff6",
        lineWidth: 2,
      },
    },
    x: {
      max: 5,
      min: 0,
      beginAtZero: true,
      position: "bottom",
      title: {
        display: true,
        color: "#3F7EAE",
        text: "クチコミ",
        font: {
          weight: "500",
        },
      },
      ticks: {
        stepSize: 1,
        color: "#3f7eae",
        font: {
          size: 10,
        },
        padding: 0,
      },
      border: {
        color: "#efeff6",
        lineWidth: 2,
      },
      grid: {
        color: "#efeff6",
        lineWidth: 2,
      },
    },
  },
};

export const dataChartjs = {
  datasets: [
    {
      label: "label",
      data: [
        {
          x: 2,
          y: 5,
        },
      ],
      backgroundColor: "#7764e4",
    },
  ],
};

const chartLabels = {
  title: "プランランキング",
  name_col: "プラングループ名",
  count_col: {
    id: "number_nights",
    label: "泊数",
  },
  revenue_col: "total_fee",
};

const colorConfig = {
  count_col: "#7764E4",
  revenue_col: "#CE858F",
};

const getRandomColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let index = 0; index < 6; index++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

const theme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: "1em",
          fontWeight: "bold",
          color: "#172B4D",
          backgroundColor: "#F0F0F7",
          border: "4px solid #FFFFFF",
          borderRadius: "10px",
          padding: "20px 40px",
          boxShadow: "0px 0px 8px #00000066",
          width: "100%",
          maxWidth: "600px",
        },
        arrow: {
          color: "darkblue",
        },
      },
    },
  },
});

export default function ReceiveSuggestions() {
  const dispatch = useDispatch();
  const notifications = useSelector((state) => state.notifications.list);
  const [otas, setOtas] = useState([]);
  const [otaCount, setOtaCount] = useState(0);
  const [allRemovedOta, setAllRemovedOta] = useState(false);
  const reportData = useSelector((state) => state.report.data);
  const competition = useSelector((state) => state.competition.list);
  const reportKey = "top_plans_number_nights";
  const [maxValue, setMaxValue] = useState({
    count: 0,
    total_fee: 0,
  });

  const [dateRange] = useState(getDefaultDateRange());

  useEffect(() => {
    dispatch(getOverviewReport(getStartEndDateString(dateRange)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange]);

  const [hotelId, setHotelId] = useState("");
  const [competitionReport, setCompetitionReport] = useState({ datasets: [] });
  useEffect(() => {
    const competitionReportTemp = competition.map((item) => {
      return {
        label_name: item?.hotel_name,
        rate: parseFloat(item?.rate),
      };
    });
    const sortedCompetitionReport = competitionReportTemp.sort((a, b) => {
      return b.rate - a.rate;
    });

    const competitionReportOrder = sortedCompetitionReport.map(
      (item, index) => {
        return {
          label: item.label_name,
          data: [
            {
              x: item?.rate,
              y: index + 1,
            },
          ],
          backgroundColor: getRandomColor(),
        };
      }
    );
    setCompetitionReport((state) => {
      return {
        ...state,
        datasets: competitionReportOrder,
      };
    });
  }, [competition]);

  const otaCountInit = 6;
  const hasReportData = reportData[reportKey]?.length;

  const qAs = [
    {
      name: "お問い合わせについて",
      article: "記事を読む",
      href: "https://support.levitt.ai/contact/how-to-contact",
    },
    {
      name: "Levittの始め方",
      article: "記事を読む",
      href: "https://support.levitt.ai/how-to-start/how-to-register",
    },
    {
      name: "AIプラン作成",
      article: "記事を読む",
      href: "https://support.levitt.ai/suggestion/generate-plan",
    },
    {
      name: "実績を登録・確認する",
      article: "記事を読む",
      href: "https://support.levitt.ai/register-achievements/import-plan",
    },
    {
      name: "レポート機能",
      article: "記事を読む",
      href: "https://support.levitt.ai/report/how-to-read",
    },
    {
      name: "アカウント設定",
      article: "記事を読む",
      href: "https://support.levitt.ai/account-setting/password-reset",
    },
  ];

  const [checked] = useState(false);

  const removeOtas = (index) => {
    const otasCopy = [...otas];
    const removedOta = otasCopy.splice(index, 1)[0]; // 削除されたOTAを取得
    setOtas(otasCopy);
    // OTAのリストが空になった場合に、setAllRemovedOta(true)を設定
    if (otasCopy.length === 0) {
      setAllRemovedOta(true);
    }

    // 削除されたOTAのIDをローカルストレージに記録する
    const removedOtasIds =
      JSON.parse(localStorage.getItem("removedOtasIds")) || [];
    // 仮に`id`プロパティを削除されたOTAの識別子とする
    removedOtasIds.push(removedOta.id);
    localStorage.setItem("removedOtasIds", JSON.stringify(removedOtasIds));
    console.log("removedOtasIds", removedOtasIds);
  };

  const removeAllOtas = () => {
    // 現在表示されている全OTAのIDを取得
    const allOtasIds = otas.map((ota) => ota.id);

    // 既存の削除されたOTAのIDリストをローカルストレージから取得し、全OTAのIDを追加
    const removedOtasIds =
      JSON.parse(localStorage.getItem("removedOtasIds")) || [];
    const updatedRemovedOtasIds = [...removedOtasIds, ...allOtasIds];
    localStorage.setItem(
      "removedOtasIds",
      JSON.stringify(updatedRemovedOtasIds)
    );
    console.log("updatedRemovedOtasIds", updatedRemovedOtasIds);

    // OTAリストを空にし、allRemovedOta状態をtrueに設定
    setOtas([]);
    setAllRemovedOta(true);

    // この場合、allRemovedOtaをローカルストレージに保存する必要はありませんが、
    // 状態の一貫性を保つために設定しても良いでしょう。
    localStorage.setItem("allRemovedOta", JSON.stringify(true));
  };

  useEffect(() => {
    if (otas.length < otaCountInit && !allRemovedOta) {
      fetchOtas(otaCountInit - otas.length, otaCount);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otas, allRemovedOta]);

  // const handleChangeCheck = () => {
  //   setChecked((prev) => !prev);
  // };

  const handleChange = (event) => {
    setHotelId(event.target.value);
  };

  const convertDate = (isoDate) => {
    const date = moment(isoDate);
    return date.format("YYYY.MM.DD");
  };

  useEffect(() => {
    // ローカルストレージからOTAの状態と削除されたOTAのIDリストを復元
    const storedOtas = JSON.parse(localStorage.getItem("otas"));
    const storedAllRemovedOta = JSON.parse(
      localStorage.getItem("allRemovedOta")
    );

    if (storedOtas) {
      setOtas(storedOtas);
    }

    setAllRemovedOta(storedAllRemovedOta || false);

    // storedAllRemovedOtaがtrueならば、OTAのフェッチを行わない
    if (!storedAllRemovedOta) {
      fetchOtas(otaCountInit, 0);
    } else {
      // ここでOTAリストをクリアするか、または表示しないようにする処理を追加することも考えられます。
      setOtas([]);
    }

    dispatch(
      getListNotice({ category: 1, status: 1, recipients: 2, limit: 10 })
    );
    dispatch(getOverviewReport(getStartEndDateString(dateRange)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleGetListHotelCompetition();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hotelId]);

  const handleGetListHotelCompetition = () => {
    const payload = {};
    if (hotelId) {
      payload.hotel_id = hotelId;
    }
    dispatch(getListHotelCompetition(payload));
  };

  const fetchOtas = async (limit, offset) => {
    const res = await dispatch(getListOta({ limit, offset }));
    if (res?.payload?.data) {
      // ローカルストレージから削除されたOTAのIDリストを取得
      const removedOtasIds =
        JSON.parse(localStorage.getItem("removedOtasIds")) || [];

      // フェッチしたOTAから削除されたものを除外
      const filteredData = res.payload.data.filter(
        (ota) => !removedOtasIds.includes(ota.id)
      );

      if (otas.length === 0) {
        setOtas(filteredData);
        setOtaCount(filteredData.length);
      } else if (filteredData.length > 0) {
        const otasCopy = [...otas, ...filteredData];
        setOtas(otasCopy);
        setOtaCount(otaCount + filteredData.length);
      }
    }
  };

  useEffect(() => {
    const max = {
      count: 0,
      total_fee: 0,
    };
    reportData[reportKey]?.forEach((row) => {
      const revenueCol = "total_fee";
      const countValue = row.number_nights;
      const revenueValue = row[revenueCol];
      if (max.count < countValue || !max.count) {
        max.count = countValue;
      }

      if (max[revenueCol] < revenueValue || !max[revenueCol]) {
        max[revenueCol] = revenueValue;
      }
    });
    setMaxValue(max);
  }, [reportData]);

  const reportPlanRanking = useMemo(() => {
    const report = reportData[reportKey] || [];
    const convertToDataChart = (data) => {
      return [
        {
          value: data.number_nights || 0,
          color: colorConfig.count_col,
          id: "count",
        },
        {
          value: data.total_fee || 0,
          color: colorConfig.revenue_col,
          id: "total_fee",
        },
      ];
    };

    return (
      <Box className={styles["report-item"]}>
        <table className={styles["report-table"]}>
          <thead>
            <tr>
              <th>{chartLabels.name_col}</th>
              <th>{chartLabels.count_col.label}</th>
              <th>売上</th>
            </tr>
          </thead>
          <tbody>
            {report?.map((row, index) => (
              <Fragment key={index}>
                <tr className={styles["detail-row"]}>
                  <td>
                    <p>{row.label_name}</p>
                  </td>
                  <td>{CurrencyFormat(row[chartLabels.count_col?.id])}</td>
                  <td>¥{CurrencyFormat(row.total_fee)}</td>
                </tr>
                <tr>
                  <td colSpan={3}>
                    <ReportBarChart
                      maxData={maxValue}
                      data={convertToDataChart(row)}
                    />
                  </td>
                </tr>
              </Fragment>
            ))}
          </tbody>
        </table>
      </Box>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportData]);

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        overflow: "auto",
        backgroundColor: "#F1F3F9",
      }}
    >
      <Container maxWidth="" sx={{ backgroundColor: "#2E4E76" }}>
        <Grid container spacing={3}>
          <Grid
            sx={{
              paddingLeft: "50px !important",
              paddingRight: "22px !important",
            }}
            item
            xs={12}
          >
            <Box
              sx={{ display: "flex", marginTop: "32px", marginBottom: "42px" }}
            >
              <Typography className={styles["content-left"]}>Home</Typography>
              <img
                src={Home}
                alt="Home"
                style={{
                  objectFit: "contain",
                  marginRight: "20px",
                }}
              />
              <Typography className={styles["content-center"]}>Home</Typography>
              <Typography
                sx={{
                  display: "flex",
                  color: "#ffff",
                  alignItems: "flex-start",
                }}
              >
                -
              </Typography>
              <Typography
                sx={{ marginLeft: "10px !important" }}
                className={styles["content-center"]}
              >
                Dashboard
              </Typography>
            </Box>
            <Box>
              <Grid
                container
                sx={{
                  justifyContent: "normal",
                  display: checked ? "none" : "flex",
                }}
                spacing={4}
              >
                {otas?.map((item, index) => (
                  <Grid item xs={4} key={index}>
                    <Box className={styles["box-button-choose"]}>
                      <Box className={styles["box-head"]}>
                        <Typography className={styles["text-button-choose"]}>
                          {OTA_OPTIONS(item?.ota)}
                        </Typography>
                        <Typography className={styles["text-button-choose"]}>
                          {OTA_CATEGORY(item?.category)}
                        </Typography>
                        {/* <IconButton
                          sx={{ p: "4px", background: "#E6E6EF" }}
                          onClick={() => removeOtas(index)}
                        >
                          <ClearIcon
                            sx={{ color: "#3F7EAE", fontSize: "16px" }}
                          />
                        </IconButton> */}
                      </Box>
                      <Typography
                        sx={{ marginTop: "15px" }}
                        className={styles["title-button"]}
                      >
                        {item?.title}
                      </Typography>
                      <Box sx={{ marginTop: "15px" }} display={"flex"}>
                        <Typography
                          className={styles["text-button-content"]}
                          sx={{ fontSize: "12px" }}
                        >
                          キャンペーン参画期限
                        </Typography>
                        <Typography className={styles["text-button-right"]}>
                          {convertDate(item?.participation_deadline)}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box className={styles["box-content"]}>
                          <Typography
                            className={styles["text-content"]}
                            sx={{ cursor: "pointer" }}
                            onClick={() => removeOtas(index)}
                          >
                            参画済みにする
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                ))}
              </Grid>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  paddingBottom: "27px",
                  paddingTop: "27px",
                }}
              >
                <Box
                  sx={{ marginRight: "25px" }}
                  className={styles["box-content"]}
                >
                  <Typography
                    className={styles["text-content"]}
                    onClick={() => removeAllOtas()}
                  >
                    すべてを参画済みにする
                  </Typography>
                </Box>
                {/* <Box
                  onClick={() => handleChangeCheck()}
                  className={styles["box-content-right"]}
                >
                  <Typography className={styles["text-content-right"]}>
                    {checked ? "すべて開く" : "すべて非表示にする"}
                  </Typography>
                </Box> */}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
      <Container
        className={styles["content-information"]}
        maxWidth="xl"
        sx={{ mt: 7.5, mb: 4, px: "40px !important" }}
      >
        <Box className={styles["box-information"]}>
          <Box className={styles["box-header"]}>
            <Typography
              className={styles["text-header-information"]}
              sx={{ display: "flex", alignItems: "center" }}
            >
              掲載順位×クチコミ
              <ThemeProvider theme={theme}>
                <MuiTooltip
                  title={
                    <>
                      OTA各社での掲載順位と口コミを他社と比較できます。
                      <br />
                      定期的に情報は更新されます。
                      <br />
                      ※表示には競合施設の登録が必要です。
                    </>
                  }
                  placement="top-end"
                >
                  <HelpOutlineRoundedIcon
                    sx={{
                      ml: "30px",
                      fontSize: "25.25px",
                      cursor: "pointer",
                    }}
                  />
                </MuiTooltip>
              </ThemeProvider>
            </Typography>
            <FormControl>
              <Select
                value={hotelId}
                onChange={handleChange}
                className={styles["select-tourist"]}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value={""} selected>
                  全て
                </MenuItem>
                {competition?.map((item, index) => (
                  <MenuItem value={item?.hotel_id} key={index}>
                    {item?.hotel_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          {competitionReport?.datasets.length > 0 ? (
            <Box
              sx={{
                backgroundColor: "#F8F9FD",
                display: "flex",
                padding: "0 20px",
              }}
            >
              <Box sx={{ flexGrow: 1 }}>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography className={styles["text-chart"]}>
                    最終更新：2023.01.01 10:00
                  </Typography>
                  <Typography
                    className={styles["text-chart"]}
                    sx={{ textAlign: "right", color: "#3F7EAE !important" }}
                  >
                    掲載順位
                  </Typography>
                </Box>
                <Scatter
                  options={options}
                  data={competitionReport}
                  sx={{ margin: "20px" }}
                />
              </Box>
              <Box sx={{ ml: 2.5, mt: "6px" }}>
                <div style={{ maxHeight: "280px", overflowY: "auto" }}>
                  {competitionReport?.datasets?.map((item, index) => (
                    <div className={styles["chart-label"]}>
                      <div
                        className={styles["circle"]}
                        style={{ backgroundColor: item.backgroundColor }}
                      ></div>
                      <p className={styles["label"]}>{item.label}</p>
                    </div>
                  ))}
                </div>

                <Typography
                  sx={{
                    color: "#7764E4 !important",
                    marginTop: "8px",
                    fontSize: "12px",
                    textDecoration: "none",
                  }}
                  component={Link}
                  to="/competitor"
                >
                  +競合施設を追加
                </Typography>
              </Box>
            </Box>
          ) : (
            <Box
              sx={{
                backgroundColor: "#F8F9FD",
                display: "flex",
                alignItems: "center",
                justifyItems: "center",
                flexGrow: 1,
                minHeight: "280px",
              }}
            >
              <Box sx={{ width: "100%", textAlign: "center" }}>
                <Typography className={styles["text-info"]}>
                  掲載順位×クチコミの表示には
                </Typography>
                <Typography className={styles["text-info"]}>
                  データのインポートが必要です。
                </Typography>
                <Button
                  variant="outlined"
                  className={styles["button-import"]}
                  component={Link}
                  to="/results/plan/import"
                >
                  インポートウィザードに移動する
                </Button>
              </Box>
            </Box>
          )}

          <Box className={styles["box-footer"]}>
            <Typography
              className={styles["text-information"]}
              sx={{
                color:
                  competitionReport?.datasets.length > 0
                    ? "#7764E4 !important"
                    : "#E6E6EF !important",
                textDecoration: "none",
              }}
              component={Link}
              to="/report"
            >
              レポートに移動する →
            </Typography>
          </Box>
        </Box>
        <Box className={styles["box-information"]}>
          <div className={styles["box-header"]}>
            <Typography
              className={styles["text-header-information"]}
              sx={{ display: "flex", alignItems: "center" }}
            >
              プラングループ ランキング
              <ThemeProvider theme={theme}>
                <MuiTooltip
                  title={
                    <>
                      プラングループごとの実績を確認できます。
                      <br />
                      ※表示にはデータのCSVインポートが必要です。
                    </>
                  }
                  placement="top-end"
                >
                  <HelpOutlineRoundedIcon
                    sx={{
                      ml: "30px",
                      fontSize: "25.25px",
                      cursor: "pointer",
                    }}
                  />
                </MuiTooltip>
              </ThemeProvider>
            </Typography>
          </div>

          {hasReportData ? (
            reportPlanRanking
          ) : (
            <Box
              sx={{
                backgroundColor: "#F8F9FD",
                display: "flex",
                alignItems: "center",
                justifyItems: "center",
                flexGrow: 1,
              }}
            >
              <Box sx={{ width: "100%", textAlign: "center" }}>
                <Typography className={styles["text-info"]}>
                  プラングループ ランキングの表示には
                </Typography>
                <Typography className={styles["text-info"]}>
                  データのインポートが必要です。
                </Typography>
                <Button
                  variant="outlined"
                  className={styles["button-import"]}
                  component={Link}
                  to="/results/plan/import"
                >
                  インポートウィザードに移動する
                </Button>
              </Box>
            </Box>
          )}
          <Box className={styles["box-footer"]}>
            <Typography
              className={styles["text-information"]}
              sx={{
                color: hasReportData
                  ? "#7764E4 !important"
                  : "#E6E6EF !important",
                textDecoration: "none",
              }}
              component={hasReportData ? Link : null}
              to={hasReportData ? "/report" : null}
            >
              {hasReportData
                ? "レポートに移動する →"
                : "プラングループレポートへ →"}
            </Typography>
          </Box>
        </Box>
        <Box className={styles["box-information"]}>
          <div className={styles["box-header"]}>
            <Typography className={styles["header-title"]}>お知らせ</Typography>
          </div>
          <Box
            sx={{
              flexGrow: 1,
              overflow: "hidden",
              maxHeight: "500px",
              paddingBottom: "20px",
            }}
          >
            <Box
              sx={{
                flexGrow: 1,
                overflow: "auto",
                maxHeight: "100%",
              }}
            >
              {notifications?.data?.map((item, index) => {
                return (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      marginBottom: "25px",
                    }}
                  >
                    <Box
                      className={styles["box-content-info"]}
                      component={Link}
                      to={`/notice/${item?.id}`}
                    >
                      {convertDate(item?.publication_date)}
                      <Typography className={styles["text-content"]}>
                        {item?.title}
                      </Typography>
                    </Box>
                  </Box>
                );
              })}
            </Box>
          </Box>
        </Box>
        <Box className={styles["box-information"]} sx={{ px: "20px" }}>
          <div className={styles["box-header"]}>
            <Typography className={styles["header-title"]}>
              Q&A サポート
            </Typography>
          </div>
          <Box
            sx={{
              flexGrow: 1,
              px: "20px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <TextField
                fullWidth
                id="fullWidth"
                placeholder="どのようなことでお悩みですか？"
                sx={{
                  p: 2,
                  boxShadow: "0px 3px 6px #2C28281C",
                  borderRadius: "10px",
                  zIndex: 2,
                  backgroundColor: "white",

                  "& .MuiInputBase-root": {
                    "&:before": {
                      display: "none",
                    },
                    "&:after": {
                      display: "none",
                    },
                  },

                  "& .MuiInputBase-input": {
                    fontSize: "14px",
                    fontWeight: "bold",
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      sx={{ color: "black", mr: 3 }}
                    >
                      <SearchRoundedIcon sx={{ fontSize: "28.2px" }} />
                    </InputAdornment>
                  ),
                }}
                variant="standard"
              />
              <ul className={styles["box-option"]}>
                {qAs.map((qa, index) => (
                  <li className={styles["text-option"]} key={index}>
                    <span className={styles["text-option-name"]}>
                      {qa.name}
                    </span>
                    <span className={styles["text-option-article"]}>
                      <Typography
                        className={styles["link-qa"]}
                        component="a"
                        href={qa.href}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {qa.article}
                      </Typography>
                    </span>
                  </li>
                ))}
              </ul>
            </Box>
          </Box>
          <Box className={styles["div-link-qa"]} sx={{ marginBottom: "20px" }}>
            <Typography
              className={styles["link-qa"]}
              component="a"
              href="https://support.levitt.ai/contact/how-to-contact"
              target="_blank"
              rel="noopener noreferrer"
            >
              Q&Aサイトに移動する
            </Typography>
          </Box>
          {/* <Box
            sx={{
              display: "flex",
              justifyContent: "space-around",
              padding: "40px 0",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box
                sx={{ display: "flex", justifyContent: "center" }}
                className={styles["box-icon"]}
                component={Link}
                to="/contact"
              >
                <MailOutlineIcon sx={{ fontSize: "40px" }} />
              </Box>
              <Typography
                className={styles["text-qa"]}
                component={Link}
                to="/contact"
              >
                お問い合わせ
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box className={styles["box-icon"]}>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <SupportAgentIcon sx={{ fontSize: "40px" }} />
                </Box>
              </Box>
              <Typography className={styles["text-qa"]}>
                チャットサポート
              </Typography>
            </Box>
          </Box> */}
        </Box>
      </Container>
    </Box>
  );
}
