import { Box, Modal, Fade, Typography } from '@mui/material';
import React from 'react';
import style from './style.module.scss';
import { Button, styled } from '@mui/material';
import { useDispatch } from 'react-redux';
import { toast } from "react-toastify";

const CancelButtonCustom = styled(Button)({
  fontWeight: "bold",
  borderRadius: 5,
  margin: 4,
  color: "#465672",
  backgroundColor: "#fff",
  boxShadow: "none",

  "&:hover": {
    backgroundColor: "#ddd",
    boxShadow: "none",
  },
});

const AgreeButtonCustom = styled(Button)({
  fontWeight: "bold",
  borderRadius: 5,
  margin: 4,
  color: "#fff",
  backgroundColor: "#172B4E",
  padding: "10px 32px",

  "&:hover": {
    backgroundColor: "#465672",
  },
});
export default function ComponentModalDelete(props) {
  const { title, open, action, handleClose, setOpen } = props;
  const dispatch = useDispatch();
  const handleDelete = async (e) => {
    e.preventDefault();
    await dispatch(action);
    toast.success("削除に成功しました。");
    setOpen(false);
  };

  return (
    <div>
      <Modal open={open} onClose={handleClose}>
        <Fade in={open}>
          <Box className={style.modal}>
            <Box className={style.content}>
              <Typography display="inline">{title ?? ""}</Typography>
            </Box>
            <Box className={style.buttons}>
              <CancelButtonCustom
                onClick={handleClose}
                variant="contained"
                type="button"
              >
                キャンセル
              </CancelButtonCustom>
              <AgreeButtonCustom
                onClick={handleDelete}
                variant="contained"
                type="button"
              >
                適用
              </AgreeButtonCustom>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
