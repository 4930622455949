import { Box, Typography, TextField, Popover } from "@mui/material";
import { useEffect, useState } from "react";
import { TEXT_REQUIRED } from "../../constants/validation";
import { editGroupPlan } from "../../features/plan/planGroupSlice";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { styled } from "@mui/system";

const INIT_ERROR = {
  group_name: null,
};

const EditGroupNamePopover = styled(Popover)({
  "& .MuiPaper-root": {
    borderRadius: "8px",
    minWidth: "216px",
    minHeight: "89px",
  },
});

const EditGroupPopover = ({ container, selectedGroup, onClose, onUpdated }) => {
  const dispatch = useDispatch();
  const [editingGroup, setEditingGroup] = useState({
    id: selectedGroup?.id || null,
    name: selectedGroup?.name || null || "",
  });
  const [errors, setErrors] = useState(INIT_ERROR);

  useEffect(() => {
    if (selectedGroup) {
      setEditingGroup({
        id: selectedGroup?.id || null,
        name: selectedGroup?.name || null || "",
      });
    } else {
      setEditingGroup({
        id: null,
        name: "",
      });
    }
  }, [selectedGroup]);

  const handleCloseEditGroup = () => {
    if (onClose) {
      onClose();
    }
    setEditingGroup({
      id: null,
      plan_group_name: "",
    });
    setErrors(INIT_ERROR);
  };

  const handleUpdateOrCreateNewGroup = async () => {
    if (!editingGroup?.name) {
      setErrors({
        ...errors,
        group_name: TEXT_REQUIRED,
      });
      return;
    }
    // update
    const res = await dispatch(
      editGroupPlan({
        id: editingGroup.id,
        name: editingGroup?.name,
      })
    );

    if (res.payload.error) {
      if (res?.payload?.data?.name[0] === "This field may not be blank.") {
        toast.error("このフィルードは必須です。");
      } else {
        toast.error("更新に失敗しました");
      }
    } else {
      if (onUpdated) {
        onUpdated({
          ...res.payload,
          plan_group_name: editingGroup?.name,
        });
      }
      toast.success("更新に成功しました");
      if (onClose) {
        onClose();
      }
    }
  };

  return (
    <EditGroupNamePopover
      id="newGroupPopover"
      open={!!container}
      anchorEl={container}
      onClose={handleCloseEditGroup}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      <Box className="edit-plan-group-popover-container" sx={{ p: 1.5 }}>
        <TextField
          value={editingGroup?.name}
          onChange={(e) =>
            setEditingGroup({
              ...editingGroup,
              name: e.target.value,
            })
          }
          placeholder="グループ名を入力してください。"
          className={`text-field new-group-input`}
          helperText={errors?.group_name}
          error={!!errors.group_name}
          sx={{
            ".Mui-error .MuiOutlinedInput-notchedOutline": {
              borderColor: "#d32f2f !important",
            },
          }}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            mt: 1.5,
          }}
        >
          <Typography
            onClick={handleCloseEditGroup}
            className={`popover--text clickable`}
            sx={{ mr: 2.5, color: "#7764E4" }}
          >
            キャンセル
          </Typography>
          <Typography
            onClick={handleUpdateOrCreateNewGroup}
            className={`popover--text clickable`}
            sx={{ color: "#7764E4" }}
          >
            保存
          </Typography>
        </Box>
      </Box>
    </EditGroupNamePopover>
  );
};

export default EditGroupPopover;
