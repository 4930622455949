
import * as React from 'react';
import Typography from '@mui/material/Typography';
import styles from './styles.module.scss';
import { Box } from '@mui/system';
import { Link } from "react-router-dom/cjs/react-router-dom.min";

export default function LandingFooter(props) {
  const { title, authLayout = false, backgroundFooter = "#2E4E76" } = props;

  const linkFooters = [
    {
      title: "サービス利用規約",
      link: "/terms",
      display: true,
    },
    {
      title: "プライバシーポリシー",
      link: "/privacy",
      display: true,
    },
    {
      title: "利用規約",
      link: "#",
      display: !authLayout,
    },
  ];

  return (
    <Box
      className={`${styles["box-content-footer"]} ${
        authLayout && styles["footer-login"]
      }`}
      sx={{ backgroundColor: backgroundFooter }}
    >
      <Box className={`${styles["box-footer"]}`}>
        {linkFooters.map((item, index) => {
          return (
            item.display && (
              <Link to={item.link} key={index} className={`${styles["link"]}`}>
                {item.title}
              </Link>
            )
          );
        })}
      </Box>
      {!authLayout && (
        <Typography className={styles["text"]}>
          {title || "Levitt ©︎"}
        </Typography>
      )}
    </Box>
  );
}
