import * as React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import styles from "./styles.module.scss";
import { Collapse, List } from "@mui/material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Box from "@mui/material/Box";
import { matchPath } from "../../helper";
import { styled } from "@mui/material/styles";
import Dashboard from "../../../src/assets/menu_icons/dashboard.svg";
import Suggestion from "../../../src/assets/menu_icons/suggestion.svg";
import Result from "../../../src/assets/menu_icons/result.svg";
import Report from "../../../src/assets/menu_icons/report.svg";
import Competing from "../../../src/assets/menu_icons/competing.svg";
import Setting from "../../../src/assets/menu_icons/setting.svg";
import SuggestionActive from "../../../src/assets/menu_icons/suggestion_active.svg";
import Campaign from "../../../src/assets/menu_icons/campaign.svg";
import CampaignActive from "../../../src/assets/menu_icons/campaign_active.svg";
import ResultActive from "../../../src/assets/menu_icons/result_active.svg";
import ReportActive from "../../../src/assets/menu_icons/report_active.svg";
import CompetingActive from "../../../src/assets/menu_icons/competing_active.svg";
import SettingActive from "../../../src/assets/menu_icons/setting_active.svg";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import { useAuth } from "../../ProvideAuth";

const CustomListItemButton = styled(ListItemButton)(() => ({
  "&.Mui-selected": {
    backgroundColor: `#F0F0F7`,

    "& .MuiListItemText-root": {
      color: "#3F7EAE",
      borderLeft: "2px solid #7764E4",
      margin: 0,
    },
  },
  "&.MuiListItemButton-root": {
    paddingTop: "0 !important",
    paddingBottom: "0 !important",

    "& .MuiListItemText-root": {
      paddingTop: "16px",
      paddingBottom: "16px",
    },
  },
}));

const MainListItems = ({ categories, path, handleLogout }) => {
  const { user: auth } = useAuth();
  const isAdmin = auth?.is_superuser;
  const [achievementsOpen, setAchievementsOpen] = React.useState(
    matchPath("/results/plan/*")
  );
  const [saleResultOpen, setSaleResultOpen] = React.useState(
    matchPath("/results/plan/*")
  );

  return (
    <React.Fragment>
      {isAdmin ? (
        <>
          {categories.map(({ children }, index) => (
            <div key={index}>
              {children.map(({ id: childId, name, active }) => (
                <ListItemButton
                  selected={active === path}
                  key={childId}
                  component={Link}
                  to={active}
                >
                  <ListItemText
                    sx={{ color: active === path ? "#7764E4" : "" }}
                    className={styles["title-sidebar"]}
                  >
                    {name}
                  </ListItemText>
                </ListItemButton>
              ))}
            </div>
          ))}
          <ListItemButton onClick={handleLogout}>
            <ListItemText
              className={styles["title-sidebar"]}
              primary="ログアウト"
            />
          </ListItemButton>
        </>
      ) : (
        <>
          <CustomListItemButton
            component={Link}
            to="/home"
            selected={matchPath("/home")}
            sx={{ padding: "15px 28px" }}
          >
            <ListItemIcon sx={{ minWidth: "24px", mr: "12px" }}>
              <img
                src={Dashboard}
                alt="Dashboard"
                style={{
                  objectFit: "contain",
                }}
              />
            </ListItemIcon>
            <ListItemText
              className={`${styles["title-sidebar"]} ${styles["no-border"]}`}
              primary="ダッシュボード"
            />
          </CustomListItemButton>
          <CustomListItemButton
            component={Link}
            to="/campaign"
            selected={matchPath("/home")}
            sx={{ padding: "15px 28px" }}
          >
            <ListItemIcon sx={{ minWidth: "24px", mr: "12px" }}>
              <img
                src={matchPath("/suggestions*") ? CampaignActive : Campaign}
                alt="Suggestion"
                style={{
                  objectFit: "contain",
                }}
              />
            </ListItemIcon>
            <ListItemText
              className={`${styles["title-sidebar"]} ${styles["no-border"]}`}
              primary="OTA施策管理"
            />
          </CustomListItemButton>
          <CustomListItemButton
            selected={matchPath("/results/plan/*")}
            sx={{ padding: "15px 0px 15px 32px" }}
            onClick={() => setAchievementsOpen(!achievementsOpen)}
          >
            <ListItemIcon sx={{ minWidth: "24px", mr: "12px" }}>
              <img
                src={matchPath("/results/plan/*") ? ResultActive : Result}
                alt="Result"
                style={{
                  objectFit: "contain",
                }}
              />
            </ListItemIcon>
            <ListItemText
              className={`${styles["title-sidebar"]} ${styles["no-border"]}`}
              primary="実績登録・確認"
            />
            <Box
              sx={{
                position: "absolute",
                right: "20px",
                display: "flex",
                alignItems: "center",
                color: achievementsOpen ? "#3F7EAE" : "currentColor",
              }}
            >
              {achievementsOpen ? <ArrowDropUpIcon /> : <ArrowRightIcon />}
            </Box>
          </CustomListItemButton>
          <Collapse in={achievementsOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <CustomListItemButton
                sx={{
                  backgroundColor: "#d8d8e3 !important",
                  pl: "40px",

                  "&.Mui-selected": {
                    backgroundColor: "#F0F0F7 !important",
                  },
                }}
                onClick={() => setSaleResultOpen(!saleResultOpen)}
                selected={saleResultOpen}
              >
                <ListItemText
                  className={styles["title-sidebar"]}
                  primary="プラン販売実績"
                  sx={{
                    pl: "23px",
                  }}
                />
                {saleResultOpen ? (
                  <ArrowDropUpIcon
                    sx={{
                      color: saleResultOpen ? "#3F7EAE" : "currentColor",
                    }}
                  />
                ) : (
                  <ArrowRightIcon />
                )}
              </CustomListItemButton>
              <Collapse in={saleResultOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <CustomListItemButton
                    sx={{
                      pl: 8,
                      backgroundColor: matchPath("/results/plan/group")
                        ? "#d8d8e3 !important"
                        : "#E6E6EF !important",
                    }}
                    component={Link}
                    className={styles["list-child"]}
                    to="/results/plan/group"
                    selected={matchPath("/results/plan/group")}
                  >
                    <ListItemText
                      className={styles["title-sidebar"]}
                      primary="プラングループ"
                      sx={{ pl: "10px" }}
                    />
                    {matchPath("/results/plan/group") ? (
                      <ChevronRightRoundedIcon
                        className={styles["icon-right"]}
                      />
                    ) : (
                      <ChevronRightRoundedIcon
                        className={styles["icon-right-disabled"]}
                      />
                    )}
                  </CustomListItemButton>
                  <CustomListItemButton
                    className={styles["list-child"]}
                    sx={{
                      pl: 8,
                      backgroundColor: matchPath(
                        "/results/plan/plans",
                        "/results/plan/group/*"
                      )
                        ? "#d8d8e3 !important"
                        : "#E6E6EF !important",
                    }}
                    component={Link}
                    to="/results/plan/plans"
                    selected={matchPath(
                      "/results/plan/plans",
                      "/results/plan/group/*"
                    )}
                  >
                    <ListItemText
                      className={styles["title-sidebar"]}
                      primary="プラン"
                      sx={{ pl: "10px" }}
                    />
                    {matchPath(
                      "/results/plan/plans",
                      "/results/plan/group/*"
                    ) ? (
                      <ChevronRightRoundedIcon
                        className={styles["icon-right"]}
                      />
                    ) : (
                      <ChevronRightRoundedIcon
                        className={styles["icon-right-disabled"]}
                      />
                    )}
                  </CustomListItemButton>
                  <CustomListItemButton
                    className={styles["list-child"]}
                    sx={{
                      pl: 8,
                      backgroundColor: matchPath("/results/plan/import")
                        ? "#d8d8e3 !important"
                        : "#E6E6EF !important",
                    }}
                    component={Link}
                    to="/results/plan/import"
                    selected={matchPath("/results/plan/import")}
                  >
                    <ListItemText
                      className={styles["title-sidebar"]}
                      primary="CSVインポート"
                      sx={{ pl: "10px" }}
                    />
                    {matchPath("/results/plan/import") ? (
                      <ChevronRightRoundedIcon
                        className={styles["icon-right"]}
                      />
                    ) : (
                      <ChevronRightRoundedIcon
                        className={styles["icon-right-disabled"]}
                      />
                    )}
                  </CustomListItemButton>
                </List>
              </Collapse>
            </List>
          </Collapse>
          <CustomListItemButton
            component={Link}
            to="/report"
            sx={{ padding: "15px 28px" }}
            selected={matchPath("/report")}
          >
            <ListItemIcon sx={{ minWidth: "24px", mr: "12px" }}>
              <img
                src={matchPath("/report") ? ReportActive : Report}
                alt="Report"
                style={{
                  objectFit: "contain",
                }}
              />
            </ListItemIcon>
            <ListItemText
              className={`${styles["title-sidebar"]} ${styles["no-border"]}`}
              primary="課題を解決する"
            />
          </CustomListItemButton>
          <CustomListItemButton
            component={Link}
            to="/suggestions/plans/create"
            selected={matchPath("/home")}
            sx={{ padding: "15px 28px" }}
          >
            <ListItemIcon sx={{ minWidth: "24px", mr: "12px" }}>
              <img
                src={matchPath("/suggestions*") ? SuggestionActive : Suggestion}
                alt="Suggestion"
                style={{
                  objectFit: "contain",
                }}
              />
            </ListItemIcon>
            <ListItemText
              className={`${styles["title-sidebar"]} ${styles["no-border"]}`}
              primary="AIプラン作成"
            />
          </CustomListItemButton>
          <CustomListItemButton
            component={Link}
            to="/competitor"
            sx={{ padding: "15px 28px" }}
            selected={matchPath("/competitor")}
          >
            <ListItemIcon sx={{ minWidth: "24px", mr: "12px" }}>
              <img
                src={matchPath("/competitor") ? CompetingActive : Competing}
                alt="Competing"
                style={{
                  objectFit: "contain",
                }}
              />
            </ListItemIcon>
            <ListItemText
              className={`${styles["title-sidebar"]} ${styles["no-border"]}`}
              primary="競合施設登録"
            />
          </CustomListItemButton>
          <CustomListItemButton
            component={Link}
            to="/setting"
            sx={{ padding: "15px 28px" }}
            selected={matchPath("/setting")}
          >
            <ListItemIcon sx={{ minWidth: "24px", mr: "12px" }}>
              <img
                src={matchPath("/setting") ? SettingActive : Setting}
                alt="Setting"
                style={{
                  objectFit: "contain",
                }}
              />
            </ListItemIcon>
            <ListItemText
              className={`${styles["title-sidebar"]} ${styles["no-border"]}`}
              primary="設定"
            />
          </CustomListItemButton>
        </>
      )}
    </React.Fragment>
  );
};

export { MainListItems };
