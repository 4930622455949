import React from "react";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import styles from "../../styles.module.scss";

const ExitDialog = ({ onClose, onOk, visible = false }) => {
  return (
    <Modal
      style={{
        backgroundColor: "rgba(46, 78, 118, .5)",
      }}
      open={visible}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box className={styles["warning-modal"]}>
        <div className={styles["warning-modal-icon"]}>
          <i
            className={`icon icon-warning-circle ${styles["information"]}`}
          ></i>
        </div>
        <Typography
          className={styles["warning-modal-title"]}
          variant="h6"
          component="h2"
        >
          グループに登録されていないプランがあります。
        </Typography>
        <Box
          sx={{
            mt: "32px",
          }}
        >
          <Button
            variant="outlined"
            className={`${styles["warning-modal-btn"]} ${styles["warning-modal-btn--save"]}`}
            onClick={onOk}
          >
            後で登録する
          </Button>
          <Button
            variant="contained"
            className={`${styles["warning-modal-btn"]} ${styles["warning-modal-btn--cancel"]}`}
            onClick={onClose}
          >
            登録画面に戻る
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ExitDialog;
