import React from 'react';
import { Route } from "react-router-dom";
import { matchPath } from "./constants/match";
import LandingHeader from "./components/landingHeader";
import LandingFooter from "./components/landingFooter";
import { Box } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { DEFAULT_PAGE_TITLE, USER_ROLE, customTheme } from "./constants";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import { Helmet } from "react-helmet-async";

const PublicRoute = ({ component: Component, ...rest }) => {
  const accessToken = localStorage.getItem("access_token");
  const is_superuser = localStorage.getItem("is_superuser");

  const redirectHomePaths = ["/login", "/register"];
  let currentPath = window.location.href;
  const path = new URL(currentPath).pathname;
  const match = redirectHomePaths.find((item) => matchPath(item, path));
  if (match && accessToken && is_superuser) {
    if (Number(is_superuser) === USER_ROLE) {
      return <Redirect to="/home" />;
    } else {
      return <Redirect to="/admin/users" />;
    }
  }

  return (
    <ThemeProvider theme={customTheme}>
      <Helmet>
        <title>{rest.title || DEFAULT_PAGE_TITLE}</title>
      </Helmet>
      <Box
        sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
      >
        <LandingHeader {...rest} />
        <Box sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
          <Route {...rest} render={(props) => <Component {...props} />} />
        </Box>
        <LandingFooter {...rest} />
      </Box>
    </ThemeProvider>
  );
};

export default PublicRoute;
