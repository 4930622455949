import { Box, Typography } from "@mui/material";
import styles from "../styles.module.scss";
import ReportLayout from "../../../components/ReportLayout";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import {
  ChartAreaBorder,
  ChartBackgroundColor,
  CurrencyFormat,
  getDefaultDateRange,
  getStartEndDateString,
} from "../../../helper";
import { useDispatch, useSelector } from "react-redux";
import { getByDayOfWeekOverview } from "../../../features/report/reportSlice";
import { useEffect, useRef, useState } from "react";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartBackgroundColor,
  ChartAreaBorder
);

const options = {
  layout: {
    padding: 8,
  },
  plugins: {
    legend: {
      display: false,
    },
    chartAreaBorder: {
      borderColor: "#D8D8E3",
      borderWidth: 1,
    },
    chartBg: {
      color: "#F8F9FD",
    },
  },
  responsive: true,
  datasets: {
    bar: {
      barPercentage: 0.6,
    },
    line: {
      pointHitRadius: 0,
      pointBorderWidth: 0,
      pointBackgroundColor: "transparent",
      pointBorderColor: "transparent",
    },
  },
  scales: {
    x: {
      stacked: true,
      ticks: {
        color: "#2E4E76",
        font: {
          weight: "500",
        },
      },
      grid: {
        color: "transparent",
      },
    },
    y: {
      stacked: true,
      ticks: {
        color: "#2E4E76",
        font: {
          weight: "500",
        },
      },
      grid: {
        color: "transparent",
      },
    },
  },
};

const weekDays = [
  {
    id: "sun",
    title: "SUN",
  },
  {
    id: "mon",
    title: "MON",
  },
  {
    id: "tue",
    title: "TUE",
  },
  {
    id: "wed",
    title: "WED",
  },
  {
    id: "thu",
    title: "THU",
  },
  {
    id: "fri",
    title: "FRI",
  },
  {
    id: "sat",
    title: "SUT",
  },
];

const labels = weekDays.map((item) => item.title);
const weekDayIds = weekDays.map((item) => item.id);

const INIT_DATA = {
  labels: labels,
  datasets: [],
};

const ReportByDayOfWeek = () => {
  const dispatch = useDispatch();
  const reportData = useSelector((state) => state.report.dayOfWeek);
  const [dataReservation, setDataReservation] = useState({ ...INIT_DATA });
  const [dataReservationPrice, setDataReservationPrice] = useState({
    ...INIT_DATA,
  });
  const [dataReservationOverview, setDataReservationOverview] = useState({
    ...INIT_DATA,
  });
  const [dataByPrices, setDataByPrices] = useState({
    ...INIT_DATA,
  });
  const [dateRange, setDateRange] = useState(getDefaultDateRange());
  const [priceRangeString, setPriceRangeString] = useState("");
  const [legendLabels, setLegendLabels] = useState([]);
  const reportContainerRef = useRef(null);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const priceRanges = reportContainerRef.current?.priceRanges || [];

  useEffect(() => {
    fetchReportData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchReportData = (prices = "", dates = dateRange) => {
    setPriceRangeString(prices);
    setDateRange(dates);
    dispatch(
      getByDayOfWeekOverview({
        price_ranges: prices,
        ...getStartEndDateString(dates),
      })
    );
  };

  const getReportData = (reportData, valueKey = "total_reservations") => {
    const reportLabels = {};
    const numberReservations = {};
    weekDayIds.forEach((weekDay, weekDayIndex) => {
      reportData[weekDay]?.forEach((report) => {
        if (!numberReservations[report.room_type]) {
          reportLabels[report.room_type] = {
            title: report.room_type,
            color: report.color,
          };
          numberReservations[report.room_type] = {
            label: report.room_type,
            backgroundColor: report.color,
            data: Array.from({ length: weekDayIds.length }, (_) => 0),
          };
        }
        numberReservations[report.room_type].data[weekDayIndex] =
          report[valueKey];
      });
    });

    return {
      legendLabels: reportLabels,
      datasets: Object.values(numberReservations),
    };
  };

  const getReportByPriceData = (
    reportData,
    priceKey = "price_range",
    valueKey
  ) => {
    const chartData = {};
    weekDayIds.forEach((weekDay, weekDayIndex) => {
      reportData[weekDay]?.forEach((report) => {
        if (!chartData[report[priceKey]]) {
          chartData[report[priceKey]] = {
            label: report[priceKey],
            backgroundColor: report.color,
            data: Array.from({ length: weekDayIds.length }, (_) => 0),
          };
        }
        chartData[report[priceKey]].data[weekDayIndex] = report[valueKey];
      });
    });
    const orderedNumberReservations = Object.keys(chartData)
      .sort((a, b) => b.localeCompare(a))
      .reduce((obj, key) => {
        obj[key] = chartData[key];
        return obj;
      }, {});

    return Object.values(orderedNumberReservations);
  };

  const setReportPriceData = (
    topReservationOverview,
    topByWeekdayPriceRange
  ) => {
    if (topReservationOverview) {
      const reportData = getReportByPriceData(
        topReservationOverview,
        "price_range",
        "number_reservations"
      );
      setDataReservationOverview({
        labels,
        datasets: reportData,
      });
    }
    if (topByWeekdayPriceRange) {
      const reportData = getReportByPriceData(
        topByWeekdayPriceRange,
        "range",
        "number_nights"
      );
      setDataByPrices({
        labels,
        datasets: reportData,
      });
    }
  };

  const setReportNumberReservations = (
    reportNumberReservations,
    reportNumberReservationsByPrice
  ) => {
    let reportLabels = {};
    if (reportNumberReservations) {
      const { legendLabels, datasets } = getReportData(
        reportNumberReservations
      );
      reportLabels = Object.values(legendLabels);
      setDataReservation({
        labels,
        datasets,
      });
    }
    if (reportNumberReservationsByPrice) {
      const { legendLabels, datasets } = getReportData(
        reportNumberReservationsByPrice,
        "total_rooms"
      );
      reportLabels = {
        ...reportLabels,
        ...Object.values(legendLabels),
      };
      setDataReservationPrice({
        labels,
        datasets,
      });
    }
    setLegendLabels(Object.values(reportLabels));
  };

  useEffect(() => {
    // report by number reservations
    const reportNumberReservations =
      reportData?.top_reservations_room_each_days;
    const reportNumberReservationsByPrice =
      reportData?.top_number_rooms_each_days;
    setReportNumberReservations(
      reportNumberReservations,
      reportNumberReservationsByPrice
    );

    // report by price
    const topReservationOverview = reportData?.top_reservation_overview;
    const topByWeekdayPriceRange = reportData?.top_by_weekday_price_range;
    setReportPriceData(topReservationOverview, topByWeekdayPriceRange);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportData]);

  const ChartLabels = ({ labels }) => {
    return (
      <Box
        className={`${styles["report-item"]} ${styles["grouped-labels"]} ${styles["grouped-labels-grid"]}`}
      >
        {labels?.map((item, index) => (
          <Box className={styles["grouped-label"]} key={index}>
            <Box
              className={styles["grouped-label-color"]}
              sx={{ backgroundColor: item.color }}
            ></Box>
            <span>{item.title}</span>
          </Box>
        ))}
      </Box>
    );
  };

  return (
    <ReportLayout
      reportTitle="曜日別データ"
      reportTooltip={'曜日ごとに最適な客室タイプと予約単価を見つけることができます。\n客室タイプと単価と曜日の相性を確認し、予約数の増加を必要としている曜日に対して最適な客室タイプと単価の組み合わせを検討してください。'}
      pageNumber={5}
      showFooter
      ref={reportContainerRef}
      onChangePriceRanges={(newPrice) => fetchReportData(newPrice)}
      onChangeDateRange={(newDates) =>
        fetchReportData(priceRangeString, newDates)
      }
    >
      <Box className={styles["report-items"]}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
          <Box className={styles["report-item"]}>
            <Typography className={styles["report-item-title"]}>
              客室タイプ別予約件数
              <span className={styles["report-item-title--span"]}>
                (予約実績)
              </span>
            </Typography>
            <Box>
              <Bar options={options} data={dataReservation} />
            </Box>
          </Box>
          <Box className={styles["report-item"]}>
            <Typography className={styles["report-item-title"]}>
              宿泊単価別予約件数
              <span className={styles["report-item-title--span"]}>
                (予約実績)
              </span>
            </Typography>
            <Box>
              <Bar options={options} data={dataReservationPrice} />
            </Box>
          </Box>
          {<ChartLabels labels={legendLabels} />}
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
          <Box className={styles["report-item"]}>
            <Typography className={styles["report-item-title"]}>
              客室タイプ別宿泊件数
              <span className={styles["report-item-title--span"]}>
                (宿泊実績)
              </span>
            </Typography>
            <Box>
              <Bar options={options} data={dataReservationOverview} />
            </Box>
          </Box>
          <Box className={styles["report-item"]}>
            <Typography className={styles["report-item-title"]}>
              宿泊単価別宿泊件数
              <span className={styles["report-item-title--span"]}>
                (宿泊実績)
              </span>
            </Typography>
            <Box>
              <Bar options={options} data={dataByPrices} />
            </Box>
          </Box>
          <Box
            className={`${styles["report-item"]} ${styles["grouped-labels"]} ${styles["grouped-labels-grid"]} ${styles["grouped-labels-grid-multiple"]}`}
          >
            {priceRanges.map((item, index) => (
              <Box className={styles["grouped-label"]} key={index}>
                <Box
                  className={styles["grouped-label-color"]}
                  sx={{ backgroundColor: item.color }}
                ></Box>
                <span>
                  {index !== priceRanges?.length - 1 && "~"}¥
                  {CurrencyFormat(item.to)}
                  {index + 1 === priceRanges?.length && "~"}
                </span>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </ReportLayout>
  );
};
export default ReportByDayOfWeek;
