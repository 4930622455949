/* eslint-disable jsx-a11y/img-redundant-alt */
import * as React from 'react';
import Typography from '@mui/material/Typography';
import styles from './styles.module.scss';
import { Box } from '@mui/system';
import LogoLanding from '../../assets/images/logo_landing.svg';
import LogoLandingInline from '../../assets/images/logo_icon_inline.svg';
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect, useState } from 'react';
import { Link } from "react-router-dom/cjs/react-router-dom.min";

export default function LandingHeader(props) {
  const { hasBackground = false, headerBgColor = "#E6E6EF" } = props;
  const [isScrolled, setIsScrolled] = useState(false);
  const isLogin = localStorage.getItem("access_token");
  const roleUser = useSelector(
    (state) => state.auth?.currentUser?.is_superuser
  );
  const history = useHistory();
  const submitViewHome = (checkRoleUser) => {
    if (checkRoleUser) {
      return history.push("/admin/users");
    } else {
      return history.push("/home");
    }
  };

  const submitLandingPage = () => {
    return history.push("/");
};

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Box
      className={styles["box-header-login"]}
      style={{
        backgroundColor:
          isScrolled || hasBackground ? headerBgColor : "transparent",
      }}
    >
      <Box onClick={() => (isLogin ? submitViewHome(roleUser) : submitLandingPage())} className={styles["header-logo-image"]}>
          <img
            src={isScrolled || hasBackground ? LogoLandingInline : LogoLanding}
            alt="logo with icon inline"
            style={{ width: "100%", objectFit: "contain" }}
          />
      </Box>
      <Box className={styles["box-right-login"]}>
        {!isLogin && (
          <>
            <Typography className={styles["text-right-login"]}>
              <Link
                to="/login"
                style={{
                  textDecoration: "none",
                  color: isScrolled || hasBackground ? "#2F4E76" : "#FFFFFF",
                }}
                className={styles["link"]}
              >
                ログイン
              </Link>
            </Typography>
            <Box
              component={Link}
              to="/register"
              className={styles["button-landing"]}
              style={{
                color: isScrolled || hasBackground ? "#2F4E76" : "#FFFFFF",
                borderColor:
                  isScrolled || hasBackground ? "#2F4E76" : "#FFFFFF",
              }}
            >
              新規登録
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
}
