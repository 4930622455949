import React from "react";
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  TextField,
  Typography,
  Modal,
} from "@mui/material";
import styles from "./styles.module.scss";
import BreadCrumb from "../../components/breadCrumb";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import ReactDatePicker, {
  CalendarContainer,
  registerLocale,
} from "react-datepicker";
import moment from "moment";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import MuiTooltip from "@mui/material/Tooltip";
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import ja from "date-fns/locale/ja";
import { useDispatch, useSelector } from "react-redux";
import { toggleMenu } from "../../features/report/reportSlice";
import ReportPdf from "../../pages/report/pdf";
registerLocale("ja", ja);

const theme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: "1em",
          fontWeight: "bold",
          color: "#172B4D",
          backgroundColor: "#F0F0F7",
          border: "4px solid #FFFFFF",
          borderRadius: "10px",
          padding: "20px 40px",
          boxShadow: "0px 0px 8px #00000066",
          width: "100%",
          maxWidth: "600px",
        },
        arrow: {
          color: "darkblue",
        },
      },
    },
  },
});

const customInputStyle = {
  background: "#F7F7F8",
  border: "none",
  borderRadius: "4px",

  "& .MuiInputBase-input": {
    p: "6px 12px",
    color: "#2E4E76",
    fontWeight: 500,
    fontSize: "14px",
  },

  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "transparent",
  },
};

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80vw",
  maxHeight: "90vh",
  display: "flex",
  flexDirection: "column",
  bgcolor: "background.paper",
  overflow: "hidden",
  border: "0",
  boxShadow: 24,
  borderRadius: "4px",

  "&:focus-visible": {
    outline: "none",
  },
};

const ReportLayout = forwardRef((props, ref) => {
  const {
    reportTitle,
    reportTooltip,
    pageNumber = 1,
    showFooter = false,
    onChangePriceRanges,
    onChangeDateRange,
  } = props;
  const currentMonth = moment();
  const DEFAULT_DATE = [
    currentMonth.startOf("month").toDate(),
    currentMonth.endOf("month").toDate(),
  ];
  const [dateRange, setDateRange] = useState(DEFAULT_DATE);
  const [startDate, setStartDate] = useState(DEFAULT_DATE[0]);
  const [endDate, setEndDate] = useState(DEFAULT_DATE[1]);
  const calStartRef = useRef();
  const calEndRef = useRef();
  const [priceRangeErrors, setPriceRangeErrors] = useState({});
  const [priceRangesString, setPriceRangeString] = useState("");
  const reportMenuOpen = useSelector((state) => state.report.menuOpen);
  const dispatch = useDispatch();
  const handleToggleMenu = () => {
    dispatch(toggleMenu());
  };

  const formattedTooltip = reportTooltip
    .split("\n")
    .map((line, index, array) => (
      <React.Fragment key={index}>
        {line}
        {index < array.length - 1 ? <br /> : null}
      </React.Fragment>
    ));

  const CustomDatePickerButton = forwardRef(({ value, onClick }, ref) => (
    <button
      className={styles["custom-datepicker-button"]}
      onClick={onClick}
      ref={ref}
    >
      {value}
      <span className={styles["icon-choose"]}>
        <ArrowDropDownIcon />
      </span>
    </button>
  ));

  const CustomCalendarContainer = ({ className, children }) => {
    return (
      <CalendarContainer className={className}>
        <div style={{ position: "relative", minHeight: "270px" }}>
          {children}
        </div>
        <div className={styles["calendar-actions"]}>
          <button
            onClick={cancelChangeDateRange}
            className={`${styles["btn-cancel"]} ${styles["btn"]}`}
          >
            キャンセル
          </button>
          <button
            onClick={handleChangeDateRange}
            className={`${styles["btn-apply"]} ${styles["btn"]}`}
          >
            適用
          </button>
        </div>
      </CalendarContainer>
    );
  };

  // eslint-disable-next-line no-unused-vars
  const [priceRanges, setPriceRanges] = useState([
    {
      to: 10000,
      color: "#E5D6F7",
    },
    {
      to: 20000,
      color: "#BB86FC",
    },
    {
      to: 30000,
      color: "#6210EE",
    },
    {
      to: 40000,
      color: "#3709B3",
    },
    {
      to: 40001,
      color: "#23046A",
    },
  ]);

  const handleChangePriceRages = (event, changingRangeIndex) => {
    const newValue = event.target.value;
    const newInputValue = parseInt(String(newValue || 0));
    const newPriceRanges = [...priceRanges];
    newPriceRanges[changingRangeIndex] = {
      ...newPriceRanges[changingRangeIndex],
      to: newInputValue,
    };
    if (changingRangeIndex === newPriceRanges.length - 2) {
      newPriceRanges[changingRangeIndex + 1].to = newInputValue + 1;
    }
    // handle check get minium value as previous range
    setPriceRanges(newPriceRanges);
    validatePriceRanges(newPriceRanges);
  };

  const validatePriceRanges = (ranges) => {
    const updatedPriceRanges = ranges;
    const errors = {};
    updatedPriceRanges?.forEach((range, index) => {
      const currentRangePrice = range?.to || 0;
      if (!index && !currentRangePrice) {
        errors[index] = currentRangePrice || 0;
      } else {
        const previousPriceRange = updatedPriceRanges[index - 1]?.to || 0;
        if (currentRangePrice <= previousPriceRange) {
          errors[index] = previousPriceRange;
        }
      }
    });
    setPriceRangeErrors(errors);
    if (Object.keys(errors).length === 0) {
      const priceRangesCopy = [...updatedPriceRanges];
      priceRangesCopy.pop();
      const newPriceRanges = priceRangesCopy.map((range) => {
        return range.to;
      });
      setPriceRangeString(newPriceRanges.join(","));
    }
  };

  useEffect(() => {
    if (onChangePriceRanges && priceRangesString) {
      const delayDebounceFn = setTimeout(() => {
        onChangePriceRanges(priceRangesString);
      }, 500);

      return () => clearTimeout(delayDebounceFn);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [priceRangesString]);

  const currentPath = window.location.pathname;

  useImperativeHandle(ref, () => ({
    priceRanges,
  }));

  const formatDate = (date) => {
    return moment(date).format("YYYY年M月D日");
  };

  const reportMenuItems = [
    {
      title: "概要",
      smallTitle: "①",
      path: "/report",
    },
    {
      title: "プラン×OTAランキング",
      smallTitle: "②",
      path: "/report/plan-ranking",
    },
    {
      title: "プラン×部屋タイプランキング",
      smallTitle: "③",
      path: "/report/plan-ranking/room-type",
    },
    {
      title: "プラン×曜日別データ",
      smallTitle: "④",
      path: "/report/plan-ranking/day-of-week",
    },
    {
      title: "部屋タイプランキング",
      smallTitle: "⑤",
      path: "/report/room-type-ranking",
    },
    {
      title: "宿泊単価別リードタイム×予約件数",
      smallTitle: "⑥",
      path: "/report/price-ranking",
    },
    {
      title: "曜日別データ",
      smallTitle: "⑦",
      path: "/report/day-of-week",
    },
  ];

  const handleChangeDate = (date, dateType = "startDate") => {
    if (dateType === "startDate") {
      setStartDate(date);
    } else {
      setEndDate(date);
    }
  };

  const handleChangeDateRange = () => {
    setDateRange([startDate, endDate]);
    calStartRef.current.setOpen(false);
    calEndRef.current.setOpen(false);
    if (onChangeDateRange) onChangeDateRange([startDate, endDate]);
  };

  const cancelChangeDateRange = () => {
    calStartRef.current.setOpen(false);
    calEndRef.current.setOpen(false);
    setStartDate(dateRange[0]);
    setEndDate(dateRange[1]);
  };

  const [isOpenDownloadModal, setIsOpenDownloadModal] = useState(false);
  const [isLoadedApi, setIsLoadedApi] = useState(false);
  const handleOpenDownloadModal = () => {
    setIsOpenDownloadModal(true);
    setIsLoadedApi(true);
  };
  const handleCloseDownloadModal = () => setIsOpenDownloadModal(false);

  const modalDownloadReport = useMemo(() => {
    return (
      <Modal
        keepMounted
        open={isOpenDownloadModal}
        onClose={handleCloseDownloadModal}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={modalStyle}>
          {isLoadedApi && (
            <ReportPdf
              dateRange={dateRange}
              priceRanges={priceRanges}
            ></ReportPdf>
          )}
        </Box>
      </Modal>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpenDownloadModal]);

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        height: "100%",
        overflow: "auto",
        p: 4,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <BreadCrumb
        content="課題を解決する"
        textRoutes={["ダッシュボード", "課題を解決する"]}
      />
      <Box className={styles["box-header"]}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box>
            <ReactDatePicker
              ref={calStartRef}
              selected={startDate}
              onChange={(date) => handleChangeDate(date, "startDate")}
              customInput={<CustomDatePickerButton />}
              dateFormat="yyyy年M月d日"
              calendarClassName="custom-datepicker"
              locale="ja"
              monthsShown={2}
              maxDate={endDate}
              onCalendarClose={cancelChangeDateRange}
              shouldCloseOnSelect={false}
              calendarContainer={CustomCalendarContainer}
            ></ReactDatePicker>
          </Box>
          <Typography sx={{ mx: 1 }}>~</Typography>
          <Box>
            <ReactDatePicker
              ref={calEndRef}
              selected={endDate}
              onChange={(date) => handleChangeDate(date, "endDate")}
              customInput={<CustomDatePickerButton />}
              dateFormat="yyyy年M月d日"
              calendarClassName="custom-datepicker"
              locale="ja"
              monthsShown={2}
              minDate={startDate}
              onCalendarClose={cancelChangeDateRange}
              shouldCloseOnSelect={false}
              calendarContainer={CustomCalendarContainer}
            ></ReactDatePicker>
          </Box>
        </Box>
        <FileDownloadOutlinedIcon
          onClick={handleOpenDownloadModal}
          className={styles["icon-download"]}
          sx={{
            cursor: "pointer",
          }}
        />
      </Box>
      <Box
        className={styles["report-container"]}
        sx={{
          mt: 3,
          gridTemplateColumns: reportMenuOpen ? "70% auto" : "1fr 76px",
        }}
      >
        <Box
          className={`${styles["report-box"]} ${styles["report-box-1"]}`}
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box className={styles["report-box-header"]}>
            <Typography
              className={styles["text-header-information"]}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <p className={styles["report-box-header-title"]}>{reportTitle}</p>
              <ThemeProvider theme={theme}>
                <MuiTooltip title={<>{formattedTooltip}</>} placement="top-end">
                  <HelpOutlineRoundedIcon
                    sx={{
                      ml: "30px",
                      fontSize: "25.25px",
                      cursor: "pointer",
                    }}
                  />
                </MuiTooltip>
              </ThemeProvider>
            </Typography>
            <p className={styles["report-box-header-date"]}>
              {formatDate(dateRange[0])}〜{formatDate(dateRange[1])}
            </p>
          </Box>
          <Box sx={{ flexGrow: 1 }}>{props.children}</Box>
          <Box className={styles["report-box-footer"]}>
            <Box className={styles["logo"]}></Box>
            <Typography className={styles["report-box-footer-text"]}>
              {pageNumber}
            </Typography>
          </Box>
        </Box>
        <Box
          className={`${styles["report-box"]} ${styles["report-menu-items"]}`}
        >
          <List className={styles["report-list"]}>
            {reportMenuItems.map((item, index) => (
              <ListItem
                key={index}
                disablePadding
                className={styles["report-menu-item"]}
              >
                <ListItemButton
                  component={Link}
                  to={item.path}
                  className={
                    styles[currentPath === item.path ? "selected" : ""]
                  }
                >
                  <ListItemText
                    sx={{
                      textAlign: reportMenuOpen ? "left" : "center",
                    }}
                    primary={reportMenuOpen ? item.title : item.smallTitle}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          <Box sx={{ paddingLeft: reportMenuOpen ? "30px" : "18px" }}>
            <Button
              variant="contained"
              disableElevation
              className={styles["btn-navigate"]}
              onClick={handleToggleMenu}
            >
              <ArrowForwardIosRoundedIcon />
            </Button>
          </Box>
        </Box>
      </Box>
      {showFooter && (
        <Box
          className={styles["report-container"]}
          sx={{
            mt: "32px",
            gridTemplateColumns: reportMenuOpen ? "70% auto" : "1fr 76px",
          }}
        >
          <Box className={styles["report-box"]} sx={{ p: "12px 32px" }}>
            <Box className={styles["price-ranges"]}>
              {priceRanges.map((item, index) => (
                <Box key={index} className={styles["price-range-item"]}>
                  <Typography variant="p" className={styles["price-range"]}>
                    {index === 0 && (
                      <span className={styles["price-range-start"]}>0</span>
                    )}
                    {index !== priceRanges.length - 1 && (
                      <span className={styles["price-range-symbol"]}>〜</span>
                    )}
                    <span className={styles["price-range-currency"]}>¥</span>
                    <TextField
                      error={priceRangeErrors[index] !== undefined}
                      variant="outlined"
                      value={item.to}
                      sx={customInputStyle}
                      type="number"
                      onChange={(e) => handleChangePriceRages(e, index)}
                      InputProps={{
                        readOnly: index === priceRanges.length - 1,
                      }}
                    />
                    {index === priceRanges.length - 1 && (
                      <span className={styles["price-range-symbol-end"]}>
                        〜
                      </span>
                    )}
                  </Typography>
                  <Box
                    sx={{
                      width: "100%",
                      height: "4px",
                      backgroundColor: item.color,
                      borderRadius: "10px",
                      mt: "4px",
                    }}
                  ></Box>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      )}
      {modalDownloadReport}
    </Box>
  );
});
export default ReportLayout;
