import { Box } from "@mui/material";
import styles from "../styles.module.scss";
import ReportLayout from "../../../components/ReportLayout";
import ReportStackBarChart from "../../../components/ReportHorizontalChart/ReportStackBarChart";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPlanOtaRanking } from "../../../features/report/reportSlice";
import {
  CurrencyFormat,
  getDefaultDateRange,
  getStartEndDateString,
} from "../../../helper";

const ReportPlanRanking = () => {
  const dispatch = useDispatch();
  const reportData = useSelector((state) => state.report.planOta);
  const [transformData, setTransformData] = useState([]);
  const [labels, setLabels] = useState({});
  const [maxCount, setMaxCount] = useState(0);
  const [dateRange, setDateRange] = useState(getDefaultDateRange());

  useEffect(() => {
    dispatch(getPlanOtaRanking(getStartEndDateString(dateRange)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange]);

  useEffect(() => {
    const labels = {};
    let maxCount = 0;
    const transformData = reportData?.map((item) => {
      labels[item.total.title] = item.total.color;
      if (item.total.number_nights > maxCount) {
        maxCount = item.total.number_nights;
      }
      return {
        ...item,
        total: {
          color: item.total.color,
          title: item.total.title,
          value: item.total.number_nights,
        },
        groups: item?.groups?.map((group) => {
          labels[group.title] = group.color;
          return {
            color: group.color,
            title: group.title,
            value: group.number_nights,
          };
        }),
      };
    });
    setLabels(labels);
    setTransformData(transformData);
    setMaxCount(maxCount);
  }, [reportData]);

  return (
    <ReportLayout
      reportTitle="プラン×OTAランキング"
      reportTooltip={'OTAごとに相性の良いプランを見つけることができます。\nOTAごとに売れやすいプランの傾向や、逆にプランに対して売れやすいOTAの傾向を確認してください。'}
      pageNumber={2}
      onChangeDateRange={(newDates) => setDateRange(newDates)}>
      <Box className={styles["report-ranking"]}>
        <table
          className={`${styles["report-table"]} ${styles["report-table--ranking"]}`}>
          <thead>
            <tr>
              <th>プラングループ名</th>
              <th>泊数</th>
              <th>予約件数</th>
              <th>平均予約単価</th>
              <th>売上</th>
            </tr>
          </thead>
          <tbody>
            {transformData.map((plan, index) => (
              <Fragment key={index}>
                <tr className={styles["detail-row"]}>
                  <td>{plan.name}</td>
                  <td>{CurrencyFormat(plan.total?.value)}</td>
                  <td>{CurrencyFormat(plan.number_reservations)}</td>
                  <td>¥{CurrencyFormat(plan.average_price)}</td>
                  <td>¥{CurrencyFormat(plan.total_fee)}</td>
                </tr>
                <tr>
                  <td colSpan={5}>
                    <ReportStackBarChart data={plan} maxValue={maxCount} />
                  </td>
                </tr>
              </Fragment>
            ))}
          </tbody>
        </table>
        <Box className={styles["grouped-labels"]}>
          {Object.entries(labels).map(([title, color], index) => (
            <Box className={styles["grouped-label"]} key={index}>
              <Box
                className={styles["grouped-label-color"]}
                sx={{ backgroundColor: color }}
              ></Box>
              <span>{title}</span>
            </Box>
          ))}
        </Box>
      </Box>
    </ReportLayout>
  );
};
export default ReportPlanRanking;
