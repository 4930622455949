import React, { useState } from "react";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import styles from "./styles.module.scss";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Select,
  TextField,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import UpdateIcon from "@mui/icons-material/Update";
import EditNoteIcon from "@mui/icons-material/EditNote";
import CachedIcon from "@mui/icons-material/Cached";
import moment from "moment";
import BreadCrumb from "../../../components/breadCrumb/index";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const textRoutes = ["ダッシュボード", "AIプラン作成", "プラン作成"];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      style={{ width: "90%" }}
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function Dashboard() {
  const [value, setValue] = useState(0);
  const history = useHistory();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const storedData = localStorage.getItem("planResponse");
  const parsedData = JSON.parse(storedData);
  const parsedDataPlan = parsedData;
  const dateRange = parsedDataPlan?.term?.match(/\d{4}年\d{2}月\d{2}日/g);
  const startDateString = dateRange ? dateRange[0] : "";
  const endDateString = dateRange ? dateRange[1] : "";
  const endDate = startDateString
    ? moment(endDateString, "YYYY年MM月DD日")
    : "";
  const startDate = endDateString
    ? moment(startDateString, "YYYY年MM月DD日")
    : "";

  if (!parsedData) {
    history.push("/plan/create");
  }
  return (
    <Box
      component="main"
      sx={{
        backgroundColor: "#F1F3F9",
        flexGrow: 1,
        height: "100vh",
        overflow: "auto",
      }}
    >
      <Toolbar />
      <Container
        maxWidth="lg"
        sx={{ mt: 4, mb: 4, maxWidth: "97% !important" }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <BreadCrumb content="提案を受ける" textRoutes={textRoutes} />
            <Box
              sx={{
                flexGrow: 1,
                bgcolor: "background.paper",
                display: "flex",
                height: "100%",
                borderRadius: "10px",
              }}
            >
              <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                sx={{
                  borderRight: 1,
                  borderColor: "divider",
                  backgroundColor: "#F1F3F9",
                  overflow: "initial",
                }}
              >
                <Tab
                  sx={{ color: "#2E4E76 !important" }}
                  className={styles["text-tab"]}
                  label="A"
                  {...a11yProps(0)}
                />
                <Tab
                  sx={{
                    color: "#FFFF !important",
                    background: "#97CBE2 !important",
                  }}
                  className={styles["text-tab"]}
                  label="B"
                  {...a11yProps(1)}
                />
                <Tab
                  sx={{
                    color: "#FFFF !important",
                    background: "#B7E1F1 !important",
                  }}
                  className={styles["text-tab"]}
                  label="C"
                  {...a11yProps(2)}
                />
                <Tab
                  className={styles["text-tab"]}
                  label={<UpdateIcon />}
                  {...a11yProps(3)}
                />
                <Tab
                  sx={{
                    color: "#B7E1F1 !important",
                    background: "#FFFF !important",
                  }}
                  className={styles["text-tab"]}
                  label={<DeleteOutlineIcon />}
                  {...a11yProps(4)}
                />
              </Tabs>
              <TabPanel value={value} index={0}>
                <Typography className={styles["text-title"]}>
                  プランタイトル
                </Typography>
                <TextField
                  value={parsedDataPlan?.plan_title}
                  className={styles["text-input"]}
                />
                <Typography className={styles["text-number"]}>
                  文字数：{parsedDataPlan?.plan_title?.length}/50
                </Typography>
                <Typography className={styles["text-description"]}>
                  プラン説明文
                </Typography>
                <textarea
                  className={styles["text-area"]}
                  id="w3review"
                  name="w3review"
                  rows="4"
                  cols="159"
                >
                  {parsedDataPlan?.plan_description}
                </textarea>
                <Typography className={styles["text-number"]}>
                  文字数：{parsedDataPlan?.plan_description?.length}
                </Typography>
                <Typography className={styles["text-description"]}>
                  特典
                </Typography>
                <textarea
                  className={styles["text-area"]}
                  id="w3review"
                  name="w3review"
                  rows="4"
                  cols="159"
                >
                  プラン説明文が入りますプラン説明文が入りますプ
                </textarea>
                <Grid sx={{ marginTop: "15px" }} container spacing={2}>
                  <Grid item xs={2}>
                    <Typography className={styles["text-box"]}>
                      参考販売価格
                    </Typography>
                  </Grid>
                  <Grid item xs={10}>
                    <TextField
                      value={parsedDataPlan?.budget}
                      className={styles["text-inputs"]}
                    />
                  </Grid>
                </Grid>
                <Grid sx={{ marginTop: "15px" }} container spacing={2}>
                  <Grid item xs={2}>
                    <Typography className={styles["text-box"]}>
                      参考販売価格
                    </Typography>
                  </Grid>
                  <Grid item xs={10}>
                    <FormControl sx={{ width: "50%", float: "left" }}>
                      <Select
                        className={styles["text-select"]}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                        sx={{
                          borderRadius: "8px",
                          background: "#FFFF 0% 0% no-repeat padding-box",
                        }}
                      ></Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={2}>
                    <Typography className={styles["text-box"]}>
                      販売期間
                    </Typography>
                  </Grid>
                  <Grid item xs={10}>
                    <Box sx={{ display: "flex", marginTop: "24px" }}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          className={styles["box-date-picker"]}
                          value={startDate ? startDate : ""}
                          sx={{ marginRight: "20px" }}
                        />
                      </LocalizationProvider>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          className={styles["box-date-picker"]}
                          value={endDate}
                        />
                      </LocalizationProvider>
                    </Box>
                  </Grid>
                </Grid>
                <Box
                  className={styles["text-title-box"]}
                  sx={{ marginTop: "15px" }}
                >
                  なぜこのプランを導き出したのかの説明が入りますなぜこのプランを導き出したのかの説明が入りますなぜこのプランを導き出したのかの説明が入りますなぜ
                  このプランを導き出したのかの説明が入りますなぜこのプランを導き出したのかの説明が入りますなぜこのプランを導き出したのかの説明が入りますなぜこの
                  プランを導き出したのかの説明が入りますなぜこのプランを導き出したのかの説明が入りますなぜこのプランを導き出したのかの説明が入りますなぜこのプラ
                  ンを導き出したのかの説明が入りますなぜこのプランを導き出したのかの説明が入りますなぜこのプランを導き出したのかの説明が入りますなぜこのプランを
                  導き出したのかの説明が入りますな
                </Box>
                <Typography
                  sx={{ marginTop: "15px" }}
                  className={styles["text-title"]}
                >
                  販売するOTAを選ぶ
                </Typography>
                <FormGroup sx={{ flexDirection: "row" }}>
                  <FormControlLabel
                    control={<Checkbox defaultChecked />}
                    label={
                      <Typography variant="h7" style={{ color: "#2E4E76" }}>
                        楽天トラベル
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    control={<Checkbox defaultChecked />}
                    label={
                      <Typography variant="h7" style={{ color: "#2E4E76" }}>
                        じゃらんNet
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    control={<Checkbox defaultChecked />}
                    label={
                      <Typography variant="h7" style={{ color: "#2E4E76" }}>
                        一休.com
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    control={<Checkbox defaultChecked />}
                    label={
                      <Typography variant="h7" style={{ color: "#2E4E76" }}>
                        Relux
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    control={<Checkbox defaultChecked />}
                    label={
                      <Typography variant="h7" style={{ color: "#2E4E76" }}>
                        るるぶトラベル
                      </Typography>
                    }
                  />
                </FormGroup>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Box className={styles["box-button-sale"]}>
                    <Typography className={styles["text-button-sale"]}>
                      販売を検討(保留)する
                    </Typography>
                  </Box>
                  <Box className={styles["box-button"]}>
                    <Typography className={styles["text-button"]}>
                      上記の内容でプランを造成する
                    </Typography>
                  </Box>
                </Box>
              </TabPanel>
              <TabPanel value={value} index={1}>
                Item Two
              </TabPanel>
              <TabPanel value={value} index={2}>
                Item Three
              </TabPanel>
              <TabPanel value={value} index={3}>
                <Typography className={styles["text-title"]}>
                  プランを再作成する
                </Typography>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Box className={styles["box-button-edit"]}>
                    <EditNoteIcon className={styles["icon-edit"]} />
                    <Typography className={styles["text-button-edit"]}>
                      条件を変更して再作成する
                    </Typography>
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <Box className={styles["button-edit"]}>入力画面へ</Box>
                    </Box>
                  </Box>
                  <Box className={styles["box-button-cache"]}>
                    <CachedIcon className={styles["icon-cache"]} />
                    <Typography className={styles["text-button-cache"]}>
                      条件を変えずに再作成する
                    </Typography>
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <Box className={styles["button-cache"]}>再作成を開始</Box>
                    </Box>
                  </Box>
                </Box>
              </TabPanel>
              <TabPanel value={value} index={4}>
                <Typography className={styles["text-title"]}>
                  作成したプランを削除する
                </Typography>
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      marginBottom: "25px",
                      marginTop: "25px",
                    }}
                  >
                    <DeleteOutlineIcon
                      sx={{ fontSize: "100px", color: "#F53C56" }}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      marginBottom: "25px",
                    }}
                  >
                    <Typography className={styles["text-delete"]}>
                      一度削除したプランは再度作成することはできません。
                      検討する場合は、作成されたそれぞれのプランより、
                      「販売を検討(保留)する」をクリックしてください。
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Box className={styles["box-delete"]}>完全に削除する</Box>
                  </Box>
                </Box>
              </TabPanel>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
