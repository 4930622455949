export const matchPath = (pattern, path) => {
    const regex = new RegExp(`^${pattern
      .replace(/\[id\]/g, '\\d+')
      .replace(/\/:([^/]+)/g, '/([^/]+)')}$`);

    const match = path.match(regex);
    if (!match) return false;

    const params = pattern
      .split('/')
      .filter(part => part.startsWith(':'))
      .map(param => param.substr(1));

    return params.every((param, index) => {
      return match[index + 1] !== undefined;
    });
  };
