/* eslint-disable react-hooks/exhaustive-deps */
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import styles from "./styles.module.scss";
import { TextField, MenuItem } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import DatePicker from "react-datepicker";
import { Formik, ErrorMessage } from "formik";
import moment from "moment";
import * as Yup from "yup";
import { toast } from "react-toastify";
import {
  getDetailNotice,
  createOrUpdateNoti
} from "../../features/notifications/noticeSlice";
import { fetchSelectOptions } from "../../features/apiSelectOptions";
import { generateMaxTextValidation , TEXT_REQUIRED } from "../../constants/validation"

export default function FormCreateOrUpdateNoti(props) {
  const { id } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [status, setStatus] = useState([]);
  const [category, setCategory] = useState([]);
  const [recipients, setRecipients] = useState([]);
  const [visibility, setVisibility] = useState([]);

  const fetchData = async () => {
    const res = await dispatch(fetchSelectOptions());
    const statusData = res.payload.data.status_options.map((item, index) => ({
      id: index + 1,
      name: item[1],
    }));
    setStatus(statusData);
    const categoryData = res.payload.data.category_options.map(
      (item, index) => ({ id: index + 1, name: item[1] })
    );
    setCategory(categoryData);
    const recipientData = res.payload.data.recipient_options.map(
      (item, index) => ({ id: index + 1, name: item[1] })
    );
    setRecipients(recipientData);
    const visibilityData = res.payload.data.visibility_options.map(
      (item, index) => ({ id: index + 1, name: item[1] })
    );
    setVisibility(visibilityData);
  };

  const formikConfig = {
    initialValues: {
      title: data?.title || "",
      status: data?.status || "1",
      category: data?.category || "1",
      recipients: data?.recipients || "1",
      visibility: data?.visibility || "1",
      publication_date: data?.publication_date
      ? data?.publication_date
      : "",
      content: data?.content || "",
    },
    onSubmit: async (values, { setSubmitting, setErrors }) => {
      dispatch(
        createOrUpdateNoti({
          ...values,
          id
        })
      ).then((res) => {
        setSubmitting(false);
        if (res.payload?.error) {
          setErrors(res.payload?.data);
          toast.error("システムエラー");
        } else {
          return history.push(`/admin/notifications`);
        }
      });
    },
    validationSchema: () => {
      return Yup.object({
        title: Yup.string().max(255,generateMaxTextValidation()).required(TEXT_REQUIRED),
        status: Yup.number().integer().required(TEXT_REQUIRED),
        category: Yup.number().integer().required(TEXT_REQUIRED),
        recipients: Yup.number()
          .integer()
          .required(TEXT_REQUIRED),
        visibility: Yup.number()
          .integer()
          .required(TEXT_REQUIRED),
        publication_date: Yup.string().required(TEXT_REQUIRED),
        content: Yup.string().required(TEXT_REQUIRED),
      });
    },
  };

  useEffect(() => {
    if (id) {
      const fetchNoticeData = async () => {
        const res = await dispatch(getDetailNotice(id));
        const noticeData = res.payload;
        setData(noticeData);
      };

      fetchNoticeData();
    }
  }, [id]);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Formik
        enableReinitialize={true}
        validateOnChange={false}
        initialValues={formikConfig.initialValues}
        onSubmit={formikConfig.onSubmit}
        validationSchema={formikConfig.validationSchema}
      >
        {({
          handleChange,
          handleBlur,
          values,
          errors,
          handleSubmit,
          setFieldValue,
        }) => (
          <Container className={styles["home-center"]} maxWidth="xl">
            <Box className={styles["box-header"]}>
              <Typography className={styles["text-header-home"]}>
                {id ? 'お知らせ編集' : 'お知らせ作成'}
              </Typography>
              <Box onClick={handleSubmit} className={styles["text-create"]}>
                保存する
              </Box>
            </Box>
            <Grid className={styles["box-grid"]} container spacing={2}>
              <Grid className={styles["text-left"]} item xs={3}>
                タイトル
              </Grid>
              <Grid className={styles["grid-item"]} item xs={9}>
                <TextField
                  name="title"
                  value={values.title}
                  onChange={handleChange}
                  className={styles["text-field"]}
                  id="filled-basic"
                  helperText={errors.title}
                  error={!!errors.title}
                />
              </Grid>
            </Grid>

            <Grid className={styles["box-grid"]} container spacing={2}>
              <Grid className={styles["text-left"]} item xs={3}>
                ステータス
              </Grid>
              <Grid className={styles["grid-item"]} item xs={9}>
                <Select
                  className={styles["text-select"]}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  name="status"
                  onChange={handleChange}
                  value={values.status ?? ""}
                >
                  {status?.map((item) => {
                    return (
                      <MenuItem
                        key={item.id}
                        className={styles["tag"]}
                        value={item.id}
                      >
                        {item.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Grid>
            </Grid>

            <Grid className={styles["box-grid"]} container spacing={2}>
              <Grid className={styles["text-left"]} item xs={3}>
                カテゴリ
              </Grid>
              <Grid className={styles["grid-item"]} item xs={9}>
                <Select
                  className={styles["text-select"]}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  name="category"
                  onChange={handleChange}
                  value={values.category ?? ""}
                >
                  {category?.map((item) => {
                    return (
                      <MenuItem
                        key={item.id}
                        className={styles["tag"]}
                        value={item.id}
                      >
                        {item.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Grid>
            </Grid>

            <Grid className={styles["box-grid"]} container spacing={2}>
              <Grid className={styles["text-left"]} item xs={3}>
                公開先
              </Grid>
              <Grid className={styles["grid-item"]} item xs={9}>
                <Select
                  className={styles["text-select"]}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  name="recipients"
                  onChange={handleChange}
                  value={values.recipients ?? ""}
                >
                  {recipients?.map((item) => {
                    return (
                      <MenuItem
                        key={item.id}
                        className={styles["tag"]}
                        value={item.id}
                      >
                        {item.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Grid>
            </Grid>

            <Grid className={styles["box-grid"]} container spacing={2}>
              <Grid className={styles["text-left"]} item xs={3}>
                公開範囲
              </Grid>
              <Grid className={styles["grid-item"]} item xs={9}>
                <Select
                  className={styles["text-select"]}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  name="visibility"
                  onChange={handleChange}
                  value={values.visibility ?? ""}
                >
                  {visibility?.map((item) => {
                    return (
                      <MenuItem
                        key={item.id}
                        className={styles["tag"]}
                        value={item.id}
                      >
                        {item.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Grid>
            </Grid>

            <Grid
              className={styles["box-grid"]}
              sx={{ borderBottom: "2px solid #2E4E76 !important" }}
              container
              spacing={2}
            >
              <Grid className={styles["text-left"]} item xs={3}>
                公開日
              </Grid>
              <Grid className={styles["grid-item"]} item xs={9}>
                <DatePicker
                  value={
                    values.publication_date
                      ? moment(values.publication_date).format(
                          "YYYY年MM月DD日"
                        )
                      : ""
                  }
                  onBlur={handleBlur("publication_date")}
                  onChange={(date) => {
                    if (date) {
                      const formattedDate = moment(date).format("YYYY-MM-DD");
                      setFieldValue("publication_date", formattedDate);
                    } else {
                      setFieldValue("publication_date", null);
                    }
                  }}
                  className={`${styles["box-date"]} ${
                    errors?.publication_date ? styles["errors-date"] : ""
                  }`}
                />
                <ErrorMessage
                  className={styles["error-date"]}
                  name="publication_date"
                  component="div"
                />
              </Grid>
            </Grid>

            <Grid className={styles["box-editor"]} container spacing={2}>
              <Grid className={styles["text-bottom"]} item xs={3}>
                本文
              </Grid>
              <Grid className={styles["grid-item"]} item xs={9}>
                <div className="custom-editor">
                  <CKEditor
                    name="publication_date"
                    editor={ClassicEditor}
                    data={values.content}
                    onChange={(event, editor) => {
                        const data = editor.getData();
                        setFieldValue('content', data);
                    }}
                    config={{
                      minHeight: "500px",
                    }}
                  />
                </div>
                <ErrorMessage
                  className={styles["error-date"]}
                  name="content"
                  component="div"
                />
              </Grid>
            </Grid>
          </Container>
        )}
      </Formik>
    </>
  );
}
