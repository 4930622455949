/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  TextField,
  Typography,
  InputAdornment,
  Button,
} from "@mui/material";
import styles from "./styles.module.scss";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { fetchActiveUSer } from "../../../../features/apiActiveUSer";
import React, { useState, useEffect, forwardRef } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { getHotels } from "../../../../features/apiGetHotel";
import * as Yup from "yup";
import { Formik } from "formik";
import { regex } from "../../../../helper";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import {
  generateMaxTextValidation,
  TEXT_REQUIRED,
} from "../../../../constants/validation";
import HTTP from "../../../../http_common";

const INIT_DATA = {
  uid: "",
  token: "",
  first_name: "",
  last_name: "",
  name: "",
  password: "",
  hotel_id: null,
  invitation_code: null,
};
const filter = createFilterOptions();

export default function RegisterAccount() {
  const [showPassword, setShowPassword] = useState(false);
  const [checkActive, setCheckActive] = useState(true);
  const [hotels, setHotels] = useState([]);
  const [currentScrollY, setCurrentScrollY] = useState(0);
  const [nextPageUrl, setNextPageUrl] = useState("");
  const [loadingHotels, setLoadingHotels] = useState(false);
  const { id, token } = useParams();
  const email = new URLSearchParams(useLocation().search).get("email");
  const dispatch = useDispatch();
  const [delaySearch, setDelaySearch] = useState();

  const shouldShowIcon = showPassword;
  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const fetchDataHotels = async (dataQuery) => {
    try {
      setLoadingHotels(true);
      const res = await dispatch(getHotels(dataQuery));
      const { results, next: nextPage } = res.payload;
      setNextPageUrl(nextPage);
      setLoadingHotels(false);
      return results?.data;
    } catch (error) {
      return [];
    }
  };

  useEffect(() => {
    fetchDataHotels().then((data) => {
      setHotels(data);
    });
  }, []);

  useEffect(() => {
    if (document.getElementById("hotelList")) {
      document.getElementById("hotelList").scrollTop = currentScrollY;
    }
  }, [hotels]);

  const inputHotelOnChange = async (query) => {
    const dataQuery = { search_param: query };
    clearTimeout(delaySearch);
    setLoadingHotels(true);
    const search = setTimeout(async () => {
      const data = await fetchDataHotels(dataQuery);
      setHotels(data || []);
      setLoadingHotels(false);
    }, 500);
    setDelaySearch(search);
  };

  const formikConfig = {
    initialValues: {
      ...INIT_DATA,
      uid: id,
      token: token,
    },
    onSubmit: async (values, { setSubmitting, setErrors }) => {
      try {
        setSubmitting(true);
        const res = await dispatch(fetchActiveUSer(values));
        if (res.payload.status === 201) {
          setCheckActive(false);
        } else {
          toast.error(res.payload.data);
        }
        setSubmitting(false);
      } catch (err) {
        toast.error("システムエラー");
        setSubmitting(false);
      }
    },
    validationSchema: () => {
      return Yup.object({
        first_name: Yup.string()
          .max(255, generateMaxTextValidation())
          .required(TEXT_REQUIRED),
        last_name: Yup.string()
          .max(255, generateMaxTextValidation())
          .required(TEXT_REQUIRED),
        password: Yup.string()
          .required("パスワードを入力してください。")
          .min(8, "パスワードは８文字以上で入力してください。")
          .matches(
            regex.password,
            "パスワードは英大文字、英小文字、数字、特殊文字が必要です。"
          ),
      });
    },
  };

  const ListBoxComponent = forwardRef((props, ref) => {
    const getHotelNextPage = () => {
      if (nextPageUrl) {
        setLoadingHotels(true);
        HTTP.get(nextPageUrl)
          .then((res) => {
            const { results, next: nextPage } = res;
            setNextPageUrl(nextPage);
            setHotels([...hotels, ...(results?.data || [])]);
            return results?.data;
          })
          .finally(() => {
            setLoadingHotels(false);
          });
      }
    };

    const handleScroll = (event) => {
      const paddingScrollBottom = 100;
      const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;
      const currentScroll = scrollTop + clientHeight + paddingScrollBottom;
      if (currentScroll >= scrollHeight && !loadingHotels) {
        getHotelNextPage();
        setCurrentScrollY(currentScroll);
      }
    };
    return <ul {...props} onScroll={handleScroll} />;
  });

  return (
    <Box
      sx={{
        flexGrow: 1,
        pt: 10,
        pb: 5,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {checkActive ? (
        <Formik
          initialValues={formikConfig.initialValues}
          onSubmit={formikConfig.onSubmit}
          validationSchema={formikConfig.validationSchema}
        >
          {({
            handleChange,
            handleBlur,
            errors,
            setFieldValue,
            handleSubmit,
          }) => (
            <Box className={styles["box-main-content"]}>
              <Box sx={{ width: "100%" }}>
                <Typography className={styles["content-account"]}>
                  アカウントを作成する
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <TextField
                    sx={{ marginTop: "32px" }}
                    className={styles["custom-field"]}
                    name="last_name"
                    id="lastName"
                    label="姓"
                    variant="outlined"
                    onChange={handleChange}
                    onBlur={handleBlur("last_name")}
                    helperText={errors.last_name}
                    error={!!errors.last_name}
                  />
                  <TextField
                    className={styles["custom-field"]}
                    name="first_name"
                    id="firstName"
                    label="名"
                    variant="outlined"
                    onChange={handleChange}
                    onBlur={handleBlur("first_name")}
                    helperText={errors.first_name}
                    error={!!errors.first_name}
                  />
                  <Autocomplete
                    freeSolo
                    loading={loadingHotels}
                    loadingText="読み込み中..."
                    componentsProps={{
                      paper: {
                        sx: {
                          width: "650px",
                          boxShadow: "0px 0px 8px #00000052",
                        },
                      },
                    }}
                    className={styles["custom-field"]}
                    id="hotelInput"
                    name="name"
                    label="hotelInput"
                    variant="outlined"
                    onChange={(event, newValue) => {
                      setFieldValue("hotel_id", null);
                      if (typeof newValue === "string") {
                        setFieldValue("name", newValue);
                      } else if (newValue && newValue.inputValue) {
                        setFieldValue("name", newValue);
                      } else {
                        setFieldValue("hotel_id", newValue?.id);
                      }
                    }}
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);
                      return filtered;
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    options={hotels}
                    getOptionLabel={(option) => {
                      if (typeof option === "string") {
                        return option;
                      }
                      if (option?.inputValue) {
                        return option?.inputValue;
                      }
                      return option?.name;
                    }}
                    onInputChange={(event, newInputValue) => {
                      inputHotelOnChange(newInputValue);
                      setFieldValue("name", newInputValue);
                    }}
                    renderOption={(props, option) => (
                      <li
                        {...props}
                        style={{ display: "flex" }}
                        key={option?.id}
                      >
                        <div style={{ width: "50%", color: "#2E4E76" }}>
                          {option?.name}
                        </div>
                        <div
                          style={{
                            width: "50%",
                            color: "#2E4E76",
                            marginLeft: 24,
                          }}
                        >
                          {option?.address}
                        </div>
                      </li>
                    )}
                    sx={{ width: "30%" }}
                    renderInput={(params) => (
                      <TextField
                        id="name"
                        label="宿泊施設名"
                        variant="outlined"
                        xs={{ fontWeight: "bold" }}
                        {...params}
                      />
                    )}
                    ListboxProps={{
                      id: "hotelList",
                    }}
                    ListboxComponent={ListBoxComponent}
                    noOptionsText={"該当する宿泊施設がありません。"}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Box
                    className={styles["custom-field"]}
                    sx={{ textAlign: "right" }}
                  >
                    <Typography sx={{ color: "#3f7eae" }}>
                      該当の施設名がありませんか？登録は
                      <Link
                        to={`/register/${id}/${token}/new-hotel?email=${encodeURIComponent(
                          email
                        )}`}
                        style={{ color: "#3f7eae" }}
                      >
                        こちら
                      </Link>
                    </Typography>
                  </Box>
                  <TextField
                    sx={{ marginTop: "32px" }}
                    className={styles["custom-field"]}
                    name="invitation_code"
                    id="invitationCode"
                    label="招待コード(任意)"
                    variant="outlined"
                    onChange={handleChange}
                    onBlur={handleBlur("invitation_code")}
                  />
                  <Box
                    sx={{ marginTop: "20px", display: "flex", width: "30%" }}
                  >
                    <Typography sx={{ marginRight: "16px" }}>ID</Typography>
                    <Typography>{email}</Typography>
                  </Box>
                  <TextField
                    className={styles["custom-field"]}
                    name="password"
                    type={showPassword ? "text" : "password"}
                    id="password"
                    label="パスワード"
                    variant="outlined"
                    onChange={handleChange}
                    onBlur={handleBlur("password")}
                    helperText={errors.password}
                    error={!!errors.password}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {shouldShowIcon ? (
                            <VisibilityOffOutlinedIcon
                              onClick={handleTogglePasswordVisibility}
                              style={{ cursor: "pointer", color: "#3f7eae" }}
                            />
                          ) : (
                            <RemoveRedEyeOutlinedIcon
                              onClick={handleTogglePasswordVisibility}
                              style={{ cursor: "pointer", color: "#3f7eae" }}
                            />
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
                <Box className={styles["box-continue"]}>
                  <Button
                    className={styles["text-button"]}
                    onClick={handleSubmit}
                  >
                    アカウントを作成
                  </Button>
                </Box>
              </Box>
            </Box>
          )}
        </Formik>
      ) : (
        <>
          <Box className={styles["box-content"]}>
            <Box sx={{ width: "100%" }}>
              <Typography className={styles["content-account"]}>
                アカウントの登録申請完了
              </Typography>
              <Box className={styles["content-account"]}>
                <Typography className={styles["content-bot-account"]}>
                  アカウントの登録が完了しました。
                  <br />
                  宿泊施設であることが確認でき次第、 <br />
                  メールでお知らせいたします。
                </Typography>
              </Box>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
}
