import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const { REACT_APP_API_ENDPOINT } = process.env;
export const fetchEditlUser = createAsyncThunk('editUser/fetchEditlUser', async (body) => {
  const access_token = localStorage.getItem('role')
  const formData = new FormData();
  formData.append('first_name', body.first_name);
  formData.append('last_name', body.last_name);
  formData.append('accommodation_name', body.accommodation_name);
  formData.append('rate', body.rate);
  formData.append('hotel_id', body.hotel_id);
  formData.append('account_category', body.account_category);
  formData.append('is_accommodation_facility', body.is_accommodation_facility);
  formData.append('all_is_enabled', body.options.all_is_enabled);
  formData.append('plan_is_enabled', body.options.plan_is_enabled);
  formData.append('ad_is_enabled', body.options.ad_is_enabled);
  formData.append('sale_is_enabled', body.options.sale_is_enabled);
  formData.append('coupon_is_enabled', body.options.coupon_is_enabled);
  formData.append('report_is_enabled', body.options.report_is_enabled);

  const response = await fetch(`${REACT_APP_API_ENDPOINT}/api/update-user/${body.id}/`, {
    method: 'PUT',
    body: formData,
    headers: {
        'Authorization': `Bearer ${access_token}`
    },
  });
  const jsonData = await response.json();
  return jsonData;
});
const apiSlice = createSlice({
  name: 'edit-user',
  initialState: {
    data: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchEditlUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchEditlUser.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchEditlUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;

      });
  },
});

export default apiSlice.reducer;
