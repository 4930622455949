import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import styles from "./styles.module.scss";
import { FormHelperText, InputLabel, MenuItem, TextField } from "@mui/material";
import Select from "@mui/material/Select";
import React, { useState } from "react";
import { register } from "../../../../features/apiRegister";
import { useDispatch } from "react-redux";

const datas = [
  { id: 0, name: "ユーザー" },
  { id: 1, name: "管理者" },
];
const INIT_DATA = {
  email: "",
  role_type: "",
};

const INIT_ERROR = {
  email: null,
};

export default function ReceiveSuggestions() {
  const [values, setValues] = useState(INIT_DATA);
  const [errors, setErrors] = useState(INIT_ERROR);
  const dispatch = useDispatch();
  const handleChange = (e) => {
    setValues((values) => ({
      ...values,
      [e.target.name]: e.target.value,
    }));
    setErrors({
      ...errors,
      email: "",
    });
  };
  const handlSelectProvinces = (event) => {
    setValues({
      ...values,
      [event.target.name]: event?.target.value,
    });
  };
  const sumbitRegisterEmail = async () => {
    const res = await dispatch(register(values));
    if (res.payload.email) {
      setErrors({
        ...errors,
        email: res.payload.email,
      });
    }
  };
  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        height: "100vh",
        overflow: "auto",
        backgroundColor: "#F1F3F9",
      }}
    >
      <Box className={styles["box-content"]}>
        <Typography className={styles["content-email"]}>
          新規アカウント作成
        </Typography>
        <Box sx={{ marginTop: "24px" }} className={styles["content-email"]}>
          <Typography className={styles["content-bottom-email"]}>
            登録に進むとサービス利用規約、
          </Typography>
          <Typography className={styles["content-bottom-email"]}>
            プライバシーポリシーに同意したことにします。
          </Typography>
          <Typography className={styles["content-bottom-email"]}>
            有料版のご利用にはクレジットカードの登録が必要です。
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <TextField
            sx={{ width: "20%", marginTop: "16px" }}
            id="outlined-basic"
            name="email"
            label="メールアドレスを入力"
            variant="outlined"
            onChange={handleChange}
          />
          <FormHelperText className={styles["error-text"]}>
            {errors.email}
          </FormHelperText>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <InputLabel
            sx={{ marginTop: "14px", marginLeft: "-18%" }}
            id="demo-simple-select-label"
          >
            役割
          </InputLabel>
          <Select
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
            sx={{
              height: "50px",
              background: "#F1F3F9 0% 0% no-repeat padding-box",
              width: "20%",
            }}
            name="role_type"
            value={values.role_type ?? ""}
            onChange={handlSelectProvinces}
          >
            {datas?.map((item) => (
              <MenuItem key={item.id} className={styles["tag"]} value={item.id}>
                <Box className={styles["box-title-name"]}>{item.name}</Box>
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box className={styles["box-continue"]}>
          <Typography
            onClick={sumbitRegisterEmail}
            className={styles["text-button"]}
          >
            Continue
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
