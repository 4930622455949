import { Box, Snackbar, SnackbarContent } from "@mui/material";
import Laptop from "../../assets/images/landingpage_banner.png";
import styles from "./styles.module.scss";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import InsertLinkRoundedIcon from "@mui/icons-material/InsertLinkRounded";
import { useState } from "react";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const InvitePage = () => {
  const authData = useSelector((state) => state.auth);
  const inviteCode = authData.currentUser?.invite_code;
  const [openSlackBar, setOpenSlackBar] = useState(false);
  const pageUrl = window.location.href + "?invite_code=" + inviteCode;

  const copyInviteCode = () => {
    try {
      navigator.clipboard.writeText(inviteCode);
      setOpenSlackBar(true);
    } catch (error) {
      toast.error("コピーできません!");
    }
  };

  const handleCloseSlackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSlackBar(false);
  };

  const openWindowPopup = (url) => {
    window.open(
      url.replace("localhost", "127.0.0.1"),
      "pop",
      "width=600, height=400, scrollbars=no"
    );
    return false;
  };

  const shareWithFacebook = () => {
    const facebookShareURL = "https://www.facebook.com/sharer/sharer.php?u=";
    openWindowPopup(facebookShareURL + pageUrl);
    return false;
  };

  const shareWithTwitter = () => {
    const twitterShareURL = "https://twitter.com/intent/tweet?";
    const text = "売れるホテルの宿泊プランをAIで簡単に作りませんか？\nLevitt Liteなら毎月5件まで無料で宿泊プランをAIに作成してもらえます！\nさらに充実したレポートで戦略的なホテルマーケティングを始められます。\n\n";
    const urlEncodedText = encodeURIComponent(text);
    const pageUrlEncoded = encodeURIComponent(pageUrl);
    openWindowPopup(twitterShareURL + "url=" + pageUrlEncoded + "&text=" + urlEncodedText);
    return false;
  };

  return (
    <Box className={styles["invite-container"]}>
      <Box className={styles["invite-banner"]}>
        <img
          src={Laptop}
          alt="banner"
          className={styles["invite-banner--img"]}
        />
      </Box>
      <h1 className={styles["invite-title"]}>
        アカウントの招待で5つのチケットを獲得
      </h1>
      <Box className={styles["invite-code"]}>
        {inviteCode}
        <ContentCopyRoundedIcon
          className={styles["invite-code--icon"]}
          onClick={() => copyInviteCode()}
        />
      </Box>
      <Box className={styles["invite-share-buttons"]}>
        <Box
          className={styles["invite--button"]}
          onClick={() => copyInviteCode()}
        >
          <InsertLinkRoundedIcon className={styles["invite--button--icon"]} />
          <span className={styles["invite--button--text"]}>COPY</span>
        </Box>
        <Box className={styles["invite--button"]} onClick={shareWithTwitter}>
          <Box
            className={`${styles["invite--button--icon"]} ${styles["twitter-icon"]}`}
          ></Box>
          <span className={styles["invite--button--text"]}>POST</span>
        </Box>
        <Box className={styles["invite--button"]} onClick={shareWithFacebook}>
          <FacebookRoundedIcon className={styles["invite--button--icon"]} />
          <span className={styles["invite--button--text"]}>POST</span>
        </Box>
      </Box>
      <p className={styles["invite-text"]}>
        あなたの招待コードで新規アカウントが登録されると
        <br />
        5つのチケットがプレゼントされます。
      </p>
      <Snackbar
        open={openSlackBar}
        autoHideDuration={1800}
        onClose={handleCloseSlackBar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <SnackbarContent
          message={"Copied!"}
          sx={{ bgcolor: "#1A1B35", display: "flex", justifyContent: "center" }}
        ></SnackbarContent>
      </Snackbar>
    </Box>
  );
};

export default InvitePage;
