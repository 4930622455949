/* eslint-disable react-hooks/exhaustive-deps */
import Box from "@mui/material/Box";
import styles from "../create/styles.module.scss";
import React from "react";
import { useParams } from "react-router-dom";
import FormCreateOrUpdateNoti from "../../../../components/FormCreateOrUpdateNoti";

export default function ReceiveSuggestions() {
    let { id } = useParams();
  return (
    <Box component="main" className={styles["box-main"]}>
      <FormCreateOrUpdateNoti id={id} />
    </Box>
  );
}
