/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { List } from '@mui/material';
import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { MainListItems } from '../pages/suggestions/listItems';
import styles from './styles.module.scss';
import LogoLandingInline from '../assets/images/logo_icon_inline.svg';
import { useHistory } from "react-router-dom";
import { fetchLogout } from "../features/apiLogout";
import { useDispatch } from "react-redux";

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
      '& .MuiDrawer-paper': {
        whiteSpace: 'nowrap',
        width: 258,
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
        boxSizing: 'border-box',
        ...(!open && {
          overflowX: 'hidden',
          transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          width: theme.spacing(7),
          [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
          },
        }),
      },
    }),
  );

const categories = [
  {
    id: 'Management',
    children: [
      { id: 'Users', active: '/admin/users', name: 'ユーザー管理', },
      { id: 'OTA', active: '/admin/OTA', name: 'OTA施策登録', },
      { id: 'Noti', active: '/admin/notifications', name: 'お知らせ管理', },
      { id: 'Application', active: '/admin/application', name: '施設登録申請', },
      { id: 'RecommendPlan', active: '/admin/recommend-plans', name: '作成プランの管理', },
    ],
  }
];

export default function Sidebar(props) {
const { open, toggleDrawer } = props;
const history = useHistory();
let currentPath = window.location.href;
const url = new URL(currentPath);
const path = url.pathname;
const dispatch = useDispatch();
const pushRouter = (page) => {
  if (page) {
    history.push(page);
  }
};
const handleLogout = async () => {
  const res = await dispatch(fetchLogout());
  if (res.payload) {
    window.location.href = "/login";
  }
};
const [openUser, setOpenUser] = useState(true);
const handleClick = () => {
  setOpenUser(!openUser);
};
  return (
    <Drawer
      sx={{ width: open ? "258px" : "72px" }}
      variant="permanent"
      open={open}
    >
      <Toolbar
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography className={styles["text-sidebar"]}>
          <img
            src={LogoLandingInline}
            alt="logo with icon inline"
            style={{ width: "100%", objectFit: "contain", marginTop: "10px" }}
          />
        </Typography>
        <IconButton
          sx={{ background: "#B7E1F1 0% 0% no-repeat padding-box" }}
          onClick={toggleDrawer}
        >
          <ChevronLeftIcon />
        </IconButton>
      </Toolbar>
      <List component="nav">
        <MainListItems
          handleLogout={handleLogout}
          path={path}
          pushRouter={pushRouter}
          categories={categories}
          open={openUser}
          handleClick={handleClick}
        />
      </List>
    </Drawer>
  );
}
