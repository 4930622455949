import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import HTTP from "../http_common";

export const fetchActiveUSer = createAsyncThunk(
  "active/fetchActiveUSer",
  async (body) => {
    const formData = new FormData();
    formData.append("uid", body.uid);
    formData.append("token", body.token);
    formData.append("last_name", body.last_name);
    formData.append("first_name", body.first_name);
    formData.append("accommodation_name", body.name);
    formData.append("password", body.password);
    formData.append("invitation_code", body.invitation_code || "");
    if (body.hotel_id) {
      formData.append("hotel_id", body.hotel_id);
    }
    const response = await HTTP.post("/api/users/activate", formData);
    return response;
  }
);

const apiSlice = createSlice({
  name: 'active-users',
  initialState: {
    data: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchActiveUSer.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchActiveUSer.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchActiveUSer.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;

      });
  },
});

export default apiSlice.reducer;
