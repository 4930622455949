import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import HTTP from '../http_common';

export const fetchLogin = createAsyncThunk("login/fetchLogin", async (body) => {
  const formData = new FormData();
  formData.append("username", body.email);
  formData.append("password", body.password);
  const response = await HTTP.post(`api/token/`, formData);
  return response;
});
const apiSlice = createSlice({
  name: "logout",
  initialState: {
    data: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchLogin.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchLogin.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchLogin.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default apiSlice.reducer;
