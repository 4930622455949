import { Box, Button, TextField, Typography } from "@mui/material";
import styles from "./styles.module.scss";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { registerNewHotel } from "../../../../../features/apiRegisterHotel.js";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  generateMaxTextValidation,
  TEXT_REQUIRED,
} from "../../../../../constants/validation";

const INIT_DATA = {
  uid: "",
  token: "",
  first_name: "",
  last_name: "",
  name: "",
  in_site_id: "",
};

export default function RegisterNewHotel() {
  let location = useLocation();
  const [checkActive, setCheckActive] = useState(true);
  let searchParams = new URLSearchParams(location.search);
  let email = searchParams.get("email");
  const dispatch = useDispatch();
  const { id, token } = useParams();

  const formikConfig = {
    initialValues: {
      ...INIT_DATA,
      uid: id,
      token: token,
      email_registration: email,
    },
    onSubmit: async (values, { setSubmitting, setErrors }) => {
      try {
        setSubmitting(true);
        try {
          const res = await dispatch(registerNewHotel(values));
          if (res.payload.status === 201) {
            toast.success(res.payload.message);
            setCheckActive(false)
          } else {
            toast.error(res.payload.message);
          }
          setSubmitting(false);
        } catch (err) {
          toast.error("システムエラー");
          setSubmitting(false);
        }
      } catch (err) {
        toast.error("システムエラー");
        setSubmitting(false);
      }
    },
    validationSchema: () => {
      return Yup.object({
        first_name: Yup.string()
          .max(255, generateMaxTextValidation())
          .required(TEXT_REQUIRED),
        last_name: Yup.string()
          .max(255, generateMaxTextValidation())
          .required(TEXT_REQUIRED),
        name: Yup.string()
          .max(255, generateMaxTextValidation())
          .required(TEXT_REQUIRED),
        in_site_id: Yup.string()
          .max(255, generateMaxTextValidation())
          .required(TEXT_REQUIRED),
      });
    },
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        pt: 10,
        pb: 5,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {checkActive ? (
        <Formik
          initialValues={formikConfig.initialValues}
          onSubmit={formikConfig.onSubmit}
          validationSchema={formikConfig.validationSchema}
        >
          {({
            handleChange,
            handleBlur,
            errors,
            handleSubmit,
            isSubmitting,
          }) => (
            <Box className={styles["box-main-content"]}>
              <Box sx={{ width: "100%" }}>
                <Typography className={styles["content-account"]}>
                  施設の登録申請
                </Typography>
                <Box className={styles["box-content"]}>
                  <Typography sx={{ margin: "0px", fontSize: "12px" }}>
                    Levittに登録されていない施設の方は、
                  </Typography>
                  <Typography sx={{ margin: "0px", fontSize: "12px" }}>
                    お手数ですが以下のフォームより申請ください。
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <TextField
                    className={styles["custom-field"]}
                    name="last_name"
                    id="last_name"
                    label="姓"
                    variant="outlined"
                    onChange={handleChange}
                    onBlur={handleBlur("last_name")}
                    helperText={errors.last_name}
                    error={!!errors.last_name}
                  />
                  <TextField
                    sx={{ marginTop: "32px" }}
                    className={styles["custom-field"]}
                    name="first_name"
                    id="first_name"
                    label="名"
                    variant="outlined"
                    onChange={handleChange}
                    onBlur={handleBlur("first_name")}
                    helperText={errors.first_name}
                    error={!!errors.first_name}
                  />
                  <TextField
                    className={styles["custom-field"]}
                    name="name"
                    id="name"
                    label="宿泊施設名"
                    variant="outlined"
                    onChange={handleChange}
                    onBlur={handleBlur("name")}
                    helperText={errors.name}
                    error={!!errors.name}
                  />
                  <Box
                    className={styles["custom-field"]}
                    sx={{
                      marginTop: "20px",
                      display: "flex",
                      alignItems: "left",
                    }}
                  >
                    <Typography sx={{ marginRight: "20px" }}>ID</Typography>
                    <Typography>{email}</Typography>
                  </Box>
                  <TextField
                    className={styles["custom-field"]}
                    name="in_site_id"
                    id="outlined-basic"
                    label="掲載しているOTAのURL"
                    variant="outlined"
                    onChange={handleChange}
                    onBlur={handleBlur("in_site_id")}
                    helperText={errors.in_site_id}
                    error={!!errors.in_site_id}
                  />
                </Box>
                <Box className={styles["box-continue"]}>
                  <Button
                    onClick={handleSubmit}
                    className={styles["text-button"]}
                    disabled={isSubmitting}
                  >
                    施設の登録を申請する
                  </Button>
                </Box>
              </Box>
            </Box>
          )}
        </Formik>
      ) : (
        <>
          <Box className={styles["box-content"]}>
            <Box sx={{ width: "100%" }}>
              <Typography className={styles["content-account"]}>
                施設の登録申請完了
              </Typography>
              <Box
                sx={{ marginTop: "20px" }}
                className={styles["content-account"]}
              >
                <Typography className={styles["title-bot-account"]}>
                  施設の登録申請完了
                  <br />
                  担当が確認次第ご連絡いたします。 <br />
                  ールでお知らせいたします。
                </Typography>
              </Box>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
}
