import {
  Box,
  Button,
  Card,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
  Popover
} from "@mui/material";
import BreadCrumb from "../../components/breadCrumb";
import styles from "./styles.module.scss";
import SearchIcon from "@mui/icons-material/Search";
import React, { useState, useEffect } from "react";
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { useDispatch, useSelector } from "react-redux";
import { getHotels } from "../../features/apiGetHotel";
import PaginationPage from "../../components/pagination";
import {
  registerHotelCompetition,
  getListHotelCompetition,
  deleteHotelCompetition
} from "../../features/competitor/competitorSlice";
import statusCode from "../../constants/statusCodes";
import { toast } from "react-toastify";

const filter = createFilterOptions();
const CompetitorPage = () => {
  const [value, setValue] = useState(null);
  const [hotels, setHotels] = useState([]);
  const [delaySearch, setDelaySearch] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [confirmDelete, setConfirmDelete] = useState(null);
  const [deleteID, setdeleteID] = useState(null);
  const [showRegister, setShowRegister] = useState(false);
  const authData = useSelector(state => state.auth);
  const competitions = useSelector((state) => state.competition);
  const competitionList = competitions?.list
  const paginate = competitions?.paginate;
  const dispatch = useDispatch();

  const inputHotelOnChange = async (query) => {
    const dataQuery = { search_param: query };
    clearTimeout(delaySearch);
    const search = setTimeout(async () => {
      const data = await fetchDataFromAPI(dataQuery);
      setHotels(data);
    }, 500);
    setDelaySearch(search);
  };

  const fetchDataFromAPI = async (dataQuery) => {
    try {
      const res = await dispatch(getHotels(dataQuery));
      setHotels(res.payload?.results?.data);
      return res.payload?.results?.data;
    } catch (error) {
      return [];
    }
  };

  useEffect(() => {
    fetchDataFromAPI()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const registerCompetition = async () => {
    try {
      const res = await dispatch(registerHotelCompetition(value?.id));
      if (res.payload.status === statusCode.CREATED) {
        toast.success("競合施設の登録に成功しました。");
        fetchHotelCompetition();
        setShowRegister(false);
        fetchDataFromAPI(value.name)
      }
    } catch (error) {
      toast.error("競合施設の登録に失敗しました。");
      return [];
    }
  }

  // get list
  const fetchHotelCompetition = async () => {
    dispatch(getListHotelCompetition({ page: currentPage }));
  };

  useEffect(() => {
    fetchHotelCompetition();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  // delete
  const showPopupDelete = async (id) => {
    if (!confirmDelete) {
      setdeleteID(id);
      setConfirmDelete(true);
    }
  }

  // cancel delete
  const handleCloseConfirmDelete = () => {
    setdeleteID(null);
    setConfirmDelete(null);
  };

  // confirm delete
  const confirmDeleteCompetition = async () => {
    try {
      await dispatch(deleteHotelCompetition(deleteID));
      handleCloseConfirmDelete();
      const competitonDelete = competitionList.filter(element => element.id === deleteID);
      if (competitonDelete[0]?.hotel === value?.id) {
        setShowRegister(true);
      }
      toast.success("競合施設を削除しました。");
      dispatch(getListHotelCompetition({ page: currentPage }));
    } catch (error) {
      toast.error("競合施設の削除に失敗しました。");
    }
  };

  useEffect(() => {
    setShowRegister(false);
    if (!value?.competition_registrations.includes(authData?.currentUser?.id)) {
      setShowRegister(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);
  return (
    <Box
      component="main"
      sx={{
        overflow: "auto",
        p: 4,
      }}>
      <BreadCrumb
        content="競合施設登録"
        textRoutes={["ダッシュボード", "競合施設登録"]}
      />
      <Box className={styles["competitor-container"]}>
        <Typography
          variant="h6"
          className={styles["competitor-container--title"]}
        >
          競合施設を登録する
        </Typography>
        <Card
          className={styles["competitor--card"]}
          sx={{
            mt: 3,
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h6"
            className={styles["competitor--card--title"]}
          >
            施設を検索する
          </Typography>
          <Typography
            variant="h6"
            className={styles["competitor--card--subtitle"]}
            sx={{
              mt: 1,
            }}
          >
            ベンチマークの宿泊施設を登録してください
          </Typography>
          <Autocomplete
            freeSolo
            disablePortal
            disableClearable={true}
            className={styles["input-container"]}
            options={hotels}
            style={{
              p: 0,
            }}
            value={value}
            onChange={(event, newValue) => {
              if (typeof newValue === "string") {
                setValue({
                  name: newValue,
                });
              } else if (newValue && newValue?.inputValue) {
                setValue({
                  name: newValue?.inputValue,
                });
              }
              setValue(newValue);
            }}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);
              return filtered;
            }}
            getOptionLabel={(option) => {
              if (typeof option === "string") {
                return option;
              }
              if (option?.inputValue) {
                return option?.inputValue;
              }
              return option?.name;
            }}
            onInputChange={(event, newInputValue) => {
              inputHotelOnChange(newInputValue);
            }}
            renderOption={(props, option) => (
              <Box component="li" {...props} sx={{ p: "10px 24px" }}>
                <Box
                  sx={{
                    width: "100%",
                    color: "#2E4E76",
                    fontWeight: "bold",
                    display: "flex",
                  }}
                >
                  <Typography
                    component={"span"}
                    sx={{
                      textAlign: "left",
                      minWidth: "70%",
                      fontWeight: "bold",
                      fontSize: "16px",
                    }}
                  >
                    {option.name}
                  </Typography>
                  <Typography
                    component={"span"}
                    sx={{
                      textAlign: "right",
                      fontWeight: "bold",
                      fontSize: "16px",
                    }}
                  >
                    {option.address}
                  </Typography>
                </Box>
              </Box>
            )}
            popupIcon={
              <Box className={styles["competitor--card--icon"]}>
                <SearchIcon />
              </Box>
            }
            renderInput={(params) => (
              <Box sx={{ width: "100%" }}>
                <TextField
                  sx={{
                    width: "100%",
                    "& .MuiOutlinedInput-root": {
                      p: 0,
                    },
                    "& .MuiAutocomplete-endAdornment": {
                      position: "relative",
                      right: "3px",
                    },
                    "& .MuiAutocomplete-popupIndicator": {
                      transform: "none !important",
                    },
                  }}
                  {...params}
                  className={styles["competitor--card--input"]}
                  placeholder="宿泊施設名を入力してください"
                />
              </Box>
            )}
          />
          {/* <Box className={styles["input-container"]}>
            <Input
              className={styles["competitor--card--input"]}
              placeholder="宿泊施設名を入力してください"
            />
            <Box className={styles["competitor--card--icon"]}>
              <SearchIcon />
            </Box>
          </Box> */}
        </Card>
        {value !== null && value !== undefined && (
          <Card
            className={styles["competitor--card"]}
            sx={{
              mt: "60px",
            }}
          >
            <Typography
              variant="h5"
              className={styles["competitor--card--title"]}
              sx={{
                fontSize: "24px !important",
              }}
            >
              {value?.name}
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mt: 2,
                fontWeight: "bold",
              }}
            >
              <Typography>
                <Typography
                  component="span"
                  sx={{
                    color: "#3F7EAE",
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  住所
                </Typography>
                <Typography
                  component="span"
                  sx={{
                    ml: "20px",
                    color: "#97CBE2",
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  {value?.postal_code} {value?.address}
                </Typography>
              </Typography>
              <Typography>
                <Typography
                  component="span"
                  sx={{
                    color: "#3F7EAE",
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  総部屋数
                </Typography>
                <Typography
                  component="span"
                  sx={{
                    ml: "20px",
                    color: "#2E4E76",
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  {value?.number_of_room}
                </Typography>
              </Typography>
              {showRegister && (
                <Box>
                  <Button
                    sx={{
                      background: "#7764E4",
                      fontSize: 16,
                      width: "100%",
                      borderRadius: "5px",
                      color: "#fff",
                      p: "12px 28px",
                      fontWeight: "bold",
                      "&:hover": {
                        background: "#5840db",
                      },
                    }}
                    onClick={registerCompetition}
                  >
                    競合施設として登録する
                  </Button>
                </Box>
              )}
            </Box>
          </Card>
        )}
        <Typography
          variant="h6"
          className={styles["competitor-container--title"]}
          sx={{
            mt: "60px",
          }}
        >
          登録済みの競合施設
        </Typography>
        <Card
          className={styles["competitor--card"]}
          sx={{
            mt: "40px",
            px: "0 !important",
            py: "28px !important",
          }}
        >
          <Table className={styles["competitor--table"]}>
            <TableBody>
              {competitionList.map((competition, index) => (
                <TableRow key={index}>
                  <TableCell>{competition?.hotel_name}</TableCell>
                  <TableCell align="center">
                    {competition?.postal_code}
                    {competition?.address}
                  </TableCell>
                  <TableCell align="center">
                    総部屋数：{competition?.number_of_room}
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{ cursor: "pointer" }}
                    onClick={() => showPopupDelete(competition?.id)}
                  >
                    登録から外す
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {paginate?.total_pages > 0 && (
            <PaginationPage
              count={paginate.total_pages}
              onChange={(event, value) => setCurrentPage(value)}
              variant="outlined"
              shape="rounded"
              page={currentPage}
            />
          )}
        </Card>
      </Box>
      <Popover
        id="confirmDelete"
        open={Boolean(confirmDelete)}
        anchorEl={confirmDelete}
        onClose={handleCloseConfirmDelete}
        classes={{ paper: styles["confirm-delete"] }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: 500,
            color: "#7764E4",
            cursor: "pointer",
          }}
          onClick={handleCloseConfirmDelete}
        >
          キャンセル
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: 500,
            ml: "20px",
            color: "#CE858F",
            cursor: "pointer",
          }}
          onClick={confirmDeleteCompetition}
        >
          削除する
        </Typography>
      </Popover>
    </Box>
  );
};

export default CompetitorPage;
