import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import HTTP from '../http_common';

export const getHotels = createAsyncThunk(
  'getHotelsList/getHotels',
  async (dataQuery) => {
    const response = await HTTP.get(`/api/hotels/`, {
      params: dataQuery,
    });
    return response;
  });

const apiSlice = createSlice({
  name: 'list-noti',
  initialState: {
    data: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getHotels.pending, (state) => {
        state.loading = true;
      })
      .addCase(getHotels.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getHotels.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;

      });
  },
});

export default apiSlice.reducer;
