/* eslint-disable react-hooks/exhaustive-deps */
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import styles from "./styles.module.scss";
import { TextField, MenuItem } from "@mui/material";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import {
  getDetailApplication,
  updateApplication
} from "../../../../features/application/applicationSlice";
import { Formik } from "formik";
import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { generateMaxTextValidation, TEXT_REQUIRED } from "../../../../constants/validation";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { regex } from "../../../../helper";

const datas = [
  { id: 0, name: "5" },
  { id: 1, name: "10" },
  { id: 2, name: "15" },
  { id: 3, name: "20" },
];

export default function ReceiveSuggestions() {
  const [data, setData] = useState([]);
  let { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (id) {
      const fetchApplicationData = async () => {
        const res = await dispatch(getDetailApplication(id));
        const applicationData = res.payload;
        setData(applicationData);
      };

      fetchApplicationData();
    }
  }, [id]);

  const sumbitCancel = () => {
    return history.push(`/admin/application`);
  };

  const formikConfig = {
    initialValues: {
      name: data?.name || "",
      postal_code: data?.postal_code || "",
      address: data?.address || "",
      number_of_room: data?.number_of_room || "5",
      is_active: 1,
    },
    onSubmit: async (values, { setSubmitting, setErrors }) => {
      dispatch(
        updateApplication({
          ...values,
          id
        })
      ).then((res) => {
        setSubmitting(false);
        if (res.payload?.error) {
          setErrors(res.payload?.data);
          toast.error("システムエラー");
        } else {
          return history.push(`/admin/application`);
        }
      });
    },
    validationSchema: () => {
      return Yup.object({
        name: Yup.string()
          .max(255, generateMaxTextValidation())
          .test(
            "is-not-blank",
            TEXT_REQUIRED,
            (value) => value && value.trim().length > 0
          ),
        address: Yup.string()
          .max(255, generateMaxTextValidation())
          .test(
            "is-not-blank",
            TEXT_REQUIRED,
            (value) => value && value.trim().length > 0
          ),
        postal_code: Yup.string()
          .required(TEXT_REQUIRED)
          .matches(
            regex.postal_code,
            "郵便番号を「XXXXXXX」または「XXX-XXXX」の形式で入力してください。"
          ),
      });
    },
  };

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        overflow: "auto",
        backgroundColor: "#F1F3F9",
      }}>
      <Container className={styles["home-center"]} maxWidth="xl">
        <Box className={styles["box-header"]}>
          <Typography className={styles["text-header"]}>申請情報</Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid className={styles["text-left"]} item xs={2}>
            名前
          </Grid>
          <Grid item xs={10}>
            <Typography className={styles["text-header-home"]}>
              {data.last_name + data.first_name}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid className={styles["text-left"]} item xs={2}>
            メールアドレス
          </Grid>
          <Grid item xs={10}>
            <Typography className={styles["text-header-home"]}>
              {data.email_registration}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid className={styles["text-left"]} item xs={2}>
            施設名
          </Grid>
          <Grid item xs={10}>
            <Typography className={styles["text-header-home"]}>{data.name}</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid className={styles["text-left"]} item xs={2}>
            掲載しているOTA
          </Grid>
          <Grid item xs={10}>
            <Typography sx={{ color: "#7764E4 !important" }} className={styles["text-header-home"]}>{data.in_site_id}</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid className={styles["text-left"]} item xs={2}>
            登録状況
          </Grid>
          <Grid item xs={10}>
            <Typography className={styles["text-header-home"]}>{data?.is_active ? "登録済み" : "未確認"}</Typography>
          </Grid>
        </Grid>
      </Container>
      <Formik
        enableReinitialize={true}
        validateOnChange={false}
        initialValues={formikConfig.initialValues}
        onSubmit={formikConfig.onSubmit}
        validationSchema={formikConfig.validationSchema}>
        {({
          handleChange,
          handleBlur,
          values,
          errors,
          handleSubmit,
        }) => (
          <Container className={styles["home-center"]} maxWidth="xl">
            <Box className={styles["box-header"]}>
              <Typography className={styles["text-header"]}>
                施設を登録する
              </Typography>
            </Box>
            <Grid container spacing={2}>
              <Grid className={styles["text-left"]} item xs={2}>
                施設名
              </Grid>
              <Grid item xs={10}>
                <TextField
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  className={styles["text-field"]}
                  id="filled-basic"
                  onBlur={handleBlur("name")}
                  helperText={errors.name}
                  error={!!errors.name}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid className={styles["text-left"]} item xs={2}>
                郵便番号
              </Grid>
              <Grid item xs={10}>
                <TextField
                  name="postal_code"
                  value={values.postal_code}
                  onChange={handleChange}
                  className={styles["text-field"]}
                  id="filled-basic"
                  onBlur={handleBlur("postal_code")}
                  helperText={errors.postal_code}
                  error={!!errors.postal_code}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid className={styles["text-left"]} item xs={2}>
                住所
              </Grid>
              <Grid item xs={10}>
                <TextField
                  name="address"
                  value={values.address}
                  onChange={handleChange}
                  className={styles["text-field"]}
                  id="filled-basic"
                  onBlur={handleBlur("address")}
                  helperText={errors.address}
                  error={!!errors.address}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid className={styles["text-left"]} item xs={2}>
                部屋数
              </Grid>
              <Grid className={styles["text-number"]} item xs={10}>
                <Select
                  className={styles["text-select"]}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  name="number_of_room"
                  onChange={handleChange}
                  value={values.number_of_room ?? ""}
                >
                  {datas?.map((item) => {
                    return (
                      <MenuItem
                        key={item.id}
                        className={styles["tag"]}
                        value={item.name}
                      >
                        {item.name}
                      </MenuItem>
                    );
                  })}
                </Select>
                部屋
              </Grid>
            </Grid>
            <Box className={styles["box-button"]}>
              <Box
                onClick={sumbitCancel}
                variant="h7"
                className={styles["text-cancel"]}>
                戻る
              </Box>
              <Box onClick={handleSubmit} variant="h7" className={styles["text-save"]}>
                承認する
              </Box>
            </Box>
          </Container>
        )}
      </Formik>
    </Box>
  );
}
