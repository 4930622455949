import React, { useEffect, useState } from "react";
import { Route } from "react-router-dom";
import { useAuth } from "./ProvideAuth";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import { ThemeProvider } from "@mui/material/styles";
import Header from "./components/Header";
import Sidebar from "./components/Sidebar";
import { ADMIN_ROLE, DEFAULT_PAGE_TITLE, customTheme } from "./constants";
import {
  Redirect,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch } from "react-redux";
import { getAuth } from "./features/auth/authSlice";
import { routers } from "./routes";
import { matchPath } from "./constants/match";
import { Helmet } from "react-helmet-async";

const PrivateRoute = ({ component: Component, role, layout, ...rest }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(true);
  const { user } = useAuth();
  const location = useLocation();
  const accessToken = localStorage.getItem("access_token");
  const is_superuser = localStorage.getItem("is_superuser");

  useEffect(() => {
    dispatch(getAuth());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleDrawer = () => {
    setOpen(!open);
  };
  const currentRouterRoles = routers.find((item) =>
    matchPath(item.path, location.pathname)
  ).role;

  if (!user && !accessToken) {
    return <Redirect to={`/login?continue=${location.pathname}`} />;
  }
  const userRole = Number(is_superuser) === ADMIN_ROLE ? "admin" : "user";
  if (!currentRouterRoles?.includes(userRole)) {
    return <Redirect to={userRole === "admin" ? "/admin/users" : "/home"} />;
  }

  return (
    <ThemeProvider theme={customTheme}>
      <Helmet>
        <title>{rest.title || DEFAULT_PAGE_TITLE}</title>
      </Helmet>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <Box>
          <Sidebar open={open} toggleDrawer={toggleDrawer} />
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
            maxHeight: "100vh",
            minHeight: "100vh",
          }}
        >
          <Header open={open} toggleDrawer={toggleDrawer} />
          <Box
            sx={{
              flexGrow: 1,
              overflow: "auto",
              backgroundColor: "#F1F3F9",
              p: layout ? 0 : 2,
            }}
          >
            <Route {...rest} render={(props) => <Component {...props} />} />
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default PrivateRoute;
