/* eslint-disable react-hooks/exhaustive-deps */
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import styles from "./styles.module.scss";
import { TextField, MenuItem, FormControlLabel, Checkbox } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { getDetailUser } from "../../../../../features/apiGetDetailUser";
import { fetchEditlUser } from "../../../../../features/apiEditUser";
import { fetchDeleteUser } from "../../../../../features/apiDeleteUser";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Container from "@mui/material/Container";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import { getHotels } from "../../../../../features/apiGetHotel";
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import {
  dataAccount,
  dataSelect,
  dataRate,
} from "../../../../../constants/index";
import FormGroup from "@mui/material/FormGroup";
import Popover from '@mui/material/Popover';

const INIT_DATA = {
  id: "",
  email: "",
  first_name: "",
  last_name: "",
  accommodation_name: "",
  rate: "5",
  hotel: "",
  account_category: "1",
  is_accommodation_facility: 1,
  options: [],
};

const INIT_ERROR = {
  first_name: "",
  last_name: "",
};

const SELECT_OPTION_DATETIME = 1;
const NOTSELECT_OPTION_DATETIME = 0;

const filter = createFilterOptions();

export default function UserDetail() {
  const [values, setValues] = useState(INIT_DATA);
  const [value, setValue] = React.useState(null);
  const [errors, setErrors] = useState(INIT_ERROR);
  const [hotels, setHotels] = useState([]);
  const history = useHistory();
  let { id } = useParams();
  const dispatch = useDispatch();
  const handleChange = (e) => {
    setValues((values) => ({
      ...values,
      [e.target.name]: e.target.value,
    }));
    setErrors({
      ...errors,
      first_name: "",
      last_name: "",
    });
  };

  const handleCheckboxChange = (name) => {
    const newChecked = !Boolean(values.options[name]);
    if (name === "all_is_enabled") {
      setValues((prevValues) => ({
        ...prevValues,
        options: {
          all_is_enabled: newChecked,
          plan_is_enabled: newChecked,
          ad_is_enabled: newChecked,
          sale_is_enabled: newChecked,
          coupon_is_enabled: newChecked,
          report_is_enabled: newChecked,
        },
      }));
    } else if (!newChecked) {
      setValues((prevValues) => ({
        ...prevValues,
        options: {
          ...prevValues.options,
          all_is_enabled: false,
          [name]: newChecked,
        },
      }));
    } else {
      let isSelectedAll = true;
      for (const key in values.options) {
        if (!values.options[key] && key !== "all_is_enabled" && key !== name) {
          isSelectedAll = false;
          break;
        }
      }
      setValues((prevValues) => ({
        ...prevValues,
        options: {
          ...prevValues.options,
          all_is_enabled: isSelectedAll,
          [name]: newChecked,
        },
      }));
    }
  };

  const sumbitEditUser = async () => {
    const updatedValues = { ...values, hotel_id: value?.id ?? null };
    const res = await dispatch(fetchEditlUser(updatedValues));
    if (res.payload.message) {
      return history.push(`/admin/users`);
    } else {
      setErrors({
        ...errors,
        first_name: res.payload?.first_name,
        last_name: res.payload?.last_name,
      });
    }
  };

  const sumbitDeleteUser = async () => {
    const res = await dispatch(fetchDeleteUser(id));
    if (res.payload.message) {
      return history.push(`/admin/users`);
    } else {
      setErrors({
        ...errors,
        first_name: res.payload?.first_name,
        last_name: res.payload?.last_name,
      });
    }
  };

  const fetchData = async () => {
    const res = await dispatch(getDetailUser(id));
    if (res.payload) {
      setValues({
        id: res.payload.id,
        email: res.payload.email,
        first_name: res.payload.first_name,
        last_name: res.payload.last_name,
        accommodation_name: res.payload.accommodation_name,
        rate: res.payload.rate || 5,
        hotel: res.payload.hotel,
        account_category: res.payload.account_category || 1,
        is_accommodation_facility: res.payload.is_accommodation_facility
          ? 1
          : 0,
        options: res.payload?.options || [],
      });
      return res.payload;
    }
  };

  const fetchDataFromAPI = async (dataQuery) => {
    try {
      const res = await dispatch(getHotels(dataQuery));
      return res.payload?.results?.data;
    } catch (error) {
      return [];
    }
  };

  useEffect(() => {
    fetchData().then((dataUser) => {
      fetchDataFromAPI().then((data) => {
        const checkHotelUser = data.some(
          (item) => item.id === dataUser?.hotel?.id
        );
        if (!checkHotelUser) {
          data.push(dataUser?.hotel);
        }
        setHotels(data);
        setValue(dataUser?.hotel || []);
      });
    });
  }, []);

  const inputHotelOnChange = async (query) => {
    const dataQuery = { search_param: query };
    const data = await fetchDataFromAPI(dataQuery);
    setHotels(data);
  };

  const sumbitCancel = () => {
    window.location.href = "/admin/users";
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        overflow: "auto",
        backgroundColor: "#F1F3F9",
      }}
    >
      <Container className={styles["home-center"]} maxWidth="xl">
        <Box className={styles["box-header"]}>
          <PersonOutlineIcon className={styles["text-icon"]} />
          <Typography className={styles["text-header-home"]}>
            アカウント一覧
          </Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid className={styles["text-left"]} item xs={3}>
            姓
          </Grid>
          <Grid item xs={9}>
            <TextField
              name="first_name"
              value={values.first_name}
              onChange={handleChange}
              className={styles["text-field"]}
              id="filled-basic"
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid className={styles["text-left"]} item xs={3}>
            名
          </Grid>
          <Grid item xs={9}>
            <TextField
              name="last_name"
              value={values.last_name}
              onChange={handleChange}
              className={styles["text-field"]}
              id="filled-basic"
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid className={styles["text-left"]} item xs={3}>
            メールアドレス
          </Grid>
          <Grid item xs={9}>
            <TextField
              disabled={true}
              value={values.email}
              className={styles["text-field"]}
              id="filled-basic"
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid className={styles["text-left"]} item xs={3}>
            施設名
          </Grid>
          <Grid item xs={9}>
            <Autocomplete
              freeSolo
              componentsProps={{
                paper: {
                  sx: { width: "650px", boxShadow: "0px 0px 8px #00000052" },
                },
              }}
              className={styles["text-field"]}
              id="outlined-basic"
              name="name"
              label="宿泊施設名"
              variant="outlined"
              value={value}
              onChange={(event, newValue) => {
                if (typeof newValue === "string") {
                  setValue({
                    name: newValue,
                  });
                } else if (newValue && newValue?.inputValue) {
                  setValue({
                    name: newValue?.inputValue,
                  });
                } else {
                  setValue(newValue);
                }
                setValue(newValue);
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
                return filtered;
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              options={hotels}
              getOptionLabel={(option) => {
                if (typeof option === "string") {
                  return option;
                }
                if (option?.inputValue) {
                  return option?.inputValue;
                }
                return option?.name || "";
              }}
              onInputChange={(event, newInputValue) => {
                inputHotelOnChange(newInputValue);
              }}
              renderOption={(props, option) => (
                <li {...props} style={{ display: "flex" }} key={option?.id}>
                  <div style={{ width: "50%", color: "#2E4E76" }}>
                    {option?.name}
                  </div>
                  <div
                    style={{ width: "50%", color: "#2E4E76", marginLeft: 24 }}
                  >
                    {option?.address}
                  </div>
                </li>
              )}
              sx={{ width: "30%" }}
              renderInput={(params) => (
                <TextField
                  id="outlined-basic"
                  name="name"
                  label="宿泊施設名"
                  variant="outlined"
                  xs={{ fontWeight: "bold" }}
                  {...params}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid className={styles["text-left"]} item xs={3}>
            アカウント分類
          </Grid>
          <Grid item xs={9}>
            <Select
              className={styles["text-select"]}
              displayEmpty
              name="account_category"
              onChange={handleChange}
              inputProps={{ "aria-label": "Without label" }}
              value={values.account_category}
            >
              {dataAccount?.map((item, index) => {
                return (
                  <MenuItem
                    key={index}
                    className={styles["tag"]}
                    value={index + 1}
                  >
                    {item}
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid className={styles["text-left"]} item xs={3}>
            機能分類
          </Grid>
          <Grid item xs={9}>
            <FormGroup className={styles["box-group"]}>
              {dataSelect?.map((item, index) => {
                return (
                  <FormControlLabel
                    key={index}
                    control={
                      <Checkbox
                        onChange={() => handleCheckboxChange(item.name)}
                        checked={values?.options?.[item.name] || false}
                      />
                    }
                    label={
                      <Typography variant="h7" className={styles["text-group"]}>
                        {item.title}
                      </Typography>
                    }
                  />
                );
              })}
            </FormGroup>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid className={styles["text-left"]} item xs={3}>
            発行可能回数
          </Grid>
          <Grid item xs={9}>
            <Select
              className={styles["text-select"]}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              value={values.rate ?? ""}
              name="rate"
              onChange={handleChange}
            >
              {dataRate?.map((item, index) => {
                return (
                  <MenuItem key={index} className={styles["tag"]} value={item}>
                    {item}
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid className={styles["text-left"]} item xs={3}>
            認証
          </Grid>
          <Grid item xs={9}>
            <FormGroup className={styles["box-group"]}>
              <FormControlLabel
                onChange={() =>
                  setValues({
                    ...values,
                    is_accommodation_facility:
                      values.is_accommodation_facility !==
                      NOTSELECT_OPTION_DATETIME
                        ? NOTSELECT_OPTION_DATETIME
                        : SELECT_OPTION_DATETIME,
                  })
                }
                checked={
                  values.is_accommodation_facility === SELECT_OPTION_DATETIME
                }
                control={<Checkbox />}
                label={
                  <Typography variant="h7" className={styles["text-group"]}>
                    宿泊施設であること確認した
                  </Typography>
                }
              />
            </FormGroup>
          </Grid>
        </Grid>
        <Box className={styles["box-button"]}>
          <Box
            onClick={sumbitCancel}
            variant="h7"
            className={styles["text-cancel"]}
          >
            戻る
          </Box>
          <Box
            onClick={sumbitEditUser}
            variant="h7"
            className={styles["text-save"]}
          >
            保存する
          </Box>
          <Box
            onClick={handleClick}
            variant="h7"
            className={styles["text-delete"]}
          >
            削除する
          </Box>
          <Popover
            className={styles["box-delete"]}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
          >
            <Box sx={{ display: "flex" }}>
              <Typography
                onClick={handleClose}
                className={styles["text-off"]}
                sx={{ p: 2.5 }}
              >
                キャンセル
              </Typography>
              <Typography
                onClick={sumbitDeleteUser}
                className={styles["delete"]}
                sx={{ p: 2.5 }}
              >
                削除する
              </Typography>
            </Box>
          </Popover>
        </Box>
      </Container>
    </Box>
  );
}
