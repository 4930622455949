import {
  Box,
  Card,
  CardContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Autocomplete,
} from "@mui/material";
import { autocompleteClasses } from "@mui/material/Autocomplete";
import BreadCrumb from "../../../../../components/breadCrumb";
import styles from "../../styles.module.scss";
import GroupItemImg from "../../../../../assets/images/group_item_list.png";
import TrendingFlatRoundedIcon from "@mui/icons-material/TrendingFlatRounded";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import SearchIcon from "@mui/icons-material/Search";
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import { useLocation } from "react-router-dom";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import { CSV_HEADER_CONFIG } from "../../../../../constants";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import React, { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import CircularProgress from "@mui/material/CircularProgress";
import Modal from "@mui/material/Modal";
import { useDispatch } from "react-redux";
import { importPlan } from "../../../../../features/plans/plansSlice";

const PlanGroupImportItems = () => {
  const location = useLocation();
  const header_successes = location?.state?.header_successes;
  const header_errors = location?.state?.header_errors;
  const header_maps = location?.state?.header_maps;
  const file = location?.state?.file;
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const headerMapState = ["", ...CSV_HEADER_CONFIG];
  const [csvItems, setCsvItems] = useState([]);

  useEffect(() => {
    const convertedItems = [];
    header_successes?.forEach((item, index) => {
      convertedItems.push({
        key: item,
        custom: header_maps[index],
      });
    });
    header_errors?.forEach((item) => {
      convertedItems.push({
        key: item,
        custom: "",
      });
    });

    setCsvItems(convertedItems);
  }, [header_successes, header_errors, header_maps]);

  const isValidCsvHeaders = () => {
    const selectedItems = csvItems.filter((item) => !!item?.custom);
    if (selectedItems?.length !== CSV_HEADER_CONFIG.length) {
      toast.error("CSVのヘッダーを確認してください");
      return false;
    }
    const formattedSelectedItems = selectedItems.map((item) => item?.custom?.trim());
    const headerErrors = formattedSelectedItems.filter(
      (item) => !CSV_HEADER_CONFIG.includes(item)
    )
    if (headerErrors.length > 0) {
      toast.error(
        `${headerErrors.join("、 ")} というフィールドが見つかりません。`
      );
      return false;
    }
    return true;
  };

  const nextAction = async () => {
    if (isValidCsvHeaders()) {
      const headerData = csvItems;
      setOpen(true);
      const res = await dispatch(
        importPlan({ file: file, headers: headerData })
      );
      if (res?.payload?.status === 201) {
        const ids = res?.payload?.ids;
        const header = res?.payload?.header;
        window.history.pushState(
          { header: header, ids: ids },
          "",
          "/results/plan/import/group"
        );
        window.location.href = "/results/plan/import/group";
      } else {
        toast.error(res?.payload?.data?.error);
      }
      setOpen(false);
    }
  };

  function updateHeaderMapState(index, newValue) {
    const newHeaderMapState = [...csvItems];
    newHeaderMapState[index].custom = newValue;
    setCsvItems(newHeaderMapState);
  }

  function isSelectedOption(option) {
    return csvItems.some((row) => row.custom === option);
  }

  const csvHeaderRows = useMemo(() => {
    return (
      <TableBody>
        {csvItems.map((row, index) => (
          <TableRow
            key={index}
            sx={{
              "&:last-child td, &:last-child th": { border: 0 },
            }}
          >
            <TableCell scope="row">{row.key}</TableCell>
            <TableCell align="right">
              <TrendingFlatRoundedIcon />
            </TableCell>
            <TableCell>
              <Autocomplete
                options={headerMapState}
                size="small"
                classes={styles["plan-group-table--input"]}
                variant="outlined"
                onChange={(_, value) => updateHeaderMapState(index, value)}
                value={row.custom}
                renderInput={(params) => <TextField {...params} />}
                getOptionDisabled={(option) => isSelectedOption(option)}
                popupIcon={<SearchIcon />}
                sx={{
                  [`& .${autocompleteClasses.popupIndicator}`]: {
                    transform: "none"
                  }
                }}
              />
            </TableCell>
            <TableCell>
              {row.custom ? (
                <CheckCircleOutlineRoundedIcon />
              ) : (
                <NotInterestedIcon sx={{ color: "red" }} />
              )}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [csvItems]);

  if (!header_successes?.length && !header_errors?.length) {
    return <Redirect to="/results/plan/import" />;
  }

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        height: "100%",
        overflow: "auto",
        p: 4,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <BreadCrumb
        content="実績登録・確認"
        textRoutes={["ダッシュボード", "実績登録・確認", "プラン販売実績"]}
      />
      <Card
        sx={{
          flexGrow: 1,
          width: "100%",
          mt: 3,
          borderRadius: "10px",
          boxShadow: "0px 3px 6px #2C28281C",
          p: 2,
        }}
      >
        <CardContent
          sx={{ p: 0, height: "100%" }}
          className={`${styles["plan-group-import--content"]} ${styles["plan-group-header"]}`}
        >
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{ height: "100%" }}
          >
            <Grid
              item
              xs={6}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <img src={GroupItemImg} alt="" />
              <Typography
                className={styles["plan-group-title"]}
                sx={{ mt: 2.25 }}
              >
                項目を一致させる
              </Typography>
              <Typography
                className={styles["plan-group-header--text"]}
                sx={{ mt: 2.25, textAlign: "center" }}
              >
                インポートしたCSVの項目と、
                <br />
                Levittの項目を一致させてください。
                <br />
                インポートしない項目は空欄にしてください。
                <br />
                空欄の項目はインポートされません。
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                height: "100%",
                overflow: "hidden",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography className={styles["plan-group-title"]}>
                項目の紐付け
              </Typography>
              <TableContainer sx={{ flexGrow: 1, overflow: "auto", mt: 2 }}>
                <Table
                  aria-label="simple table"
                  className={styles["plan-group-table"]}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>CSVの項目名</TableCell>
                      <TableCell width={"20px"} align="right"></TableCell>
                      <TableCell align="left">Levittの項目名</TableCell>
                      <TableCell align="left" width={"20px"}></TableCell>
                    </TableRow>
                  </TableHead>
                  {csvHeaderRows}
                </Table>
                <Box
                  sx={{
                    textAlign: "right",
                    paddingRight: "15px",
                    paddingTop: "15px",
                  }}
                >
                  <ArrowCircleRightOutlinedIcon
                    onClick={nextAction}
                    sx={{ cursor: "pointer", fontSize: "40px" }}
                  />
                </Box>
              </TableContainer>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          <Box className={styles["box-modal"]}>
            <Typography
              sx={{
                display: "flex",
                marginTop: "50px",
                justifyContent: "center",
              }}
              id="modal-modal-title"
              variant="h6"
              component="h2"
            >
              <CircularProgress />
            </Typography>
            <Typography
              className={styles["title-modal"]}
              id="modal-modal-description"
              sx={{ mt: 2 }}
            >
              Loading
            </Typography>
          </Box>
        </>
      </Modal>
    </Box>
  );
};

export default PlanGroupImportItems;
