import HTTP from "../../http_common";

import {
  Box,
  Button,
  Card,
  TextField,
  Typography,
} from "@mui/material";
import BreadCrumb from "../../components/breadCrumb";
import styles from "./styles.module.scss";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { useHistory } from 'react-router-dom';
import { useSelector } from "react-redux";
import { Formik } from "formik";
import { toast } from 'react-toastify';
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const ContactPage = () => {
  const authData = useSelector((state) => state.auth);
  const history = useHistory();

  const formikConfig = {
    initialValues: {
      id: authData.currentUser?.id || '',
      email: authData.currentUser?.email || '',
      first_name: authData.currentUser?.first_name || '',
      last_name: authData.currentUser?.last_name || '',
      hotel_name: authData.currentUser?.hotel_name || '',
    },
    
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const res = await HTTP.post(`/api/contact/`, {
          phone: values.phone,
          contact: values.contact,
        });

        if (res.message === 'お問い合わせありがとうございます。') {
          toast.success(res.message);
          return history.push('/contact/complete');
        } else {
          toast.error('お問い合わせの送信に失敗しました。');
        }
      } catch (error) {
        const errorMessage = error.response?.data?.message || '通信エラーが発生しました。';
        toast.error(errorMessage);
      } finally {
        setSubmitting(false);
      }
    },
  };

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        overflow: "auto",
        p: 4,
      }}
    >
      <BreadCrumb content="お問い合わせ" textRoutes={["ダッシュボード", "お問い合わせ"]} />
      <Box className={styles["contact"]}>
        <Card className={styles["contact-card"]} sx={{ mt: 3 }}>
          <Box className={styles["contact-card--header"]}>
            <MailOutlineIcon sx={{ fontSize: "30px", mr: 2 }} />
            <Typography className={styles["contact-card--title"]}>
              お問い合わせ
            </Typography>
          </Box>
          <Box className={styles["contact-card--body"]}>
            <Box>
              <Typography className={styles["contact-card--text"]}>
                お送りいただいた内容を担当が確認次第ご連絡させていただきます。
                <br />
                使用方法などに関するご質問やお問い合わせは
                <Link
                  to="/faq"
                  className={styles["contact-card--text"]}
                >
                  Q&Aサイト
                </Link>
                をご利用ください。
              </Typography>
            </Box>
            <Formik
              enableReinitialize={true}
              validateOnChange={false}
              initialValues={formikConfig.initialValues}
              onSubmit={formikConfig.onSubmit}
              validationSchema={formikConfig.validationSchema}
            >
              {({ handleChange, handleBlur, values, errors, handleSubmit }) => (
                <Box>
                  <Typography
                    className={styles["contact-card--title"]}
                  >
                    施設名
                  </Typography>
                  <Typography
                    className={styles["contact-card--title"]}
                    sx={{ mt: "12px" }}
                  >
                    {values.hotel_name}
                  </Typography>
                  <Typography
                    className={styles["contact-card--title"]}
                    sx={{ mt: "36px" }}
                  >
                    名前
                  </Typography>
                  <Typography
                    className={styles["contact-card--title"]}
                    sx={{ mt: "12px" }}
                  >
                    {values.first_name} {values.last_name}
                  </Typography>
                  <Typography
                    className={styles["contact-card--title"]}
                    sx={{ mt: "36px" }}
                  >
                    メールアドレス
                  </Typography>
                  <Typography
                    className={styles["contact-card--title"]}
                    sx={{ mt: "12px" }}
                  >
                    {values.email}
                  </Typography>
                  <Typography
                    className={styles["contact-card--title"]}
                    sx={{ mt: "36px" }}
                  >
                    電話番号
                  </Typography>
                  <TextField
                    variant="outlined"
                    fullWidth
                    sx={{ mt: "12px" }}
                    className={styles["contact-card--input"]}
                    placeholder="0123456789"
                    name="phone"
                    onChange={handleChange}
                  />
                  <Typography
                    className={styles["contact-card--title"]}
                    sx={{ mt: "36px" }}
                  >
                    お問い合わせ内容
                  </Typography>
                  <TextField
                    multiline
                    rows={6}
                    variant="outlined"
                    fullWidth
                    sx={{ mt: "12px" }}
                    className={styles["contact-card--input"]}
                    placeholder="お問い合わせ内容を入力してください。"
                    name="contact"
                    onChange={handleChange}
                  />
                  <Button
                    variant="text"
                    className={styles["contact-card--button"]}
                    sx={{ mt: 3 }}
                    onClick={handleSubmit}
                  >
                    上記の内容で送信する
                  </Button>
                </Box>
              )}
            </Formik>
          </Box>
        </Card>
      </Box>
    </Box>
  );
};

export default ContactPage;
