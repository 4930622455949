import { styled } from '@mui/system';
import {
  Box,
  Button,
  Card,
  InputAdornment,
  Menu,
  MenuItem,
  Popover,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import { forwardRef, useEffect, useMemo, useRef, useState } from "react";
import styles from "./style.module.scss";
import CreateNewFolderOutlinedIcon from "@mui/icons-material/CreateNewFolderOutlined";
import SearchIcon from "@mui/icons-material/Search";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import EnhancedTable from "../EnhancedTable";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import PropTypes from "prop-types";
import CreateRoundedIcon from "@mui/icons-material/CreateRounded";
import {
  createGroupPlan,
  editGroupPlan,
  getPlanGroups,
} from "../../features/plan/planGroupSlice";
import { useDispatch, useSelector } from "react-redux";
import { CSVLink } from "react-csv";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
// import GroupPlanPDFComponent from '../pdf/planGroup';
import ja from "date-fns/locale/ja";
import { TEXT_REQUIRED } from "../../constants/validation";
import { useHistory } from "react-router-dom";
import HTTP from "../../http_common";
import { changePlansGroup } from "../../features/plans/plansSlice";
import { toast } from "react-toastify";
import DownloadPdfLink from "../pdf/DownloadPdfLink";
import moment from "moment";
import { PLAN_TIME_FORMAT } from "../../constants";
import { LoadingComponent } from "../LoadingComponent";

registerLocale("ja", ja);

const INIT_ERROR = {
  group_name: null,
};

const CreateGroupPopover = styled(Popover)({
  "& .MuiPaper-root": {
    borderRadius: "8px",
    minWidth: "246px",
    minHeight: "89px",
  },
});

const SearchPopover = styled(Popover)({
  "& .MuiPaper-root": {
    borderRadius: "4px",
    minWidth: "398px",
    minHeight: "60px",
  },
  "& .MuiFormControl-root": {
    margin: "0px !important",
    width: "100%",
  },
  "& .MuiInputBase-root": {
    paddingLeft: "0px",
  },
});

const PlanGroupContainer = forwardRef((props, ref) => {
  const {
    headCells,
    rows,
    TableFooter,
    isPlans,
    onUpdatedPlans,
    onEventTriggerSearch,
    pdfHeaders,
    totalPdfDetail,
    groupID = null,
    children,
    csvFileName,
  } = props;
  const [startDate, setStartDate] = useState(new Date());
  const [selectedRows, setSelectedRows] = useState([]);
  const [anchorPopoverEl, setAnchorPopoverEl] = useState(null);
  const [anchorDeleteEl, setAnchorDeleteEl] = useState(null);
  const [anchorMenuEl, setAnchorMenuEl] = useState(null);
  const [anchorMovingGroup, setAnchorMovingGroup] = useState(null);
  const [anchorNewGroup, setAnchorNewGroup] = useState(null);
  const [anchorNewGroupInGroup, setAnchorNewGroupInGroup] = useState(null);
  const [newGroupName, setNewGroupName] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [searchGroup, setSearchGroup] = useState("");
  const [planGroups, setPlanGroups] = useState([]);
  const [nextGroupPage, setNextGroupPage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [editingGroup, setEditingGroup] = useState(null);
  const tableRef = useRef();
  const dispatch = useDispatch();
  const history = useHistory();
  const [errors, setErrors] = useState(INIT_ERROR);
  const [boxStates, setBoxStates] = useState(null);
  const currentChildrenPage = children?.props?.page;
  const loadingGroup = useSelector((state) => state.planGroup.loading);
  const loadingPlan = useSelector((state) => state.plans.loading);

  useEffect(() => {
    if (isPlans) {
      const delayDebounceFn = setTimeout(() => {
        getGroups();
      }, 500);
      return () => clearTimeout(delayDebounceFn);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPlans, searchGroup]);

  useEffect(() => {
    deselectAllRows();
  }, [currentChildrenPage]);

  const getGroups = () => {
    setIsLoading(true);
    dispatch(
      getPlanGroups({
        search_param: searchGroup,
      })
    ).then(({ payload }) => {
      setNextGroupPage(payload.next);
      setPlanGroups(payload.results.data);
      setIsLoading(false);
    });
  };

  const handleSearchGroupChange = (e) => {
    const { value } = e.target;
    setSearchGroup(value);
  };

  const handleClick = (event, box) => {
    setAnchorPopoverEl(event.currentTarget);
    setBoxStates(box);
  };

  const handleClose = () => {
    setAnchorPopoverEl(null);
  };

  const handleClickMenu = (event, box) => {
    setAnchorMenuEl(event.currentTarget);
    setBoxStates(box);
  };

  const handleClickScreenCSV = () => {
    return history.push(`/results/plan/import`);
  };

  const handleCloseMenu = () => {
    setAnchorMenuEl(null);
  };

  const handleClickDelete = (event) => {
    setAnchorDeleteEl(event.currentTarget);
  };

  const handleCloseDelete = () => {
    setAnchorDeleteEl(null);
  };

  const handleClickMovingGroup = (event) => {
    setAnchorMovingGroup(event.currentTarget);
  };

  const handleCloseMovingGroup = () => {
    setAnchorMovingGroup(null);
  };

  const handleClickNewGroup = (event) => {
    setAnchorNewGroup(event.currentTarget);
  };

  const handleCloseNewGroup = () => {
    setAnchorNewGroup(null);
    setEditingGroup(null);
    setNewGroupName("");
    setErrors(INIT_ERROR);
  };

  const handleEditGroupName = (event, group) => {
    setEditingGroup(group);
    setNewGroupName(group.name);
    setAnchorNewGroup(event.currentTarget);
  };

  //delete
  const handleAcceptDelete = async () => {
    if (props.handleDeleteRows) await props.handleDeleteRows(selectedRows);
    let showPreviousPage = false;
    if (selectedRows.length === rows.length) {
      showPreviousPage = true;
    }
    if (props.onEventTriggerDelete)
      await props.onEventTriggerDelete(showPreviousPage);
    setSelectedRows([]);
    deselectAllRows();
    setAnchorDeleteEl(null);
  };

  //group create
  const handleClickNewGroupInGroup = (event, box) => {
    setAnchorNewGroupInGroup(event.currentTarget);
    setBoxStates(box);
  };

  const handleCloseNewGroupInGroup = () => {
    setErrors(INIT_ERROR);
    setAnchorNewGroupInGroup(null);
  };

  // create new
  const handleAcceptCreateGroupInGroup = async () => {
    if (!!newGroupName) {
      await dispatch(createGroupPlan(newGroupName));
      setNewGroupName(null);
      props.onEventTriggerCreate();
      setAnchorNewGroupInGroup(null);
      setErrors({
        ...errors,
        group_name: null,
      });
    } else {
      setErrors({
        ...errors,
        group_name: TEXT_REQUIRED,
      });
    }
  };

  const getStartAndEndTime = (selectedTime) => {
    const currentSelectedDate = moment(selectedTime);

    return {
      start_time: currentSelectedDate
        .clone()
        .startOf("month")
        .format(PLAN_TIME_FORMAT),
      end_time: currentSelectedDate
        .clone()
        .endOf("month")
        .format(PLAN_TIME_FORMAT),
    };
  };

  useEffect(() => {
    if (onEventTriggerSearch) {
      const delayDebounceFn = setTimeout(() => {
        onEventTriggerSearch({
          search_param: searchValue,
          time_range: getStartAndEndTime(startDate),
        });
      }, 500); // Adjust the delay (in milliseconds) as per your requirements
      return () => clearTimeout(delayDebounceFn);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, startDate]);

  const open = Boolean(anchorPopoverEl);
  const openMenu = Boolean(anchorMenuEl);
  const openDelete = Boolean(anchorDeleteEl);
  const id = open ? "simple-popover" : undefined;
  const idDelete = openDelete ? "delete-popover" : undefined;

  const deselectAllRows = () => {
    tableRef.current.handleDeselectAllClick();
  };

  const CustomDatePickerButton = forwardRef(({ value, onClick }, ref) => (
    <button
      className={styles["custom-datepicker-button"]}
      onClick={onClick}
      ref={ref}
    >
      {value}
      <span className={styles["icon-choose"]}>
        <ArrowDropDownIcon sx={{ color: "#2E4E76" }} />
      </span>
    </button>
  ));

  const planAction = useMemo(() => {
    const handleSelectGroup = (group) => {
      setSelectedGroup(group?.id);
    };

    const handleUpdateOrCreateNewGroup = async () => {
      if (!newGroupName) {
        setErrors({
          ...errors,
          group_name: TEXT_REQUIRED,
        });
        return;
      }
      let res = null;
      if (editingGroup) {
        // update
        res = await dispatch(
          editGroupPlan({
            id: editingGroup.id,
            name: newGroupName,
          })
        );
      } else {
        // create
        res = await dispatch(createGroupPlan(newGroupName));
        handleChangePlansGroup(res?.payload?.id);
      }
      if (res.payload.error) {
        if (res?.payload?.data?.name[0]) {
          toast.error("このフィルードは必須です。");
        } else {
          toast.error("更新に失敗しました");
        }
      } else {
        if (editingGroup) {
          const newGroupName = res?.payload;
          const copyPlanGroups = [...planGroups];
          const planGroupIndex = copyPlanGroups.findIndex(
            (group) => group.id === editingGroup.id
          );
          copyPlanGroups[planGroupIndex] = {
            ...newGroupName,
          };
          setPlanGroups(copyPlanGroups);
        } else {
          toast.success("プラングループを作成中です。");
          dispatch(getPlanGroups());
        }
        handleCloseNewGroup();
      }
    };

    const handleScroll = async (e) => {
      const bottom = e.target.scrollHeight - e.target.scrollTop < 300;
      if (bottom && !isLoading && nextGroupPage) {
        setIsLoading(true);
        const res = await HTTP.get(nextGroupPage);
        setNextGroupPage(res.next);
        setPlanGroups([...planGroups, ...res.results.data]);
        setIsLoading(false);
      }
    };

    const handleChangePlansGroup = async (groupId = undefined) => {
      const res = await dispatch(
        changePlansGroup({
          groupId: groupId ?? selectedGroup,
          planIds: selectedRows,
        })
      );
      if (res?.payload?.error) {
        toast.error("移動に失敗しました");
      } else {
        // load list plan group when remove plan

        // check show previous page
        let showPreviousPage = false;
        if (selectedRows.length === rows.length && selectedGroup !== groupID) {
          showPreviousPage = true;
        }
        if (props.onEventTriggerChangePlansGroup) await props.onEventTriggerChangePlansGroup(showPreviousPage);
        handleCloseMovingGroup();
        deselectAllRows();
        getGroups();
        if (onUpdatedPlans) onUpdatedPlans();
      }
    };

    return (
      <>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <div
            className={`${styles["header--text"]} clickable`}
            aria-describedby="movingGroupPopover"
            variant="contained"
            onClick={handleClickMovingGroup}
          >
            既存のグループに移動
          </div>
          <Popover
            id="movingGroupPopover"
            open={!!anchorMovingGroup}
            anchorEl={anchorMovingGroup}
            onClose={handleCloseMovingGroup}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <Box
              className={styles["popover-container"]}
              sx={{ minWidth: "450px" }}
            >
              <Box sx={{ p: 1.5 }}>
                <TextField
                  placeholder="プラングループを検索"
                  className={styles["text-field"]}
                  onChange={handleSearchGroupChange}
                  value={searchGroup}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        sx={{ fontSize: "24px" }}
                      >
                        <i className="icon icon-search"></i>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <div
                className={styles["plan-group-list"]}
                onScroll={handleScroll}
              >
                {planGroups.map((group, i) => (
                  <Box
                    key={i}
                    className={styles["plan-group-list--item"]}
                    sx={{
                      background:
                        group.id === selectedGroup
                          ? "#97CBE2 !important"
                          : "transparent",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                        p: "10px 16px",

                        "&:hover": {
                          background: "#97CBE2",
                        },
                      }}
                      onClick={() => {
                        handleSelectGroup(group);
                      }}
                    >
                      <Box
                        sx={{
                          fontSize: "20px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <i className="icon icon-folder"></i>
                      </Box>
                      <div className={styles["plan-group-list--item-title"]}>
                        {group.name}
                      </div>
                    </Box>
                    <div
                      className={styles["plan-group-list--item-edit"]}
                      onClick={(e) => handleEditGroupName(e, group)}
                    >
                      <CreateRoundedIcon sx={{ fontSize: "20px" }} />
                    </div>
                    <Box sx={{ p: "20px", fontSize: "12px" }}>
                      {group.total_plans}
                    </Box>
                  </Box>
                ))}
                {isLoading && (
                  <Box
                    sx={{
                      my: 2,
                      textAlign: "center",
                      fontSize: "12px",
                    }}
                  >
                    Loading...
                  </Box>
                )}
              </div>
              <div className={styles["plan-group-list--footer"]}>
                <Button
                  variant="contained"
                  sx={{
                    width: "100%",
                    fontWeight: "bold",
                    borderRadius: "5px",
                    background: "#7764E4",

                    "&:disabled": {
                      background: "#b4b4b4",
                    },
                  }}
                  onClick={() => handleChangePlansGroup()}
                  disabled={!selectedGroup}
                >
                  選択したグループに移動する
                </Button>
              </div>
            </Box>
          </Popover>
          <div
            className={`${styles["header--text"]} clickable`}
            aria-describedby="newGroupPopover"
            variant="contained"
            onClick={handleClickNewGroup}
          >
            新規のグループに移動
          </div>
          <CreateGroupPopover
            id="newGroupPopover"
            open={!!anchorNewGroup}
            anchorEl={anchorNewGroup}
            onClose={handleCloseNewGroup}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <Box className={styles["popover-container"]} sx={{ p: 1.5 }}>
              <TextField
                value={newGroupName}
                onChange={(e) => setNewGroupName(e.target.value)}
                placeholder="グループ名を入力してください。"
                className={`${styles["text-field"]} ${styles["new-group-input"]}`}
                helperText={errors?.group_name}
                error={!!errors.group_name}
                sx={{
                  ".Mui-error .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#d32f2f !important",
                  },
                }}
              />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  mt: 1.5,
                }}
              >
                <Typography
                  onClick={handleCloseNewGroup}
                  className={`${styles["popover--text"]} clickable`}
                  sx={{ mr: 2.5, color: "#7764E4" }}
                >
                  キャンセル
                </Typography>
                <Typography
                  onClick={handleUpdateOrCreateNewGroup}
                  className={`${styles["popover--text"]} clickable`}
                  sx={{ color: "#7764E4" }}
                >
                  {editingGroup?.id ? "保存" : "作成して移動する"}
                </Typography>
              </Box>
            </Box>
          </CreateGroupPopover>
        </Box>
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    anchorMovingGroup,
    searchGroup,
    planGroups,
    isLoading,
    selectedGroup,
    anchorNewGroup,
    newGroupName,
    editingGroup,
    nextGroupPage,
    dispatch,
    selectedRows,
    errors,
  ]);

  return (
    <Card
      sx={{
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {selectedRows?.length ? (
          <>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
              className={styles["plan-group-header--selected-rows"]}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  className={`${styles["header--text"]} ${styles["first-text"]}`}
                >
                  全 {selectedRows?.length} 件選択済み
                </Typography>
                {isPlans && <>{planAction}</>}
                <div
                  className={`${styles["header--text"]} clickable`}
                  aria-describedby={idDelete}
                  variant="contained"
                  onClick={handleClickDelete}
                >
                  削除する
                </div>
                <Popover
                  id={idDelete}
                  open={openDelete}
                  anchorEl={anchorDeleteEl}
                  onClose={handleCloseDelete}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                >
                  <Box className={styles["popover-container"]} sx={{ p: 1.5 }}>
                    <Typography className={styles["popover--text"]}>
                      本当に削除してよろしいですか？
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        mt: 1.5,
                      }}
                    >
                      <Typography
                        onClick={handleCloseDelete}
                        className={`${styles["popover--text"]} clickable`}
                        sx={{ mr: 2.5, color: "#7764E4" }}
                      >
                        キャンセル
                      </Typography>
                      <Typography
                        onClick={handleAcceptDelete}
                        className={`${styles["popover--text"]} clickable`}
                        sx={{ color: "#F53C56" }}
                      >
                        削除する
                      </Typography>
                    </Box>
                  </Box>
                </Popover>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
                className={styles["deselected-rows"]}
                onClick={deselectAllRows}
              >
                <CloseRoundedIcon fontSize="large" />
                <span className={styles["deselected-rows--text"]}>閉じる</span>
              </Box>
            </Box>
          </>
        ) : (
          <>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
              className={styles["plan-group-header"]}
            >
              <Box
                sx={{
                  ml: "42px",
                }}
              >
                <ReactDatePicker
                  className={styles["box-date"]}
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  dateFormat="yyyy年M月"
                  showMonthYearPicker
                  calendarClassName="custom-datepicker"
                  locale="ja"
                  customInput={<CustomDatePickerButton />}
                />
              </Box>
              <Typography
                component="h3"
                className={styles["plan-group-title"]}
                sx={{
                  ml: 2,
                }}
              >
                に販売したプラン
              </Typography>
            </Box>
            <Stack direction="row" className={styles["group-buttons"]}>
              {!isPlans && (
                <Box
                  className={`${styles["button-text"]} ${styles["active"]} ${
                    boxStates === "boxCreate" ? styles["background"] : ""
                  }`}
                  onClick={(e) => handleClickNewGroupInGroup(e, "boxCreate")}
                  aria-describedby="newGroupInGroup"
                >
                  <CreateNewFolderOutlinedIcon fontSize="medium" />
                  <Typography>グループを作成</Typography>
                </Box>
              )}
              {/* crreate group in group plans */}
              <Popover
                id="newGroupInGroup"
                open={!!anchorNewGroupInGroup}
                anchorEl={anchorNewGroupInGroup}
                onClose={handleCloseNewGroupInGroup}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <Box className={styles["popover-container"]} sx={{ p: 1.5 }}>
                  <TextField
                    placeholder="グループ名を入力してください。"
                    name="group_name"
                    onChange={(e) => setNewGroupName(e.target.value)}
                    className={`${styles["text-field"]} ${styles["new-group-input"]}`}
                    helperText={errors.group_name}
                    error={!!errors.group_name}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      mt: 1.5,
                    }}
                  >
                    <Typography
                      onClick={handleCloseNewGroupInGroup}
                      className={`${styles["popover--text"]} clickable`}
                      sx={{ mr: 2.5, color: "#7764E4" }}
                    >
                      キャンセル
                    </Typography>
                    <Typography
                      onClick={handleAcceptCreateGroupInGroup}
                      className={`${styles["popover--text"]} clickable`}
                      sx={{ color: "#7764E4" }}
                    >
                      保存
                    </Typography>
                  </Box>
                </Box>
              </Popover>
              <Box>
                <div
                  className={`${styles["button-text"]} ${
                    boxStates === "boxSearch" ? styles["background"] : ""
                  }`}
                  aria-describedby={id}
                  onClick={(e) => handleClick(e, "boxSearch")}
                >
                  <SearchIcon fontSize="medium" />
                  <Typography>検索</Typography>
                </div>
                <SearchPopover
                  id={id}
                  open={open}
                  anchorEl={anchorPopoverEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  <TextField
                    id="outlined-basic"
                    placeholder={
                      isPlans ? "プランを検索" : "プラングループを検索"
                    }
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                    className={styles["text-field"]}
                    sx={{ width: "fit-content" }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon color="#2E4E76" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </SearchPopover>
              </Box>
              <Box
                onClick={() => handleClickScreenCSV()}
                className={styles["button-text"]}
              >
                <FormatListBulletedIcon fontSize="medium" />
                <Typography>CSV インポート</Typography>
              </Box>
              <Box>
                <div
                  className={`${styles["button-text"]} ${
                    boxStates === "boxPDF" ? styles["background"] : ""
                  }`}
                  id="basic-button"
                  aria-controls={openMenu ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={openMenu ? "true" : undefined}
                  onClick={(e) => handleClickMenu(e, "boxPDF")}
                >
                  <SaveAltIcon fontSize="medium" />
                  <Typography>ダウンロード</Typography>
                </div>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorMenuEl}
                  open={openMenu}
                  onClose={handleCloseMenu}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem
                    onClick={handleCloseMenu}
                    sx={{
                      width: "160px",
                      color: "#2E4E76",
                      fontWeight: "bold",
                      fontSize: "12px",
                    }}
                    className={styles["download-csv"]}
                  >
                    <CSVLink
                      data={rows}
                      sx={{ textDecoration: "none", color: "#2e4e76" }}
                      filename={
                        csvFileName || moment().format("YYYYMMDDHHmmss")
                      }
                    >
                      .csv
                    </CSVLink>
                  </MenuItem>

                  <MenuItem
                    sx={{
                      width: "160px",
                      color: "#2E4E76",
                      fontWeight: "bold",
                      fontSize: "12px",
                    }}
                  >
                    <DownloadPdfLink
                      onClick={handleCloseMenu}
                      rows={rows}
                      pdfHeaders={pdfHeaders}
                      totalPdfDetail={totalPdfDetail}
                      groupName={isPlans}
                      title={
                        csvFileName || (isPlans ? "プラン" : "プラングループ")
                      }
                    />
                  </MenuItem>
                </Menu>
              </Box>
            </Stack>
          </>
        )}
      </Box>
      <Box
        sx={{
          width: "100%",
          flexGrow: 1,
          overflow: "hidden",
          position: "relative",
        }}
      >
        {(loadingGroup || loadingPlan) && <LoadingComponent />}
        <EnhancedTable
          ref={tableRef}
          headCells={headCells}
          rows={rows}
          stickyHeader={true}
          onSelectedRow={(rows) => setSelectedRows(rows)}
          footerComponent={<TableFooter />}
        />
      </Box>
      {children}
    </Card>
  );
});

PlanGroupContainer.propTypes = {
  rows: PropTypes.array.isRequired,
  headCells: PropTypes.array.isRequired,
};

export default PlanGroupContainer;
