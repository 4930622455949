const statusCode = {
  OK: 200,
  CREATED: 201,
  BAD_REQUEST: 400,
  INTERNAL_SERVER_ERROR: 500,
  UN_AUTHORIZED: 401,
  NO_CONTENT: 204,
}

export default statusCode;
