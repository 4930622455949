import {
  Box,
  Button,
  Card,
  Modal,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Typography,
} from "@mui/material";
import BreadCrumb from "../../../../../components/breadCrumb";
import styles from "../../styles.module.scss";
import { useRef, useState, useEffect, useCallback } from "react";
import EnhancedTable from "../../../../../components/EnhancedTable";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import CreateNewFolderOutlinedIcon from "@mui/icons-material/CreateNewFolderOutlined";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { visuallyHidden } from "@mui/utils";
import { useDispatch, useSelector } from "react-redux";
import {
  getListPlanWithIds,
  updateMultiPlan,
} from "../../../../../features/plans/plansSlice";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Grid";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Formik, Form } from "formik";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import {
  createGroupPlan,
  editGroupPlan,
  getPlanGroups,
} from "../../../../../features/plan/planGroupSlice";
import ExitDialog from "./ExitDialog";
import CircularProgress from "@mui/material/CircularProgress";
import ja from "date-fns/locale/ja";
import HTTP from "../../../../../http_common";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
registerLocale("ja", ja);

const initData = [
  {
    id: "",
    group_plan_id: "",
  },
];
const PlanGroupImportGroup = () => {
  const tableRef = useRef();
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [editingGroup, setEditingGroup] = useState(null);
  const [editingGroupValue, setEditingGroupValue] = useState(null);
  const [dataPlanModalOpen, setDataPlanModalOpen] = useState(false);
  const [listGroupPlan, setListGroupPlan] = useState([]);
  const [listPlan, setListPlan] = useState([]);
  const [data, setData] = useState(initData);
  const [newGroupName, setNewGroupName] = useState(null);
  const [canLeave, setCanLeave] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const { groups, loading: groupLoading } = useSelector(
    (state) => state.planGroup
  );
  const { loading: planLoading } = useSelector((state) => state.plans);
  const ids = window.history?.state?.ids;
  const header = window.history?.state?.header;
  const [triggerExit, setTriggerExit] = useState({ onOk: false, path: "" });
  const [isVisibleDialog, setVisibleDialog] = useState(false);
  const [open, setOpen] = useState(false);

  const handleGoToIntendedPage = useCallback(
    (location) => {
      history.push(location);
      window.history.replaceState(null, "", location);
    },
    [history]
  );

  const deselectAllRows = () => {
    tableRef.current.handleDeselectAllClick();
  };

  const setNotImportedPlans = (importedPlanIds) => {
    const notImportedPlans = listPlan.filter(
      (plan) => !importedPlanIds.includes(plan.id)
    );
    setListPlan(notImportedPlans);
    if (!notImportedPlans.length) {
      setCanLeave(true);
    }
  };

  const handleImportedPlans = (importedPlanIds) => {
    const notImportIds = ids.filter((id) => !importedPlanIds.includes(id));
    window.history.replaceState(
      {
        ids: notImportIds,
        header: header,
      },
      "",
      history.location.pathname
    );
    setNotImportedPlans(selectedRows);
    deselectAllRows();
    const listGroupPlanCopy = [...listGroupPlan];
    const selectedGroupIndex = listGroupPlanCopy.findIndex(
      (group) => group.id === selectedGroup
    );
    if (selectedGroupIndex !== -1) {
      listGroupPlanCopy[selectedGroupIndex].plans =
        (listGroupPlanCopy[selectedGroupIndex].plans || 0) +
        importedPlanIds.length;
      setListGroupPlan(listGroupPlanCopy);
    }
    return;
  };

  useEffect(() => {
    if (triggerExit.onOk) {
      handleGoToIntendedPage(triggerExit.path);
    }

    const unblock = history.block((location) => {
      if (!listPlan?.length) {
        return true;
      }
      if (location.pathname !== "/results/plan/import/group") {
        setVisibleDialog(true);
      }
      setTriggerExit((obj) => ({ ...obj, path: location.pathname }));
      if (triggerExit.onOk) {
        return true;
      }
      return false;
    });
    return () => {
      unblock();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerExit, listPlan]);

  useEffect(() => {
    let dataChange = [];
    groups.forEach((item) => {
      let groupPlan = {
        id: item?.id,
        name: item?.name,
        plans: item?.total_plans,
      };
      dataChange.push(groupPlan);
    });
    setListGroupPlan(dataChange);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groups]);

  const setDataPlan = (res) => {
    let data = res?.payload?.results;
    let dataChange = [];
    data.forEach((item) => {
      let groupPlan = {
        id: item?.id,
        name: item?.name,
        ota_name: item?.reservation_site,
      };
      dataChange.push(groupPlan);
    });
    setListPlan(dataChange);
  };

  const getPlanByIds = async (planIds) => {
    dispatch(getPlanGroups()).then(({ payload }) => {
      setNextGroupPage(payload?.next);
    });
    const stringIds = planIds.join();
    setOpen(true);
    try {
      const resPlan = await dispatch(getListPlanWithIds(stringIds));
      if (resPlan?.payload?.status === 200) {
        setOpen(false);
        setDataPlan(resPlan);
      }
    } catch (error) {
      setOpen(false);
    }
  };

  useEffect(() => {
    if (!ids?.length) {
      history.push("/results/plan/import");
      return;
    } else {
      getPlanByIds(ids);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let data = [];
    selectedRows.forEach((value) => {
      let plan = listPlan.find((plan) => plan.id === value);
      let obj = {
        id: plan.id,
        group_plan_id: selectedGroup,
      };
      data.push(obj);
    });
    setData(data);
    // eslint-disable-next-line
  }, [selectedRows]);

  const isSelected = (id) => selectedGroup?.id === id;

  const handleOpenModifyGroup = (event, group) => {
    setEditingGroupValue(group);
    setEditingGroup(event.currentTarget);
  };

  const handleCloseModifyGroup = (event) => {
    setEditingGroup(null);
    setEditingGroupValue(null);
  };

  const handleSavePlans = (event) => {
    setDataPlanModalOpen(true);
  };

  const headCells = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "プランタイトル",
    },
    {
      id: "ota_name",
      numeric: false,
      disablePadding: false,
      label: "OTA名",
    },
  ];

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");
  const [nextGroupPage, setNextGroupPage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const createSortHandler = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const formikConfig = {
    initialValues: { data },
    onSubmit: async (values, { setSubmitting, setErrors }) => {
      let cloneValues = JSON.parse(JSON.stringify(values.data));

      dispatch(updateMultiPlan(cloneValues)).then((res) => {
        setSubmitting(false);
        if (res.payload?.error) {
          const errorData = res.payload?.data;
          setErrors(errorData);
          toast.error("システムエラー");
        } else {
          toast.success("プラングループに登録しました");
          setDataPlanModalOpen(false);
          handleImportedPlans(selectedRows);
        }
      });
    },
    handleChange: (e) => {},
  };

  useEffect(() => {
    data.forEach((plan) => {
      plan.group_plan_id = selectedGroup;
    });
    setData(data);
    // eslint-disable-next-line
  }, [selectedGroup]);

  const changeGroupName = (e) => {
    setNewGroupName(e.target.value);
  };

  const addGroupName = (e) => {
    setNewGroupName(e.target.value);
  };

  // create new group
  const handleAcceptCreateGroup = async () => {
    dispatch(createGroupPlan(newGroupName))
      .then((res) => {
        toast.success("プラングループを作成中です");
        setNewGroupName(null);
        setEditingGroup(null);
        setListGroupPlan([res?.payload, ...listGroupPlan]);
        setSelectedGroup(res?.payload?.id);
      })
      .catch((err) => {
        toast.error("システムエラー");
      });
  };

  // edit group
  const handleAcceptEditGroup = async (id) => {
    await dispatch(editGroupPlan({ id, name: newGroupName }));
    toast.success("グループの編集に成功しました。");
    setNewGroupName(null);
    setEditingGroup(null);
    dispatch(getPlanGroups());
  };

  useEffect(() => {
    if (canLeave) return history.push("/results/plan/group");
    function handleOnBeforeUnload(event) {
      if (window.location.href === "/login") {
        return;
      }
      event.preventDefault();
      event.returnValue = "";
    }
    window.addEventListener("beforeunload", handleOnBeforeUnload, {
      capture: true,
    });
    return () => {
      window.removeEventListener("beforeunload", handleOnBeforeUnload, {
        capture: true,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canLeave]);

  if (!header?.length) {
    return <Redirect to="/results/plan/import" />;
  }

  const modalImportPlans = (
    <Modal
      style={{
        backgroundColor: "rgba(46, 78, 118, .5)",
      }}
      open={dataPlanModalOpen}
      onClose={() => setDataPlanModalOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className={styles["modal-confirm"]}>
        <Box className={styles["modal-confirm-header"]}>
          <Typography className={styles["modal-confirm-title"]}>
            プラングループに登録
          </Typography>
          <CloseIcon
            className={styles["icon-close"]}
            onClick={() => setDataPlanModalOpen(false)}
          />
        </Box>
        <Formik
          enableReinitialize={true}
          validateOnChange={false}
          initialValues={formikConfig.initialValues}
          onSubmit={formikConfig.onSubmit}
          validationSchema={formikConfig.validationSchema}
        >
          {({ errors, values }) => (
            <Form>
              {selectedRows?.map((item, index) => {
                let plan = listPlan.find((plan) => plan.id === item);

                return (
                  <Box className={styles["modal-confirm-body"]} key={index}>
                    <Box className={styles["modal-confirm-body-header"]}>
                      <Typography
                        sx={{
                          fontSize: "20px",
                          fontWeight: "bold",
                          width: "17%",
                        }}
                      >
                        プラン{index + 1}
                      </Typography>
                      <div className={styles["modal-confirm-line"]}></div>
                    </Box>
                    <Grid container className={styles["modal-confirm-grid"]}>
                      <Grid
                        item
                        xs={3}
                        className={styles["modal-confirm-grid-text"]}
                      >
                        プランタイトル
                      </Grid>
                      <Grid
                        item
                        xs={9}
                        className={styles["modal-confirm-grid-text"]}
                      >
                        : {plan?.name}
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        className={styles["modal-confirm-grid-text"]}
                      >
                        OTA
                      </Grid>
                      <Grid
                        item
                        xs={9}
                        className={styles["modal-confirm-grid-text"]}
                      >
                        : {plan?.ota_name}
                      </Grid>
                      {/* <Grid
                        item
                        xs={3}
                        className={styles["modal-confirm-grid-text"]}
                      >
                        掲載期間
                      </Grid>
                      <Grid item xs={9}>
                        <Box sx={{ display: "flex", alignItems: "center" }} className={'custom-datepicker-confirm-container'}>
                          <Field name={`data.${index}.sale_period_start`}>
                            {({ field, form }) => (
                              <DatePicker
                                sx={{ marginRight: "10px" }}
                                dateFormat="yyyy.MM.dd"
                                selected={field.value}
                                onChange={(date) => {
                                  form.setFieldValue(
                                    `data.${index}.sale_period_start`,
                                    date
                                  );
                                  if (
                                    moment(date).isAfter(
                                      values[`data`]?.[index]?.sale_period_end
                                    )
                                  ) {
                                    form.setFieldValue(
                                      `data.${index}.sale_period_end`,
                                      moment(date).add(1, "days").toDate()
                                    );
                                  }
                                }}
                                customInput={
                                  <DatepickerButton className="date-picker-start" />
                                }
                                calendarClassName="custom-datepicker"
                                locale="ja"
                              />
                            )}
                          </Field>
                          <div style={{ width: "35px", textAlign: "center" }}>
                            〜
                          </div>
                          <Field name={`data.${index}.sale_period_end`}>
                            {({ field, form }) => (
                              <DatePicker
                                dateFormat="yyyy.MM.dd"
                                minDate={
                                  values[`data`]?.[index]?.sale_period_start
                                }
                                selected={field.value}
                                onChange={(date) =>
                                  form.setFieldValue(
                                    `data.${index}.sale_period_end`,
                                    date
                                  )
                                }
                                customInput={
                                  <DatepickerButton className="date-picker-end" />
                                }
                                calendarClassName="custom-datepicker"
                                locale="ja"
                              />
                            )}
                          </Field>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "24px",
                          }}
                        >
                          <div className={styles["div-error"]}>
                            <ErrorMessage
                              name={`data.${index}.sale_period_start`}
                            >
                              {(message) => (
                                <div className={styles["error-message"]}>
                                  {message}
                                </div>
                              )}
                            </ErrorMessage>
                          </div>
                          <div style={{ width: "35px" }}></div>
                          <div className={styles["div-error"]}>
                            <ErrorMessage
                              name={`data.${index}.sale_period_end`}
                            >
                              {(message) => (
                                <div className={styles["error-message"]}>
                                  {message}
                                </div>
                              )}
                            </ErrorMessage>
                          </div>
                        </Box>
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        className={styles["modal-confirm-grid-text"]}
                      >
                        宿泊対象期間
                      </Grid>
                      <Grid item xs={9}>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Field
                            name={`data.${index}.accommodation_period_start`}
                          >
                            {({ field, form }) => (
                              <DatePicker
                                sx={{ marginRight: "10px" }}
                                dateFormat="yyyy.MM.dd"
                                selected={field.value}
                                onChange={(date) => {
                                  form.setFieldValue(
                                    `data.${index}.accommodation_period_start`,
                                    date
                                  );

                                  if (
                                    moment(date).isAfter(
                                      values[`data`]?.[index]
                                        ?.accommodation_period_end
                                    )
                                  ) {
                                    form.setFieldValue(
                                      `data.${index}.accommodation_period_end`,
                                      moment(date).add(1, "days").toDate()
                                    );
                                  }
                                }}
                                customInput={
                                  <DatepickerButton className="date-picker-start" />
                                }
                                calendarClassName="custom-datepicker"
                                locale="ja"
                              />
                            )}
                          </Field>
                          <div style={{ width: "35px", textAlign: "center" }}>
                            〜
                          </div>
                          <Field
                            name={`data.${index}.accommodation_period_end`}
                          >
                            {({ field, form }) => (
                              <DatePicker
                                minDate={
                                  values[`data`]?.[index]
                                    ?.accommodation_period_start
                                }
                                dateFormat="yyyy.MM.dd"
                                selected={field.value}
                                onChange={(date) =>
                                  form.setFieldValue(
                                    `data.${index}.accommodation_period_end`,
                                    date
                                  )
                                }
                                customInput={
                                  <DatepickerButton className="date-picker-end" />
                                }
                                calendarClassName="custom-datepicker"
                                locale="ja"
                              />
                            )}
                          </Field>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "24px",
                          }}
                        >
                          <div className={styles["div-error"]}>
                            <ErrorMessage
                              name={`data.${index}.accommodation_period_start`}
                            >
                              {(message) => (
                                <div className={styles["error-message"]}>
                                  {message}
                                </div>
                              )}
                            </ErrorMessage>
                          </div>
                          <div style={{ width: "35px" }}></div>
                          <div className={styles["div-error"]}>
                            <ErrorMessage
                              name={`data.${index}.accommodation_period_end`}
                            >
                              {(message) => (
                                <div className={styles["error-message"]}>
                                  {message}
                                </div>
                              )}
                            </ErrorMessage>
                          </div>
                        </Box>
                      </Grid> */}
                    </Grid>
                  </Box>
                );
              })}
              <Box
                className={styles["modal-confirm-bottom"]}
                sx={{
                  width: "100%",
                  backgroundColor: "#f7f7fa",
                  textAlign: "center",
                  minHeight: "95px",
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    marginTop: "32px",
                    background: "#7764E4",
                    fontSize: 16,
                    width: "200px",
                    borderRadius: "5px",
                    "&:disabled": {
                      background: "#B7E1F1",
                      color: "#ffffff",
                    },
                  }}
                  disabled={!selectedRows.length || selectedGroup == null}
                  type="submit"
                >
                  グループに登録する
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );

  const handleScrollGroupPlan = async (e) => {
    const paddingScrollBottom = 400;
    const bottom =
      e.target.scrollHeight - e.target.scrollTop <= paddingScrollBottom;
    if (bottom && !isLoading && nextGroupPage) {
      setIsLoading(true);
      const res = await HTTP.get(nextGroupPage);
      setNextGroupPage(res.next);
      setListGroupPlan([...listGroupPlan, ...res.results.data]);
      setIsLoading(false);
    }
  };

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        height: "100%",
        overflow: "hidden",
        p: 4,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <BreadCrumb
        content="CSVインポート"
        textRoutes={["ダッシュボード", "実績登録・確認", "プラン販売実績"]}
      />
      <Box className={styles["import-group-container"]}>
        <Card className={styles["import-group-card"]}>
          <div className={styles["import-group-card--header"]}>
            <Typography variant="h6" className={styles["group-card--title"]}>
              インポートしたプラン
            </Typography>
          </div>
          <Box className={styles["import-group-card--body"]} sx={{ mt: 2 }}>
            <EnhancedTable
              ref={tableRef}
              headCells={headCells}
              rows={listPlan}
              stickyHeader={true}
              onSelectedRow={(rows) => setSelectedRows(rows)}
            />
            {planLoading && <Box sx={{ textAlign: "center" }}>Loading...</Box>}
          </Box>
        </Card>
        <Card className={styles["import-group-card"]}>
          <div className={styles["import-group-card--header"]}>
            <Typography variant="h6" className={styles["group-card--title"]}>
              プラングループ
            </Typography>
          </div>
          <Box
            className={styles["import-group-card--body"]}
            sx={{ mt: 2, maxHeight: 440 }}
            onScroll={handleScrollGroupPlan}
          >
            <Table className="micado-table" stickyHeader={true}>
              <TableHead className="table-head">
                <TableRow>
                  <TableCell
                    className="header-cell"
                    sx={{
                      height: "48px",
                    }}
                    colSpan={2}
                    onClick={() => createSortHandler("name")}
                  >
                    <TableSortLabel
                      active={orderBy === "name"}
                      direction={orderBy === "name" ? order : "asc"}
                    >
                      <span className={styles["header-cell"]}>
                        プラングループ名
                      </span>
                      {orderBy === "name" ? (
                        <Box component="span" sx={visuallyHidden}>
                          {order === "desc"
                            ? "sorted descending"
                            : "sorted ascending"}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell
                    align="center"
                    className="header-cell text-nowrap"
                    sx={{
                      height: "48px",
                    }}
                    onClick={() => createSortHandler("plans")}
                  >
                    <TableSortLabel
                      active={orderBy === "plans"}
                      direction={orderBy === "plans" ? order : "asc"}
                    >
                      <span className={styles["header-cell"]}>登録数</span>
                      {orderBy === "plans" ? (
                        <Box component="span" sx={visuallyHidden}>
                          {order === "desc"
                            ? "sorted descending"
                            : "sorted ascending"}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="table-body">
                <TableRow
                  className="table-body-row--add-group table-body-row"
                  onClick={handleOpenModifyGroup}
                >
                  <TableCell align="inherit" sx={{ width: "30px", pr: 0 }}>
                    <CreateNewFolderOutlinedIcon />
                  </TableCell>
                  <TableCell align="inherit" colSpan={2}>
                    新しいグループを作成
                  </TableCell>
                </TableRow>

                {listGroupPlan.map((row, index) => (
                  <TableRow
                    key={row.name + index}
                    className={`table-body-row ${styles["table-body-row"]} ${
                      selectedGroup === row.id ? styles["selected-group"] : ""
                    } `}
                    aria-checked={isSelected(row)}
                    onClick={() => {
                      setSelectedGroup(row.id);
                    }}
                  >
                    <TableCell align="inherit" sx={{ width: "30px", pr: 0 }}>
                      <FolderOpenIcon />
                    </TableCell>
                    <TableCell align="inherit">
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography
                          variant="p"
                          className={styles["plan-group-name"]}
                        >
                          {row.name}
                        </Typography>
                        <Typography
                          variant="p"
                          sx={{
                            ml: "30px",
                          }}
                          className={styles["plan-group-edit-icon"]}
                          aria-describedby="edit-group"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleOpenModifyGroup(e, row);
                          }}
                        >
                          <ModeEditIcon />
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell align="right">{row.plans || 0}</TableCell>
                  </TableRow>
                ))}
                {(isLoading || groupLoading) && (
                  <TableRow>
                    <TableCell sx={{ textAlign: "center" }} colSpan={3}>
                      Loading...
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <Popover
              sx={{
                "& .MuiPaper-root": {
                  borderRadius: "8px",
                  boxShadow: " 0px 0px 8px #00000052",
                  minWidth: "262px",
                },
              }}
              id="edit-group"
              open={Boolean(editingGroup)}
              anchorEl={editingGroup}
              onClose={handleCloseModifyGroup}
              anchorOrigin={{
                vertical: "center",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "center",
                horizontal: "right",
              }}
            >
              <Box
                sx={{
                  p: "12px",
                }}
              >
                <TextField
                  variant="outlined"
                  placeholder="グループ名を入力してください。"
                  className={styles["plan-group-input"]}
                  defaultValue={editingGroupValue?.name}
                  onChange={(e) => {
                    if (editingGroupValue?.name) {
                      changeGroupName(e);
                    } else {
                      addGroupName(e);
                    }
                  }}
                />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    mt: "12px",
                  }}
                >
                  <span
                    className={styles["plan-group-btn"]}
                    onClick={handleCloseModifyGroup}
                  >
                    キャンセル
                  </span>
                  <span
                    className={`${styles["plan-group-btn"]} ${styles["plan-group-btn--save"]}`}
                    onClick={() => {
                      if (editingGroupValue?.name) {
                        handleAcceptEditGroup(editingGroupValue?.id);
                      } else {
                        handleAcceptCreateGroup();
                      }
                    }}
                  >
                    保存
                  </span>
                </Box>
              </Box>
            </Popover>
          </Box>
          <Box className={styles["import-group-card--footer"]}>
            <Button
              variant="contained"
              sx={{
                background: "#7764E4",
                fontSize: 16,
                width: "100%",
                borderRadius: "5px",
                "&:disabled": {
                  background: "#B7E1F1",
                  color: "#ffffff",
                },
              }}
              disabled={!selectedRows.length || selectedGroup == null}
              onClick={handleSavePlans}
            >
              選択した{selectedRows.length}個のプランをグループに移動する
            </Button>
          </Box>
        </Card>
      </Box>
      {modalImportPlans}
      <ExitDialog
        visible={isVisibleDialog}
        onClose={() => setVisibleDialog(false)}
        onOk={() => {
          setTriggerExit((obj) => ({
            ...obj,
            onOk: true,
          }));
          setVisibleDialog(false);
        }}
      />
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          <Box className={styles["box-modal"]}>
            <Typography
              sx={{
                display: "flex",
                marginTop: "50px",
                justifyContent: "center",
              }}
              id="modal-modal-title"
              variant="h6"
              component="h2"
            >
              <CircularProgress />
            </Typography>
            <Typography
              className={styles["title-modal"]}
              id="modal-modal-description"
              sx={{ mt: 2 }}
            >
              Loading
            </Typography>
          </Box>
        </>
      </Modal>
    </Box>
  );
};

export default PlanGroupImportGroup;
