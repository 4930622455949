import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import HTTP from "../../http_common";
import { UNREGISTER } from "../../constants";

const initialState = {
  list: [],
  loading: false,
  paginate: {
    count: 0,
    next: null,
    previous: null,
    total_pages: 0,
  },
  error: null,
  planResult: null,
  planRequested: null,
  handlingPlans: {
    header: [],
    ids: [],
  },
};

export const getListPlan = createAsyncThunk(
  "plans/getListPlan",
  async (body) => {
    const response = await HTTP.get(`/api/plans/`, {
      params: { ...body },
    });
    return response;
  }
);

export const createPlan = createAsyncThunk("plans/createPlan", async (body) => {
  try {
    return await HTTP.post(`/api/gpt_plans/create/asy/`, body);
  } catch (error) {
    throw error;
  }
});

export const getGPTPlanAsync = createAsyncThunk("plans/getGPTPlanAsync", async (id) => {
  try {
    const response = await HTTP.get("/api/gpt_plans/get/" + id);
    return response;
  } catch (error) {
    throw error;
  }
});

export const importPlan = createAsyncThunk("plans/import", async (body) => {
  const formData = new FormData();
  formData.append("csv_file", body.file);
  body.headers.forEach((header, index) => {
    formData.append(`headers[${index}]key`, header.key);
    formData.append(`headers[${index}]custom`, header.custom);
  });
  const response = await HTTP.post(`/api/plans/import`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response;
});

export const customHeaderImportPlan = createAsyncThunk(
  "plans/customHeaderImportPlan", async (file) => {
    const formData = new FormData();
    formData.append("csv_file", file);
    const response = await HTTP.post(`/api/plans/header-import`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  });

export const getListPlanWithIds = createAsyncThunk(
  "plans/getListPlanWithIds",
  async (ids) => {
    const response = await HTTP.post("/api/plans/create-multiple", {
      ids: ids,
    });
    return response;
  }
);

export const updateMultiPlan = createAsyncThunk(
  "plans/updateMultiPlan",
  async (data) => {
    const response = await HTTP.post(`api/plans/update-multiple/`, data);
    return response;
  }
);

export const deleteMultiPlans = createAsyncThunk(
  "plans/deleteMultiPlan",
  async (ids) => {
    const response = await HTTP.delete(
      "/api/plans/create-multiple?ids=" + ids.join(",")
    );
    return response;
  }
);

export const changePlansGroup = createAsyncThunk(
  "plans/changePlansGroup",
  async ({ groupId, planIds }) => {
    const response = await HTTP.put(`api/group_plans/${groupId}/update/`, {
      plan_ids: planIds,
    });
    return response;
  }
);

export const plansSlice = createSlice({
  name: "plans",
  initialState,
  reducers: {
    setPlanRequested: (state, { payload }) => {
      state.planRequested = payload;
    },
  },
  extraReducers: {
    [getListPlan.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getListPlan.fulfilled]: (state, { payload }) => {
      state.loading = false;
      const { results, ...paginate } = payload;
      state.list = results?.data;
      state.list = results?.data.map((plan) => {
        if (plan) {
          return {
            ...plan,
            sale_period:
              plan?.sale_period_start && plan?.sale_period_end
                ? `${plan?.sale_period_start}~${plan?.sale_period_end}`
                : UNREGISTER,
            accommodation_period:
              plan?.accommodation_period_start && plan?.accommodation_period_end
                ? `${plan?.accommodation_period_start}~${plan?.accommodation_period_end}`
                : UNREGISTER,
          };
        }
        return plan;
      });
      state.paginate = paginate;
    },
    [getListPlan.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },

    [createPlan.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [createPlan.fulfilled]: (state, { payload }) => {
      state.loading = false;
      if (payload.error) {
        state.error = payload.error;
      }
    },
    [createPlan.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error;
    },

    [getGPTPlanAsync.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getGPTPlanAsync.fulfilled]: (state, { payload }) => {
      state.loading = false;
      if (payload.error) {
        state.error = payload.error;
      } else if (payload.status !== 'pending') {
        state.planResult = payload.data;
      }
    },
    [getGPTPlanAsync.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error;
    },

    [importPlan.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [importPlan.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.list = payload?.results?.data;
    },
    [importPlan.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },

    [getListPlanWithIds.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getListPlanWithIds.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.list = payload?.results?.data;
    },
    [getListPlanWithIds.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },

    [updateMultiPlan.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [updateMultiPlan.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.list = payload?.results?.data;
    },
    [updateMultiPlan.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },
  },
});
export const { setPlanRequested } = plansSlice.actions;
export default plansSlice.reducer;
