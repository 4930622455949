/* eslint-disable jsx-a11y/anchor-is-valid */
import { Box, Typography } from "@mui/material";
import styles from "./styles.module.scss";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import LandingBanner from "../../../src/assets/images/landingpage_banner.png";
import { getListNotice } from "../../features/notifications/noticeSlice";
import { NEW_TYPE, CHECK_STATUS } from "../../constants/index";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

export default function Landing() {
  const dispatch = useDispatch();
  const notifications = useSelector((state) => state.notifications.list.data);
  const roleUser = useSelector(
    (state) => state.auth?.currentUser?.is_superuser
  );
  const history = useHistory();
  const isLogin = localStorage.getItem("access_token");

  const submitViewHome = (checkRoleUser) => {
    if (checkRoleUser) {
      return history.push("/admin/users");
    } else {
      return history.push("/home");
    }
  };

  const submitLogin = () => {
    return history.push("/login");
  };

  const convertDate = (isoDate) => {
    const date = new Date(isoDate);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}.${month}.${day}`;
  };

  useEffect(() => {
    const dataQuery = {
      category: NEW_TYPE,
      limit: 5,
      status: CHECK_STATUS,
      recipients: 1,
    };
    dispatch(getListNotice(dataQuery));
  }, [dispatch]);

  return (
    <>
      <Box className={styles["box-main-content"]}>
        <Box className={styles["box-banner"]}>
          <Box className={styles["box-banner-cover"]}>
            <Typography className={styles["title-header"]}>
              A new era for OTA operations.
            </Typography>
            <Typography className={styles["title-content"]}>
              micado++ is an AI system, fully tailored to meet the needs of
              accommodation facilities. It empowers users to create plans
              instantly and receive OTA-compatible proposals. With micado++, not
              only can you reduce the time spent managing OTA operations, but
              also expect significant enhancements in performance.”
            </Typography>
            <Box className={styles["box-footer"]}>
              <Typography className={styles["text-footer"]}>
                国内OTAのプラン作成やセール参画、広告出稿まで、
                <br />
                データをもとに、AIにより最適な提案を瞬時に出力
                <br />
                時間削減と売上向上が1分で達成できる時代に。
              </Typography>
              <Box
                onClick={() =>
                  isLogin ? submitViewHome(roleUser) : submitLogin()
                }
                className={styles["box-try-it"]}
              >
                <a className={styles["title-footer-tryit"]}>
                  Try it
                  <Typography className={styles["icon-north-east"]}>
                    →
                  </Typography>
                </a>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className={styles["box-image"]}>
          <Box className={styles["box-image-cover"]}>
            <img
              style={{ width: "100%" }}
              src={LandingBanner}
              alt="logo with icon inline"
            />
          </Box>
        </Box>
      </Box>
      <Box className={styles["box-content"]}>
        <Box className={styles["cover-box"]}>
          <Box className={`${styles["box-left"]} ${styles["box-wrap"]}`}>
            <Typography className={styles["title-header-left"]}>
              国内OTA運用をAIで自動化できる
              <br />
              データに基づく最適な運用を始める
            </Typography>
            <Typography
              sx={{ marginTop: "40px" }}
              className={styles["title-content-left"]}
            >
              Levittを使えば、マーケットのトレンドや、自社の状況に合わせて最も最適な判断を瞬時に行うことが可能です。
            </Typography>
            <Typography className={styles["title-content-left"]}>
              国内OTAのあらゆるデータを収集し、プランのタイトルから説明文、販売期間や特典の内容までもがAIによって提案されるため、人が考える時間をかける必要はありません。
            </Typography>
            <Typography className={styles["title-content-left"]}>
              {" "}
              国内トップクラスのコンサルティングチームのノウハウを学習したAIが日本全国の全ての宿泊施設のOTA運用を最適化し、旅行を活性化させます。
            </Typography>
            {/* <Box className={styles["box-footer-left"]}>
              <a className={styles["title-footer"]} href="/privacy">
                Pricing
                <Typography className={styles["icon-east"]}>→</Typography>
              </a>
            </Box> */}
          </Box>
          <Box className={`${styles["box-right"]}  ${styles["box-wrap"]}`}>
            <Box className={styles["box-news-release-notes"]}>
              <Typography className={styles["text-left"]}>
                LATEST NEWS
              </Typography>
              <Typography
                to="/release-note"
                component={Link}
                className={styles["text-right"]}
              >
                RELEASE NOTE
              </Typography>
            </Box>
            {notifications?.map((item, index) => {
              return (
                <Box className={styles["box-bottom"]} key={index}>
                  <Link
                    className={styles["detail-link"]}
                    to={"/news/" + item.id}
                  >
                    <Typography
                      className={`${styles["text-content"]} ${styles["long-text"]}`}
                    >
                      {item.title}
                    </Typography>
                    <Typography className={styles["text-content"]}>
                      {convertDate(item.publication_date)}
                    </Typography>
                  </Link>
                </Box>
              );
            })}
            {notifications?.length > 0 && (
              <Typography
                className={styles["text-view"]}
                component={Link}
                to="/news"
                sx={{ textDecoration: "none", float: "right" }}
              >
                View All
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
}
