import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
  Image,
} from "@react-pdf/renderer";
import SourceHanSansJP_Regular from "../../assets/fonts/SourceHanSansJP-Regular.otf";
import SourceHanSansJP_Bold from "../../assets/fonts/SourceHanSansJP-Bold.otf";
import SourceHanSansJP_Medium from "../../assets/fonts/SourceHanSansJP-Medium.otf";
import { UNREGISTER } from "../../constants";
import Logo from "../../assets/images/logo.png";
import { getCellValue } from "../../helper";

Font.register({
  family: "SourceHanSansJP",
  fonts: [
    {
      src: SourceHanSansJP_Regular,
    },
    { src: SourceHanSansJP_Medium, fontWeight: 500 },
    { src: SourceHanSansJP_Bold, fontWeight: 700 },
  ],
});
// Create styles
const styles = StyleSheet.create({
  page: {
    display: "flex",
    flexDirection: "column",
    fontFamily: "SourceHanSansJP",
    padding: "10pt 16pt",
  },
  tableTitle: {},
  table: {
    display: "block",
    width: "100%",
  },
  tableRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    backgroundColor: "#F1F3F9",
  },
  tableCol: {
    color: "#2E4E76",
    fontSize: 8,
    fontWeight: 700,
    padding: "8pt 12pt",
    textAlign: "right",
  },
  tableColHeader: {
    color: "#8898AA",
    textAlign: "right",
  },
  tableColFirst: {
    color: "#172B4D",
    textAlign: "left",
    borderStyle: "solid",
    borderWidth: 2,
    borderColor: "#8F8F9F",
    borderBottomWidth: 0,
    borderTopWidth: 0,
    borderLeftWidth: 0,
  },
  tableColFirstHeader: {
    color: "#3F7EAE",
  },
  tableRowOdd: {
    backgroundColor: "#F7FAFC",
  },
  tableRowSum: {
    backgroundColor: "#E6E6EF",
  },
  textHeader: {
    fontWeight: 500,
    fontSize: 12,
    color: "#3F7EAE",
  },
});

const getStyleRow = (index) => {
  if (index % 2 === 0) {
    return {
      ...styles.tableRow,
      backgroundColor: "#FFFFFF",
    };
  }
  return {
    ...styles.tableRow,
    ...styles.tableRowOdd,
  };
};

const getStyleCol = (index, isHeader, cellValue) => {
  const appendStyle = {};
  if (cellValue === UNREGISTER) {
    appendStyle.color = "#F53C56";
  }

  if (index) {
    if (isHeader) {
      return {
        ...styles.tableCol,
        ...styles.tableColHeader,
        ...appendStyle,
      };
    }
    return {
      ...styles.tableCol,
      ...appendStyle,
    };
  }

  if (isHeader) {
    return {
      ...styles.tableCol,
      ...styles.tableColFirst,
      ...styles.tableColFirstHeader,
      ...appendStyle,
    };
  }

  return {
    ...styles.tableCol,
    ...styles.tableColFirst,
    ...appendStyle,
  };
};

const getCellData = (row, header) => {
  const cellValue = getCellValue(row, header.id);
  if (!cellValue) {
    return "-";
  }
  return cellValue;
};

// Create Document Component
const PlansDocument = ({
  rows = [],
  headers = [],
  totalPdfDetail = [],
  groupName = "",
}) => {
  return (
    <Document>
      <Page
        size="A3"
        wrap={false}
        orientation={"landscape"}
        style={styles.page}
      >
        <View
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "10px",
            width: "100%",
            flexDirection: "row",
          }}
        >
          <View style={{ display: "flex", flexGrow: 1 }}>
            {groupName && (
              <Text
                style={{
                  ...styles.textHeader,
                  fontWeight: 700,
                }}
              >
                {groupName}
              </Text>
            )}
          </View>
          <View
            style={{
              textAlign: "right",
              display: "flex",
              flexGrow: 1,
              justifyContent: "flex-end",
              flexDirection: "row",
            }}
          >
            <Text style={styles.textHeader}>対象期間：2023年7月</Text>
            <Text style={{ ...styles.textHeader, marginLeft: "20px" }}>
              作成日：2023.10.10
            </Text>
          </View>
        </View>
        <View style={styles.table}>
          {/* Header Row */}
          <View style={styles.tableRow}>
            {headers?.map((header, index) => (
              <Text
                style={{
                  ...getStyleCol(index, true, header.title),
                  width: header.width,
                  minWidth: header.width,
                  maxWidth: header.width,
                  ...(header.styles || {}),
                }}
                key={index}
              >
                {header.title}
              </Text>
            ))}
          </View>

          {/* TOTAL ROW */}
          <View style={{ ...styles.tableRow, ...styles.tableRowSum }}>
            {totalPdfDetail?.map((total, index) => (
              <Text
                style={{
                  ...getStyleCol(index, true, total.value),
                  width: total.width,
                  minWidth: total.width,
                  maxWidth: total.width,
                  ...(total.styles || {}),
                }}
                key={index}
              >
                {total.value}
              </Text>
            ))}
          </View>

          {/* Body Rows */}
          {rows?.map((row, rowIndex) => (
            <View style={getStyleRow(rowIndex)} key={rowIndex}>
              {headers?.map((header, index) => (
                <Text
                  key={header.id}
                  style={{
                    ...getStyleCol(index, false, row[header.id]),
                    width: header.width,
                    minWidth: header.width,
                    maxWidth: header.width,
                    ...(header.styles || {}),
                    ...(header.bodyColor ? { color: header.bodyColor } : {}),
                  }}
                >
                  {getCellData(row, header)}
                </Text>
              ))}
            </View>
          ))}
          <View
            style={{
              display: "flex",
              flexDirection: "row-reverse",
              marginTop: "20px",
              width: "100%",
              marginRight: "0",
            }}
            fixed
          >
            <Image src={Logo} style={{ width: "150px" }} />
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default PlansDocument;
