import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import HTTP from "../../http_common";

const initialState = {
  list: [],
  loading: false,
  error: null,
  plan: null,
  listAll: [],
  paginate: {
    next: null,
    previous: null,
    total_pages: 0,
    count: 0,
  },
};

export const getRecommendPlanListAll = createAsyncThunk(
  "recommendPlan/getRecommendPlanListAll",
  async (params) => {
    const response = await HTTP.get(`api/gpt_plans/list-gpt-plans-all/`, {
      params,
    });
    return response;
  }
);


export const getRecommendPlanList = createAsyncThunk(
  "recommendPlan/getRecommendPlanList",
  async (params) => {
    const response = await HTTP.get(`api/gpt_plans/list-gpt-plans/`, {
      params,
    });
    return response;
  }
);

export const getRecommendPlan = createAsyncThunk(
  "recommendPlan/getPlanResult",
  async (id) => {
    const response = await HTTP.post(`api/gpt_plans/fetch-gpt-plan/`, {
      id: id
    });
    return response;
  }
);

export const createRecommendPlan = createAsyncThunk(
  "recommendPlan/createRecommendPlan",
  async ({ body }) => {
    try {
      const response = await HTTP.post(`api/gpt_plans/resister/`, body);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const updateRecommendPlan = createAsyncThunk(
  "recommendPlan/updateRecommendPlan",
  async ({ id, body }) => {
    try {
      const response = await HTTP.put(`api/group_plans/${id}/`, body);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const deleteRecommendPlan = createAsyncThunk(
  "recommendPlan/deleteRecommendPlan",
  async (id) => {
    const response = await HTTP.delete(`api/recommend_plan/${id}/`);
    return response;
  }
);

export const recommendPlanSlice = createSlice({
  name: "recommendPlan",
  initialState,
  reducers: {
    setPlanRequested: (state, { payload }) => {
      state.planRequested = payload;
    },
  },
  extraReducers: {
    [getRecommendPlan.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getRecommendPlan.fulfilled]: (state, { payload }) => {
      state.loading = false;
      payload.ota_sell = payload?.ota_sell?.split(",");
      state.plan = payload[0];
    },
    [getRecommendPlan.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },
    [getRecommendPlanList.fulfilled]: (state, { payload }) => {
      state.list = payload;
      // state.paginate.next = payload.next;
      // state.paginate.previous = payload.previous;
      // state.paginate.count = payload.count;
      // state.paginate.total_pages = payload.results?.total_pages;
    },
    [createRecommendPlan.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [createRecommendPlan.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.plan = payload;
    },
    [createRecommendPlan.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },
    [getRecommendPlanListAll.fulfilled]: (state, { payload }) => {
      state.listAll = payload;
    },
    [getRecommendPlanListAll.pending]: (state, { payload }) => {
      state.loading = true;
      state.error = null;
    },
    [getRecommendPlanListAll.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },
  },
});

export default recommendPlanSlice.reducer;
