/* eslint-disable react-hooks/exhaustive-deps */
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import styles from "./styles.module.scss";
import { TextField, MenuItem } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import {
  getOptionsViewOta,
  createOrUpdateOta,
  getOtaById,
} from "../../features/ota/otasSlice";
import DatePicker from "react-datepicker";
import { Formik, ErrorMessage } from "formik";
import moment from "moment";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { generateMaxTextValidation, TEXT_REQUIRED } from "../../constants/validation"
import { ja } from "date-fns/locale";

export default function FormCreateorUpdateOta(props) {
  const { id } = props;
  const [data, setData] = useState([]);
  const [ota, setOtas] = useState([]);
  const [category, setCategory] = useState([]);
  const history = useHistory();
  const dispatch = useDispatch();

  const fetchData = async () => {
    const res = await dispatch(getOptionsViewOta());
    const otasData = res.payload.data.ota_options.map((item, index) => ({
      id: index + 1,
      name: item[1],
    }));
    setOtas(otasData);
    const categoryData = res.payload.data.category_options.map(
      (item, index) => ({ id: index + 1, name: item[1] })
    );
    setCategory(categoryData);
    if (!data?.ota) {
      setData({
        ...data,
        ota: otasData[0].id,
        category: categoryData[0].id,
      });
    }
  };

  const formikConfig = {
    initialValues: {
      title: data?.title || "",
      ota: data?.ota || "",
      category: data?.category || "",
      participation_deadline: data?.participation_deadline
        ? data?.participation_deadline
        : "",
    },
    onSubmit: async (values, { setSubmitting, setErrors }) => {
      dispatch(
        createOrUpdateOta({
          ...values,
          id: id,
        })
      ).then((res) => {
        setSubmitting(false);
        if (res.payload?.error) {
          setErrors(res.payload?.data);
          toast.error("システムエラー");
        } else {
          return history.push(`/admin/OTA`);
        }
      });
    },
    validationSchema: () => {
      return Yup.object({
        title: Yup.string()
          .max(255, generateMaxTextValidation())
          .required(TEXT_REQUIRED),
        category: Yup.number().integer().required(TEXT_REQUIRED),
        participation_deadline: Yup.string().required(TEXT_REQUIRED),
        ota: Yup.number().integer().required(TEXT_REQUIRED),
      });
    },
  };

  useEffect(() => {
    if (id) {
      const fetchNoticeData = async () => {
        const res = await dispatch(getOtaById(id));
        const noticeData = res.payload;
        setData(noticeData);
      };

      fetchNoticeData();
    }
  }, [id]);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Formik
        enableReinitialize={true}
        validateOnChange={false}
        initialValues={formikConfig.initialValues}
        onSubmit={formikConfig.onSubmit}
        validationSchema={formikConfig.validationSchema}
      >
        {({
          handleChange,
          handleBlur,
          values,
          errors,
          handleSubmit,
          setFieldValue,
        }) => (
          <Container className={styles["home-center"]} maxWidth="xl">
            <Box className={styles["box-header"]}>
              <Typography className={styles["text-header-home"]}>
                {id ? "OTA施策編集" : "OTA施策登録"}
              </Typography>
              <Box
                onClick={() => handleSubmit()}
                className={styles["text-create"]}
              >
                保存する
              </Box>
            </Box>

            <Grid className={styles["box-grid"]} container spacing={2}>
              <Grid className={styles["text-left"]} item xs={3}>
                OTA
              </Grid>
              <Grid className={styles["grid-item"]} item xs={9}>
                <Select
                  className={styles["text-select"]}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  name="ota"
                  onChange={handleChange}
                  value={values.ota}
                >
                  {ota?.map((item) => {
                    return (
                      <MenuItem
                        key={item.id}
                        className={styles["tag"]}
                        value={item.id}
                      >
                        {item.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Grid>
            </Grid>

            <Grid className={styles["box-grid"]} container spacing={2}>
              <Grid className={styles["text-left"]} item xs={3}>
                タイトル
              </Grid>
              <Grid className={styles["grid-item"]} item xs={9}>
                <TextField
                  name="title"
                  value={values.title}
                  onChange={handleChange}
                  onBlur={handleBlur("title")}
                  className={styles["text-field"]}
                  id="filled-basic"
                  helperText={errors.title}
                  error={!!errors.title}
                />
              </Grid>
            </Grid>

            <Grid className={styles["box-grid"]} container spacing={2}>
              <Grid className={styles["text-left"]} item xs={3}>
                カテゴリ
              </Grid>
              <Grid className={styles["grid-item"]} item xs={9}>
                <Select
                  className={styles["text-select"]}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  name="category"
                  onChange={handleChange}
                  value={values.category}
                >
                  {category?.map((item) => {
                    return (
                      <MenuItem
                        key={item.id}
                        className={styles["tag"]}
                        value={item.id}
                      >
                        {item.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Grid>
            </Grid>

            <Grid className={styles["box-grid"]} container spacing={2}>
              <Grid className={styles["text-left"]} item xs={3}>
                参画期限
              </Grid>
              <Grid className={styles["grid-item"]} item xs={9}>
                <DatePicker
                  locale={ja}
                  value={
                    values.participation_deadline
                      ? moment(values.participation_deadline).format(
                          "YYYY年MM月DD日"
                        )
                      : ""
                  }
                  calendarClassName="custom-datepicker"
                  onBlur={handleBlur("participation_deadline")}
                  onChange={(date) => {
                    if (date) {
                      const formattedDate = moment(date).format("YYYY-MM-DD");
                      setFieldValue("participation_deadline", formattedDate);
                    } else {
                      setFieldValue("participation_deadline", null);
                    }
                  }}
                  className={`${styles["box-date"]} ${
                    errors?.participation_deadline ? styles["errors-date"] : ""
                  }`}
                />
                <ErrorMessage
                  className={styles["error-date"]}
                  name="participation_deadline"
                  component="div"
                />
              </Grid>
            </Grid>
          </Container>
        )}
      </Formik>
    </>
  );
}
