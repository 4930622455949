import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import HTTP from "../http_common";

export const registerNewHotel = createAsyncThunk(
  "active/registerNewHotel",
  async (body) => {
    const formData = new FormData();
    formData.append("token", body.token);
    formData.append("last_name", body.last_name);
    formData.append("first_name", body.first_name);
    formData.append("name", body.name);
    formData.append("in_site_id", body.in_site_id);
    formData.append("address", body.address);
    formData.append("email_registration", body.email_registration);
    const response = await HTTP.post(`/api/hotels/`, formData);
    return response;
  }
);

const apiSlice = createSlice({
  name: 'create-new-hotel',
  initialState: {
    data: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(registerNewHotel.pending, (state) => {
        state.loading = true;
      })
      .addCase(registerNewHotel.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(registerNewHotel.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;

      });
  },
});

export default apiSlice.reducer;
