/* eslint-disable jsx-a11y/anchor-is-valid */
import { Box, Typography } from "@mui/material";
import styles from "./styles.module.scss";
import React from "react";

export default function Privacy() {
  return (
    <>
      <Box className={styles["box-main-content"]}>
        <Box className={styles["box-title"]}>
          <Box className={styles["box-date"]}>
            <Typography className={styles["title-header"]}>
              プライバシーポリシー
            </Typography>
          </Box>
        </Box>
        <Box className={styles["box-news"]}>
          <Box className={styles["box-date"]}>
            <Typography className={styles["title-date"]}>
              2023.01.01 更新
            </Typography>
          </Box>
          <Box className={styles["box"]}>
            <Typography className={styles["title-content"]}>
              利用規約の本文が入ります。利用規約の本文が入ります。利用規約の本文が入ります。利用規約の本文が入ります。利用規約の本文が入ります。利用規約の本文が入ります。利用規約の本文が入ります。利用規約の本文が入ります。利用規約の本文が入ります。利用規約の本文が入ります。利用規約の本文が入ります。利用規約の本文が入ります。利用規約の本文が入ります。利用規約の本文が入ります。利用規約の本文が入ります。利用規約の本文が入ります。利用規約の本文が入ります。利用規約の本文が入ります。利用規約の本文が入ります。利用規約の本文が入ります。利用規約の本文が入ります。利用規約の本文が入ります。利用規約の本文が入ります。利用規約の本文が入ります。利用規約の本文が入ります。利用規約の本文が入ります。利用規約の本文が入ります。利用規約の本文が入ります。利用規約の本文が入ります。利用規約の本文が入ります。利用規約の本文が入ります。利用規約の本文が入ります。利用規約の本文が入ります。利用規約の本文が入ります。利用規約の本文が入ります。利用規約の本文が入ります。
            </Typography>
            <Typography className={styles["title-box-header"]}>
              H2タイトル
            </Typography>
            <Typography className={styles["title-content"]}>
              利用規約の本文が入ります。利用規約の本文が入ります。利用規約の本文が入ります。利用規約の本文が入ります。利用規約の本文が入ります。利用規約の本文が入ります。利用規約の本文が入ります。利用規約の本文が入ります。利用規約の本文が入ります。利用規約の本文が入ります。利用規約の本文が入ります。利用規約の本文が入ります。利用規約の本文が入ります。利用規約の本文が入ります。利用規約の本文が入ります。利用規約の本文が入ります。利用規約の本文が入ります。利用規約の本文が入ります。利用規約の本文が入ります。利用規約の本文が入ります。利用規約の本文が入ります。利用規約の本文が入ります。利用規約の本文が入ります。利用規約の本文が入ります。利用規約の本文が入ります。利用規約の本文が入ります。利用規約の本文が入ります。利用規約の本文が入ります。利用規約の本文が入ります。利用規約の本文が入ります。利用規約の本文が入ります。利用規約の本文が入ります。利用規約の本文が入ります。利用規約の本文が入ります。利用規約の本文が入ります。利用規約の本文が入ります。
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
}
