import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import HTTP from '../../http_common';

const initialState = {
  list: [],
  loading: false,
  error: null,
}

export const getListUser = createAsyncThunk('users/getListUser', async (body) => {
  const response = await HTTP.get(`/api/list-users/`, {
    params: { ...body },
  });
  return response;
});

export const updateProfile = createAsyncThunk('users/updateProfile', async (body) => {
  const formData = new FormData();
  if (body.avatar) {
    formData.append('avatar', body.avatar);
  }
  formData.append('id', body.id);
  formData.append('first_name', body.first_name);
  formData.append('last_name', body.last_name);
  formData.append('email', body.email);
  if (body.password) {
    formData.append('password', body.password);
  }
  const response = await HTTP.put(`/api/update-profile`,formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response;
});

export const updateNotification = createAsyncThunk('users/updateNotification', async (body) => {

  return await HTTP.put(`/api/users/update-notification-settings/`,
    body
    , {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
});

export const deleteProfile = createAsyncThunk('users/deleteProfile', async () => {
  const response = await HTTP.post(`/api/delete-profile`);
  return response;
});

export const updatePartnerId = createAsyncThunk('users/updatePartnerId', async (body) => {
  const response = await HTTP.put(`/api/users/update_partner_id/`,
    { rakuten_id: body.rakuten_id ?? null,
      jaran_id: body.jaran_id ?? null,
      ikkyu_id: body.ikkyu_id ?? null }
    , {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  return response;
});

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
  },
  extraReducers: {
    [getListUser.pending]: (state) => {
      state.loading = true
    },
    [getListUser.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.list = payload;
    },
    [getListUser.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },
    [updateProfile.pending]: (state) => {
      state.loading = true
    },
    [updateProfile.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.list = payload;
    },
    [updateProfile.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },
    [deleteProfile.pending]: (state) => {
      state.loading = true
    },
    [deleteProfile.fulfilled]: (state, { payload }) => {
      state.loading = false;
    },
    [deleteProfile.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },
    [updatePartnerId.pending]: (state) => {
      state.loading = true
    },
    [updatePartnerId.fulfilled]: (state, { payload }) => {
      state.loading = false;
    },
    [updatePartnerId.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },
  }
})

// Action creators are generated for each case reducer function
// export const { setUsers } = usersSlice.actions

export default usersSlice.reducer
